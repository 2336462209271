import {Component} from '@angular/core';
import {environment} from '@app/environment';
import {AbstractShowComponent} from '@app/main';
import {
	ContractModel,
	ContractService,
} from '@contracts/frontend-api';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
	templateUrl: './contracts-page-show.component.html',
	styleUrls:   ['./contracts-page-show.component.scss'],
})
export class ContractsPageShowComponent extends AbstractShowComponent<ContractModel, ContractService> {
	readonly environment = environment;
	canCreateContracts   = false;

	constructor(
		contractService: ContractService,
	) {
		super(contractService);
	}

	get hasNoChildren$(): Observable<boolean | undefined> {
		return this.getModel()
				   .contractSections
				   .value
				   .pipe(map(sections => (sections == null) ? sections : (sections.length < 1)));
	}

	protected async onNewModel(newModel: ContractModel, oldModel?: ContractModel): Promise<void> {
		await super.onNewModel(newModel, oldModel);
		newModel.contractSections.permissions.canCreate
				.then(permitted => this.canCreateContracts = permitted);
	}

	protected async getModelName(): Promise<string | undefined> {
		return this.getModel().name.firstValue;
	}

	protected async postDeleteRedirect(): Promise<void> {
		const parent = await this.model?.masterContract.firstValue;
		return AbstractShowComponent.redirectToPageOrList(this.router, environment.masterContractsFullUrl, parent?.id);
	}
}
