import {DatePipe} from '@angular/common';
import {
	Component,
	Input,
} from '@angular/core';

@Component({
	selector:    'portal-show-field-date-intervall',
	templateUrl: './show-field-date-intervall.component.html',
	styleUrls:   ['./show-field-date-intervall.component.scss'],
})
export class ShowFieldDateIntervallComponent {
	@Input({required: true}) label!: string;
	@Input({required: true}) startDate: Date | undefined | null;
	@Input({required: true}) endDate: Date | undefined | null;

	constructor(
		protected readonly datePipe: DatePipe,
	) {
	}

	get value(): undefined | string | null {
		if(this.startDate == null)
			return this.startDate;

		if(this.endDate == null)
			return this.datePipe.transform(this.startDate);

		return `${this.datePipe.transform(this.startDate)} - ${this.datePipe.transform(this.endDate)}`;
	}

}
