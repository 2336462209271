import {Component} from '@angular/core';
import {environment} from '@app/environment';
import {AbstractShowComponent} from '@app/main';
import {
	NewsDidReadModel,
	NewsDidReadService,
	NewsModel,
	NewsService,
} from '@contracts/frontend-api';

@Component({
	selector:    'portal-news-show-page',
	templateUrl: './news-show-page.component.html',
	styleUrls:   ['./news-show-page.component.scss'],
})
export class NewsShowPageComponent extends AbstractShowComponent<NewsModel, NewsService> {
	protected readonly environment = environment;
	protected newsDidRead?: NewsDidReadModel;

	constructor(
		newsService: NewsService,
		private readonly newsDidReadService: NewsDidReadService,
	) {
		super(newsService);
	}

	get isMarkedAsRead(): boolean {
		return this.newsDidRead != null;
	}

	async markAsRead(): Promise<void> {
		if(this.newsDidRead != null)
			return;

		this.newsDidRead = await this.newsDidReadService.create({
			newsId: this.getModel().id,
		});
	}

	async markAsUnread(): Promise<void> {
		if(this.newsDidRead == null)
			return;

		await this.newsDidReadService.delete(this.newsDidRead);
		this.newsDidRead = undefined;
	}

	protected getModelName(): Promise<string | undefined> {
		return this.getModel().title.firstValue;
	}

	protected async onNewModel(newModel: NewsModel, oldModel?: NewsModel): Promise<void> {
		await super.onNewModel(newModel, oldModel);

		const find = await this.newsDidReadService.find({
			column:     'news_id',
			value:      newModel.id,
			comparator: '=',
		});

		if(find.data.length < 1)
			this.newsDidRead = undefined;

		this.newsDidRead = find.data[0];
	}
}
