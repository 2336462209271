<portal-headline
	label="general.modelList"
	[labelArgs]="{model: 'medicalStores'}"
	[menu]="menu"
>
	<mat-menu #menu>
		@if (canCreate) {
			<a
				mat-menu-item
				[routerLink]="[environment.medicalStoresFullUrl,'create']"
			>
				<fa-icon matMenuItemIcon [icon]="iconService.CREATE"></fa-icon>
				<span>{{ 'medicalStore.createMainHouse' | translate }}</span>
			</a>
		}
	</mat-menu>
</portal-headline>

<mat-card>
	<mat-card-content>
		<portal-medical-store-list/>
	</mat-card-content>
</mat-card>
