import {Component} from '@angular/core';
import {environment} from '@app/environment';
import {IconService} from '@app/main';
import {
	RawContentModel,
	RawContentService,
} from '@contracts/frontend-api';
import {CategoryItemInterface} from '../../components/file/files-card/model-files-card.component';

@Component({
	selector:    'portal-download-section-page',
	templateUrl: './download-section-page.component.html',
	styleUrls:   ['./download-section-page.component.scss'],
})
export class DownloadSectionPageComponent {
	readonly publishedCategories: CategoryItemInterface[] = [
		{name: 'generalInformation'},
		{name: 'faqAndStatements'},
		{name: 'fixedAmounts'},
		{name: 'sectionPg05'},
		{name: 'sectionPg08'},
		{name: 'sectionPg09_23'},
		{name: 'sectionPg17'},
		{name: 'sectionPg23'},
		{name: 'sectionPg24'},
		{name: 'sectionPg37'},
		{name: 'sectionPg38'},
		{name: 'contractLessState'},
	];
	relationModel: RawContentModel;

	constructor(
			private readonly rawContentService: RawContentService,
			public iconService: IconService,
	) {
		this.relationModel = this.rawContentService.getById(environment.downloadRawContentId);
	}
}
