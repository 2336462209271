import {DtoCreationFormHelper} from '@angular-helpers/frontend-api';
import {HttpErrorResponse} from '@angular/common/http';
import {
	Component,
	QueryList,
	TemplateRef,
	ViewChildren,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
	UntypedFormControl,
	UntypedFormGroup,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {
	ActivatedRoute,
	Router,
} from '@angular/router';
import {EmployeeEditRolesComponent} from '@app/contracts';
import {environment} from '@app/environment';
import {DialogService} from '@app/main';
import {
	ConfirmDialogAnswer,
	ConfirmDialogComponent,
	IconService,
} from '@app/main';
import {
	EmployeeDtoModel,
	EmployeeModel,
	EmployeeService,
	MedicalStoreModel,
	MedicalStoreService,
} from '@contracts/frontend-api';
import {
	map,
	mergeMap,
	take,
} from 'rxjs/operators';

@Component({
	selector:    'portal-employee-create-form',
	templateUrl: './employee-create-form.component.html',
	styleUrls:   ['./employee-create-form.component.scss'],
})
export class EmployeeCreateFormComponent {
	@ViewChildren('page') pages!: QueryList<TemplateRef<unknown>>;

	public control?: UntypedFormGroup;

	public rolesForm = new UntypedFormGroup({ // TODO this is a workaround https://git.biv.to/contracts/frontend-api/-/issues/4
		roles: new UntypedFormControl([]),
	});
	public cancelDialogRef?: MatDialogRef<ConfirmDialogComponent, ConfirmDialogAnswer>;
	private _medicalStore?: MedicalStoreModel;
	private helper?: DtoCreationFormHelper<EmployeeModel, EmployeeService>;
	public errorHasOccurred?: Error;

	constructor(
		private readonly employeeService: EmployeeService,
		private readonly medicalStoreService: MedicalStoreService,
		private readonly activatedRoute: ActivatedRoute,
		private readonly router: Router,
		private readonly iconService: IconService,
		private readonly dialogService: DialogService,
	) {
		this.activatedRoute.params.pipe(
				takeUntilDestroyed(),
				mergeMap((parameters) => {
					if(parameters.id == null)
						throw new Error('no parameters detected');

					this.medicalStore = this.medicalStoreService.getById(parameters.id);
					this.helper       = DtoCreationFormHelper.create(EmployeeDtoModel, this.employeeService, {
						medicalStore: this.medicalStore,
					});

					return this.helper.control;
				}),
				map((control) => this.control = control),
		).subscribe();
	}

	set medicalStore(ms: MedicalStoreModel | undefined) {
		this._medicalStore = ms;

		if(this.helper == null)
			return;

		this.helper.fill({
			medicalStore: this.medicalStore,
		});
	}

	get medicalStore(): MedicalStoreModel | undefined {
		return this._medicalStore;
	}

	public async saveForm(): Promise<void> {
		if(this.helper == null)
			return;

		try {
			const savedEmployee = await this.helper.save();
			if(savedEmployee == null)
				return;

			await EmployeeEditRolesComponent.saveRoles(savedEmployee, this.rolesForm, this.employeeService);
			await this.router.navigate([
				environment.employeeFullUrl,
				savedEmployee.id,
			]);
		} catch(error) {
			if(error instanceof Error || error instanceof HttpErrorResponse || error === undefined)
				this.errorHasOccurred = error;
			else
				this.errorHasOccurred = new Error(`${error}`);
			throw error;
		}
	}

	async backToMedicalStore(): Promise<boolean> {
		return this.router.navigate([
			environment.medicalStoresFullUrl,
			this.medicalStore?.id,
		]);
	}

	async onAbort(): Promise<unknown> {
		if(!this.control)
			throw new Error('no Form detected');

		if(!this.control.dirty && !this.rolesForm.dirty)
			return this.backToMedicalStore();

		this.cancelDialogRef    = await this.openAbortDialog();
		const afterClosedAnswer = await this.cancelDialogRef.afterClosed().pipe(take(1)).toPromise();
		if(afterClosedAnswer == null)
			return;

		if(afterClosedAnswer === ConfirmDialogAnswer.negative)
			return this.backToMedicalStore();

		return;
	}

	async openAbortDialog(): Promise<MatDialogRef<ConfirmDialogComponent>> {

		return this.dialogService.openConfirmDialog({
				labelNegative: 'system.abortChangesDialog.labelNegative',
				labelPositiv:  'system.abortChangesDialog.labelPositiv',
				title:         'system.abortChangesDialog.title',
				message:       'system.abortChangesDialog.message',
				icon:          this.iconService.DIALOG_ATTENTION,
			});
	}
}
