import {HttpErrorResponse} from '@angular/common/http';
import {
	Component,
	DestroyRef,
	Directive,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	QueryList,
	SimpleChanges,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {UntypedFormGroup} from '@angular/forms';
import {
	MatDialog,
	MatDialogRef,
} from '@angular/material/dialog';
import {MatStepper} from '@angular/material/stepper';
import {Router} from '@angular/router';
import {
	Observable,
	timer,
} from 'rxjs';
import {
	VersionErrorDialogComponent,
	VersionErrorDialogData,
} from 'src/app/contracts/components/version-error-dialog/version-error-dialog.component';

export enum FieldErrorType {
	email = 'email',
}

@Directive({
	selector: '[error-field]',
})
export class ErrorFieldDirective {
	@Input() fieldName = 'email';
	@Input() stepper?: MatStepper;
	@Input() stepperIndex?: number;

	constructor(
		private readonly elementRef: ElementRef,
	) {

	}

	public focus(): void {
		if(this.stepper != null && this.stepperIndex != null)
			this.stepper.selectedIndex = this.stepperIndex;

		this.elementRef.nativeElement.focus();
	}

}


@Component({
	selector:    'portal-form-error',
	templateUrl: './form-error.component.html',
	styleUrls:   ['./form-error.component.scss'],
})
export class FormErrorComponent implements OnChanges {
	@Input({required: true}) error?: Error | HttpErrorResponse;
	@Input() duration            = 7_000;
	@Input() control?: UntypedFormGroup;
	@Input() errorFields?: QueryList<ErrorFieldDirective>;
	@Output() readonly retrySave = new EventEmitter<boolean>();
	public versionDialog?: MatDialogRef<VersionErrorDialogComponent, boolean>;
	selfRemoveTimer$?: Observable<unknown>;
	protected hide               = true;

	constructor(
		protected router: Router,
		protected dialog: MatDialog,
		protected readonly destroyRef: DestroyRef,
	) {
	}

	ngOnChanges(changes: SimpleChanges): void {
		if('error' in changes && changes.error.currentValue != null) {
			if(this.error instanceof HttpErrorResponse && this.isVersionError(this.error)) {
				this.openDialog();
				return;
			}

			if(this.error instanceof HttpErrorResponse && this.isEmailError(this.error)) {
				const email = this.control?.get('email');
				email?.setErrors({
					email: true,
				});
				this.getField(FieldErrorType.email)?.focus();
			}

			this.hide             = false;
			this.selfRemoveTimer$ = timer(this.duration).pipe(
				takeUntilDestroyed(this.destroyRef),
			);
			this.selfRemoveTimer$.subscribe(() => {
				this.hide = true;
			});
		}
	}

	isVersionError(error: Error | null | undefined): boolean {
		if(!(error instanceof HttpErrorResponse))
			return false;


		return !(this.getVersion(error) == null);
	}

	isEmailError(error: Error | null | undefined): boolean {
		if(!(error instanceof HttpErrorResponse))
			return false;

		if(error.error == null)
			throw new Error('cannot verify error type');

		const message = error.error.message;
		if(!(typeof message === 'string'))
			return false;

		if(message.startsWith('The \'email\' must be a valid email address'))
			return true;

		return false;
	}

	getField(name: string): ErrorFieldDirective | null {
		if(this.errorFields == null)
			throw null;

		const field = this.errorFields.find(field => field.fieldName === name);
		if(field == null)
			throw null;

		return field;
	}

	openDialog(): void {
		const error = this.error;
		if(error == null || !(error instanceof HttpErrorResponse))
			return;

		const version = this.getVersion(error);
		if(version == null)
			return;

		const data: VersionErrorDialogData = {
			error,
			control: this.control,
			version,
		};

		this.versionDialog = this.dialog.open(VersionErrorDialogComponent, {
			maxWidth:          'max-width',
			disableClose:      true,
			closeOnNavigation: false,
			data,
		});

		this.versionDialog.afterClosed()
		    .pipe(takeUntilDestroyed(this.destroyRef))
		    .subscribe((response) => this.retrySave.emit(response));
	}

	private getVersion(error: HttpErrorResponse | undefined): number | null {
		if(error == null)
			return null;

		if(error.error == null || !(typeof error.error.message === 'string'))
			return null;

		const versionReg = error.error.message.match(/newest Version: '(\d+)'/);
		if((Boolean(versionReg)) && versionReg.length > 0) {
			const match = versionReg[1];
			return Number.parseInt(match, 10);
		}

		return null;
	}
}
