@if (model) {
	@if (hasLink) {
		<portal-show-field-url
			[label]="label"
			[urlType]="URL_TYPE.internal"
			[url]="ENVIRONMENT.medicalStoresFullUrl +'/'+ model.id"
			[value]="model.name.value | async">
		</portal-show-field-url>
	} @else {
		<portal-show-field
			[label]="label"
			[value]="model.name.value | async">
		</portal-show-field>
	}
}
