import {DtoCreationFormHelper} from '@angular-helpers/frontend-api';
import {HttpErrorResponse} from '@angular/common/http';
import {
	Component,
	Input as RouteInput,
	OnDestroy,
	OnInit,
	QueryList,
	ViewChildren,
} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {environment} from '@app/environment';
import {ErrorFieldDirective} from '@app/main';
import {
	MedicalStoreDtoModel,
	MedicalStoreModel,
	MedicalStoreService,
} from '@contracts/frontend-api';
import {NGXLogger} from 'ngx-logger';
import {Subject} from 'rxjs';

@Component({
	selector:    'portal-medical-store-page-create',
	templateUrl: './medical-store-page-create.component.html',
	styleUrls:   ['./medical-store-page-create.component.scss'],
})
export class MedicalStorePageCreateComponent implements OnInit, OnDestroy {
	formHelper!: DtoCreationFormHelper<MedicalStoreModel, MedicalStoreService>;
	isSaving   = false;
	control?: UntypedFormGroup;
	masterDataControl?: UntypedFormGroup;
	contactControl?: UntypedFormGroup;
	contractControl?: UntypedFormGroup;
	destroyed$ = new Subject<void>();
	parent?: MedicalStoreModel;
	public errorHasOccurred?: Error;
	@ViewChildren(ErrorFieldDirective) errorFields?: QueryList<ErrorFieldDirective>

	constructor(
		private readonly medicalStoreService: MedicalStoreService,
		private readonly router: Router,
		private readonly logger: NGXLogger,
	) { }

	ngOnInit(): void {
		this.buildForm();
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

	async create(): Promise<void> {
		this.isSaving = true;
		this.errorHasOccurred = undefined;
		try {
			const medicalStore = await this.formHelper.save();
			if(!medicalStore)
				return;

			await this.router.navigate([
				environment.medicalStoresFullUrl,
				medicalStore.id,
			]);
		} catch(error) {
			if(error instanceof Error || error instanceof HttpErrorResponse || error === undefined)
				this.errorHasOccurred = error;
			else
				this.errorHasOccurred = new Error(`${error}`);
			throw error;
		} finally {
			this.isSaving = false;
		}
	}

	private buildForm(): void {
		this.formHelper = DtoCreationFormHelper.create(
			MedicalStoreDtoModel,
			this.medicalStoreService,
			this.buildFormValue(),
		);
		this.formHelper.control.then(control => {
			this.control            = control;
			const masterDataControl = new UntypedFormGroup({});
			const contactControl    = new UntypedFormGroup({});
			const contractControl   = new UntypedFormGroup({});

			const unmappedControls: string[] = [];

			Object.keys(this.control.controls).forEach(ctrlName => {
				switch(ctrlName) {
					case 'name':
					case 'legalForm':
					case 'address':
					case 'landesinnung':
					case 'professionalAssociation':
						masterDataControl.addControl(ctrlName, control.controls[ctrlName]);
						break;

					case 'ik':
						contractControl.addControl(ctrlName, control.controls[ctrlName]);
						break;

					case 'email':
					case 'phone':
					case 'fax':
					case 'website':
						contactControl.addControl(ctrlName, control.controls[ctrlName]);
						break;

					case 'bivNumber':
					case 'doesMip':
					case 'parent':
					case 'proprioceptiveDeposits':
					case 'psa':
					case 'publishable':
					case 'supplyArea':
					case 'certificateIso9001StartAt':
					case 'certificateIso9001EndAt':
					case 'certificateIso13485StartAt':
					case 'certificateIso13485EndAt':
					case 'users':
					case 'iqzCategories':
					case 'hmmParticipation':
						// these fields aren't editable on creation
						break;

					default:
						unmappedControls.push(ctrlName);
				}
			});

			if(unmappedControls.length > 0)
				this.logger.warn(`Unmapped inputs`, unmappedControls);

			this.masterDataControl = masterDataControl;
			this.contactControl    = contactControl;
			this.contractControl   = contractControl;
		});
	}

	private buildFormValue(): { parent: MedicalStoreModel | undefined } {
		return {
			parent: this.parent,
		};
	}

	@RouteInput()
	set id(id: unknown) {
		if(typeof id !== 'string') {
			this.parent = undefined;
			return;
		}

		this.parent = this.medicalStoreService.getById(id);
		if(this.control)
			this.formHelper.fill(this.buildFormValue());
	}
}
