import {PropertyInterface} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
} from '@angular/core';

interface NameInterface {
	salutation?: PropertyInterface<string | undefined>;
	name: PropertyInterface<string | undefined>;
}

@Component({
	selector:    'portal-common-show-name',
	templateUrl: './common-show-name.component.html',
	styleUrls:   ['./common-show-name.component.scss'],
})
export class CommonShowNameComponent<T extends NameInterface> {
	@Input({required: true}) model!: T;
}
