import {DtoEditFormHelper} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
} from '@angular/core';
import {
	IconService,
	ModelHelper,
} from '@app/main';
import {
	LandesinnungDtoModel,
	LandesinnungModel,
	LandesinnungService,
} from '@contracts/frontend-api';

@Component({
	selector:    'portal-landesinnung-card-master-data',
	templateUrl: './landesinnung-card-master-data.component.html',
	styleUrls:   ['./landesinnung-card-master-data.component.scss'],
})
export class LandesinnungCardMasterDataComponent {
	formHelper!: DtoEditFormHelper<LandesinnungModel, LandesinnungService>;
	private _landesinnung!: LandesinnungModel;
	public canUpdate?: Promise<boolean>;

	constructor(
		protected landesinnungService: LandesinnungService,
		public iconService: IconService,
	) {
	}

	get landesinnung(): LandesinnungModel {
		return this._landesinnung;
	}

	@Input({required: true}) set landesinnung(value: LandesinnungModel) {
		if(this._landesinnung === value)
			return;

		this._landesinnung = value;
		this.formHelper    = DtoEditFormHelper.create(
				LandesinnungDtoModel,
				this.landesinnung,
				this.landesinnungService,
		);

		this.canUpdate = ModelHelper.findEditableProperty([
			this.landesinnung.name,
			this.landesinnung.phone,
			this.landesinnung.fax,
			this.landesinnung.email,
			this.landesinnung.website,
		]);
	}
}
