<div class="fixedWidth">
	<portal-headline label="page.downloads.header"/>
	<mat-card>
		<mat-card-content>
			<div class="primary-information">
				<p>Nachstehend stellen wir Ihnen allgemeine, aber auch Anlass- und produktgruppenspezifische Informationen zum Download zur Verfügung. Bitte beachten Sie, dass insbesondere die allgemeinen Übersichten laufend aktualisiert werden. Um sicherzustellen, dass Sie mit der neuesten Version arbeiten, prüfen Sie bitte in regelmäßigen Abständen, ob eine neue Version zum Download bereitsteht.</p>
			</div>
			@if (relationModel) {
				<portal-model-files-card [relationModel]="relationModel" [categories]="publishedCategories" [hasHeader]="false"/>
			}
		</mat-card-content>
	</mat-card>
</div>
