import {
	Component,
	OnInit,
} from '@angular/core';
import {environment} from '@app/environment';
import {
	IconService,
	MinimalColumns,
} from '@app/main';
import {
	MedicalStoreService,
	MedicalStoreUserModel,
	MedicalStoreUserService,
} from '@contracts/frontend-api';

@Component({
	selector:    'portal-medical-store-users-page',
	templateUrl: './medical-store-users-list-page.component.html',
	styleUrls:   ['./medical-store-users-list-page.component.scss'],
})
export class MedicalStoreUsersListPageComponent implements OnInit {
	protected readonly environment                                    = environment;
	protected canCreate?: boolean;
	protected userTableHeaders: MinimalColumns<MedicalStoreUserModel> = {
		name:  {
			label: 'medicalStore.name',
			index: 0,
		},
		email: {
			label: 'common.email',
			index: 1,
		},
	};

	constructor(
		public readonly medicalStoreService: MedicalStoreService,
		public readonly medicalStoreUserService: MedicalStoreUserService,
		protected readonly iconService: IconService,
	) {
	}

	ngOnInit(): void {
		this.loadPermissions();
	}

	private async loadPermissions(): Promise<void> {
		let page = await this.medicalStoreService.getPage(0);

		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		while(true) {
			for(const medicalStore of page.data) {
				if(await medicalStore.users.permissions.canUpdate) {
					this.canCreate = true;
					return;
				}
			}
			if(!page.hasNextPage())
				break;

			page = await page.loadNextPage();
		}
	}
}
