<portal-editable
	[validator]="control"
	label="employee.roles"
>
	<mat-form-field>
		<portal-input-status-icon matSuffix [validator]="control"/>
		<mat-chip-grid #chipList>
			@for (role of currentRoles;track role) {
				<mat-chip-row (removed)="removeRole(role)">{{ role.name.value | async }}
					@if (control.enabled) {
						<button matChipRemove>
							<fa-icon [icon]="iconService.REMOVE"></fa-icon>
						</button>
					}
				</mat-chip-row>
			}
			<input
				#input
				[(ngModel)]="searchValue" [disabled]="control.disabled"
				[matAutocomplete]="auto"
				[matChipInputFor]="chipList"
				matInput/>

			@for (error of formHelperService.getErrors(control) | keyvalue;track error) {
				<mat-error>
					{{ 'input.error.' + error.key | translate: error.value }}
				</mat-error>
			}
		</mat-chip-grid>
		<mat-autocomplete #auto="matAutocomplete" (optionSelected)="roleSelected($event.option.value)">
			@for (role of possibleRoles$ | async;track role) {
				<mat-option [value]="role">{{ role.name.value | async }}</mat-option>
			}
		</mat-autocomplete>
	</mat-form-field>
</portal-editable>
