<portal-model-editable-box
	[icon]="iconService.PRE_QUALIFICATION_CERTIFICATE"
	headline="general.masterData"
	[formHelper]="null"
	[hasEditButton]="false"
>
	@if (preQualificationCertificate) {
		<ng-template #showModeContent>
			<portal-pre-qualification-certificate-show-accreditation-body [model]="preQualificationCertificate"/>
			<portal-pre-qualification-certificate-show-certificate-identifier [model]="preQualificationCertificate"/>
			<portal-show-file [file]="preQualificationCertificate.file.value | async"/>
		</ng-template>
	}

</portal-model-editable-box>
