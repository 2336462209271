<portal-headline
	label="general.modelList"
	[labelArgs]="{model: 'landesinnungs'}"
/>

<mat-card>
	<mat-card-content>
		<portal-landesinnung-list [data]="landesinnungsPage | async"/>
	</mat-card-content>
</mat-card>
