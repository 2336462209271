import {Level8Error} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
	OnInit,
} from '@angular/core';
import {
	FormControl,
	FormGroup,
} from '@angular/forms';
import {SelectFieldEntry} from '@app/main';
import {IqzCategoryService} from '@contracts/frontend-api';

@Component({
	selector:    'portal-iqz-training-course-edit-category[control]',
	templateUrl: './iqz-training-course-edit-category.component.html',
	styleUrls:   ['./iqz-training-course-edit-category.component.scss'],
})
export class IqzTrainingCourseEditCategoryComponent implements OnInit {
	@Input('control') parent!: FormGroup;
	possibleValues?: SelectFieldEntry[];

	constructor(
		private readonly iqzCategoryService: IqzCategoryService,
	) {
	}

	get control(): FormControl {
		const fieldName = 'category';
		const control   = this.parent.get(fieldName);
		if(control instanceof FormControl)
			return control;

		throw new Level8Error(`Unexpected type for field ${fieldName} - expected '${FormControl.name}' got '${typeof control}' (${control})`);
	}

	ngOnInit(): void {
		this.loadPossibleValues().then(possibleValues => this.possibleValues = possibleValues);
	}

	protected async loadPossibleValues(): Promise<SelectFieldEntry[]> {
		const possibleValues: SelectFieldEntry[] = [];

		const models = await this.iqzCategoryService.getAllModels();
		for(const model of models) {
			const name = await model.name.firstValue;
			if(name == null)
				continue;

			possibleValues.push({
				label: name,
				value: model,
			});
		}

		return possibleValues;
	}
}
