import {DtoEditFormHelper} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
} from '@angular/core';
import {
	IconService,
	ModelHelper,
} from '@app/main';
import {
	ContractDtoModel,
	ContractModel,
	ContractService,
} from '@contracts/frontend-api';

@Component({
	selector:    'portal-contract-card-master-data',
	templateUrl: './contract-card-master-data.component.html',
	styleUrls:   ['./contract-card-master-data.component.scss'],
})
export class ContractCardMasterDataComponent {
	formHelper!: DtoEditFormHelper<ContractModel, ContractService>;

	private _contract!: ContractModel;
	public canUpdate?: Promise<boolean>;

	constructor(
		protected contractService: ContractService,
		public iconService: IconService,
	) {
	}

	get contract(): ContractModel {
		return this._contract;
	}

	@Input({required: true}) set contract(value: ContractModel) {
		if(this._contract === value)
			return;

		this._contract  = value;
		this.formHelper = DtoEditFormHelper.create(
				ContractDtoModel,
				this.contract,
				this.contractService,
		);

		this.canUpdate = ModelHelper.findEditableProperty([
			this.contract.name,
			this.contract.validityStartAt,
			this.contract.terminatedAt,
			this.contract.minimumTermAt,
			this.contract.periodOfValidityAt,
			this.contract.noticePeriodDelay,
			this.contract.negotiators,
			this.contract.initialSigner,
			this.contract.mandateGrantor,
		]);
	}
}
