import {DtoEditFormHelper} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
} from '@angular/core';
import {
	IconService,
	ModelHelper,
} from '@app/main';
import {
	ProfessionalAssociationDtoModel,
	ProfessionalAssociationModel,
	ProfessionalAssociationService,
} from '@contracts/frontend-api';

@Component({
	selector:
				 'portal-professional-association-card-master-data',
	templateUrl: './professional-association-card-master-data.component.html',
	styleUrls:   ['./professional-association-card-master-data.component.scss'],
})
export class ProfessionalAssociationCardMasterDataComponent {
	formHelper!: DtoEditFormHelper<ProfessionalAssociationModel,
		ProfessionalAssociationService>;
	private _professionalAssociation!: ProfessionalAssociationModel;
	public canUpdate?: Promise<boolean>;

	constructor(
		protected professionalAssociationService: ProfessionalAssociationService,
		public iconService: IconService,
	) {}

	get professionalAssociation(): ProfessionalAssociationModel {
		return this._professionalAssociation;
	}

	@Input({required: true}) set professionalAssociation(value: ProfessionalAssociationModel) {
		if(this._professionalAssociation === value)
			return;

		this._professionalAssociation = value;
		this.formHelper               = DtoEditFormHelper.create(
				ProfessionalAssociationDtoModel,
				this.professionalAssociation,
				this.professionalAssociationService,
		);

		this.canUpdate = ModelHelper.findEditableProperty([
			this.professionalAssociation.name,
			this.professionalAssociation.phone,
			this.professionalAssociation.fax,
			this.professionalAssociation.email,
			this.professionalAssociation.website,
		]);
	}
}
