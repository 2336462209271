import {
	AbstractPermissionedModel,
	Level8Error,
	ModelId,
	ModelNameHelper,
} from '@angular-helpers/frontend-api';
import {
	inject,
	Type,
} from '@angular/core';
import {
	CanActivateFn,
	Router,
	UrlTree,
} from '@angular/router';
import {AuthService} from '@app/main';
import {
	ContractAccessionService,
	InstitutionskennzeichenModel,
} from '@contracts/frontend-api';
import {first} from 'rxjs/operators';

export const HAS_PERMISSION_GUARD_USE_ROUTE_ID = null;

export interface PermissionInterface {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	class: Type<AbstractPermissionedModel<any>>;
	id?: ModelId | typeof HAS_PERMISSION_GUARD_USE_ROUTE_ID;
}


export function hasAPermissionGuard(permissions: PermissionInterface[]): CanActivateFn {
	return async (route, state) => {
		const router = inject(Router);

		const permissionResults$ = permissions
			.map(permission => {
				if(permission.id === HAS_PERMISSION_GUARD_USE_ROUTE_ID)
					permission.id = route.params.id;

				return permission;
			})
			.map(permission => hasPermission(permission.class, permission.id ?? undefined));

		const permissionResults = await Promise.all(permissionResults$);

		if(!permissionResults.includes(true))
			return redirectToPermissionMissingPage(router);

		return true;
	};
}

function redirectToPermissionMissingPage(router: Router): UrlTree {
	// redirect workaround: https://github.com/angular/angular/issues/27148#issuecomment-831414318
	const extras = router.getCurrentNavigation();
	if(extras != null)
		extras.extras.skipLocationChange = true;

	return router.parseUrl('/errors/403');
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function hasPermissionGuard(modelClass: Type<AbstractPermissionedModel<any>>, id?: ModelId | typeof HAS_PERMISSION_GUARD_USE_ROUTE_ID): CanActivateFn {
	return hasAPermissionGuard([
		{
			class: modelClass,
			id,
		},
	]);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function hasPermission(modelClass: Type<AbstractPermissionedModel<any>>, id?: ModelId): Promise<boolean> {
	// load service here due to a bug that 'await' seems to lose injection context 🤷‍
	const serviceClass = ModelNameHelper.fromClass(modelClass).asServiceClass();
	const service      = inject(serviceClass);

	// work around for users without any contract accession
	let fallbackPermission;
	if(service instanceof ContractAccessionService)
		fallbackPermission = hasPermission(InstitutionskennzeichenModel);

	const authService = inject(AuthService);
	const isLoggedIn  = await authService.isLoggedIn$.pipe(first()).toPromise();
	if(!isLoggedIn)
		return false;

	if(id == null) {
		const models = await service.getPage(0, {pageSize: 1});
		if(models.data.length <= 0 && fallbackPermission != null)
			return await fallbackPermission;

		return models.data.length > 0;
	}

	const model = service.getById(id);
	if(!(model instanceof AbstractPermissionedModel))
		throw new Level8Error(`Unexpected model. Expected ${AbstractPermissionedModel.constructor.name} got ${model.constructor.name}`);

	return await model.permissions.canRead;
}
