import {DatePipe} from '@angular/common';
import {Injectable} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import moment from 'moment';

@Injectable({
	providedIn: 'root',
})
export class FormHelperService {
	constructor(protected datePipe: DatePipe) {
	}

	getErrors(control: AbstractControl): Map<string, unknown> {
		const values: Map<string, unknown> = new Map();
		const errors                       = control.errors;
		if(errors != null) {
			Object.keys(errors)
				  .reduce(
						  (last, property) => last.set(property, this.castValues(Reflect.get(errors, property))),
						  values,
				  );
		}

		return values;
	}

	protected castValues(input: unknown): unknown {
		if(typeof input !== 'object' || input == null)
			return input;

		Object.keys(input).forEach(key => {
			const valueRaw = Reflect.get(input, key);
			if(moment.isMoment(valueRaw)) {
				Reflect.set(input, key, this.datePipe.transform(valueRaw.toDate()));
				return;
			}

			if(valueRaw instanceof Date) {
				Reflect.set(input, key, this.datePipe.transform(valueRaw));
				return;
			}
		});

		return input;
	}
}
