import {FocusOrigin} from '@angular/cdk/a11y';
import {
	Component,
	EventEmitter,
	Input,
	Output,
	ViewChild,
} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {ThemePalette} from '@angular/material/core';
import {IconService} from '@app/main';

@Component({
	selector:    'portal-spinning-button',
	templateUrl: './spinning-button.component.html',
	styleUrls:   ['./spinning-button.component.scss'],
})
export class SpinningButtonComponent {
	/** @deprecated **/
	@Input() text?: string;
	@Input({required: true}) processing!: boolean;
	@Output() clicked            = new EventEmitter();
	@Input() color: ThemePalette = 'primary';
	@Input() disabled            = false;

	@ViewChild('button') button!: MatButton;

	constructor(
		public iconService: IconService,
	) {
	}

	focus(origin?: FocusOrigin, options?: FocusOptions): void {
		this.button.focus(origin, options);
	}
}
