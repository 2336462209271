<portal-headline
	label="model.contractingParty"
	[backlink]="true"
/>
<portal-loading [loading]="control == null">
	@if (control) {
		<mat-card>
			<mat-card-content>
				<mat-stepper orientation="vertical" [linear]="true">
					<mat-step [completed]="control.valid" [editable]="!isSaving">
						<ng-template matStepLabel>{{ 'general.masterData' | translate }}</ng-template>
						<ng-template matStepperIcon="edit" let-index="index">{{ index + 1 }}</ng-template>
						<ng-template matStepperIcon="done" let-index="index">{{ index + 1 }}</ng-template>
						<ng-template matStepperIcon="error" let-index="index">{{ index + 1 }}</ng-template>
						<div class="grid-2">
							<portal-contracting-party-edit-name [control]="control"/>
							<portal-contracting-party-edit-insurance-count [control]="control"/>
							<portal-contracting-party-edit-contact [control]="control"/>
						</div>
						<div class="stepper-actions">
							<button mat-button matStepperNext>{{ 'actions.next' | translate }}</button>
						</div>
					</mat-step>
					<mat-step>
						<ng-template matStepLabel>{{ 'general.create.finish.label' | translate }}</ng-template>
						<p>{{ 'contractingParty.text' | translate }}</p>
						<div class="stepper-actions">
							<button mat-button matStepperPrevious>{{ 'actions.previous' | translate }}</button>
							<portal-spinning-button (click)="this.onSubmit$.next()" [processing]="isSaving">{{ 'actions.createNow' | translate }}</portal-spinning-button>
						</div>
						@if (this.errorHasOccurred) {
							<portal-form-error [error]="this.errorHasOccurred"/>
						}
					</mat-step>
				</mat-stepper>
			</mat-card-content>
		</mat-card>
	}
</portal-loading>
