import {
	Component,
	Inject,
} from '@angular/core';
import {
	MAT_DIALOG_DATA,
	MatDialogRef,
} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {environment} from '@app/environment';
import {IconService} from '@app/main';
import {
	MedicalStoreModel,
	MedicalStoreService,
	MedicalStoreUserModel,
	MedicalStoreUserService,
} from '@contracts/frontend-api';
import {
	from,
	Observable,
	Subject,
} from 'rxjs';
import {
	debounceTime,
	map,
	mergeMap,
	tap,
} from 'rxjs/operators';

export interface MedicalStoreUserDeleteDataInterface {
	userId: string;
}

interface ToDelete {
	user: MedicalStoreUserModel;
	store: MedicalStoreModel;
}

@Component({
	selector:    'portal-medical-store-user-delete-dialog',
	templateUrl: './medical-store-user-delete-dialog.component.html',
	styleUrls:   ['./medical-store-user-delete-dialog.component.scss'],
})
export class MedicalStoreUserDeleteDialogComponent {
	protected isDeleting                     = false;
	protected readonly user: MedicalStoreUserModel;
	protected isLoading: boolean;
	protected loadUsers$: Observable<{ store: MedicalStoreModel; user: MedicalStoreUserModel }[]>;
	protected loadedMedicalStoreUserRemovals = new Map<string, ToDelete>();
	protected delete$                        = new Subject<ToDelete>();
	protected onDelete$: Observable<boolean>;

	constructor(
		protected readonly medicalStoreService: MedicalStoreService,
		protected readonly medicalStoreUserService: MedicalStoreUserService,
		protected readonly iconService: IconService,
		protected readonly dialogRef: MatDialogRef<MedicalStoreUserDeleteDialogComponent>,
		protected readonly router: Router,
		@Inject(MAT_DIALOG_DATA) public data: MedicalStoreUserDeleteDataInterface,
	) {
		this.user      = this.medicalStoreUserService.getById(this.data.userId);
		this.isLoading = true;

		this.loadUsers$ = this.user.medicalStores.value.pipe(
			map((stores) => stores?.map((store) => ({
				store,
				user: this.user,
			}))),
			map((removals) => {

				if(removals == null)
					return [];


				for(const removal of removals) {
					this.loadedMedicalStoreUserRemovals.set(removal.store.id, {
						store: removal.store,
						user:  this.user,
					});
				}

				return removals;
			}),
			tap(() => {
				this.isLoading = false;
			}),
		);

		this.onDelete$ = this.delete$.pipe(
			tap(() => {
				this.isLoading = true;
			}),
			mergeMap(() => from([...this.loadedMedicalStoreUserRemovals.values()])),
			map((toDelete) => this.medicalStoreService.removeMedicalStoreUser(toDelete.store, toDelete.user)),
			debounceTime(1_000),
			mergeMap(() => this.router.navigate([environment.medicalStoreUsersFullUrl])),
			map((resault) => {
				this.isLoading = false;
				this.dialogRef.close();
				return resault;
			}),
		);
	}

	async delete(): Promise<void> {
		this.isDeleting     = true;
		const medicalStores = await this.user.medicalStores.firstValue ?? [];
		const deletes$      = medicalStores.map(medicalStore => this.medicalStoreService.removeMedicalStoreUser(
			medicalStore,
			this.user,
		));
		await Promise.all(deletes$);

		this.isDeleting = false;
		this.dialogRef.close();
		await this.router.navigate([environment.medicalStoreUsersFullUrl]);
	}

	decline(): void {
		this.dialogRef.close();
	}

}
