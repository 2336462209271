@if (model) {
	<portal-headline
		label="model.export"
		[menu]="menu"
	>
		<mat-menu #menu>
			@if (model.permissions.canDelete | async) {
				<button mat-menu-item (click)="showDeleteDialog()">
					<fa-icon matMenuItemIcon [icon]="iconService.DELETE"></fa-icon>
					<span>{{ 'actions.delete' | translate }}</span>
				</button>
			}
		</mat-menu>
	</portal-headline>
	<div class="flex-container">
		<portal-export-card-master-data [export]="model"/>
		<ng-container *ngLet="(model.masterContracts.value | async) as masterContracts">
			@if (masterContracts?.length !== 0 && masterContracts !== undefined) {
				<portal-master-contract-card-list [masterContracts]="masterContracts"/>
			}
		</ng-container>
		<ng-container *ngLet="(model.contracts.value | async) as contracts">
			@if (contracts?.length !== 0 && contracts !== undefined) {
				<portal-contract-card-list [contracts]="contracts"/>
			}
		</ng-container>
		<ng-container *ngLet="(model.contractSections.value | async) as contractSections">
			@if (contractSections?.length !== 0 && contractSections !== undefined) {
				<portal-contract-section-card-list [contractSections]="contractSections"/>
			}
		</ng-container>
		@if (((getModel().files.value | async)?.length ?? 0) > 0) {
			<portal-model-files-card
				[relationModel]="getModel()"
				[categories]="fileCategories"
				sortBy="validityStartAt"
				sortDirection="desc"
			/>
		}
	</div>
}
