import {PermissionedPropertyInterface} from '@angular-helpers/frontend-api';

export class ModelHelper {

	static async findEditableProperty(properties: PermissionedPropertyInterface<unknown>[]): Promise<boolean> {
		const permissions$ = properties.map(property => property.permissions.canUpdate.catch(error => false));
		const permissions  = await Promise.all(permissions$);
		return permissions.includes(true);
	}
}
