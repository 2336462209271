import {
	AbstractService,
	ResultPageModel,
} from '@angular-helpers/frontend-api';
import {AbstractApiService} from '@angular-helpers/frontend-api/lib/models/abstract-model/abstract.api-service';
import {
	Directive,
	Input,
	TemplateRef,
} from '@angular/core';
import {AnyModel} from '../../Helper/any';

export interface TableColumnData<ModelType> {
	model: ModelType;
	columnId: string;
}

@Directive({
	selector: 'ng-template[column][data],ng-template[column][service]',
})
export class TableColumnTemplateDirective<ModelType> {
	@Input({required: true}) column!: string;
	@Input() data?: ModelType[] | ResultPageModel<ModelType> | undefined | null;
	@Input() service?: AbstractService<AbstractApiService, ModelType & AnyModel>;

	constructor(
			public template: TemplateRef<TableColumnData<ModelType>>,
	) { }

	static ngTemplateContextGuard<ModelType>(dir: TableColumnTemplateDirective<ModelType>, ctx: unknown): ctx is TableColumnData<ModelType> {
		return true;
	}
}
