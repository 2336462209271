import {
	DtoCreationFormHelper,
	PageCache,
} from '@angular-helpers/frontend-api';
import {Component} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {environment} from '@app/environment';
import {
	ConfirmDialogComponent,
	ConfirmDialogConfig,
	IconService,
} from '@app/main';
import {
	NewsDtoModel,
	NewsModel,
	NewsService,
} from '@contracts/frontend-api';
import {of} from 'rxjs';
import {
	map,
	mergeMap,
	take,
} from 'rxjs/operators';

@Component({
	selector:    'portal-news-create-page',
	templateUrl: './news-create-page.component.html',
	styleUrls:   ['./news-create-page.component.scss'],
})
export class NewsCreatePageComponent {
	newsForm?: UntypedFormGroup;
	errorHasOccurred: Error | undefined;
	protected newsFormManager: DtoCreationFormHelper<NewsModel, NewsService>;

	constructor(
			public readonly newsService: NewsService,
			private readonly router: Router,
			public dialog: MatDialog,
			protected readonly iconService: IconService,
	) {
		this.newsFormManager = DtoCreationFormHelper.create(
			NewsDtoModel,
			this.newsService,
			{
				importance: 'middle',
			},
		);

		this.newsFormManager.control.then((form) => {
			this.newsForm = form;
		});
	}

	saveForm(): void {
		PageCache.remove(this.newsService);  // todo this should happen in library
		this.newsFormManager
			.save()
			.then((newsModel) => {
				if(newsModel == null)
					return;

				return this.router.navigate([environment.newsFullUrl]);
			})
			.catch((error) => {
				this.errorHasOccurred = error;
			});
	}

	onAbort(): void {
		if(this.newsForm == null)
			return;

		const confirmOptions: ConfirmDialogConfig = {
			labelNegative: 'system.abortChangesDialog.labelNegative',
			labelPositiv:  'system.abortChangesDialog.labelPositiv',
			title:         'system.abortChangesDialog.title',
			message:       'system.abortChangesDialog.message',
			icon:          this.iconService.DIALOG_ATTENTION,
		};

		of({})
			.pipe(
				mergeMap(() => {
					if(this.newsForm == null)
						return of(1);

					if(this.newsForm.dirty || this.newsForm.touched) {
						return this.dialog
								   .open(ConfirmDialogComponent, {
									   minWidth: 'max-content',
									   data:     confirmOptions,
								   })
								   .afterClosed();
					}

					return of(1);
				}),
				take(1),
				map((result) => {
					if(Boolean(result)) {
						return this.router.navigate([
							environment.newsFullUrl,
						]);
					}

					return result;
				}),
			)
			.subscribe();
	}
}
