import {DtoCreationFormHelper} from '@angular-helpers/frontend-api';
import {HttpErrorResponse} from '@angular/common/http';
import {
	Component,
	Input as RouteInput,
	OnDestroy,
	OnInit,
} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {environment} from '@app/environment';
import {
	InstitutionskennzeichenDtoInterface,
	InstitutionskennzeichenDtoModel,
	InstitutionskennzeichenModel,
	InstitutionskennzeichenService,
	MedicalStoreModel,
	MedicalStoreService,
} from '@contracts/frontend-api';
import {Subject} from 'rxjs';


@Component({
	selector:    'portal-institutionskennzeichen-page-create',
	templateUrl: './institutionskennzeichen-page-create.component.html',
	styleUrls:   ['./institutionskennzeichen-page-create.component.scss'],
})
export class InstitutionskennzeichenPageCreateComponent implements OnInit, OnDestroy {
	formHelper?: DtoCreationFormHelper<InstitutionskennzeichenModel, InstitutionskennzeichenService>;
	medicalStore?: MedicalStoreModel;
	isSaving   = false;
	control?: UntypedFormGroup;
	destroyed$ = new Subject<void>();
	public errorHasOccurred?: Error;

	constructor(
		private readonly institutionskennzeichenService: InstitutionskennzeichenService,
		private readonly medicalStoreService: MedicalStoreService,
		private readonly router: Router,
	) { }

	ngOnInit(): void {
		this.buildForm();
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

	async create(): Promise<void> {
		if(!this.formHelper)
			return;

		this.isSaving = true;
		try {
			// todo fails if IK exists but has been deleted https://git.biv.to/contracts/api/-/issues/168
			const institutionskennzeichen = await this.formHelper.save();
			if(!institutionskennzeichen)
				return;

			// todo should happen in lib
			this.medicalStore?.institutionskennzeichens.reload();

			await this.router.navigate([
				environment.institutionskennzeichenFullUrl,
				institutionskennzeichen.id,
			]);
		} catch(error){
			if(error instanceof Error || error instanceof HttpErrorResponse || error === undefined)
				this.errorHasOccurred = error;
			else
				this.errorHasOccurred = new Error(`${error}`);
			throw error;
		} finally {
			this.isSaving = false;
		}
	}

	private async buildForm(): Promise<void> {
		this.formHelper = DtoCreationFormHelper.create(
			InstitutionskennzeichenDtoModel,
			this.institutionskennzeichenService,
			this.buildFormValue(),
		);
		this.control    = await this.formHelper.control;

		InstitutionskennzeichenDtoModel.addUnusedNumberValidator(this.control, this.institutionskennzeichenService);
	}

	private buildFormValue(): Partial<InstitutionskennzeichenDtoInterface> {
		return {
			medicalStore: this.medicalStore,
		};
	}

	@RouteInput()
	set id(id: unknown) {
		if(typeof id !== 'string')
			throw new Error(`Invalid id: ${id}`);

		this.medicalStore = this.medicalStoreService.getById(id);
		if(this.control)
			this.formHelper?.fill(this.buildFormValue());
	}
}
