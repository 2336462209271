<div mat-dialog-title class="dialog-title logo">
	<fa-icon class="dialog-icon" [icon]="iconService.DELETE"></fa-icon>
	<div class="title-text">{{ 'deleteDialog.title' | translate }}</div>
</div>
<div mat-dialog-content class="scroll-shadows dialog-content">
	<p>{{ 'deleteDialog.question' | translate }}</p>
	<div class="grid-2">
		<label>{{ 'common.name' | translate }}</label>
		<p>{{ user.name.value | async }}</p>
		<label>{{ 'common.email' | translate }}</label>
		<p>{{ user.email.value | async }}</p>
	</div>
</div>
<div mat-dialog-actions class="actionbar">
	<div class="actions">
		@if (isDeleting === false) {
			<button mat-stroked-button (click)="delete()" color="warn">
				@if (isDeleting) {
					<fa-icon [icon]="iconService.LOADING_SPINNER" [spin]="true"></fa-icon>
				} @else {
					{{ 'actions.delete' | translate }}
				}
			</button>
		}
		@if (isDeleting) {
			<button mat-stroked-button [disabled]="isDeleting">
				<fa-icon
					[icon]="iconService.LOADING_SPINNER"
					[spin]="true"></fa-icon>
			</button>
		}

		<button (click)="decline()" class="right" color="primary" mat-raised-button>{{ 'actions.abort' | translate }}</button>
	</div>
</div>
