import {TranslateDefaultParser} from '@ngx-translate/core';
import {NGXLogger} from 'ngx-logger';

type TransformArgument = (object | undefined)[];

export class TranslateRecursiveParser extends TranslateDefaultParser {
	readonly FIND_TRANSLATION_VARIABLE = /{{\s?([^{}\s]*)\s*\|t\s*}}/g;
	protected lookups: string[]        = [];

	constructor(
		protected readonly logger: NGXLogger,
	) {
		super();
	}

	getValue(allTranslations: unknown, requestedTranslation: string, reset = true): unknown {
		if(reset)
			this.lookups = [];

		const translationMain = super.getValue(allTranslations, requestedTranslation);

		if(translationMain == null)
			return translationMain;

		if(typeof translationMain === 'string' && !/\|t\s*}}/.test(translationMain))
			return translationMain;

		return (args: TransformArgument) => this.transformAnything(
				translationMain,
				requestedTranslation,
				allTranslations,
				args,
		);
	}

	interpolate(expr: string | ((params: unknown) => string), params?: unknown): string {
		if(typeof expr === 'string') {
			if(params == null)
				return expr;

			return expr.replace(
					this.templateMatcher,
					(substring: string, b: string) => super.getValue(params, b) ?? substring,
			);
		}

		if(typeof expr === 'function')
			return expr(params);

		// this should not happen, but an unrelated TranslateService test depends on it
		return  expr as string;
	}

	protected transformAnything(translationMain: unknown, query: string, allTranslations: unknown, args: TransformArgument): unknown {
		if(translationMain === '' || translationMain == null)
			return `{{${query}}}`;

		if(translationMain === query) {
			this.logger.warn(`translation missing: ${query}`);
			return `{{${translationMain}}}`;
		}

		if(Array.isArray(translationMain))
			return translationMain.map(translation => this.transformRecursive(allTranslations, translation, args));

		if(typeof translationMain == 'object') {
			return Object.keys(translationMain).reduce((obj: Record<string, unknown>, prop) => {
				const nextQuery = `${query}.${prop}`;
				obj[prop]       = this.transformAnything(
						super.getValue(allTranslations, nextQuery),
						nextQuery,
						allTranslations,
						args,
				);
				return obj;
			}, {});
		}

		if(typeof translationMain !== 'string')
			return translationMain;

		return this.transformRecursive(allTranslations, translationMain, args);
	}

	protected transformRecursive(allTranslations: unknown, mainTranslation: string, args: TransformArgument): string {
		mainTranslation = mainTranslation.replace(
			/{{\s?([^{}\s]*)\s?}}/g,
			(substring, b) => super.getValue(args, b) ?? substring,
		);

		return mainTranslation.replace(this.FIND_TRANSLATION_VARIABLE, (fullMach, variableName) => {
			let translation = fullMach;

			if(this.lookups.includes(variableName)) {
				this.logger.error('Recursion in translation detected',
					{
						mainTranslation,
						variableName,
					},
				);
				return '{{RECURSION_DETECTED}}';
			}

			this.lookups.push(variableName);

			const transformResult = this.getValue(allTranslations, variableName.trim(), false);
			if(typeof transformResult === 'function')
				translation = transformResult(args);
			if(typeof transformResult == 'string')
				translation = transformResult;

			return translation;
		});
	}
}
