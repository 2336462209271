import {DtoEditFormHelper} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
} from '@angular/core';
import {
	IconService,
	ModelHelper,
} from '@app/main';
import {
	ContractSectionDtoModel,
	ContractSectionModel,
	ContractSectionService,
} from '@contracts/frontend-api';


@Component({
	selector:    'portal-contract-section-card-master-data',
	templateUrl: './contract-section-card-master-data.component.html',
	styleUrls:   ['./contract-section-card-master-data.component.scss'],
})
export class ContractSectionCardMasterDataComponent {
	formHelper!: DtoEditFormHelper<ContractSectionModel, ContractSectionService>;
	public canUpdate?: Promise<boolean>;

	constructor(
		protected contractSectionService: ContractSectionService,
		public iconService: IconService,
	) {
	}

	private _contractSection!: ContractSectionModel;
	get contractSection(): ContractSectionModel {
		return this._contractSection;
	}

	@Input({required: true}) set contractSection(value: ContractSectionModel) {
		if(this._contractSection === value)
			return;

		this._contractSection = value;
		this.formHelper       = DtoEditFormHelper.create(
				ContractSectionDtoModel,
				this.contractSection,
				this.contractSectionService,
		);

		this.canUpdate = ModelHelper.findEditableProperty([
			this.contractSection.validityStartAt,
			this.contractSection.terminatedAt,
			this.contractSection.minimumTermAt,
			this.contractSection.periodOfValidityAt,
			this.contractSection.noticePeriodDelay,
			this.contractSection.negotiators,
			this.contractSection.initialSigner,
			this.contractSection.mandateGrantor,
		]);
	}
}
