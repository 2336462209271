@if (showModeContent) {
	<mat-card class="fill-parent">
		@if (hasHeader) {
			<mat-card-header class="logo">
				@if (icon) {
					<fa-icon [icon]="icon" mat-card-avatar></fa-icon>
				}
				@if (headline) {
					<mat-card-title>{{ headline | translate }}</mat-card-title>
				}
			</mat-card-header>
		}
		<mat-card-content [class.grid-2]="enableContentGrid" #content>
			<ng-container [ngTemplateOutlet]="showModeContent"></ng-container>
		</mat-card-content>
		@if (hasEditButton === true && editModeContent != null) {
			<a class="editHover" routerLink="." (click)="openEditDialog()">
				<fa-icon [icon]="iconService.EDIT"></fa-icon>
			</a>
		}
	</mat-card>
}
