<div mat-dialog-title class="dialog-title logo">
	@if (data.icon) {
		<fa-icon class="dialog-icon" [icon]="data.icon" mat-card-avatar></fa-icon>
	}
	<div class="title-text">{{ data.title | translate }}</div>
</div>
<div mat-dialog-content class="scroll-shadows dialog-content">
	@for (msg of (data.message | translate | asArray);track msg) {
		<p>{{ msg }}</p>
	}
</div>
<div mat-dialog-actions class="actionbar">
	<div class="actions">
		@if (data.labelNegative) {
			<button mat-stroked-button [mat-dialog-close]="RETURN_NEGATIVE">{{ data.labelNegative | translate }}</button>
		}
		<button [mat-dialog-close]="RETURN_POSITIVE" class="right" color="primary" mat-raised-button>{{ data.labelPositiv | translate }}</button>
	</div>
</div>
