import {
	Level8Error,
	PhoneHelper,
} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
} from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
} from '@angular/forms';

@Component({
	selector: 'portal-common-edit-fax',
	templateUrl: './common-edit-fax.component.html',
	styleUrls: ['./common-edit-fax.component.scss'],
})
export class CommonEditFaxComponent {
	@Input({
		required: true,
		alias:    'control',
	}) parent!: UntypedFormGroup;

	get control(): UntypedFormControl {
		const fieldName = 'fax';
		const control   = this.parent.get(fieldName);
		if(control instanceof UntypedFormControl)
			return control;

		throw new Level8Error(`Unexpected type for field ${fieldName} - expected '${UntypedFormControl.name}' got '${typeof control}' (${control})`);
	}

	formatNumber(): void {
		if(this.control.value.length === 0)
			return;

		if(this.control.valid)
			this.control.setValue(PhoneHelper.formatForDisplay(this.control.value));
	}
}
