import {Level8Error} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
} from '@angular/core';
import {
	FormControl,
	FormGroup,
} from '@angular/forms';

@Component({
	selector: 'portal-iqz-training-course-edit-is-canceled[control]',
	templateUrl: './iqz-training-course-edit-is-canceled.component.html',
	styleUrls: ['./iqz-training-course-edit-is-canceled.component.scss'],
})
export class IqzTrainingCourseEditIsCanceledComponent {
	@Input('control') parent!: FormGroup;

	get control(): FormControl {
		const fieldName = 'isCanceled';
		const control   = this.parent.get(fieldName);
		if(control instanceof FormControl)
			return control;

		throw new Level8Error(`Unexpected type for field ${fieldName} - expected '${FormControl.name}' got '${typeof control}' (${control})`);
	}
}
