import {HttpErrorResponse} from '@angular/common/http';
import {
	Component,
	DestroyRef,
	EventEmitter,
	Inject,
	OnInit,
	Output,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
	FormControl,
	UntypedFormGroup,
} from '@angular/forms';
import {
	MAT_DIALOG_DATA,
	MatDialogRef,
} from '@angular/material/dialog';
import {IconService} from '@app/main';
import {Observable} from 'rxjs';
import {
	finalize,
	map,
} from 'rxjs/operators';

export interface VersionErrorDialogData {
	error: HttpErrorResponse;
	control: UntypedFormGroup | undefined;
	version: number;
}

@Component({
	selector:    'portal-version-error-dialog',
	templateUrl: './version-error-dialog.component.html',
	styleUrls:   ['./version-error-dialog.component.scss'],
})
export class VersionErrorDialogComponent implements OnInit {
	@Output() confirmAction = new EventEmitter<unknown>();
	isSaving = false;
	versionField?: FormControl<number>;
	protected versionOverwrite?: Observable<boolean>;

	constructor(
		@Inject(MAT_DIALOG_DATA) protected data: VersionErrorDialogData,
		protected dialogRef: MatDialogRef<VersionErrorDialogComponent, boolean>,
		protected iconService: IconService,
		protected readonly destroyRef: DestroyRef,
	) {}

	ngOnInit(): void {
		if(this.data.control != null) {
			const field = this.data.control.get('version');

			if(!(field instanceof FormControl))
				return;

			this.versionField     = field;
			this.versionOverwrite = this.versionOverwrite$();

			this.versionOverwrite.pipe(
				takeUntilDestroyed(this.destroyRef),
			).subscribe((isSuccess) => {
				this.dialogRef.close(isSuccess);
			});
		}
	}


	versionOverwrite$(): Observable<boolean> {
		return this.confirmAction.pipe(
			map(() => {
				this.isSaving = true;
				if(this.versionField == null)
					return false;

				this.versionField.setValue(this.data.version);

				return true;
			}),
			finalize(() => {
				this.isSaving = false;
			}),
		);
	}

	reloadPage(): void {
		document.location.reload();
	}
}
