import {
	Component,
	Input,
} from '@angular/core';
import {ContractSectionModel} from '@contracts/frontend-api';

@Component({
	selector:    'portal-contract-show-versorgungsbereich',
	templateUrl: './contract-show-versorgungsbereich.component.html',
	styleUrls:   ['./contract-show-versorgungsbereich.component.scss'],
})
// todo delete this
export class ContractShowVersorgungsbereichComponent {
	@Input({required: true}) model!: ContractSectionModel;
}
