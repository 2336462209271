import {
	Component,
	Input,
} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

@Component({
	selector:    'portal-address-edit-model',
	templateUrl: './address-edit-model.component.html',
	styleUrls:   ['./address-edit-model.component.scss'],
})
export class AddressEditModelComponent {
	@Input({required: true}) control!: UntypedFormGroup;
}
