import {
	Component,
	Input,
} from '@angular/core';
import {RelativeDateService} from '@app/main';
import {Delay} from '@contracts/frontend-api';

@Component({
	selector:    'portal-show-relative-date',
	templateUrl: './show-relative-date.component.html',
	styleUrls:   ['./show-relative-date.component.scss'],
})
export class ShowRelativeDateComponent {
	@Input({required: true}) label!: string;
	@Input() description?: string;
	showValue?: string | null;

	constructor(
		private readonly relativeDateService: RelativeDateService,
	) {
	}

	private _value?: Delay | null;
	@Input({required: true}) get value(): Delay | null | undefined {
		return this._value;
	}

	set value(value: Delay | null | undefined) {
		if(this._value === value)
			return;

		this._value = value;

		if(value == null) {
			this.showValue = value;
			return;
		}

		const dateObject = this.relativeDateService.valueToDateObject(value);
		if(dateObject != null)
			this.showValue = this.relativeDateService.dateObjectToTranslatedDescription(dateObject);
	}
}
