<header>
	@if (label) {
		<h1 class="backlink">
			@if (url) {
				<a [routerLink]="url" [queryParams]="queryParameters">
					<fa-icon [icon]="iconService.BACK" (click)="checkFallbackHistory($event)"></fa-icon>
				</a>
			}
			{{ label | translate: labelArgs }}
		</h1>
	}
	@if (menu && !isMenuEmpty) {
		<button mat-stroked-button class="menu" [matMenuTriggerFor]="menu">
			<fa-icon [icon]="iconService.MENU"></fa-icon>
		</button>
	}
</header>
