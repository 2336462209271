import {Level8Error} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
} from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
} from '@angular/forms';
import {
	combineLatestSafe,
	SelectFieldEntry,
} from '@app/main';
import {
	LandesinnungModel,
	LandesinnungService,
} from '@contracts/frontend-api';
import {
	from,
	Observable,
} from 'rxjs';
import {
	filter,
	map,
	mergeMap,
} from 'rxjs/operators';

@Component({
	selector: 'portal-landesinnung-edit',
	templateUrl: './landesinnung-edit.component.html',
	styleUrls: ['./landesinnung-edit.component.scss'],
})
export class LandesinnungEditComponent {
	@Input({
		required: true,
		alias:    'control',
	}) parent!: UntypedFormGroup;
	possibleValues$: Observable<SelectFieldEntry[]>;

	constructor(
		protected landesinnungService: LandesinnungService,
	) {
		const landesinnungs$ = this.landesinnungService.getAllModels();
		this.possibleValues$ = from(landesinnungs$).pipe(
			mergeMap(landesinnungs => combineLatestSafe(landesinnungs.map(landesinnung =>
				landesinnung.name.value.pipe(
						filter((name): name is string => typeof name === 'string'),
						map(name => ({
							label: name,
							value: landesinnung,
						})),
				)))),
		);
	}

	get control(): UntypedFormControl {
		const fieldName = 'landesinnung';
		const control   = this.parent.get(fieldName);
		if(control instanceof UntypedFormControl)
			return control;

		throw new Level8Error(`Unexpected type for field ${fieldName} - expected '${UntypedFormControl.name}' got '${typeof control}' (${control})`);
	}

	get currentValue(): SelectFieldEntry | undefined {
		const innung = this.control.value as LandesinnungModel | null | undefined;

		if(innung == null)
			return undefined;

		if(innung.name.currentValue == null)
			return undefined;

		return {
			label: innung.name.currentValue,
			value: innung,
		};
	}
}
