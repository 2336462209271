import {HttpErrorResponse} from '@angular/common/http';
import {
	Component,
	Inject,
	Signal,
	TemplateRef,
} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {IconDefinition} from '@fortawesome/fontawesome-svg-core';
import {Observable} from 'rxjs';
import {FormHelperService} from '../../Helper/form-helper.service';
import {IconService} from '../../services/icon.service';

export interface BaseDialogData {
	content: TemplateRef<unknown>;
	headline: string;
	icon: IconDefinition;
	cancelButtonText?: string;
	control?: UntypedFormGroup;
	acceptText: string | undefined;
	enableContentGrid: boolean;
	save$?: Observable<unknown>;
	formHelper?: unknown;
	error?: Signal<Error | undefined>;
	save?: () => Promise<void>;
	cancel?: () => Promise<void>;
	errorDetected?: (error: Error, observable: Observable<unknown>) => Observable<unknown>;
}


@Component({
	selector:    'portal-base-dialog',
	templateUrl: './base-dialog.component.html',
	styleUrls:   ['./base-dialog.component.scss'],
})
export class BaseDialogComponent {
	protected errorHasOccurred?: Error;
	protected isSaving = false;

	constructor(
		protected readonly iconService: IconService,
		protected readonly formHelperService: FormHelperService,
		@Inject(MAT_DIALOG_DATA) protected readonly data: BaseDialogData,
	) {
	}

	save(): Promise<unknown> {
		if(this.data.save == null)
			return Promise.resolve(true);

		this.isSaving = true;

		return this.data.save().then(value => {
			this.isSaving = false;
			return value;
		}).catch(error => {
			if(error instanceof Error || error instanceof HttpErrorResponse)
				this.errorHasOccurred = error;
			else
				throw error;
		});
	}

	abortEditing(): Promise<unknown> {
		if(this.data.cancel == null)
			return Promise.resolve();

		return this.data.cancel().catch(error => {
			if(error instanceof Error)
				this.errorHasOccurred = error;

			throw error;
		});
	}

	async onErrorResolved(retry: boolean): Promise<void> {
		if(!retry)
			return;

		await this.save();
	}
}
