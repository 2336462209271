<mat-card>
	<mat-card-header class="logo">
		<fa-icon [icon]="iconService.PRE_QUALIFICATION_CERTIFICATE" mat-card-avatar></fa-icon>
		<mat-card-title>{{ 'preQualificationCertificate.validity' | translate }}</mat-card-title>
	</mat-card-header>
	
	<mat-card-content>
		<div class="grid-2">
			<portal-pre-qualification-certificate-show-validity-start-at [model]="preQualificationCertificate"/>
			<portal-pre-qualification-certificate-show-validity-end-at [model]="preQualificationCertificate"/>
			<portal-pre-qualification-certificate-show-versorgungsbereichs [model]="preQualificationCertificate"/>
		</div>
	</mat-card-content>
</mat-card>
