import {
	Component,
	Input,
} from '@angular/core';
import {environment} from '@app/environment';
import {UrlType} from '@app/main';
import {ProfessionalAssociationModel} from '@contracts/frontend-api';

@Component({
	selector:    'portal-professional-association-show',
	templateUrl: './professional-association-show.component.html',
	styleUrls:   ['./professional-association-show.component.scss'],
})
export class ProfessionalAssociationShowComponent {
	@Input({required: true}) model: ProfessionalAssociationModel | undefined | null;
	readonly URL_TYPE    = UrlType;
	readonly ENVIRONMENT = environment;
}
