<portal-editable
	[icon]="icon"
	[label]="label"
	[validator]="validator"
	[showAlways]="showIfNotEditable"
>
	<mat-form-field class="fill">
		<mat-datepicker-toggle matPrefix [for]="datePicker"></mat-datepicker-toggle>
		<!--suppress AngularInvalidExpressionResultType -->
		<input (dateChange)="change.emit()" [placeholder]="placeholder ?? ''" matInput [formControl]="validator" [min]="earliestDate" [max]="latestDate" [matDatepicker]="datePicker" (focus)="datePicker.open()"/>
		<mat-hint>{{ 'formats.date' | translate }}</mat-hint>
		<mat-datepicker #datePicker>
			<mat-datepicker-actions>
				<button mat-button matDatepickerCancel>{{ 'actions.cancel' | translate }}</button>
				<button mat-raised-button color="primary" matDatepickerApply>{{ 'actions.apply' | translate }}</button>
			</mat-datepicker-actions>
		</mat-datepicker>
		@for (error of formHelperService.getErrors(validator) | keyvalue;track error) {
			<mat-error>
				{{ 'input.error.' + error.key | translate: error.value }}
			</mat-error>
		}

		<portal-input-status-icon matSuffix [validator]="validator"/>
	</mat-form-field>
</portal-editable>
