import {
	Component,
	Input,
} from '@angular/core';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';

@Component({
	selector:    'portal-show-field-boolean',
	templateUrl: './show-field-boolean.component.html',
	styleUrls:   ['./show-field-boolean.component.scss'],
})
export class ShowFieldBooleanComponent {
	@Input({required: true}) label                  = '';
	@Input() icon?: IconDefinition;
	@Input({required: true}) value?: boolean | null = undefined;
	@Input() labelTrue                              = 'Ja';
	@Input() labelFalse                             = 'Nein';
	@Input() nullLabel                              = 'Unbekannt';
	@Input() hideIfNull                             = true;
}
