import {
	Pipe,
	PipeTransform,
} from '@angular/core';
import {MedicalStoreModel} from '@contracts/frontend-api';
import {
	combineLatest,
	Observable,
	of,
} from 'rxjs';
import {
	map,
	mergeMap,
} from 'rxjs/operators';
import {ElementEntryStateInterface} from '../../../contracts/components/medical-store/properties/medical-store-user/edit/medical-store-user-edit.component';

@Pipe({
	name: 'canViewStorePermission',
})
export class CanViewStorePermissionPipe implements PipeTransform {

	transform(medicalStoreModel: MedicalStoreModel, library: Map<string, ElementEntryStateInterface>, selectedUserId?: string): Observable<boolean> {
		const state = library.get(medicalStoreModel.id);
		if(state == null) {
			return combineLatest([
				medicalStoreModel.permissions.canUpdate,
				medicalStoreModel.parent.value.pipe(
					mergeMap(parent => {
						if(parent == null)
							return of(false);

						return parent.users.value.pipe(
							map((users) => {
								if(users == null || users.length<1)
									return false;

								return users.some((user) => user.user.id === selectedUserId);
							}),
						);
					}),
				),
			]).pipe(
				map(([hasPermission, hasParentView]) => hasPermission && !hasParentView),
			);
		}

		return of(!state.state.inheritedView);
	}
}
