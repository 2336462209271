<portal-model-editable-box
	[icon]="iconService.PROFESSIONAL_ASSOCIATION"
	headline="general.masterData"
	[formHelper]="formHelper"
	[hasEditButton]="canUpdate | async"
>

	@if (professionalAssociation) {
		<ng-template #showModeContent>
			<portal-common-show-name [model]="professionalAssociation"/>
			<portal-address-show-model [model]="professionalAssociation.address.value | async"/>
			<portal-common-show-phone [model]="professionalAssociation"/>
			<portal-common-show-fax [model]="professionalAssociation"/>
			<portal-common-show-email [model]="professionalAssociation"/>
			<portal-common-show-website [model]="professionalAssociation"/>
			<!--		<portal-common-show-contact [model]="professionalAssociation"/>-->
			<!--todo #75-->
		</ng-template>
	}

	<ng-template #editModeContent let-control>
		<portal-common-edit-name [control]="control"/>
		<portal-address-edit-model [control]="control.controls.address"/>
		<portal-common-edit-phone [control]="control"/>
		<portal-common-edit-fax [control]="control"/>
		<portal-common-edit-email [control]="control"/>
		<portal-common-edit-website [control]="control"/>
		<!--		<portal-common-edit-contact [control]="control"/>-->
		<!--todo #75-->
	</ng-template>

</portal-model-editable-box>
