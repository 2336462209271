import {NgModule} from '@angular/core';
import {
	RouterModule,
	Routes,
} from '@angular/router';
import {environment} from '@app/environment';
import {
	HAS_PERMISSION_GUARD_USE_ROUTE_ID,
	hasAPermissionGuard,
	hasPermissionGuard,
	LoginGuard,
	MaintenanceGuard,
	TermsAndConditionsAcceptedGuard,
	UrlMatchers,
} from '@app/main';
import {
	ContractAccessionModel,
	ContractingPartyModel,
	ContractModel,
	ContractSectionModel,
	EmployeeModel,
	ExportModel,
	InstitutionskennzeichenModel,
	LandesinnungModel,
	MasterContractModel,
	MedicalStoreModel,
	NewsModel,
	PreQualificationCertificateModel,
	ProfessionalAssociationModel,
	RawContentModel,
} from '@contracts/frontend-api';
import {EmployeeCreateFormComponent} from './components/employee/create-form/employee-create-form.component';
import {ContractsComponent} from './contracts.component';
import {CalculationManualsPageComponent} from './pages/calculation-manuals-page/calculation-manuals-page.component';
import {ContractAccessingPageComponent} from './pages/contract-accessing/contract-accessing-page.component';
import {ContractAccessionListPageComponent} from './pages/contract-accession/list/contract-accession-list-page.component';
import {ContractAccessionShowPageComponent} from './pages/contract-accession/show/contract-accession-show-page.component';
import {ContractSectionsPageCreateComponent} from './pages/contract-sections/create/contract-sections-page-create.component';
import {ContractSectionsPageShowComponent} from './pages/contract-sections/show/contract-sections-page-show.component';
import {ContractingPartyPageCreateComponent} from './pages/contracting-party/create/contracting-party-page-create.component';
import {ContractingPartyPageListComponent} from './pages/contracting-party/list/contracting-party-page-list.component';
import {ContractingPartyPageShowComponent} from './pages/contracting-party/show/contracting-party-page-show.component';
import {ContractsPageCreateComponent} from './pages/contracts/create/contracts-page-create.component';
import {ContractsPageShowComponent} from './pages/contracts/show/contracts-page-show.component';
import {DownloadSectionPageComponent} from './pages/download-section-page/download-section-page.component';
import {EmployeePageShowComponent} from './pages/employee/show/employee-page-show.component';
import {ExportShowPageComponent} from './pages/export-show-page/export-show-page.component';
import {InstitutionskennzeichenPageCreateComponent} from './pages/institutionskennzeichen/create/institutionskennzeichen-page-create.component';
import {InstitutionskennzeichenPageShowComponent} from './pages/institutionskennzeichen/show/institutionskennzeichen-page-show.component';
import {IqzParticipationPageCreateComponent} from './pages/iqz-participation/create/iqz-participation-page-create.component';
import {LandesinnungPageListComponent} from './pages/landesinnung/list/landesinnung-page-list.component';
import {LandesinnungPageShowComponent} from './pages/landesinnung/show/landesinnung-page-show.component';
import {MasterContractsPageCreateComponent} from './pages/master-contracts/create/master-contracts-page-create.component';
import {MasterContractsPageListComponent} from './pages/master-contracts/list/master-contracts-page-list.component';
import {MasterContractsPageShowComponent} from './pages/master-contracts/show/master-contracts-page-show.component';
import {MedicalStoreUserEditCreatePageComponent} from './pages/medical-store-user/edit-create/medical-store-user-edit-create-page.component';
import {MedicalStoreUsersListPageComponent} from './pages/medical-store-user/list/medical-store-users-list-page.component';
import {MedicalStorePageCreateComponent} from './pages/medical-store/create/medical-store-page-create.component';
import {MedicalStoreListPageComponent} from './pages/medical-store/list/medical-store-list-page.component';
import {MedicalStoreShowPageComponent} from './pages/medical-store/show/medical-store-show-page.component';
import {NewsCreatePageComponent} from './pages/news/create/news-create-page.component';
import {NewsListPageComponent} from './pages/news/list/news-list-page.component';
import {NewsShowPageComponent} from './pages/news/show/news-show-page.component';
import {PreQualificationCertificatePageCreateComponent} from './pages/pre-qualification-certificate/create/pre-qualification-certificate-page-create.component';
import {PreQualificationCertificateShowPageComponent} from './pages/pre-qualification-certificate/show/pre-qualification-certificate-show-page.component';
import {ProfessionalAssociationPageListComponent} from './pages/professional-association/list/professional-association-page-list.component';
import {ProfessionalAssociationPageShowComponent} from './pages/professional-association/show/professional-association-page-show.component';

const routes: Routes = [
	{
		path:             '',
		component:        ContractsComponent,
		canActivate:      [MaintenanceGuard],
		canActivateChild: [
			LoginGuard,
			TermsAndConditionsAcceptedGuard,
		],
		children:         [
			{
				path:        environment.downloadSectionBaseUrl,
				component:   DownloadSectionPageComponent,
				canActivate: [hasPermissionGuard(RawContentModel, environment.downloadRawContentId)],
			},
			{
				path:        environment.calculationManualsBaseUrl,
				component:   CalculationManualsPageComponent,
				canActivate: [
					hasAPermissionGuard([
						{
							class: RawContentModel,
							id:    environment.calculationManualsRawContentId,
						},
						{
							class: RawContentModel,
							id:    environment.calculationManualsPreviewsRawContentId,
						},
					]),
				],
			},
			{
				path:     environment.exportBaseUrl,
				children: [
					{
						matcher:     UrlMatchers.uuid(),
						component:   ExportShowPageComponent,
						canActivate: [hasPermissionGuard(ExportModel, HAS_PERMISSION_GUARD_USE_ROUTE_ID)],
					},
				],
			},
			{
				path: environment.newsBaseUrl,
				children: [
					{
						path:        '',
						component:   NewsListPageComponent,
						canActivate: [hasPermissionGuard(NewsModel)],
					},
					{
						matcher:     UrlMatchers.uuid(),
						component:   NewsShowPageComponent,
						canActivate: [hasPermissionGuard(NewsModel, HAS_PERMISSION_GUARD_USE_ROUTE_ID)],
					},
					{
						path:        'create',
						component:   NewsCreatePageComponent,
						canActivate: [hasPermissionGuard(NewsModel)],
					},
				],
			},
			{
				path:     environment.medicalStoresBaseUrl,
				children: [
					{
						path:        '',
						component:   MedicalStoreListPageComponent,
						canActivate: [hasPermissionGuard(MedicalStoreModel)],
					},
					{
						path:        'create',
						component:   MedicalStorePageCreateComponent,
						canActivate: [hasPermissionGuard(MedicalStoreModel)],
					},
					{
						matcher:          UrlMatchers.uuid(),
						canActivateChild: [hasPermissionGuard(MedicalStoreModel, HAS_PERMISSION_GUARD_USE_ROUTE_ID)],
						children:         [
							{
								path:      '',
								component: MedicalStoreShowPageComponent,
							},
							{
								path:      'create-employee',
								component: EmployeeCreateFormComponent,
							},
							{
								path:      'create-branch-office',
								component: MedicalStorePageCreateComponent,
							},
							{
								path:      'create-institutionskennzeichen',
								component: InstitutionskennzeichenPageCreateComponent,
							},
							{
								path:      'create-iqz-participation',
								component: IqzParticipationPageCreateComponent,
							},
						],
					},
				],
			},
			{
				path:             environment.medicalStoreUsersUrl,
				canActivateChild: [hasPermissionGuard(MedicalStoreModel)],
				children:         [
					{
						path:      '',
						component: MedicalStoreUsersListPageComponent,
					},
					{
						path:      'create',
						component: MedicalStoreUserEditCreatePageComponent,
					},
					{
						matcher:  UrlMatchers.uuid(),
						children: [
							{
								path:      '',
								component: MedicalStoreUserEditCreatePageComponent,
							},
						],
					},
				],
			},
			{
				path:     environment.masterContractsBaseUrl,
				children: [
					{
						path:        '',
						component:   MasterContractsPageListComponent,
						canActivate: [hasPermissionGuard(MasterContractModel)],
					},
					{
						path:        'create',
						component:   MasterContractsPageCreateComponent,
						canActivate: [hasPermissionGuard(MasterContractModel)],
					},
					{
						matcher:          UrlMatchers.uuid(),
						canActivateChild: [hasPermissionGuard(MasterContractModel, HAS_PERMISSION_GUARD_USE_ROUTE_ID)],
						children:         [
							{
								path:      '',
								component: MasterContractsPageShowComponent,
							},
							{
								path:      'create-contract',
								component: ContractsPageCreateComponent,
							},
						],
					},
				],
			},
			{
				path:     environment.contractsBaseUrl,
				children: [
					{
						matcher:          UrlMatchers.uuid(),
						canActivateChild: [hasPermissionGuard(ContractModel, HAS_PERMISSION_GUARD_USE_ROUTE_ID)],
						children:         [
							{
								path:      '',
								component: ContractsPageShowComponent,
							},
							{
								path:      'create-contract-section',
								component: ContractSectionsPageCreateComponent,
							},
						],
					},
				],
			},
			{
				path:     environment.contractSectionsBaseUrl,
				children: [
					{
						matcher:     UrlMatchers.uuid(),
						component:   ContractSectionsPageShowComponent,
						canActivate: [hasPermissionGuard(ContractSectionModel)],
					},
				],
			},
			{
				path:     environment.contractAccessingBaseUrl,
				children: [
					{
						path:        '',
						component:   ContractAccessingPageComponent,
						canActivate: [hasPermissionGuard(ContractAccessionModel)],
					},
				],
			},
			{
				path:     environment.contractAccessionBaseUrl,
				children: [
					{
						path:        '',
						component:   ContractAccessionListPageComponent,
						canActivate: [hasPermissionGuard(ContractAccessionModel)],
					},
					{
						matcher:     UrlMatchers.uuid(),
						component:   ContractAccessionShowPageComponent,
						canActivate: [hasPermissionGuard(ContractAccessionModel, HAS_PERMISSION_GUARD_USE_ROUTE_ID)],
					},
				],
			},
			{
				path:     environment.employeeBaseUrl,
				children: [
					{
						matcher:     UrlMatchers.uuid(),
						component:   EmployeePageShowComponent,
						canActivate: [hasPermissionGuard(EmployeeModel, HAS_PERMISSION_GUARD_USE_ROUTE_ID)],
					},
				],
			},
			{
				path:     environment.preQualificationCertificateBaseUrl,
				children: [
					{
						matcher:     UrlMatchers.uuid(),
						component:   PreQualificationCertificateShowPageComponent,
						canActivate: [
							hasPermissionGuard(
								PreQualificationCertificateModel,
								HAS_PERMISSION_GUARD_USE_ROUTE_ID,
							),
						],
					},
					{
						path:        'create',
						component:   PreQualificationCertificatePageCreateComponent,
						canActivate: [hasPermissionGuard(PreQualificationCertificateModel)],
					},
				],
			},
			{
				path:     environment.contractingPartyBaseUrl,
				children: [
					{
						path:        '',
						component:   ContractingPartyPageListComponent,
						canActivate: [hasPermissionGuard(ContractingPartyModel)],
					},
					{
						matcher:     UrlMatchers.uuid(),
						component:   ContractingPartyPageShowComponent,
						canActivate: [hasPermissionGuard(ContractingPartyModel, HAS_PERMISSION_GUARD_USE_ROUTE_ID)],
					},
					{
						path:        'create',
						component:   ContractingPartyPageCreateComponent,
						canActivate: [hasPermissionGuard(ContractingPartyModel)],
					},
				],
			},
			{
				path:     environment.landesinnungBaseUrl,
				children: [
					{
						path:        '',
						component:   LandesinnungPageListComponent,
						canActivate: [hasPermissionGuard(LandesinnungModel)],
					},
					{
						matcher:     UrlMatchers.uuid(),
						component:   LandesinnungPageShowComponent,
						canActivate: [hasPermissionGuard(LandesinnungModel, HAS_PERMISSION_GUARD_USE_ROUTE_ID)],
					},
				],
			},
			{
				path:     environment.professionalAssociationBaseUrl,
				children: [
					{
						path:        '',
						component:   ProfessionalAssociationPageListComponent,
						canActivate: [hasPermissionGuard(ProfessionalAssociationModel)],
					},
					{
						matcher:     UrlMatchers.uuid(),
						component:   ProfessionalAssociationPageShowComponent,
						canActivate: [
							hasPermissionGuard(
								ProfessionalAssociationModel,
								HAS_PERMISSION_GUARD_USE_ROUTE_ID,
							),
						],
					},
				],
			},
			{
				path:     environment.institutionskennzeichenBaseUrl,
				children: [
					{
						matcher:     UrlMatchers.uuid(),
						component:   InstitutionskennzeichenPageShowComponent,
						canActivate: [
							hasPermissionGuard(
								InstitutionskennzeichenModel,
								HAS_PERMISSION_GUARD_USE_ROUTE_ID,
							),
						],
					},
				],
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class ContractsRoutingModule {
}
