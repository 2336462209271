@for (requirement of requirements;track requirement) {
	@switch (requirement.requirementFunction.name.value | async) {
		@case (requirementFunctionName.preQualificationCertificateExistsCheck) {
			<p>{{ 'requirementFunction.' + (requirement.requirementFunction.name.value | async) | translate }}: {{ getVbByEntry($any(requirement)).name.value | async }}
				<ng-container [ngTemplateOutlet]="deleteButton" [ngTemplateOutletContext]="{ $implicit: requirement}"></ng-container>
			</p>
		}
		@case (requirementFunctionName.employeeHasDataCheck) {
			<p>{{ 'requirementFunction.' + (requirement.requirementFunction.name.value | async) | translate }}: {{ getRoleByEntry($any(requirement)).name.value | async }}
				<ng-container [ngTemplateOutlet]="deleteButton" [ngTemplateOutletContext]="{ $implicit: requirement}"></ng-container>
			</p>
		}
		@case (requirementFunctionName.negateEmployeeHasDataCheck) {
			<p>{{ 'requirementFunction.' + (requirement.requirementFunction.name.value | async) | translate }}: {{ getRoleByEntry($any(requirement)).name.value | async }}
				<ng-container [ngTemplateOutlet]="deleteButton" [ngTemplateOutletContext]="{ $implicit: requirement}"></ng-container>
			</p>
		}
		@case (requirementFunctionName.medicalStorePropertyIsOneOfValueCheck) {
			<p> {{ 'requirementFunction.' + (requirement.requirementFunction.name.value | async) | translate }}: {{ $any(requirement.parameters).values }}
				<ng-container [ngTemplateOutlet]="deleteButton" [ngTemplateOutletContext]="{ $implicit: requirement}"></ng-container>
			</p>
		}
		@case (requirementFunctionName.preQualificationCertificateOneOfExistsCheck) {
			<p> {{ 'requirementFunction.' + (requirement.requirementFunction.name.value | async) | translate }}: {{ getVersorgungsBereicheList$(requirement.parameters) | async }}
				<ng-container [ngTemplateOutlet]="deleteButton" [ngTemplateOutletContext]="{ $implicit: requirement}"></ng-container>
			</p>
		}
	}
}
@if (isAddingRequirement === false && requirements.length < 1) {
	<span class="message">{{ 'requirementFunction.emptyList' | translate }}</span>
}

@if (isAddingRequirement) {
	<span class="message">{{ 'requirementFunction.adding' | translate }}</span>
	<mat-form-field>
		<mat-select [(ngModel)]="selectedRequirementFunction">
			@for (requirement of possibleRequirements;track requirement) {
				<mat-option [value]="requirement">{{ 'requirementFunction.' + (requirement.name.value | async) | translate }}</mat-option>
			}
		</mat-select>
	</mat-form-field>
	@if (selectedRequirementFunction) {
		@switch (selectedRequirementFunction.name.value | async) {
			@case (requirementFunctionName.preQualificationCertificateExistsCheck) {
				<mat-form-field>
					<mat-select [(ngModel)]="selectedVersorgungsbereich">
						@for (vb of possibleVersorgungsbereichs;track vb) {
							<mat-option [value]="vb">{{ vb.name.value | async }}</mat-option>
						}
					</mat-select>
				</mat-form-field>
				@if (selectedVersorgungsbereich != null) {
					<button mat-stroked-button (click)="savePqcRequirement()">{{ 'actions.save' | translate }}</button>
				}
			}
			@case (requirementFunctionName.employeeHasDataCheck) {
				<mat-form-field>
					<mat-select [(ngModel)]="selectedRole">
						@for (role of possibleRoles;track role) {
							<mat-option [value]="role">{{ role.name.value | async }}</mat-option>
						}
					</mat-select>
				</mat-form-field>
				@if (selectedRole != null) {
					<button mat-stroked-button (click)="saveEmployeeRoleRequirement()">{{ 'actions.save' | translate }}</button>
				}
			}
			@case (requirementFunctionName.negateEmployeeHasDataCheck) {
				<mat-form-field>
					<mat-select [(ngModel)]="selectedRole">
						@for (role of possibleRoles;track role) {
							<mat-option [value]="role">{{ role.name.value | async }}</mat-option>
						}
					</mat-select>
				</mat-form-field>
				@if (selectedRole != null) {
					<button mat-stroked-button (click)="saveNegateEmployeeRoleRequirement()">{{ 'actions.save' | translate }}</button>
				}
			}
			@case (requirementFunctionName.medicalStorePropertyIsOneOfValueCheck) {
				<mat-form-field>
					<mat-select [formControl]="propertyField" [multiple]="true">
						@for (state of stateList;track state) {
							<mat-option [value]="state">{{ state }}</mat-option>
						}
					</mat-select>
					@for (error of formHelperService.getErrors(propertyField) | keyvalue;track error) {
						<mat-error>
							{{ 'input.error.' + error.key | translate: error.value }}
						</mat-error>
					}
				</mat-form-field>
				@if (propertyField.valid) {
					<button mat-stroked-button (click)="saveMedicalStoreHasStateOfStatesRequirement()">{{ 'actions.save' | translate }}</button>
				}
			}
			@case (requirementFunctionName.preQualificationCertificateOneOfExistsCheck) {
				<mat-form-field>
					<mat-select [formControl]="selectVersorgungsBereichField" [multiple]="true">
						@for (vb of possibleVersorgungsbereichs;track vb) {
							<mat-option [value]="vb">{{ vb.name.value | async }}</mat-option>
						}
					</mat-select>
					@for (error of formHelperService.getErrors(propertyField) | keyvalue;track error) {
						<mat-error>
							{{ 'input.error.' + error.key | translate: error.value }}
						</mat-error>
					}
				</mat-form-field>
				@if (propertyField.valid) {
					<button mat-stroked-button (click)="savePQZOneOfRequirement()">{{ 'actions.save' | translate }}</button>
				}
			}
		}
	}
} @else {
	<button mat-button (click)="addRequirement()">{{ 'actions.add' | translate }}</button>
}


<ng-template #deleteButton let-requirement>
	<button mat-icon-button (click)="removeRequirementFromSave(requirement)">
		<fa-icon [icon]="this.iconService.DELETE"></fa-icon>
	</button>
</ng-template>
