@if (earliestDateControl ?? (earliestDateParent | async);as earliestDate) {
	<portal-edit-field-date
		label="baseContract.periodOfValidityAt"
		[validator]="control"
		[earliestDate]="earliestDate$ | async"
		[placeholder]="inheritedValue | async | date"
		[latestDate]="inheritedValue | async"
	>
	</portal-edit-field-date>
} @else {
	<p>{{ 'baseContract.periodOfValidityAt' | translate }}</p>
	<mat-form-field>
		<input matInput [matDatepicker]="datepicker" disabled/>
		<mat-datepicker #datepicker></mat-datepicker>
		<mat-datepicker-toggle matPrefix matSuffix [for]="datepicker"></mat-datepicker-toggle>
	</mat-form-field>
}
