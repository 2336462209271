import {DtoEditFormHelper} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import {
	IconService,
	ModelHelper,
} from '@app/main';
import {
	ExportDtoModel,
	ExportModel,
	ExportService,
} from '@contracts/frontend-api';

@Component({
	selector:    'portal-export-card-master-data',
	templateUrl: './export-card-master-data.component.html',
	styleUrls:   ['./export-card-master-data.component.scss'],
})
export class ExportCardMasterDataComponent implements OnChanges {
	@Input({required: true}) export!: ExportModel | null | undefined;
	public formHelper?: DtoEditFormHelper<ExportModel, ExportService>;
	public canUpdate?: Promise<boolean>;

	constructor(
			public iconService: IconService,
			public exportService: ExportService,
	) { }

	ngOnChanges(changes: SimpleChanges): void {
		if(this.export != null) {
			this.formHelper = DtoEditFormHelper.create(
				ExportDtoModel,
				this.export,
				this.exportService,
			);

			this.canUpdate = ModelHelper.findEditableProperty([
				this.export.nextAt,
				this.export.standardDelay,
			]);
		}
	}
}
