import {
	Component,
	Input,
} from '@angular/core';
import {environment} from '@app/environment';
import {IconService} from '@app/main';
import {MedicalStoreModel} from '@contracts/frontend-api';

@Component({
	selector:    'portal-medical-store-short-card',
	templateUrl: './medical-store-short-card.component.html',
	styleUrls:   ['./medical-store-short-card.component.scss'],
})
export class MedicalStoreShortCardComponent {
	@Input({required: true}) model?: MedicalStoreModel | null;
	@Input({required: true}) title!: string;
	readonly environment = environment;

	constructor(
			public iconService: IconService,
	) {
	}
}
