import {
	Level8Error,
	ResultPageModel,
} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
	OnInit,
} from '@angular/core';
import {environment} from '@app/environment';
import {
	IconService,
	MinimalColumns,
	SearchFilter,
} from '@app/main';
import {
	NewsModel,
	NewsService,
} from '@contracts/frontend-api';

@Component({
	selector:    'portal-news-list-page',
	templateUrl: './news-list-page.component.html',
	styleUrls:   ['./news-list-page.component.scss'],
})
export class NewsListPageComponent implements OnInit {
	@Input() textPreviewLength         = 80;
	headers: MinimalColumns<NewsModel> = {
		displayStartAt: {
			label:     'news.displayStartAt',
			index:     1,
			isVisible: true,
		},
		displayEndAt:   {
			label:     'news.displayEndAt',
			index:     2,
			isVisible: false,
		},
		// todo this column is way to big
		title: {
			label: 'news.title',
			index: 3,
		},
		// todo this column is way to small
		text:       {
			label:         'news.text',
			index:         4,
			prepareSearch: (value) => getText(value),
		},
		importance: {
			label:     'news.importance',
			index:     5,
			isVisible: false,
		},
	};
	protected readonly environment     = environment;
	protected canCreate                = false;
	protected newsDataPage?: Promise<ResultPageModel<NewsModel>>;
	protected readonly searchFilter    = new CSearchFilter();

	constructor(
		protected readonly newsService: NewsService,
		protected readonly iconService: IconService,
	) {
	}

	ngOnInit(): void {
		this.newsDataPage = this.newsService.find(
			{
				column:     'importance',
				comparator: '!=',
				value:      'system',
			},
			{
				pageSize: 15,
			},
		);

		NewsModel.permissionsClass.canCreate({}).then((canCreate) => {
			this.canCreate = canCreate;
		});
	}
}

function getText(value: unknown): string {
	if(typeof value === 'string') {
		const tempDivElement     = document.createElement('div');
		tempDivElement.innerHTML = value;
		return tempDivElement.textContent ?? tempDivElement.innerText;
	}

	throw new Level8Error(`Argument of unexpected type (${typeof value}): ${value}`);
}

class CSearchFilter extends SearchFilter<NewsModel> {
	protected async getModelValue(field: string, model: NewsModel): Promise<unknown> {
		switch(field) {
			case 'text':
				const text = await model.text.firstValue;
				return getText(text);

			default:
				return super.getModelValue(field, model);
		}
	}
}
