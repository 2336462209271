import {
	InheritedCoalesceProperty,
	Level8Error,
} from '@angular-helpers/frontend-api';
import {
	Component,
	DestroyRef,
	Input,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
	UntypedFormControl,
	UntypedFormGroup,
} from '@angular/forms';
import {
	ContractModel,
	ContractSectionModel,
	MasterContractModel,
} from '@contracts/frontend-api';
import moment, {Moment} from 'moment';
import {
	Observable,
	of,
} from 'rxjs';
import {
	mergeMap,
	share,
	take,
} from 'rxjs/operators';

@Component({
	selector:    'portal-base-contract-edit-terminated-at',
	templateUrl: './base-contract-edit-terminated-at.component.html',
	styleUrls:   ['./base-contract-edit-terminated-at.component.scss'],
})
export class BaseContractEditTerminatedAtComponent implements OnChanges {
	@Input({
		required: true,
		alias:    'control',
	}) parent!: UntypedFormGroup;
	@Input() model?: MasterContractModel | ContractModel | ContractSectionModel;
	protected initialTerminatedAt$?: Observable<Date | null | undefined>;

	constructor(
			protected readonly destroyRef: DestroyRef,
	) {
	}

	ngOnChanges(changes: SimpleChanges): void {
		let terminatedAt$ = this.value;

		if(terminatedAt$ == null)
			terminatedAt$ = of(this.control.value);


		this.initialTerminatedAt$ = terminatedAt$.pipe(
				take(1),
				share(),
				takeUntilDestroyed(this.destroyRef),
		);
	}

	get control(): UntypedFormControl {
		const fieldName = 'terminatedAt';
		const control   = this.parent.get(fieldName);
		if(control instanceof UntypedFormControl)
			return control;

		throw new Level8Error(`Unexpected type for field ${fieldName} - expected '${UntypedFormControl.name}' got '${typeof control}' (${control})`);
	}

	get value(): Observable<Date | undefined | null> | undefined {
		if(this.model == null)
			return undefined;

		if(this.model.terminatedAt instanceof InheritedCoalesceProperty)
			return this.model.terminatedAt.withParent.value;

		return this.model.terminatedAt.value;
	}

	get latestDateControl(): Date | moment.Moment | undefined | null {
		const fieldName = 'periodOfValidityAt';
		const control   = this.parent.get(fieldName);
		if(control instanceof UntypedFormControl)
			return control.value;

		throw new Level8Error(`Unexpected type for field ${fieldName} - expected '${UntypedFormControl.name}' got '${typeof control}' (${control})`);
	}

	get latestDateParent(): Observable<Date | null | undefined> {
		if(this.model == null)
			return of(undefined);

		if(this.model.periodOfValidityAt instanceof InheritedCoalesceProperty)
			return this.model.periodOfValidityAt.withParent.value;

		return this.model.periodOfValidityAt.value;
	}

	get earliestDate(): Observable<Date | Moment | undefined> {
		return of(this.earliestDateControl)
			.pipe(mergeMap(control => control != null ? of(control) : this.earliestDateParent));
	}

	get earliestDateParent(): Observable<Date | undefined> {
		if(this.model == null)
			return of(undefined);

		if(this.model.validityStartAt instanceof InheritedCoalesceProperty)
			return this.model.validityStartAt.withParent.value;

		return this.model.validityStartAt.value;
	}

	get earliestDateControl(): Date | moment.Moment | undefined | null {
		const fieldName = 'validityStartAt';
		const control   = this.parent.get(fieldName);
		if(control instanceof UntypedFormControl)
			return control.value;

		throw new Level8Error(`Unexpected type for field ${fieldName} - expected '${UntypedFormControl.name}' got '${typeof control}' (${control})`);
	}
}
