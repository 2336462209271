import {DtoEditFormHelper} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
} from '@angular/core';
import {
	IconService,
	ModelHelper,
} from '@app/main';
import {
	MasterContractDtoModel,
	MasterContractModel,
	MasterContractService,
} from '@contracts/frontend-api';

@Component({
	selector:    'portal-master-contract-card-master-data',
	templateUrl: './master-contract-card-master-data.component.html',
	styleUrls:   ['./master-contract-card-master-data.component.scss'],
})
export class MasterContractCardMasterDataComponent {
	formHelper!: DtoEditFormHelper<MasterContractModel, MasterContractService>;
	private _masterContract!: MasterContractModel;
	public canUpdate?: Promise<boolean>;

	constructor(
		protected masterContractService: MasterContractService,
		public iconService: IconService,
	) {
	}

	get masterContract(): MasterContractModel {
		return this._masterContract;
	}

	@Input({required: true}) set masterContract(value: MasterContractModel) {
		if(this._masterContract === value)
			return;

		this._masterContract = value;
		this.formHelper      = DtoEditFormHelper.create(
				MasterContractDtoModel,
				this.masterContract,
				this.masterContractService,
		);

		this.canUpdate = ModelHelper.findEditableProperty([
			this.masterContract.name,
			this.masterContract.validityStartAt,
			this.masterContract.terminatedAt,
			this.masterContract.minimumTermAt,
			this.masterContract.noticePeriodDelay,
			this.masterContract.periodOfValidityAt,
			this.masterContract.negotiators,
			this.masterContract.initialSigner,
			this.masterContract.mandateGrantor,
		]);
	}
}
