<portal-headline
	label="news.createNew"
	[backlink]="true"
/>

@if (newsForm) {
	<mat-card>
		<mat-card-content class="grid grid-2">
			<portal-news-edit-display-start-at [control]="newsForm"/>
			<portal-news-edit-display-end-at [control]="newsForm"/>
			<portal-news-edit-importance [control]="newsForm"/>
			<portal-news-edit-title [control]="newsForm"/>
			<portal-news-edit-summary [control]="newsForm"/>
			<portal-news-edit-text [control]="newsForm"/>
		</mat-card-content>
		<mat-card-actions>
			@if (errorHasOccurred) {
				<portal-form-error [error]="errorHasOccurred"/>
			}
			<button mat-raised-button (click)="onAbort()">{{ 'actions.cancel' | translate }}</button>
			<button mat-raised-button class="right" [color]="'primary'" (click)="saveForm()">{{ 'actions.save' | translate }}</button>
		</mat-card-actions>
	</mat-card>
}
