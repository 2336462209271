import {PropertyInterface} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
} from '@angular/core';
import {UrlType} from '@app/main';

interface WebsiteInterface {
	website: PropertyInterface<string | undefined>;
}

@Component({
	selector:    'portal-common-show-website',
	templateUrl: './common-show-website.component.html',
	styleUrls:   ['./common-show-website.component.scss'],
})
export class CommonShowWebsiteComponent<T extends WebsiteInterface> {
	@Input({required: true}) model!: T;
	urlType = UrlType;
}
