<portal-edit-field-select
	label="model.versorgungsbereich"
	[options]="possibleValues"
	[control]="vbDataControl"
	[nullable]="false"
	[initialEntry]="vbDataControl.value"
/>
<portal-pseudo-versorgungsbereich-edit
    #advancedOptions
		[class.hide-field]="!showPsVbField"
		[fieldControl]="vbDataControl"
		(psVbCreated)="addPsVb($event)"
/>
