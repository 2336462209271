import { Injectable } from '@angular/core';
import {
	AuthService,
	ConfirmDialogAnswer,
	ConfirmDialogConfig,
	IconService,
} from '@app/main';
import { DialogService } from './dialog.service';

enum Browser {
	edge             = 'Edge',
	opera            = 'Opera',
	chrome           = 'Chrome',
	internetExplorer = 'Internet Explorer',
	firefox          = 'Firefox',
	safari           = 'Safari',
	unknown          = 'Unknown',
}

@Injectable({
	providedIn: 'root',
})
export class DetectOutdatedBrowserService {
	readonly isOutdatedBrowser = this._isOutdatedBrowser();
	readonly SAVE_CONFIRMATION = 'outdated-browser-confirmed';

	constructor(
		private readonly dialogService: DialogService,
		private readonly iconService: IconService,
		private readonly authService: AuthService,
	) {
	}

	isConfirmed(): boolean {
		return localStorage.getItem(this.SAVE_CONFIRMATION) != null;
	}

	track(): void {
		this.authService.user$.subscribe(async user => {
			if(user == null)
				return;

			const outdatedBrowser = this.isOutdatedBrowser;
			if(outdatedBrowser === false)
				return;

			if(this.isConfirmed())
				return;

			let data: ConfirmDialogConfig | null;
			if(outdatedBrowser === true) {
				data = {
					icon:          this.iconService.ICON_ALERT,
					title:         'system.outdatedBrowser.outdatedBrowser.title',
					message:       'system.outdatedBrowser.outdatedBrowser.message',
					labelPositiv:  'system.outdatedBrowser.abort',
					labelNegative: 'system.outdatedBrowser.continue',
				};
			} else {
				data = {
					icon:          this.iconService.ICON_ALERT,
					title:         'system.outdatedBrowser.unknownBrowser.title',
					message:       'system.outdatedBrowser.unknownBrowser.message',
					labelPositiv:  'system.outdatedBrowser.abort',
					labelNegative: 'system.outdatedBrowser.continue',
				};
			}
			const response = await this.dialogService.getResult(data);
			switch(response) {
				case ConfirmDialogAnswer.negative:
					localStorage.setItem(this.SAVE_CONFIRMATION, 'magicValue');
					break;

				case ConfirmDialogAnswer.positive:
					window.location.href = 'https://browsehappy.com';
					break;

				case ConfirmDialogAnswer.unknown:
			}
		});
	}

	detectBrowserName(): Browser {
		const agent = navigator.userAgent.toLowerCase();

		if(agent.indexOf('edge') > -1)
			return Browser.edge;

		if(agent.indexOf('opr') > -1 && ('opr' in window))
			return Browser.opera;

		if(agent.indexOf('chrome') > -1 && ('chrome' in window))
			return Browser.chrome;

		if(agent.indexOf('trident') > -1)
			return Browser.internetExplorer;

		if(agent.indexOf('firefox') > -1)
			return Browser.firefox;

		if(agent.indexOf('safari') > -1)
			return Browser.safari;

		return Browser.unknown;
	}

	detectBrowserVersion(): string {
		const userAgent = navigator.userAgent;
		let tem;
		let matchTest   = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

		if(/trident/i.test(matchTest[1])) {
			tem = /\brv[ :]+(\d+)/g.exec(userAgent) ?? [];
			return tem[1];
		}

		if(matchTest[1] === 'Chrome') {
			tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
			if(tem != null)
				return tem.slice(1).join(' ').replace('OPR', '');
		}

		matchTest = matchTest[2] ? [
			matchTest[1],
			matchTest[2],
		] : [
			navigator.appName,
			navigator.appVersion,
			'-?',
		];
		if((tem = userAgent.match(/version\/(\d+(\.\d+)?)/i)) != null)
			matchTest.splice(1, 1, tem[1]);

		return matchTest[1];
	}

	protected _isOutdatedBrowser(): boolean | null {
		const browser = this.detectBrowserName();
		const version = Number.parseFloat(this.detectBrowserVersion());

		switch(browser) {
			case Browser.firefox:
				return version < 93;

			case Browser.edge:
			case Browser.chrome:
				return version < 94;

			case Browser.safari:
				return version < 15.1;

			case Browser.opera:
				return version < 82;

			case Browser.unknown:
				return null;

			case Browser.internetExplorer:
				return true;
		}
	}
}
