import {
	Component,
	Input,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import {environment} from '@app/environment';
import {
	ContractModel,
	MasterContractModel,
} from '@contracts/frontend-api';
import {
	Observable,
	Subject,
} from 'rxjs';
import {
	catchError,
	map,
} from 'rxjs/operators';

@Component({
	selector:    'portal-contract-show',
	templateUrl: './contract-show.component.html',
	styleUrls:   ['./contract-show.component.scss'],
})
export class ContractShowComponent implements OnChanges {
	public model$                                          = new Subject<ContractModel | undefined>();
	public parent$: Observable<MasterContractModel> | null = null;
	public modelName$?: Observable<string>;

	@Input({required: true}) model?: ContractModel | null | undefined;

	readonly environment = environment;

	ngOnChanges(changes: SimpleChanges): void {
		if(this.model == null)
			return;

		this.model$.next(this.model);

		this.modelName$ = this.model.name.value.pipe(
			map((name) => {
				if(name == null)
					throw new Error('name is undefined');

				return name;
			}),
			catchError((error, caughtObservable) => {
				console.warn(error.message);
				return caughtObservable;
			}),
		);

		this.parent$ = this.model.parent.value.pipe(
			map((parent) => {
				if(parent == null)
					throw new Error('parent is undefined');

				return parent;
			}),
			catchError((error, caughtObservable) => {
				console.warn(error.message);
				return caughtObservable;
			}),
		);
	}
}
