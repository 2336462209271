import {
	DtoEditFormHelper,
	Level8Error,
} from '@angular-helpers/frontend-api';
import {
	AsyncPipe,
	NgIf,
} from '@angular/common';
import {
	Component,
	DestroyRef,
	inject,
	OnInit,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
	FormControl,
	ReactiveFormsModule,
} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogRef} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {TaskDetectorService} from '@app/contracts';
import {
	BaseDialogComponent,
	BaseDialogData,
	DialogService,
	IconService,
	MainModule,
} from '@app/main';
import {
	HmmParticipation,
	MedicalStoreDtoModel,
	MedicalStoreModel,
	MedicalStoreService,
} from '@contracts/frontend-api';
import {TranslateModule} from '@ngx-translate/core';
import {
	first,
	tap,
} from 'rxjs/operators';

@Component({
	selector:    'portal-hmm-participation-popup',
	templateUrl: './hmm-participation-popup.component.html',
	styleUrl:    './hmm-participation-popup.component.scss',
	standalone: true,
	imports:    [
		TranslateModule,
		AsyncPipe,
		MatRadioModule,
		MainModule,
		MatInputModule,
		ReactiveFormsModule,
		MatButtonModule,
		NgIf,
	],
})
export class HmmParticipationPopupComponent implements OnInit {
	@ViewChild('popup') popup!: TemplateRef<unknown>;
	protected readonly taskDetectorService                                                                   = inject(TaskDetectorService);
	protected readonly dialogService                                                                         = inject(DialogService);
	protected readonly iconService                                                                           = inject(IconService);
	protected readonly destroyRef                                                                            = inject(DestroyRef);
	protected readonly medicalStoreService                                                                   = inject(MedicalStoreService);
	protected readonly hmmParticipation                                                                      = HmmParticipation;
	protected readonly formHelpers: Map<MedicalStoreModel, DtoEditFormHelper<MedicalStoreModel, MedicalStoreService>> = new Map();
	protected readonly controls: Map<MedicalStoreModel, Promise<FormControl>>                                         = new Map();
	protected medicalStores?: MedicalStoreModel[];
	protected dialogRef?: MatDialogRef<BaseDialogComponent>;

	ngOnInit(): void {

		this.taskDetectorService.hmmParticipationMissing$
		    .pipe(
			    takeUntilDestroyed(this.destroyRef),
			    first(),
			    tap(async () => {
				    // data must be loaded before dialog is opened or change detection seems miss this change ¯\_(ツ)_/¯
				    this.medicalStores = await this.medicalStoreService.getAllModels();
			    }),
		    )
		    .subscribe(() => {
			    const dialogData: BaseDialogData = {
				    acceptText:        'actions.save',
				    cancelButtonText:  'actions.delay',
				    enableContentGrid: false,
				    icon:              this.iconService.ICON_ALERT,
				    content:           this.popup,
				    headline:          'medicalStore.hmmParticipationPopup.title',
				    cancel:            async () => this.dialogRef?.close(),
				    save:              this.save.bind(this),
			    };
			    this.dialogRef                   = this.dialogService.openBaseDialog(dialogData);
		    });
	}

	protected async setAll(value: HmmParticipation): Promise<void> {
		for(const control$ of this.controls.values()) {
			const control = await control$;
			control.markAsDirty();
			control.markAsTouched();
			control.setValue(value);
		}
	}

	protected async getFormHelper(medicalStore: MedicalStoreModel): Promise<DtoEditFormHelper<MedicalStoreModel, MedicalStoreService>> {
		let formHelper = this.formHelpers.get(medicalStore);
		if(formHelper == null) {
			formHelper = DtoEditFormHelper.create(MedicalStoreDtoModel, medicalStore, this.medicalStoreService);
			this.formHelpers.set(medicalStore, formHelper);
		}

		return formHelper;
	}

	protected getControl(medicalStore: MedicalStoreModel): Promise<FormControl> {
		let control = this.controls.get(medicalStore);
		if(control == null) {
			control = this.getFormHelper(medicalStore)
			              .then(formHelper => formHelper.control)
			              .then(control => {
				              const formHelperControl = control.controls.hmmParticipation;
				              if(!(formHelperControl instanceof FormControl))
					              throw new Level8Error(`Invalid control for hmmParticipation`);

				              return formHelperControl;
			              });
			this.controls.set(medicalStore, control);
		}

		return control;
	}

	protected async save(): Promise<void> {
		const savingPromises: Promise<unknown>[] = [];
		for(const [, formHelper] of this.formHelpers.entries())
			savingPromises.push(formHelper.save());


		await Promise.all(savingPromises);
		this.dialogRef?.close();
	}

}
