import {KeyValue} from '@angular/common';
import {
	Component,
	Input,
} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {
	FormHelperService,
	LabelSelectFieldEntry,
	SelectFieldEntry,
} from '@app/main';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector:    'portal-edit-field-multiselect',
	templateUrl: './edit-field-multiselect.component.html',
	styleUrls:   ['./edit-field-multiselect.component.scss'],
})
export class EditFieldMultiselectComponent {
	@Input({required: true}) label!: string;
	@Input({required: true}) control?: UntypedFormControl;
	@Input() initialEntries?: SelectFieldEntry[];
	@Input() inheritedValues?: unknown[] | null;

	constructor(
			public formHelperService: FormHelperService,
			protected readonly translateService: TranslateService,
	) {
	}

	get placeholder(): string | undefined {
		if(this.inheritedValues != null) {
			return this.options?.filter(option => this.inheritedValues?.includes(option.value))
					   .map(option => 'label' in option ? option.label : option.value)
					   .join(', ');
		}

		if(this.control == null)
			return this.control;

		if(this.control.value === null)
			return this.translateService.instant('general.nullOption');

		return undefined;
	}

	get optionsMap(): ReadonlyMap<unknown, string> | undefined {
		return this.options?.reduce(
				(map, value) => ('label' in value) ? map.set(value.value, value.label) : map,
				new Map<unknown, string>(),
		);
	}

	private _options?: SelectFieldEntry[];
	@Input() get options(): SelectFieldEntry[] | null | undefined {
		return this._options ?? undefined;
	}

	set options(options: SelectFieldEntry[] | null | undefined) {
		if(options === this._options)
			return;

		this._options = options ?? undefined;

		const currentSelection = this.control?.value;
		if(this.control == null || currentSelection == null || !Array.isArray(currentSelection) || options == null)
			return;

		const newSelection = currentSelection.filter(val => options.map(o => o.value).includes(val));
		if(newSelection.length !== currentSelection.length)
			this.control.setValue(newSelection, {emitEvent: false});
	}

	get isEmpty(): boolean {
		return (this.options?.length ?? 0) < 1;
	}

	sortByValue(a: KeyValue<unknown, string>, b: KeyValue<unknown, string>): number {
		return a.value.localeCompare(b.value);
	}

	isInherited(value: unknown): boolean {
		if(this.inheritedValues == null)
			return false;

		return this.inheritedValues.includes(value);
	}

	protected get initialEntriesData(): LabelSelectFieldEntry[] | undefined {
		if(this.initialEntries == null)
			return this.initialEntries;

		return this.initialEntries.map(entry => {
			if('label' in entry)
				return entry;

			return {
				label:  entry.value,
				value:  entry,
				filter: entry.filter,
			};
		});
	}
}
