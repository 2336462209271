import {
	Component,
	Input,
} from '@angular/core';
import {
	BaseContractApiService,
	BaseContractModel,
	BaseContractService,
	ContractModel,
	ContractSectionModel,
	MasterContractModel,
} from '@contracts/frontend-api';
import {
	Observable,
	throwError,
} from 'rxjs';

@Component({
	selector:    'portal-base-contract-show-billing-date',
	templateUrl: './base-contract-show-billing-delay.component.html',
	styleUrls:   ['./base-contract-show-billing-delay.component.scss'],
})
export class BaseContractShowBillingDelayComponent<Model extends BaseContractModel<BaseContractService<BaseContractApiService, Model>>> {
	@Input({required: true}) model?: Model;

	getRelativeDate(): Observable<string | undefined> | undefined {
		if(this.model == null)
			return this.model;

		if(this.model instanceof MasterContractModel)
			return this.model.billingDelay.value;

		if(this.model instanceof ContractSectionModel || this.model instanceof ContractModel)
			return this.model.billingDelay.withParent.value;

		return throwError('Unknown Contract Model');
	}

}
