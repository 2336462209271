<span>{{ 'preQualificationCertificate.selectVersorgungsbereichs' | translate }}</span>

<portal-loading [loading]="versorgungsbereichs == null">

	@for (error of formHelperService.getErrors(control) | keyvalue;track error) {
		<mat-error>
			{{ 'input.error.' + error.key | translate: error.value }}
		</mat-error>
	}

	@if (versorgungsbereichs) {
		<div
			class="table"
			[style.--columns]="subgroups.length"
		>
			@for (produktgruppe of produktgruppes;track produktgruppe;let row = $index) {
				<div class="row">
					@if (versorgungsbereichs.get(produktgruppe);as versorgungsbereichsPg) {
						<div class="first row-{{row}}" (click)="toggleSelectionAll(versorgungsbereichsPg)">{{ produktgruppe }}</div>
						@for (subgroup of subgroups;track subgroup;let column = $index) {
							@if (versorgungsbereichsPg.get(subgroup);as vb) {
								<div
									class="entry row-{{row}} column-{{column}}"
									(click)="toggleSelection(vb)"
									[class.selected]="isSelected(vb)"
								>
									{{ subgroup }}
								</div>
							}
						}
					}
				</div>
			}
		</div>
	}
</portal-loading>
