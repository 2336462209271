@if (file) {
	<portal-showable
		[label]="label"
		[value]="file"
	>
		@if ((file.showUrl.value | async) || (file.downloadUrl.value | async)) {
			<div class="body">
				<a routerLink="." (click)="showFile()" class="invisible-link">{{ file.description.value | async }}</a>
				<a [routerLink]="null" (click)="downloadFile()">
					<fa-icon [icon]="iconService.FILE_DOWNLOAD"></fa-icon>
				</a>
				@if (showValidityStartAt) {
					<span class="validityStartAt pushRight">({{ file.validityStartAt.value | async | date }})</span>
				}
			</div>
		}
	</portal-showable>
}
