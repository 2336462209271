<portal-headline
	label="model.contractingParty"
	[menu]="menu"
	[backlink]="true"
>
	<mat-menu #menu>
		@if ((isEmpty | async) === true && (getModel().permissions.canDelete | async)) {
			<button mat-menu-item (click)="showDeleteDialog()">
				<fa-icon matMenuItemIcon [icon]="iconService.DELETE"></fa-icon>
				<span>{{ 'actions.delete' | translate }}</span>
			</button>
		}
	</mat-menu>
</portal-headline>

<div class="flex-container">
	<portal-contracting-party-card-master-data [contractingParty]="getModel()"/>
	<ng-container *ngLet="getModel().masterContracts.value | async as masterContracts">
		@if (masterContracts == null || masterContracts.length > 1) {
			<portal-master-contract-card-list [masterContracts]="masterContracts"/>
		}
	</ng-container>
</div>
