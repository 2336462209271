import {Component} from '@angular/core';
import {environment} from '@app/environment';
import {AbstractShowComponent} from '@app/main';
import {
	MasterContractModel,
	MasterContractService,
} from '@contracts/frontend-api';
import {
	from,
	Observable,
	of,
} from 'rxjs';
import {
	first,
	mergeMap,
} from 'rxjs/operators';

@Component({
	templateUrl: './master-contracts-page-show.component.html',
	styleUrls:   ['./master-contracts-page-show.component.scss'],
})
export class MasterContractsPageShowComponent extends AbstractShowComponent<MasterContractModel, MasterContractService> {
	protected readonly environment = environment;
	protected canCreateContracts   = false;
	protected canDeleteContract    = false;

	constructor(
		masterContractService: MasterContractService,
	) {
		super(masterContractService);
	}

	get hasChildren$(): Observable<boolean> {
		return this.getModel()
		           .contracts
		           .mapToObservable(children => (children == null) ? false : (children.length > 0));
	}

	protected async onNewModel(newModel: MasterContractModel, oldModel?: MasterContractModel): Promise<void> {
		await super.onNewModel(newModel, oldModel);
		newModel.contracts.permissions.canCreate
		        .then(permitted => this.canCreateContracts = permitted);

		this.hasChildren$.pipe(
			mergeMap(hasChildren => {
				if(hasChildren)
					return of(false);

				return from(newModel.permissions.canDelete);
			}),
			first(),
		).toPromise()
		    .then(permitted => this.canDeleteContract = permitted);
	}

	protected async getModelName(): Promise<string | undefined> {
		return this.getModel().name.firstValue;
	}
}
