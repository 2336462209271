import {Level8Error} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
} from '@angular/forms';
import {environment} from '@app/environment';
import {AngularEditorConfig} from '@kolkov/angular-editor';


@Component({
	selector:  'portal-news-edit-text',
	templateUrl: './news-edit-text.component.html',
	styleUrls: ['./news-edit-text.component.scss'],
})
export class NewsEditTextComponent implements OnChanges {
	@Input({
		required: true,
		alias:    'control',
	}) parent!: UntypedFormGroup;
	protected control!: UntypedFormControl;
	protected readonly config: AngularEditorConfig = {...environment.config};
	private readonly fieldName                     = 'text';

	ngOnChanges(changes: SimpleChanges): void {
		const control = this.parent.get(this.fieldName);
		if(!(control instanceof UntypedFormControl))
			throw new Level8Error(`Unexpected type for field ${this.fieldName} - expected '${UntypedFormControl.name}' got '${typeof control}' (${control})`);

		this.control         = control;
		this.config.editable = control.enabled;

	}
}
