import {combineLatest, Observable, timer} from 'rxjs';
import {map} from 'rxjs/operators';

export class Tools {
	protected static readonly TIMER$ = timer(0, 15_000);
	protected static foo?: Observable<string>;

	static getEditedAt(updatedAt: Observable<Date>): Observable<string> {
		return combineLatest([
			updatedAt,
			this.TIMER$,
		]).pipe(map(([updatedAtValue, _]) => this.formatEditedAt(updatedAtValue)));
	}

	static formatEditedAt(updatedAt: Date | undefined): string {
		if(updatedAt === undefined)
			return '';

		const now = new Date();
		let diff  = now.getFullYear() - updatedAt.getFullYear();
		if(diff > 1)
			return `${diff} Jahren`;
		if(diff > 0)
			return `${diff} Jahr`;

		diff = now.getMonth() - updatedAt.getMonth();
		if(diff > 1)
			return `${diff} Monaten`;
		if(diff > 0)
			return `${diff} Monat`;

		diff = now.getDate() - updatedAt.getDate();
		if(diff > 1)
			return `${diff} Tagen`;
		if(diff > 0)
			return `${diff} Tag`;

		diff = now.getHours() - updatedAt.getHours();
		if(diff > 1)
			return `${diff} Stunden`;
		if(diff > 0)
			return `${diff} Stunde`;

		diff = now.getMinutes() - updatedAt.getMinutes();
		if(diff > 1)
			return `${diff} Minuten`;
		if(diff > 0)
			return `${diff} Minute`;

		return 'Soeben';
	}
}
