@if (data != null) {
	<portal-table-client-side-searchable
		[headers]="tableHeaders"
		[searchFilter]="searchFilter"
		[columnLink]="environment.medicalStoresFullUrl"
		[data]="data"
	>
		<ng-template column="mainHouse" let-model="model" [data]="data">
			<portal-skeleton-field [content]="model.parent.value">
				<ng-template let-parent skeletonContent>
					<p>{{ (parent == null ? 'medicalStore.mainHouse' : 'medicalStore.branchOffice') | translate }}</p>
				</ng-template>
			</portal-skeleton-field>
		</ng-template>
		<ng-template column="institutionskennzeichens" let-model="model" [data]="data">
			<portal-skeleton-field [content]="iks$(model)" loadingContent="123456789">
				<ng-template let-iks skeletonContent>
					@for (ik of iks;track ik;let last = $last) {
						{{ ik | formatInstitutionskennzeichen }}
						@if (!last) {
							<br/>
						}
					}
				</ng-template>
			</portal-skeleton-field>
		</ng-template>
		<ng-template column="landesinnung" let-model="model" [data]="data">
			<portal-skeleton-field [content]="$any((model.landesinnung.value | async))?.name?.value" loadingContent="Landesinnung für Orthopädie-Technik Dortmund">
			</portal-skeleton-field>
		</ng-template>
		<ng-template column="fax" let-model="model" [data]="data">
			<portal-skeleton-field [content]="model.fax.value" loadingContent="023045566987">
				<ng-template let-number skeletonContent>
					{{ number | phone }}
				</ng-template>
			</portal-skeleton-field>
		</ng-template>
		<ng-template column="phone" let-model="model" [data]="data">
			<portal-skeleton-field [content]="model.phone.value" loadingContent="023045566987">
				<ng-template let-number skeletonContent>
					{{ number | phone }}
				</ng-template>
			</portal-skeleton-field>
		</ng-template>
		<ng-template column="address" let-model="model" [data]="data">
			<portal-skeleton-field [content]="printAddress(model.address.value)" loadingContent="Dummystraße 55, 12345 Exampleburg">
			</portal-skeleton-field>
		</ng-template>
	</portal-table-client-side-searchable>
}

@if (data == null) {
	<portal-table-server-side-searchable
		[headers]="tableHeaders"
		[columnLink]="environment.medicalStoresFullUrl"
		[service]="medicalStoreService"
	>
		<ng-template column="mainHouse" let-model="model" [service]="medicalStoreService">
			<portal-skeleton-field [content]="model.parent.value">
				<ng-template let-parent skeletonContent>
					<p>{{ (parent == null ? 'medicalStore.mainHouse' : 'medicalStore.branchOffice') | translate }}</p>
				</ng-template>
			</portal-skeleton-field>
		</ng-template>
		<ng-template column="institutionskennzeichens" let-model="model" [service]="medicalStoreService">
			<portal-skeleton-field [content]="iks$(model)">
				<ng-template let-iks skeletonContent>
					@for (ik of iks;track ik;let last = $last) {
						{{ ik | formatInstitutionskennzeichen }}
						@if (!last) {
							<br/>
						}
					}
				</ng-template>
			</portal-skeleton-field>
		</ng-template>
		<ng-template column="landesinnung" let-model="model" [service]="medicalStoreService">
			<portal-skeleton-field [content]="$any((model.landesinnung.value | async))?.name?.value"/>
		</ng-template>
		<ng-template column="fax" let-model="model" [service]="medicalStoreService">
			<portal-skeleton-field [content]="model.fax.value">
				<ng-template let-number skeletonContent>
					{{ number | phone }}
				</ng-template>
			</portal-skeleton-field>
		</ng-template>
		<ng-template column="phone" let-model="model" [service]="medicalStoreService">
			<portal-skeleton-field [content]="model.phone.value">
				<ng-template let-number skeletonContent>
					{{ number | phone }}
				</ng-template>
			</portal-skeleton-field>
		</ng-template>
		<ng-template column="address" let-model="model" [service]="medicalStoreService">
			<portal-skeleton-field [content]="printAddress(model.address.value)"/>
		</ng-template>
	</portal-table-server-side-searchable>
}
