import {
	Component,
	Input,
} from '@angular/core';
import {environment} from '@app/environment';
import {MedicalStoreModel} from '@contracts/frontend-api';

@Component({
	selector:    'portal-medical-store-show-institutionskennzeichens',
	templateUrl: './medical-store-show-institutionskennzeichens.component.html',
	styleUrls:   ['./medical-store-show-institutionskennzeichens.component.scss'],
})
// todo this should be named 'institutionskennzeichen-show'
export class MedicalStoreShowInstitutionskennzeichensComponent {
	@Input({required: true}) model!: MedicalStoreModel;
	readonly environment = environment;
}
