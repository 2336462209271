import {Level8Error} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
	OnDestroy,
	OnInit,
} from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
} from '@angular/forms';
import {
	FormHelperService,
	unique,
} from '@app/main';
import {
	VersorgungsbereichModel,
	VersorgungsbereichService,
} from '@contracts/frontend-api';
import {Moment} from 'moment';
import {
	combineLatest,
	Observable,
	of,
	Subscription,
} from 'rxjs';
import {
	first,
	map,
} from 'rxjs/operators';

@Component({
	selector: 'portal-pre-qualification-certificate-edit-versorgungsbereichs',
	templateUrl: './pre-qualification-certificate-edit-versorgungsbereichs.component.html',
	styleUrls: ['./pre-qualification-certificate-edit-versorgungsbereichs.component.scss'],
})
export class PreQualificationCertificateEditVersorgungsbereichsComponent implements OnInit, OnDestroy {
	versorgungsbereichs?: Map<string, Map<string, VersorgungsbereichModel>>;
	produktgruppes: string[]                                = [];
	subgroups: string[]                                     = [];
	protected subscriptions: Subscription[]                 = [];
	protected _allVersorgungsbereichs?: VersorgungsbereichModel[];
	private _parent!: UntypedFormGroup;
	private _selectedVb: readonly VersorgungsbereichModel[] = [];

	constructor(
		private readonly versorgungsbereichService: VersorgungsbereichService,
		public readonly formHelperService: FormHelperService,
	) {
	}

	get control(): UntypedFormControl {
		const fieldName = 'versorgungsbereichs';
		const control   = this.parent.get(fieldName);
		if(control instanceof UntypedFormControl)
			return control;

		throw new Level8Error(`Unexpected type for field ${fieldName} - expected '${UntypedFormControl.name}' got '${typeof control}' (${control})`);
	}

	get selectedVb(): readonly VersorgungsbereichModel[] {
		return this._selectedVb;
	}

	set selectedVb(value: readonly VersorgungsbereichModel[]) {
		this._selectedVb = value;
		this.control.markAsTouched();
		this.control.markAsDirty();
		this.control.setValue(value.map(vb => vb.id));
	}

	get parent(): UntypedFormGroup {
		return this._parent;
	}

	@Input({
		required: true,
		alias:    'control',
	})
	set parent(parent: UntypedFormGroup) {
		this._parent = parent;

		this.subscriptions.push(this.validityStartAtControl.valueChanges.subscribe(() => this.buildVersorgungsbereichs()));
	}

	protected get validityStartAt(): Moment | Date | undefined {
		return this.validityStartAtControl.value;
	}

	protected get validityStartAtControl(): UntypedFormControl {
		const control = this.parent.get('validityStartAt');
		if(control == null) {
			throw new Level8Error(`Incorrect form: validityStartAt missing in [${Object.keys(this.parent.controls)
																					   .join(',')}]`);
		}

		if(!(control instanceof UntypedFormControl))
			throw new Level8Error(`Incorrect form type: expected ${UntypedFormControl.name} got '${typeof control}'`);

		return control;
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	ngOnInit(): void {
		this.buildVersorgungsbereichs();
	}

	isSelected(vb: VersorgungsbereichModel): boolean {
		return this.selectedVb.includes(vb);
	}

	toggleSelectionAll(vbs: ReadonlyMap<string, VersorgungsbereichModel>): void {
		let isSelected = true;
		for(const [, vb] of vbs) {
			if(!this.isSelected(vb)) {
				isSelected = false;
				break;
			}
		}

		if(isSelected)
			vbs.forEach(vb => this.unselectVb(vb));
		else
			vbs.forEach(vb => this.selectVb(vb));
	}

	selectVb(vb: VersorgungsbereichModel): void {
		if(this.isSelected(vb))
			return;

		this.selectedVb = [
			...this.selectedVb,
			vb,
		];
	}

	unselectVb(vb: VersorgungsbereichModel): void {
		this.selectedVb = this.selectedVb.filter(value => value !== vb);
	}

	toggleSelection(vb: VersorgungsbereichModel): void {
		if(this.selectedVb.includes(vb))
			this.unselectVb(vb);
		else
			this.selectVb(vb);
	}

	protected isValidVb$(vb: VersorgungsbereichModel): Observable<boolean> {
		const validityStartAt = this.validityStartAt;
		if(validityStartAt == null)
			return of(false);


		return combineLatest([
			vb.validityStartAt.value,
			vb.validityEndAt.value,
		]).pipe(
			map(([vbStartAt, vbEndAt]) => {
				if(vbStartAt == null || vbStartAt > validityStartAt)
					return false;

				if(vbEndAt == null)
					return true;

				if(vbEndAt < validityStartAt)
					return false;

				return true;
			}),
		);
	}

	protected async buildVersorgungsbereichs(): Promise<void> {
		const subgroups: string[]      = [];
		const produktgruppes: string[] = [];
		const pgMap                    = new Map<string, Map<string, VersorgungsbereichModel>>();
		const versorgungsbereichs      = await this.getAllModels();
		for(const versorgungsbereich of versorgungsbereichs) {
			if((await this.isValidVb$(versorgungsbereich).pipe(first()).toPromise()) === false)
				continue;

			const name = await versorgungsbereich.nameShort.firstValue;
			if(name == null)
				continue;

			const produktgruppe = name.match(/^[0-9]*/)?.pop();
			if(produktgruppe == null || produktgruppe === '')
				continue;

			produktgruppes.push(produktgruppe);

			let subMap = pgMap.get(produktgruppe);
			if(subMap == null) {
				subMap = new Map<string, VersorgungsbereichModel>();
				pgMap.set(produktgruppe, subMap);
			}

			const subgroup = name.substr(produktgruppe.length);
			subgroups.push(subgroup);
			subMap.set(subgroup, versorgungsbereich);
		}

		this.subgroups      = subgroups
			.filter(unique)
			.sort();
		this.produktgruppes = produktgruppes
			.filter(unique)
			.sort();

		this.versorgungsbereichs = pgMap;
	}

	private async getAllModels(): Promise<VersorgungsbereichModel[]> {
		if(!this._allVersorgungsbereichs)
			this._allVersorgungsbereichs = await this.versorgungsbereichService.getAllModels();

		return this._allVersorgungsbereichs;
	}
}
