<portal-table-client-side-searchable
		[headers]="tableHeaders"
		[searchFilter]="searchFilter"
		[columnLink]="environment.professionalAssociationFullUrl"
		[data]="data"
>
	<ng-template column="fax" let-model="model" [data]="data">
		<portal-skeleton-field [content]="model.fax.value">
			<ng-template let-number skeletonContent>
				{{ number | phone }}
			</ng-template>
		</portal-skeleton-field>
	</ng-template>
	<ng-template column="phone" let-model="model" [data]="data">
		<portal-skeleton-field [content]="model.phone.value">
			<ng-template let-number skeletonContent>
				{{ number | phone }}
			</ng-template>
		</portal-skeleton-field>
	</ng-template>
	<ng-template column="website" let-model="model" [data]="data">
		<portal-skeleton-field [content]="model.website.value">
			<ng-template let-site skeletonContent>
				{{ site | website }}
			</ng-template>
		</portal-skeleton-field>
	</ng-template>
</portal-table-client-side-searchable>
