import {Level8Error} from '@angular-helpers/frontend-api';
import {NestedTreeControl} from '@angular/cdk/tree';
import {
	Component,
	Injector,
	OnInit,
	runInInjectionContext,
} from '@angular/core';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {
	IsActiveMatchOptions,
	NavigationEnd,
	Router,
} from '@angular/router';
import {environment} from '@app/environment';
import {
	ContractAccessionModel,
	ContractingPartyModel,
	LandesinnungModel,
	MasterContractModel,
	MedicalStoreModel,
	NewsModel,
	ProfessionalAssociationModel,
	RawContentModel,
} from '@contracts/frontend-api';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {
	faAngleDown,
	faAngleUp,
} from '@fortawesome/free-solid-svg-icons';
import {
	Observable,
	of,
} from 'rxjs';
import {
	filter,
	map,
	mergeMap,
} from 'rxjs/operators';
import {hasPermission} from '../../guards/has-permission.guard';
import {combineLatestSafe} from '../../Helper/combine-latest-empty-safe';
import {AuthService} from '../../services/auth.service';
import {IconService} from '../../services/icon.service';

@Component({
	selector:    'portal-sidenav',
	templateUrl: './sidenav.component.html',
	styleUrls:   ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
	readonly ICON_EXPANDED                                    = faAngleUp;
	readonly ICON_COMPRESSED                                  = faAngleDown;
	public treeControl                                        = new NestedTreeControl<MenuItem>((node: MenuItem) => node.children);
	public dataSource                                         = new MatTreeNestedDataSource<MenuItem>();
	protected readonly ITEMS_INTERFACE: (MenuItemInterface)[] = [
		{
			name:    'general.home',
			url:     '/',
			icon:    this.iconService.HOME,
			visible: this.auth.isLoggedIn$.pipe(
				mergeMap(isLoggedIn => {
						if(!isLoggedIn)
							return of(false);

						return runInInjectionContext(this.injector, () => hasPermission(NewsModel));
					},
				),
				map(value => !value),
			),
		},
		{
			name:     'page.start',
			icon:     this.iconService.HOME,
			children: [
				{
					name:    'general.dashboard',
					url:     environment.dashboardFullUrl,
					visible: this.auth.isLoggedIn$.pipe(
						mergeMap(isLoggedIn => {
								if(!isLoggedIn)
									return of(false);

								return runInInjectionContext(this.injector, () => hasPermission(NewsModel));
							},
						),
					),
				},
				{
					name:    'news.sidenavTitle',
					url:     environment.newsFullUrl,
					visible: this.auth.isLoggedIn$.pipe(
						mergeMap(isLoggedIn => {
								if(!isLoggedIn)
									return of(false);

								return runInInjectionContext(this.injector, () => hasPermission(NewsModel));
							},
						),
					),
				},
				{
					name:    'general.newsletter',
					url:     environment.newsletterFullUrl,
					visible: this.auth.isLoggedIn$.pipe(
						mergeMap(isLoggedIn => {
								if(!isLoggedIn)
									return of(false);

								return runInInjectionContext(
									this.injector,
									() => hasPermission(RawContentModel, environment.newsletterRawContentId),
								);
							},
						),
					),
				},
			],
		},
		{
			name:     'page.infoPages.header',
			icon:     this.iconService.MEMBER_INFO,
			children: [
				{
					name:    'page.infoPages.mdr.header',
					url:     environment.infoPageMdrFullUrl,
					visible: this.auth.isLoggedIn$.pipe(
						mergeMap(isLoggedIn => {
								if(!isLoggedIn)
									return of(false);

								return runInInjectionContext(
									this.injector,
									() => hasPermission(
										RawContentModel,
										environment.memberInformationPageRawContentId,
									),
								);
							},
						),
					),
				},
				{
					name:    'page.infoPages.imageCampaignCraftsmanship.header',
					url:     environment.infoPageImageCampaignCraftsmanshipFullUrl,
					visible: this.auth.isLoggedIn$.pipe(
						mergeMap(isLoggedIn => {
								if(!isLoggedIn)
									return of(false);

								return runInInjectionContext(
									this.injector,
									() => hasPermission(
										RawContentModel,
										environment.memberInformationPageRawContentId,
									),
								);
							},
						),
					),
				},
			],
		},
		{
			name:     'page.my',
			icon:     this.iconService.MEDICAL_STORE,
			children: [
				{
					name:    'model.medicalStores',
					url:     environment.medicalStoresFullUrl,
					visible: this.auth.isLoggedIn$.pipe(
						mergeMap(isLoggedIn => {
								if(!isLoggedIn)
									return of(false);

								return runInInjectionContext(this.injector, () => hasPermission(MedicalStoreModel));
							},
						),
					),
				},
				{
					name:    'model.contractAccessions',
					url:     environment.contractAccessionFullUrl,
					visible: this.auth.isLoggedIn$.pipe(
						mergeMap(isLoggedIn => {
								if(!isLoggedIn)
									return of(false);

								return runInInjectionContext(
									this.injector,
									() => hasPermission(ContractAccessionModel),
								);
							},
						),
					),
				},
				{
					name:    'model.medicalStoreUsers',
					url:     environment.medicalStoreUsersFullUrl,
					visible: this.auth.isLoggedIn$.pipe(
						mergeMap(isLoggedIn => {
								if(!isLoggedIn)
									return of(false);

								return runInInjectionContext(this.injector, () => hasPermission(MedicalStoreModel));
							},
						),
					),
				},
			],
		},
		{
			name:    'model.masterContracts',
			url:     environment.masterContractsFullUrl,
			icon:    this.iconService.MASTER_CONTRACT,
			visible: this.auth.isLoggedIn$.pipe(
				mergeMap(isLoggedIn => {
						if(!isLoggedIn)
							return of(false);

						return runInInjectionContext(this.injector, () => hasPermission(MasterContractModel));
					},
				),
			),
		},
		{
			name:    'general.downloads',
			url:     environment.downloadSectionFullUrl,
			icon:    this.iconService.FILE_DOWNLOAD,
			visible: this.auth.isLoggedIn$.pipe(
				mergeMap(isLoggedIn => {
						if(!isLoggedIn)
							return of(false);

						return runInInjectionContext(
							this.injector, () => hasPermission(RawContentModel, environment.downloadRawContentId),
						);
					},
				),
			),
		},
		{
			name:    'general.calculationManuals',
			url:     environment.calculationManualsFullUrl,
			icon:    this.iconService.CALCULATION_HANDBOOK,
			visible: this.auth.isLoggedIn$.pipe(
				mergeMap(isLoggedIn => {
						if(!isLoggedIn)
							return of(false);

						return Promise.all([
							runInInjectionContext(
								this.injector,
								() => hasPermission(RawContentModel, environment.calculationManualsRawContentId),
							),
							runInInjectionContext(
								this.injector,
								() => hasPermission(RawContentModel, environment.calculationManualsPreviewsRawContentId),
							),
						]).then(permissions => permissions.includes(true));
					},
				),
			),
		},
		{
			name:     'page.parties',
			icon:     this.iconService.LANDESINNUNG,
			children: [
				{
					name:    'model.landesinnungs',
					url:     environment.landesinnungFullUrl,
					visible: this.auth.isLoggedIn$.pipe(
						mergeMap(isLoggedIn => {
								if(!isLoggedIn)
									return of(false);

								return runInInjectionContext(
									this.injector,
									() => hasPermission(LandesinnungModel),
								);
							},
						),
					),
				},
				{
					name:    'model.professionalAssociations',
					url:     environment.professionalAssociationFullUrl,
					visible: this.auth.isLoggedIn$.pipe(
						mergeMap(isLoggedIn => {
								if(!isLoggedIn)
									return of(false);

								return runInInjectionContext(
									this.injector,
									() => hasPermission(ProfessionalAssociationModel),
								);
							},
						),
					),
				},
				{
					name:    'model.contractingParties',
					url:     environment.contractingPartyFullUrl,
					visible: this.auth.isLoggedIn$.pipe(
						mergeMap(isLoggedIn => {
								if(!isLoggedIn)
									return of(false);

								return runInInjectionContext(
									this.injector,
									() => hasPermission(ContractingPartyModel),
								);
							},
						),
					),
				},
			],
		},
		{
			name:    'general.contact',
			url:     environment.externalContactUrl,
			icon:    this.iconService.CONTACT,
			visible: of(true),
		},
		{
			name:      'general.bivPage',
			url:       'https://biv-ot.org',
			icon:      '/assets/img/logo-biv.svg',
			isSvgIcon: true,
			visible:   of(true),
		},
	];
	readonly ITEMS: MenuItem[]                                = this.ITEMS_INTERFACE.map(entry => new MenuItem(entry));

	constructor(
		private readonly router: Router,
		private readonly auth: AuthService,
		private readonly iconService: IconService,
		private readonly injector: Injector,
	) {
		this.router.events
		    .pipe(filter((event: unknown) => event instanceof NavigationEnd))
		    .subscribe(() => this.expandMenu());
	}

	ngOnInit(): void {
		this.fillMenu();
	}

	isExternalUrl(node: MenuItem): boolean {
		return node.url?.match(/^https?:\/\//) != null;
	}

	isActiveUrl(node: MenuItem, exact = true): boolean {
		if(node.children?.some(child => this.isActiveUrl(child, exact)) === true)
			return true;

		if(node.url == null)
			return false;

		const matchOptions: IsActiveMatchOptions = {
			paths:        exact ? 'exact' : 'subset',
			matrixParams: 'ignored',
			queryParams:  'ignored',
			fragment:     'ignored',
		};

		return this.router.isActive(node.url, matchOptions);
	}

	assertMenuItem(item: unknown): MenuItem {
		if(item instanceof MenuItem)
			return item;

		const given = (typeof item === 'object' && item != null) ? item.constructor.name : item;
		throw new Level8Error(`Unexpected menu item. Expected '${MenuItem.name}' got '${given}'`);
	}

	protected expandMenu(): void {
		this.ITEMS.forEach(item => {
			if(this.isActiveUrl(item, false))
				this.treeControl.expand(item);
			else
				this.treeControl.collapse(item);
		});
	}

	private fillMenu(): void {
		this.dataSource.data = this.ITEMS;
	}
}

interface MenuBaseItemInterface {
	name: string;
	isSvgIcon?: boolean;
	icon: IconProp | string;
}

interface MenuParentItemInterface extends MenuBaseItemInterface {
	children: MenuChildItemInterface[];
}

interface MenuChildItemInterface {
	name: string;
	url: string;
	urlParameters?: object;
	visible: Observable<boolean> | undefined;
}

interface MenuNotParentItemInterface extends MenuBaseItemInterface {
	url: string;
	urlParameters?: object;
	visible: Observable<boolean> | undefined;
}

type MenuItemInterface = MenuParentItemInterface | MenuNotParentItemInterface;

class MenuItem {
	readonly children?: MenuItem[];
	readonly level: number;
	readonly name: string;
	readonly url?: string;
	readonly urlParameters?: object;
	readonly visible?: Observable<boolean>;
	readonly icon?: IconProp | string;
	readonly isSvgIcon: boolean;

	constructor(
		protected readonly raw: MenuItemInterface | MenuChildItemInterface,
		protected readonly parent?: MenuItem,
	) {
		this.name      = raw.name;
		this.level     = (parent != null) ? parent.level + 1 : 0;
		this.icon      = 'icon' in raw ? raw.icon : undefined;
		this.isSvgIcon = 'isSvgIcon' in raw ? raw.isSvgIcon ?? false : false;

		const isParent = 'children' in raw;
		if(isParent) {
			this.children = raw.children.map(child => new MenuItem(child, this));
			this.visible  = combineLatestSafe(this.children.map(child => child.visible).map(child => child ?? of(undefined)))
				.pipe(map(results => !results.every(x => x === false))); // Not all are hidden => parent is visible
		} else {
			this.url           = raw.url;
			this.urlParameters = raw.urlParameters;
			this.visible       = raw.visible;
		}
	}
}