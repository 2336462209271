<ng-container *ngLet="_content$ | async as loadedContent">
	@if (loading) {
		<span class="skeleton">{{ loadingContent }}</span>
	} @else {
		<ng-container
			[ngTemplateOutlet]="contentTemplate?.template ?? defaultContent"
			[ngTemplateOutletContext]="{ $implicit: loadedContent}"
		></ng-container>
		<ng-template #content>
			<ng-container
				[ngTemplateOutlet]="contentTemplate?.template ?? defaultContent"
				[ngTemplateOutletContext]="{ $implicit: loadedContent}"
			></ng-container>
		</ng-template>
		<ng-template #defaultContent let-content>
			<div id="ng-content-container" #ngContent>
				<ng-content></ng-content>
			</div>
			@if (!ngContent.hasChildNodes()) {
				{{ content }}
			}
		</ng-template>
	}
</ng-container>


