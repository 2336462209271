import {DtoEditFormHelper} from '@angular-helpers/frontend-api';
import {HttpErrorResponse} from '@angular/common/http';
import {
	Component,
	Input,
	OnChanges,
	SimpleChanges,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {
	BaseDialogComponent,
	ConfirmDialogAnswer,
	ConfirmDialogConfig,
	DialogService,
	IconService,
} from '@app/main';
import {
	NewsDtoModel,
	NewsModel,
	NewsService,
} from '@contracts/frontend-api';

@Component({
	selector:    'portal-news-card-master-data',
	templateUrl: './news-card-master-data.component.html',
	styleUrls:   ['./news-card-master-data.component.scss'],
})
export class NewsCardMasterDataComponent implements OnChanges {
	@Input({required: true}) news!: NewsModel;
	@ViewChild('editModeTemplate') editPopupContent?: TemplateRef<unknown>;

	headline  = 'general.masterData';
	canUpdate = true;
	editable  = true;
	control?: UntypedFormGroup;
	dialog?: MatDialogRef<BaseDialogComponent, unknown>;
	isSaving  = false;
	errorHasOccurred?: Error;
	protected formHelper?: DtoEditFormHelper<NewsModel, NewsService>;

	constructor(
			private readonly newsService: NewsService,
			protected readonly iconService: IconService,
			protected dialogService: DialogService,
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		this.news.permissions.canUpdate.then(canUpdate => this.canUpdate = canUpdate);
	}

	async editThis(): Promise<void> {
		if(this.editPopupContent == null)
			return;

		this.formHelper = DtoEditFormHelper.create(
				NewsDtoModel,
				this.news,
				this.newsService,
		);

		this.dialog = this.dialogService.openBaseDialog({
			content:           this.editPopupContent,
			headline:          this.headline,
			icon:              this.iconService.NEWS,
			acceptText:        'actions.save',
			cancelButtonText:  'actions.cancel',
			control:           await this.formHelper.control,
			enableContentGrid: true,
			save:              this.save.bind(this),
			cancel:            this.abortEditing.bind(this),
		});

		this.dialog.backdropClick().subscribe(() => this.abortEditing());
	}

	async abortEditing(forceClose = false): Promise<void> {
		if(this.formHelper == null) {
			this.closeEditDialog();
			return;
		}

		if(!forceClose && (await this.formHelper.control).dirty) {
			const confirmDialogData: ConfirmDialogConfig = {
				labelPositiv:  'system.abortChangesDialog.labelPositiv',
				labelNegative: 'system.abortChangesDialog.labelNegative',
				title:         'system.abortChangesDialog.title',
				message:       'system.abortChangesDialog.message',
				icon:          this.iconService.DIALOG_ATTENTION,
			};

			const confirmDialog = this.dialogService.openConfirmDialog(confirmDialogData);
			confirmDialog.afterClosed().subscribe(answer => {
				if(answer === ConfirmDialogAnswer.negative)
					this.abortEditing(true);
			});

			return;
		}

		this.closeEditDialog();
		await this.formHelper.reset();
	}

	closeEditDialog(): void {
		this.dialog?.close();
		this.dialog = undefined;
	}

	async save(): Promise<void> {
		if(this.isSaving)
			return;

		if(this.formHelper && (await this.formHelper.isInvalid())) {
			// this.scrollToFirstInvalidFormControl();
			return;
		}

		this.isSaving = true;
		try {
			await this.formHelper?.save();
			this.closeEditDialog();
			this.errorHasOccurred = undefined;
		} catch(error) {
			if(error instanceof Error || error instanceof HttpErrorResponse || error === undefined)
				this.errorHasOccurred = error;
			else
				this.errorHasOccurred = new Error(`${error}`);

			throw error;
		} finally {
			this.isSaving = false;
		}
	}
}


