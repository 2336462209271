import {
	Component,
	Input,
} from '@angular/core';
import {
	MedicalStoreDtoModel,
	MedicalStoreModel,
} from '@contracts/frontend-api';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
	selector:    'portal-medical-store-show-supply-area',
	templateUrl: './medical-store-show-supply-area.component.html',
	styleUrls:   ['./medical-store-show-supply-area.component.scss'],
})
export class MedicalStoreShowSupplyAreaComponent {
	@Input({required: true}) model!: MedicalStoreModel;

	get areas$(): Observable<(string | null)[] | undefined> {
		return this.model.supplyArea.value.pipe(
			map(supplyArea => supplyArea
				?.replace('-', ' - ')
				.split(',')
				.map(area => ((area === MedicalStoreDtoModel.NO_SUPPLY_AREA) ? null : area)),
			),
		);
	}
}
