@if (model) {
	<portal-headline
		label="model.contractAccession"
		[menu]="menu"
		[backlink]="true"
	>
		<mat-menu #menu>
			@if ((model.permissions.canDelete | async) && ((model.state.value | async) === 'joined')) {
				<button mat-menu-item (click)="showRescindDialog()">
					<fa-icon matMenuItemIcon [icon]="iconService.RESCIND"></fa-icon>
					<span>{{ 'contractAccession.actions.rescind' | translate }}</span>
				</button>
			}
			@if (hasEndDate$ || (model.permissions.canUpdate | async) === false) {
				<button mat-menu-item (click)="showResignDialog()" [disabled]="this.hasEndDate$ | async">
					<fa-icon matMenuItemIcon [icon]="iconService.RESIGN"></fa-icon>
					<span>{{ 'actions.rescind' | translate }}</span>
				</button>
			}
		</mat-menu>
	</portal-headline>
	<div class="flex-container">
		<portal-contract-accession-card-master-data class="shrink" [contractAccession]="model"/>
		<portal-contract-accession-card-timings [contractAccession]="model"/>
	</div>
}
