import {
	Directive,
	ElementRef,
	HostBinding,
	HostListener,
	Input,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import {
	DomSanitizer,
	SafeUrl,
} from '@angular/platform-browser';
import {RouterLink} from '@angular/router';

@Directive({
	selector: 'a[href][obfuscate]',
})
export class ObfuscateLinkDirective implements OnChanges {
	@HostBinding('href') hrefAttr?: RouterLink['href'] | SafeUrl;
	@Input() href?: RouterLink['href'];
	protected isRevealed = false;

	constructor(
		protected readonly thisElement: ElementRef,
		protected readonly domSanitizer: DomSanitizer,
	) {
	}

	@HostListener('mouseenter')
	onMouseover(): void {
		this.reveal();
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.thisElement.nativeElement.innerHTML = this.getObfuscatedString(this.thisElement.nativeElement.innerHTML);

		if(this.isRevealed || this.href == null) {
			this.hrefAttr = this.href;
			return;
		}

		this.hrefAttr = this.domSanitizer.bypassSecurityTrustUrl(this.getObfuscatedString(this.href));
	}


	getObfuscatedString(href: string): string {
		return `\u202E${href.split('').reverse().join('')}\u202C`;
	}

	reveal(): void {
		this.isRevealed = true;
		this.hrefAttr   = this.href;
	}
}
