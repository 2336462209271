import {Level8Error} from '@angular-helpers/frontend-api';
import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
} from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import {
	combineLatestSafe,
	EditFieldSelectComponent,
	FormatInstitutionskennzeichenPipe,
	SelectFieldEntry,
} from '@app/main';
import {
	InstitutionskennzeichenModel,
	InstitutionskennzeichenService,
} from '@contracts/frontend-api';
import {
	from,
	Observable,
	Subscription,
} from 'rxjs';
import {
	filter,
	map,
	mergeMap,
} from 'rxjs/operators';

@Component({
	selector:    'portal-institutionskennzeichen-select',
	templateUrl: './institutionskennzeichen-select.component.html',
	styleUrls:   ['./institutionskennzeichen-select.component.scss'],
})
export class InstitutionskennzeichenSelectComponent implements OnChanges {
	@Input('control') parent                          = new UntypedFormGroup({
		institutionskennzeichen: new UntypedFormControl(undefined, Validators.required),
	});
	@Input() label: EditFieldSelectComponent['label'] = 'model.institutionskennzeichen';
	@Output() readonly change                         = new EventEmitter<InstitutionskennzeichenModel | null>();
	readonly possibleValues$                          = this.buildPossibleValues$();
	protected sub?: Subscription;

	constructor(
			private readonly institutionskennzeichenService: InstitutionskennzeichenService,
			protected readonly formatInstitutionskennzeichenPipe: FormatInstitutionskennzeichenPipe,
	) {
		this.subscribeToChange();
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.subscribeToChange();
	}

	private subscribeToChange(): void {
		this.sub?.unsubscribe();
		this.sub = this.control.valueChanges.subscribe(value => {
			if(value != null && !(value instanceof InstitutionskennzeichenModel))
				value = null;

			this.change.emit(value);
		});
	}

	get control(): UntypedFormControl {
		const fieldName = 'institutionskennzeichen';
		const control   = this.parent.get(fieldName);
		if(control instanceof UntypedFormControl)
			return control;

		throw new Level8Error(`Unexpected type for field ${fieldName} - expected '${UntypedFormControl.name}' got '${typeof control}' (${control})`);
	}

	protected buildPossibleValues$(): Observable<SelectFieldEntry[]> {
		const iks$ = this.institutionskennzeichenService.getAllModels();
		return from(iks$).pipe(
			mergeMap(iks => combineLatestSafe(iks.map(ik =>
				ik.number.value.pipe(
						filter((ikNumber): ikNumber is string => typeof ikNumber === 'string'),
						map(ikNumber => ({
							label: this.formatInstitutionskennzeichenPipe.transform(ikNumber),
							value: ik,
						})),
						map(option => ({
							label:  option.label,
							value:  option.value,
							filter: (search: string) => {
								if(search.includes(' '))
									return option.label.includes(search);

							const label = option.label.replace(/\s/g, '');
							return label.includes(search);
						},
					})),
				)))),
		);
	}
}
