<!-- eslint-disable @html-eslint/require-li-container -->
<div class="wrapper">
	<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
		<mat-nested-tree-node *matTreeNodeDef="let node">
			<ng-container *ngLet="assertMenuItem(node) as node">
				<ng-container *ngLet="node.visible === undefined || (node.visible | async) as visible">
					<ng-container *ngLet="(visible === true || visible === undefined) as isVisible">
						@if (visible !== false) {
							<li [class.activeChild]="isActiveUrl(node, false)" [class.active]="isActiveUrl(node)" class="level-{{node.level}}">
								<div class="mat-tree-node">
									@if (isExternalUrl(node)) {
										<a notExternal [href]="isVisible ? node.url : undefined" class="entry">
											<ng-container *ngTemplateOutlet="linkContent"></ng-container>
										</a>
									} @else {
										@if (node.url) {
											<a [class.active]="isActiveUrl(node)" [routerLink]="isVisible ? node.url : undefined" [queryParams]="node.urlParameters" class="entry">
												<ng-container *ngTemplateOutlet="linkContent"></ng-container>
											</a>
										} @else {
											<span [class.active]="isActiveUrl(node)" [class.toggler]="isVisible && node.children" class="entry" matTreeNodeToggle>
                        <ng-container *ngTemplateOutlet="linkContent"></ng-container>
                      </span>
										}
									}
									<ng-template #linkContent>
										@if (node.isSvgIcon) {
											<div class="icon svgContainer">
												<svg class="iconZ" [inlineSVG]="node.icon"/>
											</div>
										} @else {
											@if (node.icon) {
												<fa-icon [icon]="node.icon" class="icon"></fa-icon>
											}
										}
										<ng-template #icon>
											@if (node.icon) {
												<fa-icon [icon]="node.icon" class="icon"></fa-icon>
											}
										</ng-template>
										<portal-skeleton-field [loading]="visible === null">
											<span class="name">{{ node.name | translate }}</span>
										</portal-skeleton-field>
									</ng-template>
									@if (isVisible && node.children) {
										<fa-icon [icon]="treeControl.isExpanded(node) ? ICON_EXPANDED : ICON_COMPRESSED" class="toggle" matTreeNodeToggle></fa-icon>
									}
								</div>
								@if (isVisible && node.children) {
									<ul [class.inactive]="!treeControl.isExpanded(node)" class="submenu">
										<ng-container matTreeNodeOutlet></ng-container>
									</ul>
								}
							</li>
						}
					</ng-container>
				</ng-container>
			</ng-container>
		</mat-nested-tree-node>
	</mat-tree>

	<div class="additions">
		<a class="no-link" routerLink="/terms-and-conditions">{{ 'page.termsAndConditions.name' | translate }}</a>
		<a class="no-link" routerLink="/impressum">{{ 'page.impressum.name' | translate }}</a>
		<a class="no-link" routerLink="/privacy">{{ 'page.privacy.name' | translate }}</a>
	</div>
</div>
