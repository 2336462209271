<ng-container *ngLet="(model?.parent?.value | async) as contract">
	<ng-container *ngLet="(contract?.parent?.value | async) as masterContract">
		<portal-showable
			label="model.contract"
			[value]="(model?.name?.value | async) &&( contract?.name?.value | async) && (masterContract?.name?.value | async)"
		>
			<div>
				<a class="invisible-link" [routerLink]="[environment.masterContractsFullUrl ,masterContract?.id]">{{ masterContract?.name?.value | async }}</a> /
				<a class="invisible-link" [routerLink]="[environment.contractsFullUrl, contract?.id]">{{ contract?.name?.value | async }}</a> /
				<a class="invisible-link" [routerLink]="[environment.contractSectionsFullUrl, model?.id]">{{ model?.name?.value | async }}</a>
			</div>
		</portal-showable>
	</ng-container>
</ng-container>
