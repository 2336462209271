import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'asArray',
})
export class AsArrayPipe implements PipeTransform {

	transform<T>(value: T | T[]): T[] {
		if(Array.isArray(value))
			return value;

		return [value];
	}

}
