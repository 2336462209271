@if (hasData) {
	<mat-card>
		<mat-card-header class="logo">
			<fa-icon [icon]="iconService.CONTRACTING_PARTY" mat-card-avatar></fa-icon>
			<mat-card-title>{{ 'baseContract.joinedParties' | translate }}</mat-card-title>
		</mat-card-header>
		<mat-card-content>
			<portal-table-client-side-searchable
				[headers]="tableHeaders"
				[searchFilter]="searchFilter"
				[columnLink]="getColumnLink"
				[data]="data"
				[withMenu]="false"
			>
				<ng-template column="name" let-model="model" [data]="data">
					{{ model.contractingParty.name.value | async }}
				</ng-template>
			</portal-table-client-side-searchable>
		</mat-card-content>
	</mat-card>
}

