import {DestroyRef, Injectable} from '@angular/core';
import {UrlSerializer} from '@angular/router';
import {environment} from '@app/environment';
import {AuthService} from '@app/main';
import {
	NewsService,
	UserService,
} from '@contracts/frontend-api';
import {NewsDidReadGuard} from './news-did-read.guard';

@Injectable({
	providedIn: 'root',
})
export class TermsAndConditionsAcceptedGuard extends NewsDidReadGuard {
	constructor(
		readonly newsService: NewsService,
		protected readonly userService: UserService,
		protected readonly urlSerializer: UrlSerializer,
		readonly authService: AuthService,
		readonly destroyRef: DestroyRef,
	) {
		super(
			newsService.getById(environment.termsAndConditionsNewsId),
			environment.acceptTermsAndConditionsFullUrl,
			userService,
			urlSerializer,
			authService,
			destroyRef,
		);
	}
}
