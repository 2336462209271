import {
	Component,
	Input,
} from '@angular/core';
import {
	BaseContractApiService,
	BaseContractModel,
	BaseContractService,
	ContractModel,
	ContractSectionModel,
} from '@contracts/frontend-api';
import {Observable} from 'rxjs';

@Component({
	selector:    'portal-base-contract-show-minimum-term',
	templateUrl: './base-contract-show-minimum-term-at.component.html',
	styleUrls:   ['./base-contract-show-minimum-term-at.component.scss'],
})
export class BaseContractShowMinimumTermAtComponent<Model extends BaseContractModel<BaseContractService<BaseContractApiService, Model>>> {
	@Input({required: true}) model?: Model;

	get value(): Observable<Date | undefined | null> | undefined {
		if(this.model == null)
			return this.model;

		if(this.model instanceof ContractModel || this.model instanceof ContractSectionModel)
			return this.model.minimumTermAt.withParent.value;

		return this.model.minimumTermAt.value;
	}
}
