import {
	Component,
	DestroyRef,
	OnInit,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {MatDialog} from '@angular/material/dialog';
import {WithdrawalComponent} from '@app/contracts';
import {AbstractShowComponent} from '@app/main';
import {
	ContractAccessionModel,
	ContractAccessionService,
} from '@contracts/frontend-api';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
	templateUrl: './contract-accession-show-page.component.html',
	styleUrls:   ['./contract-accession-show-page.component.scss'],
})
export class ContractAccessionShowPageComponent extends AbstractShowComponent<ContractAccessionModel, ContractAccessionService> implements OnInit {
	public hasEndDate$?: Observable<boolean>;

	constructor(
		modelService: ContractAccessionService,
		protected readonly dialog: MatDialog,
		protected readonly destroyRef: DestroyRef,
	) {
		super(modelService);
	}
	
	showResignDialog(): void {
		this.showDialog(false);
	}
	
	showRescindDialog(): void {
		this.showDialog(true);
	}
	
	ngOnInit(): void {
		this.hasEndDate$ = this.getModel().accessionEndAt.value.pipe(
				takeUntilDestroyed(this.destroyRef),
				map((hasDate) => Boolean(hasDate)),
		);
	}

	protected getModelName(): Promise<string | undefined> {
		return this.getModel().institutionskennzeichen.firstValue.then(ik => ik?.number.firstValue);
	}
	
	private showDialog(dialogTypeDelete: boolean): void {
		this.dialog.open(WithdrawalComponent, {
			minWidth: 'max-content',
			data:     {
				dialogTypeDelete,
				contractAccession: this.getModel(),
			},
		});
	}
}
