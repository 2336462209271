import {
	InheritedCoalesceProperty,
	Level8Error,
} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
} from '@angular/forms';
import {
	ContractModel,
	ContractSectionModel,
	MasterContractModel,
} from '@contracts/frontend-api';
import moment from 'moment';
import {
	Observable,
	of,
} from 'rxjs';
import {first} from 'rxjs/operators';


@Component({
	selector:    'portal-base-contract-edit-validity-start',
	templateUrl: './base-contract-edit-validity-start.component.html',
	styleUrls:   ['./base-contract-edit-validity-start.component.scss'],
})
export class BaseContractEditValidityStartComponent implements OnChanges {
	@Input({
		required: true,
		alias:    'control',
	}) parent!: UntypedFormGroup;
	@Input() model?: MasterContractModel | ContractModel | ContractSectionModel;
	@Input() parentModel?: MasterContractModel | ContractModel;
	private readonly fieldName = 'validityStartAt';

	get control(): UntypedFormControl {
		const control = this.parent.get(this.fieldName);
		if(control instanceof UntypedFormControl)
			return control;

		throw new Level8Error(`Unexpected type for field ${this.fieldName} - expected '${UntypedFormControl.name}' got '${typeof control}' (${control})`);
	}

	get inheritedValue(): Observable<Date | undefined | null> | undefined {
		if(this.parentModel != null) {
			if(this.parentModel instanceof MasterContractModel)
				return this.parentModel.validityStartAt.value;

			return this.parentModel.validityStartAt.withParent.value;
		}

		if(this.model == null)
			return undefined;

		if(this.model instanceof MasterContractModel)
			return undefined;

		return this.model.validityStartAt.inherited;
	}

	get latestDateControl(): Date | moment.Moment | undefined | null {
		return this.control.value;
	}

	get latestDateParent(): MasterContractModel['validityStartAt']['value'] | undefined {
		if(this.model == null)
			return undefined;

		if(this.model.validityStartAt instanceof InheritedCoalesceProperty)
			return this.model.validityStartAt.withParent.value;

		return this.model.validityStartAt.value;
	}

	get validityStartAt$(): MasterContractModel['validityStartAt']['value'] {
		if(this.model == null)
			return of(undefined);

		if(this.model instanceof MasterContractModel)
			return this.model.validityStartAt.value;

		return this.model.validityStartAt.withParent.value;
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.validityStartAt$.pipe(first()).subscribe(currentValue => {
			const now = new Date();
			if(currentValue != null && currentValue < now)
				this.control.disable();
		});
	}
}
