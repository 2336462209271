@if (fieldControlStart) {
	<portal-editable label="medicalStore.doesIso13485" [validator]="fieldControlStart">
		<div class="flex-row">
			<mat-form-field class="fill">
				<mat-label>{{ 'general.since' | translate }}</mat-label>
				<portal-input-status-icon matSuffix [validator]="fieldControlStart"/>
				<input matInput [formControl]="fieldControlStart" [matDatepicker]="isoJoined" (focus)="isoJoined.open()"/>
				<mat-datepicker-toggle matSuffix [for]="isoJoined"></mat-datepicker-toggle>
				<mat-datepicker #isoJoined></mat-datepicker>
				<mat-hint>{{ 'formats.date' | translate }}</mat-hint>
			</mat-form-field>
			&nbsp;
			@if (fieldControlStart && fieldControlEnd && fieldControlStart.value) {
				<mat-form-field class="fill">
					<mat-label>{{ 'general.until' | translate }}</mat-label>
					<portal-input-status-icon matSuffix [validator]="fieldControlEnd"/>
					<input matInput [formControl]="fieldControlEnd" [matDatepicker]="isoLeft" (focus)="isoLeft.open()" [min]="fieldControlStart.value"/>
					@for (error of formHelperService.getErrors(fieldControlEnd) | keyvalue;track error) {
						<mat-error>
							{{ 'input.error.' + error.key | translate: error.value }}
						</mat-error>
					}
					<mat-datepicker-toggle matSuffix [for]="isoLeft"></mat-datepicker-toggle>
					<mat-datepicker #isoLeft></mat-datepicker>
					<mat-hint>{{ 'formats.date' | translate }}</mat-hint>
				</mat-form-field>
			}
		</div>
	</portal-editable>
}
