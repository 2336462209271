import {
	Component,
	Input,
} from '@angular/core';
import {IconService} from '@app/main';
import {
	ContractModel,
	ContractRequirementModel,
	ContractSectionModel,
	MasterContractModel,
} from '@contracts/frontend-api';
import {
	EMPTY,
	Observable,
} from 'rxjs';

@Component({
	selector:    'portal-base-contract-requirements-card',
	templateUrl: './base-contact-requirements-card.component.html',
	styleUrls:   ['./base-contact-requirements-card.component.scss'],
})
export class BaseContactRequirementsCardComponent {
	@Input({required: true}) contract?: MasterContractModel | ContractModel | ContractSectionModel;

	constructor(
		public iconService: IconService,
	) { }


	get requirements(): Observable<ContractRequirementModel[] | undefined> {
		if(this.contract == null)
			return EMPTY;


		if(this.contract instanceof MasterContractModel)
			return this.contract.requirements.value;

		return this.contract.requirements.withParent.value;
	}

}
