@if (model) {
	<portal-headline
		label="model.contractSection"
		[menu]="menu"
		[backlink]="environment.contractsFullUrl + '/' + (model.contract.value | async)?.id"
	>
		<mat-menu #menu>
			@if (model.permissions.canDelete | async) {
				<button mat-menu-item (click)="showDeleteDialog()">
					<fa-icon matMenuItemIcon [icon]="iconService.DELETE"></fa-icon>
					<span>{{ 'actions.delete' | translate }}</span>
				</button>
			}
		</mat-menu>
	</portal-headline>
	<div class="flex-container">
		<portal-contract-section-card-master-data [contractSection]="model"/>
		<portal-contract-section-card-contracting [contractSection]="model"/>
		<portal-base-contract-requirements-card [contract]="model"/>
		<portal-base-contract-card-joined-parties [data]="model.joinedParties.withParent.value | async"/>
		<portal-base-contract-card-files [contract]="model"/>
	</div>
}
