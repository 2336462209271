<portal-model-editable-box
	[icon]="iconService.EMPLOYEE"
	headline="general.masterData"
	[formHelper]="formHelper"
	[hasEditButton]="canUpdate | async"
>

	@if (employee) {
		<ng-template #showModeContent>
			<portal-employee-show-name [model]="employee"/>
			<portal-common-show-email [model]="employee"/>
			<portal-common-show-phone [model]="employee"/>
			<portal-medical-store-show [model]="employee.medicalStore.value | async"/>
			<portal-employee-show-roles [model]="employee"/>
		</ng-template>
	}

	<ng-template #editModeContent let-control>
		<portal-employee-edit-salutation [control]="control"/>
		<portal-common-edit-name [control]="control"/>
		<portal-common-edit-email [control]="control"/>
		<portal-common-edit-phone [control]="control"/>
		<portal-employee-edit-roles [control]="control"/>
	</ng-template>

</portal-model-editable-box>
