<ng-container *ngLet="model | asArray as iks">
	<portal-showable
		label="model.institutionskennzeichen"
		[hidden]="iks.length < 1">
		<div class="wrapper">
			@for (ik of iks;track ik;let last = $last) {
				@if (ik != null) {
					<portal-copyable [content]="ik.number.value">
						<portal-show-field-url
							[label]="null"
							[urlType]="urlType.internal"
							[url]="environment.institutionskennzeichenFullUrl+'/'+ik.id"
							[value]="ik.number?.value | async | formatInstitutionskennzeichen"
						/>
						@if (!last) {
							,
						}
					</portal-copyable>
				}
			}
		</div>
	</portal-showable>
</ng-container>

