import {Level8Error} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
} from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
} from '@angular/forms';
import {FormHelperService} from '@app/main';

@Component({
	selector:    'portal-medical-store-edit-iso9001',
	templateUrl: './medical-store-edit-iso9001.component.html',
	styleUrls:   ['./medical-store-edit-iso9001.component.scss'],
})
export class MedicalStoreEditIso9001Component {
	@Input({required: true}) control?: UntypedFormGroup;

	private readonly fieldNameStart = 'certificateIso9001StartAt';
	private readonly fieldNameEnd   = 'certificateIso9001EndAt';

	constructor(
		protected readonly formHelperService: FormHelperService,
	) {
	}

	get fieldControlStart(): UntypedFormControl {
		const control = this.control?.get(this.fieldNameStart);
		if(control instanceof UntypedFormControl)
			return control;

		throw new Level8Error(`Unexpected type for field ${this.fieldNameStart} - expected '${UntypedFormControl.name}' got '${typeof control}' (${control})`);

	}

	get fieldControlEnd(): UntypedFormControl {
		const control = this.control?.get(this.fieldNameEnd);
		if(control instanceof UntypedFormControl)
			return control;


		throw new Level8Error(`Unexpected type for field ${this.fieldNameEnd} - expected '${UntypedFormControl.name}' got '${typeof control}' (${control})`);

	}
}
