import {
	Pipe,
	PipeTransform,
} from '@angular/core';
import {
	isObservable,
	Observable,
} from 'rxjs';
import {map} from 'rxjs/operators';

declare type OptionalString = string | null | undefined;

@Pipe({
	name: 'formatInstitutionskennzeichen',
})
export class FormatInstitutionskennzeichenPipe implements PipeTransform {
	static format(value: OptionalString): OptionalString {
		if(value == null)
			return value;


		const match = value.match(/.{1,3}/g);
		if(match == null)
			return value;

		return match.join(' ');
	}

	transform(value: string): string;
	transform(value: OptionalString): OptionalString;
	transform(value: Observable<OptionalString>): Observable<OptionalString>;
	transform(value: Observable<string>): Observable<string>;
	transform(value: Observable<string> | undefined): Observable<string> | undefined;
	transform(value: Observable<OptionalString> | undefined): Observable<OptionalString> | undefined;
	transform(value: OptionalString | Observable<OptionalString>): OptionalString | Observable<OptionalString> {
		if(isObservable(value))
			return value.pipe(map((s) => FormatInstitutionskennzeichenPipe.format(s)));


		return FormatInstitutionskennzeichenPipe.format(value);
	}
}
