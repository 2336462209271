<portal-model-editable-box
	[icon]="iconService.CONTRACTING_PARTY"
	headline="general.masterData"
	[formHelper]="formHelper"
	[hasEditButton]="canUpdate | async"
>

	@if (contractingParty) {
		<ng-template #showModeContent>
			<portal-contracting-party-show-name [model]="contractingParty"/>
			<portal-contracting-party-show-insurance-count [model]="contractingParty"/>
			<portal-contracting-party-show-contact [model]="contractingParty"/>
		</ng-template>
	}

	<ng-template #editModeContent let-control>
		<portal-contracting-party-edit-name [control]="control"/>
		<portal-contracting-party-edit-insurance-count [control]="control"/>
		<portal-contracting-party-edit-contact [control]="control"/>
	</ng-template>

</portal-model-editable-box>
