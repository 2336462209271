import {
	Component,
	Input,
} from '@angular/core';
import {environment} from '@app/environment';
import {ContractSectionModel} from '@contracts/frontend-api';

@Component({
	selector:    'portal-contract-section-show',
	templateUrl: './contract-section-show.component.html',
	styleUrls:   ['./contract-section-show.component.scss'],
})
export class ContractSectionShowComponent {
	@Input({required: true}) model?: ContractSectionModel | null;
	readonly environment = environment;
}
