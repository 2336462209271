@if (model) {
	<portal-headline
		label="model.landesinnung"
		[menu]="menu"
		[backlink]="true"
	>
		<mat-menu #menu>
			@if ((model.permissions.canDelete | async) && (hasMedicalStores)) {
				<button mat-menu-item (click)="showDeleteDialog()">
					<fa-icon matMenuItemIcon [icon]="iconService.DELETE"></fa-icon>
					<span>{{ 'actions.delete' | translate }}</span>
				</button>
			}
		</mat-menu>
	</portal-headline>
	<div class="flex-container">
		<portal-landesinnung-card-master-data [landesinnung]="model"/>
		<!--		disabled since currently shows medical stores that doesn't belong to this -->
		<!--		<ng-container *ngLet="(model.medicalStores.value | async) as medicalStores">-->
		<!--			<portal-medical-store-card-list class="col-12" *ngIf="medicalStores == null || medicalStores.data.length > 0" [medicalStores]="medicalStores"/>-->
		<!--		</ng-container>-->
	</div>
}
