<ng-container *ngLet="requirements | async as requirements">
	@if (requirements != null && requirements.length > 0) {
		<portal-model-editable-box
			[icon]="iconService.REQUIREMENTS"
			headline="baseContract.requirements"
			[formHelper]="null"
			[hasEditButton]="false"
		>
			<ng-template #showModeContent>
				<portal-requirement [requirements]="requirements"/>
			</ng-template>
		</portal-model-editable-box>
	}
</ng-container>
