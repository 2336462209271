import {
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';

@Component({
	selector:    'portal-edit-field-boolean',
	templateUrl: './edit-field-boolean.component.html',
	styleUrls:   ['./edit-field-boolean.component.scss'],
})
export class EditFieldBooleanComponent {
	@Input() labelTrue  = 'common.yes';
	@Input() labelFalse = 'common.no';
	@Input({required: true}) label!: string;
	@Input({required: true}) validator!: UntypedFormControl;
	@Output() change = new EventEmitter<boolean>();
}
