import {
	Pipe,
	PipeTransform,
} from '@angular/core';
import {AddressInterface} from '@contracts/frontend-api';

@Pipe({
	name: 'address',
})
export class AddressPipe implements PipeTransform {

	transform(address: AddressInterface | undefined | null): string {
		if(address == null)
			return '';

		return `${address.street} ${address.houseNumber}, ${address.zip} ${address.town}`;
	}
}
