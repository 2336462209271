import {
	Component,
	Input,
} from '@angular/core';
import {environment} from '@app/environment';
import {UrlType} from '@app/main';
import {EmployeeModel} from '@contracts/frontend-api';

@Component({
	selector:    'portal-employee-show',
	templateUrl: './employee-show.component.html',
	styleUrls:   ['./employee-show.component.scss'],
})
export class EmployeeShowComponent {
	@Input({required: true}) employee?: EmployeeModel | null;
	@Input() label = 'model.employee';
	urlType        = UrlType;
	environment    = environment;
}
