import {ResultPageModel} from '@angular-helpers/frontend-api';
import {
	Component,
	inject,
	Input,
} from '@angular/core';
import {environment} from '@app/environment';
import {
	combineLatestSafe,
	IconService,
	MinimalColumns,
	PhonePipe,
	SearchFilter,
} from '@app/main';
import {
	AddressInterface,
	MedicalStoreModel,
	MedicalStoreService,
} from '@contracts/frontend-api';
import {
	Observable,
	of,
} from 'rxjs';
import {
	first,
	map,
	mergeMap,
} from 'rxjs/operators';

@Component({
	selector:    'portal-medical-store-list',
	templateUrl: './medical-store-list.component.html',
	styleUrls:   ['./medical-store-list.component.scss'],
})
export class MedicalStoreListComponent {
	@Input() data: MedicalStoreModel[] | ResultPageModel<MedicalStoreModel> | undefined | null;
	environment                                              = environment;
	readonly searchFilter = new CSearchFilter();
	readonly tableHeaders: MinimalColumns<MedicalStoreModel> = {
		mainHouse:                {
			isVisible:    false,
			isSearchable: false,
			label:        'medicalStore.mainHouse',
			index:        0,
		},
		institutionskennzeichens: {
			label:         'model.institutionskennzeichens',
			index:         1,
			prepareSearch: (value) => {
				if(typeof value === 'string')
					return value.replace(/\s/g, '');
				return value;
			},
		},
		name:                     {
			label: 'medicalStore.name',
			index: 2,
		},
		email:                    {
			isVisible: false,
			label:     'common.email',
			index:     3,
		},
		phone:                    {
			isVisible: false,
			label:     'common.phone',
			index:     4,
		},
		fax:                      {
			isVisible: false,
			label:     'common.fax',
			index:     5,
		},
		landesinnung:             {
			isVisible: false,
			label:     'model.landesinnung',
			index:     6,
		},
		address:                  {
			label: 'common.address',
			index: 7,
		},
	};

	constructor(
		protected readonly iconService: IconService,
		protected readonly medicalStoreService: MedicalStoreService,
	) {
	}

	static formatAddress(address: AddressInterface | null | undefined): string {
		if(address == null)
			return '';

		return `${address.street} ${address.houseNumber}, ${address.zip} ${address.town}`;
	}

	static getIks$(model: MedicalStoreModel): Observable<string[]> {
		return model.institutionskennzeichens.value.pipe(
			mergeMap(institutionskennzeichens => combineLatestSafe(institutionskennzeichens?.map(institutionskennzeichen => institutionskennzeichen.number.value))),
			map(institutionskennzeichens => institutionskennzeichens?.filter((ik): ik is string => typeof ik === 'string') ?? []),
		);
	}

	iks$(model: MedicalStoreModel): Observable<string[]> {
		return MedicalStoreListComponent.getIks$(model);
	}

	printAddress(address: Observable<AddressInterface | null | undefined>): Observable<string> {
		return address.pipe(map(a => MedicalStoreListComponent.formatAddress(a)));
	}
}


class CSearchFilter extends SearchFilter<MedicalStoreModel> {
	protected phonePipe = inject(PhonePipe);

	protected async getModelValue(field: string, model: MedicalStoreModel): Promise<unknown> {
		switch(field) {
			case 'mainHouse':
				return (await model.parent.firstValue) === null;

			case 'institutionskennzeichens':
				return MedicalStoreListComponent.getIks$(model).pipe(
						map(institutionskennzeichens => institutionskennzeichens.join('\n')),
						first(),
				).toPromise();

			case 'landesinnung':
				return model.landesinnung.value.pipe(
					mergeMap(landesinnung => landesinnung?.name.value ?? of(undefined)),
					map(name => name ?? ''),
				).pipe(first()).toPromise();

			case 'phone':
				return this.phonePipe.transform(await model.phone.firstValue);

			case 'fax':
				return this.phonePipe.transform(await model.fax.firstValue);

			case 'address':
				return MedicalStoreListComponent.formatAddress(await model.address.firstValue);

			default:
				return super.getModelValue(field, model);
		}
	}
}
