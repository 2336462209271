import {Component} from '@angular/core';
import {AbstractShowComponent} from '@app/main';
import {
	LandesinnungModel,
	LandesinnungService,
	MedicalStoreService,
} from '@contracts/frontend-api';

@Component({
	templateUrl: './landesinnung-page-show.component.html',
	styleUrls:   ['./landesinnung-page-show.component.scss'],
})
export class LandesinnungPageShowComponent extends AbstractShowComponent<LandesinnungModel, LandesinnungService> {
	hasMedicalStores?: boolean;

	constructor(
		protected medicalStoreService: MedicalStoreService,
		modelService: LandesinnungService,
	) {
		super(modelService);
	}

	protected async onNewModel(newModel: LandesinnungModel, oldModel?: LandesinnungModel): Promise<void> {
		const page         = await this.medicalStoreService.find({
			column:     'landesinnung_id',
			value:      newModel.id,
			comparator: '=',
		}, {pageSize: 1});
		this.hasMedicalStores = page.data.length > 0;
	}

	protected getModelName(): Promise<string | undefined> {
		return this.getModel().name.firstValue;
	}
}
