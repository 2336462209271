<portal-headline
	label="institutionskennzeichen.create.title"
	[backlink]="true"
/>


@if (control) {
	<mat-card>
		<mat-card-content>
			<mat-stepper orientation="vertical" linear="true" [formGroup]="control">
				<ng-template matStepperIcon="edit" let-index="index">{{ index + 1 }}</ng-template>
				<ng-template matStepperIcon="done" let-index="index">{{ index + 1 }}</ng-template>
				<ng-template matStepperIcon="error" let-index="index">{{ index + 1 }}</ng-template>
				<mat-step [stepControl]="control">
					<ng-template matStepLabel>{{ 'general.masterData' | translate }}</ng-template>
					<div class="grid-2">
						<portal-medical-store-show [model]="medicalStore" [hasLink]="false"/>
						<portal-institutionskennzeichen-edit-number [control]="control"/>
					</div>
					<ng-container *ngTemplateOutlet="stepperActions"></ng-container>
				</mat-step>
				<mat-step>
					<ng-template matStepLabel>{{ 'general.create.finish.label' | translate }}</ng-template>
					<p>{{ 'institutionskennzeichen.create.finish.text' | translate }}</p>
					<div class="stepper-actions">
						<button mat-button matStepperPrevious>{{ 'actions.previous' | translate }}</button>
						<portal-spinning-button (click)="create()" [processing]="isSaving">{{ 'actions.createNow' | translate }}</portal-spinning-button>
					</div>
				</mat-step>
				<ng-template #stepperActions>
					<div class="stepper-actions">
						<button mat-button matStepperPrevious>{{ 'actions.previous' | translate }}</button>
						<button mat-button color="primary" matStepperNext>{{ 'actions.next' | translate }}</button>
					</div>
				</ng-template>
			</mat-stepper>
		</mat-card-content>
		@if (this.errorHasOccurred) {
			<portal-form-error [error]="this.errorHasOccurred"/>
		}
	</mat-card>
} @else {
	<portal-loading/>
}
