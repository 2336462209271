<portal-model-editable-box
	[icon]="iconService.PRE_QUALIFICATION_CERTIFICATE"
	[headline]="label"
	[formHelper]="null"
	[enableContentGrid]="false"
	[hasEditButton]="false"
>
	@if (preQualificationCertificates && preQualificationCertificates.length > 0) {
		<ng-template #showModeContent>
			<portal-pre-qualification-certificate-list [data]="preQualificationCertificates"/>
		</ng-template>
	}
</portal-model-editable-box>
