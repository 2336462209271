import {ViewportScroller} from '@angular/common';
import {NgModule} from '@angular/core';
import {
	NavigationEnd,
	Router,
	RouterModule,
	Routes,
	Scroll,
} from '@angular/router';
import {environment} from '@app/environment';
import {MaintenanceGuard} from '@app/main';
import {
	filter,
	tap,
} from 'rxjs/operators';

const routes: Routes = [
	{
		path:         environment.baseUrl,
		loadChildren: () => import('@app/contracts').then(m => m.ContractsModule).catch(() => {
			location.reload();
			return new Promise<never>(() => 0);
		}),
	},
	{
		path:         '',
		canActivate:  [MaintenanceGuard],
		loadChildren: () => import('@app/pages').then(m => m.PagesModule).catch(() => {
			location.reload();
			return new Promise<never>(() => 0);
		}),
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			bindToComponentInputs:     true,
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {


	constructor(
		router: Router,
		viewportScroller: ViewportScroller,
	) {
		let currentPage: NavigationEnd | null = null;
		let scrollToTop                       = true;
		router.events.pipe(
			      tap(event => {
				      if(!(event instanceof NavigationEnd))
					      return;

				      scrollToTop = event.urlAfterRedirects.split('?')[0] !== currentPage?.urlAfterRedirects.split('?')[0];
				      currentPage = event;
			      }),
			      filter((event): event is Scroll => event instanceof Scroll),
		      )
		      .subscribe(event => {
			      const top: [number, number] = [
				      0,
				      0,
			      ];
			      if(scrollToTop)
				      viewportScroller.scrollToPosition(event.position ?? top);
		      });
	}
}
