import {Injectable} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {environment} from '@app/environment';
import {BehaviorSubject} from 'rxjs';

declare type Themes = 'defaultTheme' | 'localTheme' | 'devTheme';

@Injectable({
	providedIn: 'root',
})
export class ThemeServiceService {
	static readonly THEME_DEFAULT = 'defaultTheme';
	static readonly THEME_LOCAL   = 'localTheme';
	static readonly THEME_DEV     = 'devTheme';
	static readonly THEMES        = [ThemeServiceService.THEME_DEFAULT, ThemeServiceService.THEME_DEV, ThemeServiceService.THEME_LOCAL];
	static readonly DARK_MODE     = 'darkMode';
	static readonly LIGHT_MODE    = 'lightMode';
	static readonly MODES         = [ThemeServiceService.DARK_MODE, ThemeServiceService.LIGHT_MODE];
	readonly currentTheme$        = new BehaviorSubject<Themes>(ThemeServiceService.THEME_DEFAULT);
	readonly darkMode$            = new BehaviorSubject<boolean>(false);
	protected readonly THEME_NODE = document.getElementsByTagName('html')[0];

	constructor() {
		this.currentTheme$.pipe(takeUntilDestroyed()).subscribe(theme => {
			const classNames = Array.from(this.THEME_NODE.classList).filter(x => !ThemeServiceService.THEMES.includes(x));
			classNames.push(theme);

			this.THEME_NODE.className = classNames.join(' ');
		});

		this.darkMode$.pipe(takeUntilDestroyed()).subscribe(mode => {
			const classNames = Array.from(this.THEME_NODE.classList).filter(x => !ThemeServiceService.MODES.includes(x));
			classNames.push(mode ? ThemeServiceService.DARK_MODE : ThemeServiceService.LIGHT_MODE);

			this.THEME_NODE.className = classNames.join(' ');
		});

		// init mode
		this.darkMode$.next(window.matchMedia('(prefers-color-scheme: dark)').matches);
		window.matchMedia('(prefers-color-scheme: dark)')
		      .addEventListener('change', event => this.darkMode$.next(event.matches));


		// init theme
		switch(environment.stage) {
			case 'LOCAL':
				this.currentTheme$.next(ThemeServiceService.THEME_LOCAL);
				break;

			case 'DEVELOPMENT':
				this.currentTheme$.next(ThemeServiceService.THEME_DEV);
				break;

			default:
				this.currentTheme$.next(ThemeServiceService.THEME_DEFAULT);
				break;
		}
	}


	set currentTheme(theme: Themes) {
		if(this.currentTheme$.value === theme)
			return;

		this.currentTheme$.next(theme);
	}

	set darkMode(mode: boolean) {
		if(this.darkMode$.value === mode)
			return;

		this.darkMode$.next(mode);
	}
}
