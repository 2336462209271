import {Component} from '@angular/core';
import {environment} from '@app/environment';
import {IconService} from '@app/main';
import {
	RawContentModel,
	RawContentService,
} from '@contracts/frontend-api';
import {CategoryItemInterface} from '../../components/file/files-card/model-files-card.component';

@Component({
	selector:    'portal-download-section-page',
	templateUrl: './calculation-manuals-page.component.html',
	styleUrls:   ['./calculation-manuals-page.component.scss'],
})
export class CalculationManualsPageComponent {
	protected readonly publishedPreviewCategories: CategoryItemInterface[] = [
		{name: 'calculationManualPreviewSection'},
	];
	protected readonly publishedCategories: CategoryItemInterface[]        = [
		{name: 'calculationManualGeneralSection'},
	];
	protected readonly relationModel: RawContentModel;
	protected readonly relationModelPreview: RawContentModel;

	constructor(
		private readonly rawContentService: RawContentService,
		protected readonly iconService: IconService,
	) {
		this.relationModel        = this.rawContentService.getById(environment.calculationManualsRawContentId);
		this.relationModelPreview = this.rawContentService.getById(environment.calculationManualsPreviewsRawContentId);
	}
}
