import {
	HttpClient,
	HttpErrorResponse,
} from '@angular/common/http';
import {Component} from '@angular/core';
import {
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms';
import {
	AuthService,
	CustomValidators,
	FormHelperService,
} from '@app/main';
import {UserService} from '@authentication/frontend-api';
import {first} from 'rxjs/operators';

@Component({
	selector:    'portal-news-page',
	templateUrl: './newsletter-page.component.html',
	styleUrls:   ['./newsletter-page.component.scss'],
})
export class NewsletterPageComponent {
	newsLetter2Go = new FormGroup({
		// eslint-disable-next-line @typescript-eslint/naming-convention
		first_name: new FormControl(''),
		// eslint-disable-next-line @typescript-eslint/naming-convention
		last_name: new FormControl(''),
		gender:    new FormControl(null),
		email:     new FormControl(
			'',
			[
				Validators.required,
				Validators.email,
			],
		),
		// eslint-disable-next-line @typescript-eslint/naming-convention
		client_id: new FormControl(''),

		subscriptions: new FormGroup({
			bivdireckt:       new FormControl(false),
			bivdirecktWuV:    new FormControl(false),
		}, CustomValidators.checkboxGroupValidator),

		confirmation: new FormControl(false, Validators.requiredTrue),
	});

	globalMailError: string | null           = null;
	success                                  = false;
	private readonly n2gSubscribeUrl: string = 'https://api.newsletter2go.com/forms/submit/yj7s3ghz-il1a11av-111c?type=subscribe';
	private readonly bivDirectId             = 't6t659p3';
	private readonly bivDirecktCovi19Id      = 'wt1pjdxv';
	private readonly bivDirecktWuvId         = '693cbtnr';

	constructor(
		public readonly formHelperService: FormHelperService,
		protected readonly httpClient: HttpClient,
		readonly authService: AuthService,
		readonly userService: UserService,
	) {
		authService.user$.pipe(first()).toPromise().then(async token => {
			if(token == null)
				return;

			const userId = token.getSubject();
			this.newsLetter2Go.controls.client_id.setValue(userId);
			const user = userService.getById(token.getSubject());

			const email = await user.email.firstValue;
			if(email != null)
				this.newsLetter2Go.controls.email.setValue(email);

			const name = await user.name.firstValue;
			if(name != null) {
				const [firstName, lastName] = name.split(' ', 2);
				this.newsLetter2Go.controls.first_name.setValue(firstName);
				this.newsLetter2Go.controls.last_name.setValue(lastName);
			}
		});
	}

	async sendForm(): Promise<void> {
		if(!this.newsLetter2Go.valid || this.newsLetter2Go.value.confirmation === false)
			return;

		const formData = this.newsLetter2Go.value;
		const list     = [];

		if(formData.subscriptions?.bivdireckt === true)
			list.push(this.bivDirectId);

		if(formData.subscriptions?.bivdirecktWuV === true)
			list.push(this.bivDirecktWuvId);

		const recipient = {...formData};
		delete recipient.subscriptions;

		const dtoFormData = {
			recipient,
			lists:   list,
			captcha: {},
		};

		const response = await this.httpClient.post(this.n2gSubscribeUrl, dtoFormData, {observe: 'response'})
								   .toPromise()
								   .catch(x => {
									   if(x instanceof HttpErrorResponse)
										   return x;
									   throw x;
								   });

		if(response instanceof HttpErrorResponse) {
			this.globalMailError = response.error.errorMessage;
			return;
		}

		switch(response.status) {
			case 200:
				this.globalMailError = 'Die Mailadresse ist bereits angemeldet';
				break;

			case 201:
				this.globalMailError = null;
				this.success         = true;
				this.newsLetter2Go.reset();
				this.newsLetter2Go.markAsPristine();
				break;

			default:
				this.globalMailError = 'Ein unbekannter Fehler ist aufgetreten. Bitte Versuchen Sie es später erneut.';
		}
	}
}
