<portal-headline
	label="general.modelList"
	[labelArgs]="{model: 'professionalAssociations'}"
/>

<mat-card>
	<mat-card-content>
		<portal-professional-association-list [data]="professionalAssociations | async"/>
	</mat-card-content>
</mat-card>
