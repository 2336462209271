import {
	Component,
	Inject,
} from '@angular/core';
import {
	MAT_DIALOG_DATA,
	MatDialogRef,
} from '@angular/material/dialog';
import {IconService} from '@app/main';

export interface DeleteModelDialogData {
	modelType: string;
	modelName: string;
	deleteCallback?: () => Promise<unknown>;
}

@Component({
	selector:    'portal-delete-model-dialog',
	templateUrl: './delete-model-dialog.component.html',
	styleUrls:   ['./delete-model-dialog.component.scss'],
})
export class DeleteModelDialogComponent {
	isDeleting = false;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: DeleteModelDialogData,
		private readonly self: MatDialogRef<DeleteModelDialogComponent, boolean>,
		public iconService: IconService,
	) {
	}


	delete(): void {
		if(this.isDeleting)
			return;

		this.isDeleting = true;

		if(!this.data.deleteCallback) {
			this.self.close(true);
			return;
		}

		this.data.deleteCallback()
			.then(() => {
				this.self.close(true);
			})
			.catch(() => {
				// todo error handling
				this.isDeleting = false;
			});
	}
}
