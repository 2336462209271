@if (loading) {
	<div class="loading">
		<mat-progress-bar mode="indeterminate"></mat-progress-bar>
	</div>
} @else {
	<ng-container *ngLet="(content ?? columnContentTemplates?.get(0)?.template) as template">
		@if (template != null) {
			<ng-container [ngTemplateOutlet]="template"></ng-container>
		} @else {
			<ng-content></ng-content>
		}
	</ng-container>
}

