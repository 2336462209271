<portal-headline
	label="preQualificationCertificate.create.title"
/>


@if (control && fileControl && accreditationControl && ikControl && employeeControl && validityControl && versorgungsbereichsControl) {
	<mat-card>
		<mat-card-content>
			<mat-stepper orientation="vertical" linear="true" [formGroup]="control">
				<ng-template matStepperIcon="edit" let-index="index">{{ index + 1 }}</ng-template>
				<ng-template matStepperIcon="done" let-index="index">{{ index + 1 }}</ng-template>
				<ng-template matStepperIcon="error" let-index="index">{{ index + 1 }}</ng-template>
				<mat-step [stepControl]="fileControl" [hasError]="fileControl.touched && fileControl.invalid">
					<ng-template matStepLabel>{{ 'preQualificationCertificate.create.file' | translate }}</ng-template>
					<p>{{ 'preQualificationCertificate.create.fileHint' | translate }}</p>
					<div class="grid-2">
						<portal-file-create [control]="control" label="preQualificationCertificate.file" [acceptedFileTypes]="[FileType.pdf]"/>
					</div>
					<ng-container *ngTemplateOutlet="stepperActions"></ng-container>
				</mat-step>
				<mat-step [stepControl]="accreditationControl" [hasError]="accreditationControl.touched && accreditationControl.invalid">
					<ng-template matStepLabel>{{ 'preQualificationCertificate.create.accreditation' | translate }}</ng-template>
					<div class="grid-2">
						<portal-pre-qualification-certificate-edit-accreditation-body [control]="control"/>
						<portal-pre-qualification-certificate-edit-certificate-identifier [control]="control"/>
					</div>
					<ng-container *ngTemplateOutlet="stepperActions"></ng-container>
				</mat-step>
				<mat-step [stepControl]="ikControl" [hasError]="ikControl.touched && ikControl.invalid">
					<ng-template matStepLabel>{{ 'preQualificationCertificate.create.institutionskennzeichen' | translate }}</ng-template>
					<div class="grid-2">
						<portal-institutionskennzeichen-select [control]="control"/>
					</div>
					<ng-container *ngTemplateOutlet="stepperActions"></ng-container>
				</mat-step>
				<mat-step [stepControl]="employeeControl" [hasError]="employeeControl.touched && employeeControl.invalid">
					<ng-template matStepLabel>{{ 'preQualificationCertificate.create.employee' | translate }}</ng-template>
					<div class="grid-2">
						<portal-employee-select [control]="control" [medicalStore]="control.controls.institutionskennzeichen.value?.medicalStore?.value | async" label="preQualificationCertificate.employee"/>
					</div>
					<ng-container *ngTemplateOutlet="stepperActions"></ng-container>
				</mat-step>
				<mat-step [stepControl]="validityControl" [hasError]="validityControl.touched && validityControl.invalid">
					<ng-template matStepLabel>{{ 'preQualificationCertificate.create.validity' | translate }}</ng-template>
					@if (control) {
						<div class="grid-2">
							<portal-pre-qualification-certificate-edit-validity-start-at [control]="control"/>
							<portal-pre-qualification-certificate-edit-validity-end-at [control]="control"/>
						</div>
					}
					<ng-container *ngTemplateOutlet="stepperActions"></ng-container>
				</mat-step>
				<mat-step [stepControl]="versorgungsbereichsControl" [hasError]="versorgungsbereichsControl.touched && versorgungsbereichsControl.invalid">
					<ng-template matStepLabel>{{ 'preQualificationCertificate.create.versorgungsbereichs' | translate }}</ng-template>
					<div class="grid-2">
						<portal-pre-qualification-certificate-edit-versorgungsbereichs [control]="control"/>
					</div>
					<ng-container *ngTemplateOutlet="stepperActions"></ng-container>
				</mat-step>
				<mat-step>
					<ng-template matStepLabel>{{ 'general.create.finish.label' | translate }}</ng-template>
					<p>{{ 'preQualificationCertificate.create.finish.text' | translate }}</p>
					@if (control.errors) {
						<mat-error>{{ 'preQualificationCertificate.create.finish.error' | translate }}</mat-error>
					}
					<ul>
						@for (error of formHelperService.getErrors(control) | keyvalue;track error) {
							<li>
								<mat-error>
									{{ 'input.error.' + error.key | translate: error.value }}
								</mat-error>
							</li>
						}
					</ul>
					<div class="stepper-actions">
						<button mat-button matStepperPrevious>{{ 'actions.previous' | translate }}</button>
						<portal-spinning-button (click)="create()" [processing]="isSaving">{{ 'actions.createNow' | translate }}</portal-spinning-button>
					</div>
				</mat-step>
				<ng-template #stepperActions>
					<div class="stepper-actions">
						<button mat-button matStepperPrevious>{{ 'actions.previous' | translate }}</button>
						<button mat-button color="primary" matStepperNext>{{ 'actions.next' | translate }}</button>
					</div>
				</ng-template>
			</mat-stepper>
		</mat-card-content>
		@if (this.errorHasOccurred) {
			<portal-form-error [error]="this.errorHasOccurred"/>
		}
	</mat-card>
} @else {
	<portal-loading/>
}
