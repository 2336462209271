import {
	Component,
	Input,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';

@Component({
	selector:    'portal-export-edit-next-at',
	templateUrl: './export-edit-next-at.component.html',
	styleUrls:   ['./export-edit-next-at.component.scss'],
})
export class ExportEditNextAtComponent implements OnChanges {
	@Input({required: true}) form!: UntypedFormGroup;
	@Input() fieldName = 'nextAt';

	public field?: UntypedFormControl;
	public earliestDate$ = new BehaviorSubject(new Date());

	ngOnChanges(changes: SimpleChanges): void {
		const field = this.form.get(this.fieldName);

		if(!(field instanceof UntypedFormControl))
			return;

		this.field = field;
	}
}
