import {Component} from '@angular/core';
import {environment} from '@app/environment';

@Component({
  selector:    'portal-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls:   ['./terms-and-conditions.component.scss'],
})
export class TermsAndConditionsComponent {
  readonly environment = environment;
}
