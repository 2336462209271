import {Component} from '@angular/core';
import {environment} from '@app/environment';
import {AbstractShowComponent} from '@app/main';
import {
	ExportModel,
	ExportService,
} from '@contracts/frontend-api';
import {Environment} from 'src/environments/environment';

@Component({
	selector:    'portal-export-show-page',
	templateUrl: './export-show-page.component.html',
	styleUrls:   ['./export-show-page.component.scss'],
})
export class ExportShowPageComponent extends AbstractShowComponent<ExportModel, ExportService> {
	readonly fileCategories = [
		{name: 'contractMemberListExport'},
		{name: 'supplyAreaListExport'},
	];

	protected getModelName(): Promise<string | undefined> {
		return new Promise((success) => success(this.getModel().id));
	}

	constructor(
			exportService: ExportService,
	) {
		super(exportService);
	}

	get environment(): Environment {
		return environment;
	}

}
