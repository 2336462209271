import {
	Component,
	Input,
} from '@angular/core';
import {
	BaseContractApiService,
	BaseContractModel,
	BaseContractService,
	ContractModel,
	ContractSectionModel,
} from '@contracts/frontend-api';
import {Observable} from 'rxjs';

@Component({
	selector:    'portal-base-contract-show-awarded-mandates',
	templateUrl: './base-contract-show-mandate-grantor.component.html',
	styleUrls:   ['./base-contract-show-mandate-grantor.component.scss'],
})
export class BaseContractShowMandateGrantorComponent<Model extends BaseContractModel<BaseContractService<BaseContractApiService, Model>>> {
	@Input({required: true}) model?: Model;

	get value(): Observable<string[] | null | undefined> | undefined {
		if(this.model == null)
			return this.model;

		if(this.model instanceof ContractModel || this.model instanceof ContractSectionModel)
			return this.model.mandateGrantor.withParent.value;

		return this.model.mandateGrantor.value;
	}
}
