@if (validator && visible) {
	<label [class.disabled]="validator.disabled">
		@if (icon) {
			<fa-icon [icon]="icon"></fa-icon>
		}
		@if (label) {
			{{ label | translate }}
		}
		@if (description) {
			<portal-info [description]="description"/>
		}
	</label>
	<ng-content></ng-content>
}
