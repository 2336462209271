import {
	Pipe,
	PipeTransform,
} from '@angular/core';
import {MedicalStoreModel} from '@contracts/frontend-api';
import {
	combineLatest,
	Observable,
	of,
} from 'rxjs';
import {
	map,
	mergeMap,
} from 'rxjs/operators';
import {ElementEntryStateInterface} from '../../../contracts/components/medical-store/properties/medical-store-user/edit/medical-store-user-edit.component';

@Pipe({
	name: 'canEditStorePermission',
})
export class CanEditStorePermissionPipe implements PipeTransform {

	transform(medicalStoreModel: MedicalStoreModel, library: Map<string, ElementEntryStateInterface>, selectedUserId?: string): Observable<boolean> {
		const state = library.get(medicalStoreModel.id);

		if(state == null) {
			return combineLatest([
				medicalStoreModel.parent.value.pipe(
					mergeMap((parent) => {
						if(parent == null)
							return of(false);


						return parent.users.value.pipe(
							map((users) => {
								if(users == null)
									return false;

								const found = users.find(user => user.user.id === selectedUserId);
								return !(found == null) || false;
							}),
						);
					}),
				),
				medicalStoreModel.permissions.canUpdate,
			]).pipe(
				map(([parentSelected, hasPermission]) => hasPermission && !parentSelected),
			);
		}

		return of(!state.state.inheritedEdit);
	}
}
