export class FileHelper {
	static formatFileSize(bytes: number): string {
		const units = 'kMGTPEZY'.split('').reverse();
		let unit    = '';
		while(bytes > 1_024) {
			bytes /= 1_024;
			unit = units.pop() ?? '';
		}

		return `${bytes.toFixed(0)} ${unit}B`;
	}
}
