import {PhoneHelper} from '@angular-helpers/frontend-api';
import {
	AbstractControl,
	UntypedFormGroup,
	ValidationErrors,
	ValidatorFn,
} from '@angular/forms';

export class CustomValidators {
	static checkboxGroupValidator(formGroup: AbstractControl): ValidationErrors | null {
		if(!(formGroup instanceof UntypedFormGroup))
			return null;

		const checkedKeys = Object.keys(formGroup.controls).filter((key) => formGroup.controls[key].value);

		if(checkedKeys.length === 0)
			return {required: true};

		return null;
	}

	static isValidPhone(control: AbstractControl): ValidationErrors | null {
		return PhoneHelper.valid(control);
	}

	static isRequiredIf(required: boolean): ValidatorFn {
		return (control: AbstractControl): { [key: string]: unknown } | null => {
			if(required === false)
				return null;

			if(control.value != null)
				return null;

			return {required: {value: control.value}};
		};
	}

	static isRequiredWhen(required: () => boolean): ValidatorFn {
		return (control: AbstractControl): { [key: string]: unknown } | null => {
			if(required() === false)
				return null;

			if(control.value != null)
				return null;

			return {required: {value: control.value}};
		};
	}

	static isValidRegex(regex: RegExp, errorCode: string): ValidatorFn {
		return (control: AbstractControl): { [key: string]: unknown } | null => CustomValidators.isMatchingRegex(
				control,
				regex,
				errorCode,
		);
	}

	static isValidLeg(control: AbstractControl): ValidationErrors | null {
		const regex = /^[0-9]{6}$/;
		return CustomValidators.isMatchingRegex(control, regex, 'leg');
	}

	static isValidUrl(control: AbstractControl): ValidationErrors | null {
		const regex = /^(http|https):\/\/(([\p{L}\p{N}\p{S}_.-])+(\.?([\p{L}\p{N}]|xn--[\p{L}\p{N}-]+)+\.?))((?:\/(?:[-\p{L}\p{N}._~!$&'()*+,;=:@]|%[0-9A-Fa-f]{2})*)*)((?:\?(?:[-\p{L}\p{N}._~!$&'\[\]()*+,;=:@/?]|%[0-9A-Fa-f]{2})*)?)((?:#(?:[-\p{L}\p{N}._~!$&'()*+,;=:@/?]|%[0-9A-Fa-f]{2})*)?)$/iu;
		return CustomValidators.isMatchingRegex(control, regex, 'url');
	}

	static isValidId(control: AbstractControl): ValidationErrors | null {
		const regex = /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;
		return CustomValidators.isMatchingRegex(control, regex, 'id');
	}

	static isValidEMail(control: AbstractControl): ValidationErrors | null {
		const regex = /^[\p{L}\p{N}._%+-]+@[\p{L}\p{N}.-]+\.[\p{L}]{2,}$/u;
		return CustomValidators.isMatchingRegex(control, regex, 'email');
	}

	protected static isMatchingRegex(control: AbstractControl, regex: RegExp, errorCode: string): ValidationErrors | null {
		const value = control.value;
		if(typeof value != 'string' || value === '')
			return null;


		const match = regex.test(value);
		if(match)
			return null;
		else
			return {[errorCode]: {value}};
	}
}
