@if (model) {
	<a [routerLink]="environment.medicalStoresFullUrl+'/'+model.id" class="no-link highlightable">
		<mat-card>
			<mat-card-header class="logo">
				<fa-icon [icon]="iconService.MEDICAL_STORE" mat-card-avatar></fa-icon>
				<mat-card-title>{{ title | translate }}</mat-card-title>
			</mat-card-header>
			<mat-card-content>
				@if ((model.address.value | async);as address) {
					<p>
						{{ address.name }}
						<br/>
						{{ address.street }} {{ address.houseNumber }}
						<br/>
						{{ address.zip }} {{ address.town }}
					</p>
				}
			</mat-card-content>
		</mat-card>
	</a>
}
