import {Level8Error} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
} from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
} from '@angular/forms';
import {
	combineLatestSafe,
	SelectFieldEntry,
} from '@app/main';
import {MedicalStoreModel} from '@contracts/frontend-api';
import {
	Observable,
	of,
} from 'rxjs';
import {
	filter,
	map,
	mergeMap,
} from 'rxjs/operators';

@Component({
	selector: 'portal-employee-select',
	templateUrl: './employee-select.component.html',
	styleUrls: ['./employee-select.component.scss'],
})
export class EmployeeSelectComponent {
	@Input({
		required: true,
		alias:    'control',
	}) parent!: UntypedFormGroup;
	@Input('label') label = 'model.employee';
	@Input() medicalStore?: MedicalStoreModel | null;

	get possibleValues$(): Observable<SelectFieldEntry[] | undefined> {
		if(this.medicalStore == null)
			return of(undefined);

		return this.medicalStore.employees.value.pipe(
			mergeMap(employees => combineLatestSafe(employees?.map(employee =>
				employee.name.value.pipe(
						filter((name): name is string => typeof name === 'string'),
						map((name): SelectFieldEntry => ({
							label: name,
							value: employee,
						})),
				)))),
		);
	}

	get control(): UntypedFormControl {
		const fieldName = 'employee';
		const control   = this.parent.get(fieldName);
		if(control instanceof UntypedFormControl)
			return control;

		throw new Level8Error(`Unexpected type for field ${fieldName} - expected '${UntypedFormControl.name}' got '${typeof control}' (${control})`);
	}
}
