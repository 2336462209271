import {
	Component,
	Input,
} from '@angular/core';
import {ContractAccessionModel} from '@contracts/frontend-api';

@Component({
	selector:    'portal-contract-accession-contract',
	templateUrl: './contract-accession-contract.component.html',
	styleUrls:   ['./contract-accession-contract.component.scss'],
})
// todo delete this - its unused and wrong (contract-show-component is the correct implementation)
export class ContractAccessionContractComponent {
	@Input({required: true}) model!: ContractAccessionModel;

}
