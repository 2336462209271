import {
	Component,
	Input,
} from '@angular/core';
import {PreQualificationCertificateModel} from '@contracts/frontend-api';

@Component({
	selector:    'portal-pre-qualification-certificate-show-accreditation-body',
	templateUrl: './pre-qualification-certificate-show-accreditation-body.component.html',
	styleUrls:   ['./pre-qualification-certificate-show-accreditation-body.component.scss'],
})
export class PreQualificationCertificateShowAccreditationBodyComponent {
	@Input({required: true}) model!: PreQualificationCertificateModel;
}
