<portal-editable
	[validator]="control"
	[label]="label"
>
	<mat-form-field>
		<input
        #fileInput
				[hidden]="true"
				type="file"
				[accept]="acceptedFileTypes"
				(change)="fileSelected($event)"/>
		<input
			(click)="selectFile()"
			[class.empty]="!file"
			[value]="file?.name ?? ('file.choose' | translate)"
			class="mat-input-element"
			readonly/>
		<input
        #matInput
				class="fakeInput"
				[formControl]="fileNameControl"
				matInput/>

		@for (error of formHelperService.getErrors(control) | keyvalue;track error) {
			<mat-error>
				{{ 'input.error.' + error.key | translate: error.value }}
			</mat-error>
		}
		@for (error of formHelperService.getErrors(fileNameControl) | keyvalue;track error) {
			<mat-error>
				{{ 'input.error.' + error.key | translate: error.value }}
			</mat-error>
		}

		@if (file) {
			<fa-icon matPrefix [icon]="iconService.FILE_PDF"></fa-icon>
		}
		@if (file) {
			<mat-hint matSuffix>{{ formatFileSize(file.size) }}</mat-hint>
		}

	</mat-form-field>
</portal-editable>
