import {
	Component,
	DestroyRef,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChange,
	SimpleChanges,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
	UntypedFormControl,
	UntypedFormGroup,
	ValidatorFn,
} from '@angular/forms';
import {
	DateSelect,
	FormHelperService,
	RelativeDateService,
} from '@app/main';

// todo #93 replace this class by edit-fiel-select

@Component({
	selector:    'portal-base-relative-date-select',
	templateUrl: './portal-base-relative-date-select.component.html',
	styleUrls:   ['./portal-base-relative-date-select.component.scss'],
})
export class BaseRelativeDateSelectComponent implements OnChanges {
	@Input() control?: UntypedFormGroup;
	@Input() label    = '';
	@Input() fieldName?: string;
	@Input() placeHolder: string | null | undefined;
	@Input() disabled = false;
	@Input() description?: string;
	@Output() change  = new EventEmitter<string>();

	dateSelected: string | null | undefined;
	field?: UntypedFormControl;
	fieldValidator?: ValidatorFn;

	constructor(
		protected relativeDateService: RelativeDateService,
		protected formHelperService: FormHelperService,
		protected readonly destroyRef: DestroyRef,
	) {
	}

	get selectOptions(): DateSelect[] {
		if(this.fieldName === 'joiningDelay' || this.fieldName === 'noticePeriodDelay')
			return this.relativeDateService.getBaseDelayOptions();

		if(this.fieldName === 'noticePeriodNegotiationDelay')
			return this.relativeDateService.getDelayOptions();

		return this.relativeDateService.getAllSelectOptions();
	}

	onValueSelect(selected: string): void {
		if(this.field == null)
			return;

		this.writeValue(selected);
		this.field.markAsDirty();
	}

	writeValue(relativeDate: string): void {
		if(this.field == null)
			return;

		if(!this.relativeDateService.valueExists(relativeDate))
			return;

		this.field.setValue(relativeDate, {emitEvent: false});
	}

	ngOnChanges(changes: SimpleChanges): void {
		const newControlChange = changes.control as (SimpleChange | undefined); // workaround for angular incorrect type hinting
		if(newControlChange instanceof SimpleChange) {
			const newControl = changes.control.currentValue;
			this.setControl(newControl);
		}

		if(this.field != null)
			this.disabled = this.field.disabled;
		else {
			if(this.fieldName == null)
				return;

			if(this.control == null)
				return;

			const field = this.control.get(this.fieldName);
			if(field)
				this.disabled = field.disabled;

		}
	}

	setControl(control: UntypedFormGroup): void {
		this.control = control;
		if(this.fieldName == null)
			return;

		const field = this.control.get(this.fieldName);
		if(!(field instanceof UntypedFormControl))
			return;

		this.field = field;
		this.field.valueChanges
		    .pipe(takeUntilDestroyed(this.destroyRef))
		    .subscribe((value) => {
			    this.writeValue(value);
		    });
		this.dateSelected = this.hasOption(this.field.value) ? this.field.value : undefined;
		const validator   = field.validator;
		if(validator == null)
			return;

		this.fieldValidator = validator;
	}

	hasOption(optionValue: string): boolean {
		return this.selectOptions.find((option) => option.value === optionValue) != null;
	}
}
