import {PropertyInterface} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
} from '@angular/core';

interface ContactInterface {
	contact: PropertyInterface<string | undefined>;
}

@Component({
	selector:    'portal-common-show-contact',
	templateUrl: './common-show-contact.component.html',
	styleUrls:   ['./common-show-contact.component.scss'],
})
export class CommonShowContactComponent<T extends ContactInterface> {
	@Input({required: true}) model!: T;
}
