import {
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {FormHelperService} from '@app/main';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import moment from 'moment';

@Component({
	selector:    'portal-edit-field-date',
	templateUrl: './edit-field-date.component.html',
	styleUrls:   ['./edit-field-date.component.scss'],
})
export class EditFieldDateComponent {
	@Input() icon?: IconDefinition;
	@Input({required: true}) label!: string;
	@Input({required: true}) validator!: UntypedFormControl;
	@Input() showIfNotEditable = false;
	@Input() earliestDate?: moment.Moment | Date | undefined | null;
	@Input() latestDate?: moment.Moment | Date | undefined | null;
	@Input() placeholder?: string | null;
	@Output() change           = new EventEmitter();

	constructor(
		protected readonly formHelperService: FormHelperService,
	) {
	}
}