import {
	Component,
	Input,
} from '@angular/core';
import {PreQualificationCertificateModel} from '@contracts/frontend-api';

@Component({
	selector:    'portal-pre-qualification-certificate-show-validity-end-at',
	templateUrl: './pre-qualification-certificate-show-validity-end-at.component.html',
	styleUrls:   ['./pre-qualification-certificate-show-validity-end-at.component.scss'],
})
export class PreQualificationCertificateShowValidityEndAtComponent {
	@Input({required: true}) model!: PreQualificationCertificateModel;
}
