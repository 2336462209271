<portal-showable
	[label]="label"
	[icon]="icon"
	[value]="value"
>
	@if (urlType !== 'internal') {
		<portal-copyable [content]="value ?? ''">
			<ng-container *ngTemplateOutlet="content"/>
		</portal-copyable>
	} @else {
		<ng-container *ngTemplateOutlet="content"></ng-container>
	}

</portal-showable>


<ng-template #content>
	@if (urlType === URL_TYPE.internal) {
		<a class="invisible-link" [routerLink]="href" [href]="href">{{ renderValue }}</a>
	} @else {
		<a class="invisible-link" [href]="href">{{ renderValue }}</a>
	}
</ng-template>