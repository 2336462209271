<portal-showable
	label="baseContract.status"
	[value]="status$ | async"
>
	<div class="wrapper">
		<ng-container *ngLet="status$ | async as data">
			@if (data == null) {
				<p class="state">{{ 'common.invalid' | translate }}</p>
			} @else {
				<p class="state">{{ data.description ?? '' | translate: {date: (data.date | date)} }}</p>
			}
		</ng-container>
	</div>
</portal-showable>
