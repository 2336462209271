import {PhoneHelper} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
} from '@angular/core';
import {
	PhonePipe,
	ShowableComponent,
	UrlType,
} from '@app/main';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {WebsitePipe} from '../../pipes/website.pipe';

@Component({
	selector:    'portal-show-field-url',
	templateUrl: './show-field-url.component.html',
	styleUrls:   ['./show-field-url.component.scss'],
})
export class ShowFieldUrlComponent {
	@Input({required: true}) label!: ShowableComponent['label'];
	@Input({required: true}) value: string | undefined | null;
	@Input({required: true}) urlType!: UrlType;
	@Input() icon?: IconDefinition;
	@Input() url?: string;
	readonly URL_TYPE = UrlType;

	constructor(
		private readonly phonePipe: PhonePipe,
		private readonly websitePipe: WebsitePipe,
	) {
	}

	get renderValue(): string {
		if(this.value == null)
			return '';

		switch(this.urlType) {
			case UrlType.phone:
				return this.phonePipe.transform(this.value);

			case UrlType.domain:
				return this.websitePipe.transform(this.value);

			default:
				return this.value;
		}
	}

	get href(): string {
		if(this.urlType === UrlType.internal)
			return this.url ?? '';

		if(this.value == null)
			return '';

		switch(this.urlType) {
			case UrlType.mail:
				return `mailto:${this.value}`;

			case UrlType.phone:
				return PhoneHelper.formatUrl(this.value);

			case UrlType.domain:
				if(this.value.match(/^[^:]*:\/\//) == null)
					return `https://${this.value}`;

				return this.value;
		}
	}
}
