@if (field != null) {
	<portal-editable
		[label]="label"
		[validator]="field"
		[description]="description"
	>
		<mat-form-field class="select-form-field">
			<mat-select class="units" #select [formControl]="field" (valueChange)="onValueSelect(select.value)" [disabled]="disabled" [placeholder]="(this.placeHolder | TranslateRelativeDate) ?? ''">
				@for (date of selectOptions;track date) {
					<mat-option class="un-option" [value]="date.value">
						{{ date.value | TranslateRelativeDate }}
					</mat-option>
				}
			</mat-select>
			<portal-input-status-icon matSuffix [validator]="field"/>
			@for (error of formHelperService.getErrors(field) | keyvalue;track error) {
				<mat-error>
					{{ 'input.error.' + error.key | translate: error.value }}
				</mat-error>
			}
		</mat-form-field>
	</portal-editable>
} @else {
	<portal-showable
		[label]="label"
		[value]="placeHolder"
		[description]="description"
	>
		<p>{{ placeHolder | TranslateRelativeDate }}</p>
	</portal-showable>
}
