import {DtoEditFormHelper} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
} from '@angular/core';
import {
	IconService,
	ModelHelper,
} from '@app/main';
import {
	MedicalStoreDtoModel,
	MedicalStoreModel,
	MedicalStoreService,
} from '@contracts/frontend-api';

@Component({
	selector:    'portal-medical-store-contacts-card',
	templateUrl: './medical-store-contacts-card.component.html',
	styleUrls:   ['./medical-store-contacts-card.component.scss'],
})
export class MedicalStoreContactsCardComponent {
	formHelper!: DtoEditFormHelper<MedicalStoreModel, MedicalStoreService>;
	private _medicalStore!: MedicalStoreModel;
	public canUpdate?: Promise<boolean>;

	constructor(
		protected medicalStoreService: MedicalStoreService,
		public iconService: IconService,
	) {
	}

	get medicalStore(): MedicalStoreModel {
		return this._medicalStore;
	}

	@Input({required: true}) set medicalStore(value: MedicalStoreModel) {
		if(this.medicalStore === value)
			return;

		this._medicalStore = value;
		this.formHelper    = DtoEditFormHelper.create(
				MedicalStoreDtoModel,
				this.medicalStore,
				this.medicalStoreService,
		);

		this.canUpdate = ModelHelper.findEditableProperty([
			this.medicalStore.email,
			this.medicalStore.phone,
			this.medicalStore.fax,
			this.medicalStore.website,
		]);
	}
}
