<portal-headline
	[backlink]="true"
	[label]="label+'.createNew'"
/>

@if (formHelper && control && masterDataControl && contractingControl && joinedPartiesControl) {
	<mat-card>
		<mat-card-content>
			<mat-stepper #stepper orientation="vertical" linear="true" [formGroup]="control">
				<ng-template matStepperIcon="edit" let-index="index">{{ index + 1 }}</ng-template>
				<ng-template matStepperIcon="done" let-index="index">{{ index + 1 }}</ng-template>
				<ng-template matStepperIcon="error" let-index="index">{{ index + 1 }}</ng-template>

				<mat-step [stepControl]="masterDataControl">
					<ng-template matStepLabel>{{ 'baseContract.masterData' | translate }}</ng-template>
					<div class="grid-2">
						@if (isContract(parent)) {
							<portal-contract-show [model]="parent"/>
						} @else if (isMasterContract(parent)) {
							<portal-master-contract-show [model]="parent"/>
						}
						@if (isContract(parent)) {
							<portal-contract-section-edit-versorgungsbereich [parentModel]="parent" [control]="control" (valueChanges)="onVbChange($event)"/>
						} @else {
							<portal-base-contract-edit-name [control]="control"/>
						}
						<portal-base-contract-edit-validity-start [parentModel]="parent" [control]="control"/>
						@if (!isContractSection(parent)) {
							<portal-base-contract-edit-minimum-term [parentModel]="parent" [control]="control"/>
						}
						<portal-base-contract-edit-notice-period-negotiations [parentModel]="parent" [control]="control"/>
						<portal-base-contract-edit-negotiators [parentModel]="parent" [control]="control"/>
						<portal-base-contract-edit-mandate-grantor [parentModel]="parent" [control]="control"/>
						<portal-base-contract-edit-initial-signer [parentModel]="parent" [control]="control"/>
					</div>
					<ng-container *ngTemplateOutlet="stepperActions"></ng-container>
				</mat-step>

				<mat-step [stepControl]="contractingControl">
					<ng-template matStepLabel>{{ 'baseContract.contractingData' | translate }}</ng-template>
					<div class="grid-2">
						<portal-base-contract-edit-maximum-back-dating-delay [parentModel]="parent" [control]="control"/>
						<portal-base-contract-edit-notice-period [parentModel]="parent" [control]="control"/>
						<portal-base-contract-edit-joining-delay [parentModel]="parent" [control]="control"/>
						<portal-base-contract-edit-billing-delay [parentModel]="parent" [control]="control"/>
					</div>
					<ng-container *ngTemplateOutlet="stepperActions"></ng-container>
				</mat-step>

				<mat-step [stepControl]="joinedPartiesControl">
					<ng-template matStepLabel>{{ 'baseContract.joinedParties' | translate }}</ng-template>
					<div class="grid-2">
						@if (isEditingMasterContract()) {
							<portal-base-contract-edit-joined-parties [parentModel]="parent" [control]="joinedPartiesControl"/>
						} @else {
							<portal-contract-external-contract-number-list-edit [formHelper]="formHelper" [control]="joinedPartiesControl" [parent]="parent" [type]="externalContractNumberType.legs"/>
							<portal-contract-external-contract-number-list-edit [formHelper]="formHelper" [control]="joinedPartiesControl" [parent]="parent" [type]="externalContractNumberType.mip"/>
						}
					</div>
					<ng-container *ngTemplateOutlet="stepperActions"></ng-container>
				</mat-step>

				<mat-step>
					<ng-template matStepLabel>{{ 'baseContract.requirements' | translate }}</ng-template>
					<portal-requirement-create-requirement [(requirements)]="requirements" [currentVB]="vbSelected.asObservable()"/>
					<ng-container *ngTemplateOutlet="stepperActions"></ng-container>
				</mat-step>

				<mat-step>
					<ng-template matStepLabel>{{ 'baseContract.finish.label' | translate }}</ng-template>
					<p>{{ 'baseContract.finish.text' | translate }}</p>
					<div class="stepper-actions">
						<button mat-button matStepperPrevious>{{ 'actions.previous' | translate }}</button>
						<portal-spinning-button (click)="saveForm()" [processing]="isSaving">{{ 'actions.createNow' | translate }}</portal-spinning-button>
					</div>
				</mat-step>

				<ng-template #stepperActions>
					<div class="stepper-actions">
						<button mat-button matStepperPrevious *ngIf="stepper.selectedIndex > 0">{{ 'actions.previous' | translate }}</button>
						<button mat-button color="primary" matStepperNext>{{ 'actions.next' | translate }}</button>
					</div>
				</ng-template>
			</mat-stepper>

			@if (this.errorHasOccurred) {
				<portal-form-error [error]="this.errorHasOccurred"/>
			}
		</mat-card-content>
	</mat-card>
} @else {
	<portal-loading/>
}
