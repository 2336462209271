import {Level8Error} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
	OnDestroy,
} from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
} from '@angular/forms';
import {FormHelperService} from '@app/main';
import {MedicalStoreDtoModel} from '@contracts/frontend-api';
import {Subscription} from 'rxjs';

@Component({
	selector:    'portal-medical-store-edit-legal-form',
	templateUrl: './medical-store-edit-legal-form.component.html',
	styleUrls:   ['./medical-store-edit-legal-form.component.scss'],
})
export class MedicalStoreEditLegalFormComponent implements OnDestroy {
	legalFormsFiltered: string[]  = [];
	subscriptions: Subscription[] = [];
	private _parent!: UntypedFormGroup;

	constructor(
		public formHelperService: FormHelperService,
	) {
	}

	get parent(): UntypedFormGroup {
		return this._parent;
	}

	@Input({
		required: true,
		alias:    'control',
	}) set parent(value: UntypedFormGroup) {
		this._parent = value;

		const subscription = this.control.valueChanges.subscribe(currentValue => {
			currentValue            = currentValue?.toLowerCase() ?? '';
			this.legalFormsFiltered = MedicalStoreDtoModel.POSSIBLE_LEGAL_FORMS
														  .filter(
																  possibleForm => possibleForm
																		  .toLowerCase()
																		  .includes(currentValue),
														  ).sort();
		});

		this.subscriptions.push(subscription);
	}

	get control(): UntypedFormControl {
		const fieldName = 'legalForm';
		const control   = this.parent.get(fieldName);
		if(control instanceof UntypedFormControl)
			return control;

		throw new Level8Error(`Unexpected type for field ${fieldName} - expected '${UntypedFormControl.name}' got '${typeof control}' (${control})`);
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}
}
