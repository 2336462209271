import {
	Component,
	ElementRef,
	Input,
	ViewChild,
} from '@angular/core';
import {MatTooltip} from '@angular/material/tooltip';
import {IconService} from '@app/main';
import {
	Observable,
	timer,
} from 'rxjs';
import {first} from 'rxjs/operators';

@Component({
	selector:    'portal-copyable',
	templateUrl: './copyable.component.html',
	styleUrls:   ['./copyable.component.scss'],
})
export class CopyableComponent {
	@ViewChild('matTooltip') readonly matTooltip!: MatTooltip;
	@ViewChild('content') readonly contentElement!: ElementRef<HTMLDivElement>;
	@Input() content: string | Observable<unknown> | undefined;
	isCopied = false;
	protected readonly TOOLTIP_COPY   = 'actions.copy';
	tooltip  = this.TOOLTIP_COPY;
	protected readonly TOOLTIP_COPIED = 'actions.copied';
	protected readonly TOOLTIP_TIME   = 5_000;

	constructor(
			protected readonly iconService: IconService,
	) {
	}

	async copy(): Promise<void> {
		let text = this.content;

		if(text instanceof Observable) {
			const asyncText = await text.pipe(first()).toPromise();
			if(typeof asyncText !== 'string')
				return;
			text = asyncText;
		}

		if(text === undefined)
			text = this.contentElement.nativeElement.innerText;

		await navigator.clipboard.writeText(text);

		this.tooltip  = this.TOOLTIP_COPIED;
		this.isCopied = true;
		this.matTooltip.toggle();

		timer(this.TOOLTIP_TIME).subscribe(() => {
			this.isCopied = false;
			this.tooltip  = this.TOOLTIP_COPY;
		});
	}

}
