import {
	Level8Error,
	PageCache,
} from '@angular-helpers/frontend-api';
import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	SecurityContext,
} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {environment} from '@app/environment';
import {IconService} from '@app/main';
import {
	NewsDidReadModel,
	NewsDidReadService,
	NewsModel,
	NewsService,
} from '@contracts/frontend-api';
import {map} from 'rxjs/operators';

@Component({
	selector:    'portal-news-show',
	templateUrl: './news-show.component.html',
	styleUrls:   ['./news-show.component.scss'],
})
export class NewsShowComponent implements OnInit {
	@Input({required: true}) news!: NewsModel;
	@Input() asReadMarkable              = false;
	@Input() markedAsRead                = false;
	@Output() readonly markedAsReadEvent = new EventEmitter<boolean>();
	@Output() readonly closed            = new EventEmitter<void>();

	readonly environment = environment;
	html?: string | null;

	protected newsDidRead?: NewsDidReadModel;
	protected isProcessing?: Promise<unknown>;

	constructor(
			private readonly sanitizer: DomSanitizer,
			protected readonly iconService: IconService,
			private readonly newsService: NewsService,
			private readonly newsDidReadService: NewsDidReadService,
	) { }

	ngOnInit(): void {
		this.markedAsReadEvent.subscribe((read) => this.markedAsRead = read);

		this.news.text.value.pipe(
			map((text) => this.sanitizer.sanitize(SecurityContext.HTML, text ?? '')),
		).subscribe((safeHtml) => {
			this.html = safeHtml;
		});
	}

	async markAsRead(): Promise<void> {
		this.markedAsReadEvent.emit(true);

		while(this.isProcessing != null)
			await this.isProcessing;

		this.isProcessing = (async () => {
			this.newsDidRead = await this.newsDidReadService.create({
				newsId: this.news.id,
			});
			PageCache.remove(this.newsDidReadService); // todo this should happen in library

			this.isProcessing = undefined;
		})();
	}

	async getNewsDidReadModel(): Promise<NewsDidReadModel> {
		if(this.newsDidRead == null) {
			const searchResult = await this.newsDidReadService.find({
				column:     'newsId',
				comparator: '=',
				value:      this.news.id,
			});
			if(searchResult.data.length !== 0)
				throw new Level8Error(`Can't find NewsDidReadModel for news#${this.news.id}`);


			this.newsDidRead = searchResult.data[0];
		}

		return this.newsDidRead;
	}

	async markAsUnread(): Promise<void> {
		this.markedAsReadEvent.emit(false);


		while(this.isProcessing != null)
			await this.isProcessing;

		this.isProcessing = (async () => {
			const newsDidRead = await this.getNewsDidReadModel();

			await this.newsDidReadService.delete(newsDidRead);
			PageCache.remove(this.newsDidReadService); // todo this should happen in library

			this.isProcessing = undefined;
		})();
	}

	hide(): void {
		this.closed.next();
	}
}
