import {
	Component,
	Input,
} from '@angular/core';
import {environment} from '@app/environment';
import {UrlType} from '@app/main';
import {MedicalStoreModel} from '@contracts/frontend-api';

@Component({
	selector:    'portal-medical-store-show',
	templateUrl: './medical-store-show.component.html',
	styleUrls:   ['./medical-store-show.component.scss'],
})
export class MedicalStoreShowComponent {
	@Input({required: true}) model: MedicalStoreModel | undefined | null;
	@Input() label       = 'model.medicalStore';
	@Input() hasLink     = true;
	readonly URL_TYPE    = UrlType;
	readonly ENVIRONMENT = environment;
}
