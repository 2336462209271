import {
	Component,
	Input,
} from '@angular/core';
import {Observable} from 'rxjs';

@Component({
	selector:    'portal-export-show-standard-delay',
	templateUrl: './export-show-standard-delay.component.html',
	styleUrls:   ['./export-show-standard-delay.component.scss'],
})
export class ExportShowStandardDelayComponent {
	@Input({required: true}) standardDelay$?: Observable<string | undefined>;
}
