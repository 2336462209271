@if (control) {
	<portal-editable [label]="label" [validator]="control">
		<mat-form-field>
			<portal-input-status-icon matSuffix [validator]="control"/>
			<mat-select [formControl]="control" [placeholder]="placeholder ?? ''" multiple>
				@if (options != null) {
					@if (isEmpty) {
						<mat-option [value]="undefined">{{ 'general.noOptions' | translate: {model: label} }}</mat-option>
					}
					@if (optionsMap;as optionsMap) {
						@for (option of optionsMap | keyvalue : sortByValue;track option) {
							@if (!isInherited(option.key)) {
								<mat-option [value]="option.key">{{ option.value }}</mat-option>
							} @else {
								<mat-option [value]="option.key" disabled class="flex">{{ option.value }}
									<span class="hint">{{ 'input.inheritedEntry' | translate }}</span>
								</mat-option>
							}
						}
					}
				} @else {
					@if (initialEntriesData;as entries) {
						@for (initialEntry of entries;track initialEntry) {
							<mat-option [value]="initialEntry.value">{{ initialEntry.label }}</mat-option>
						}
					}
					<mat-option value="loading" disabled>
						<div class="loading">
							<mat-spinner class="spinner" diameter="20"></mat-spinner>
							<span>{{ 'general.loading' | translate }}</span>
						</div>
					</mat-option>
				}
				<ng-template #loading>
					@if (initialEntriesData;as entries) {
						@for (initialEntry of entries;track initialEntry) {
							<mat-option [value]="initialEntry.value">{{ initialEntry.label }}</mat-option>
						}
					}
					<mat-option value="loading" disabled>
						<div class="loading">
							<mat-spinner class="spinner" diameter="20"></mat-spinner>
							<span>{{ 'general.loading' | translate }}</span>
						</div>
					</mat-option>
				</ng-template>
			</mat-select>
			@for (error of formHelperService.getErrors(control) | keyvalue;track error) {
				<mat-error>
					{{ 'input.error.' + error.key | translate: error.value }}
				</mat-error>
			}
		</mat-form-field>
	</portal-editable>
}
