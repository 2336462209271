<portal-model-editable-box
	[icon]="iconService.PLACEHOLDER"
	headline="general.masterData"
	[formHelper]="formHelper"
	[hasEditButton]="canUpdate | async"
>
	@if (export) {
		<ng-template #showModeContent>
			<portal-export-show-next-at [nextAt$]="export.nextAt.value"/>
			<portal-export-show-standard-delay [standardDelay$]="export.standardDelay.value"/>
			<portal-export-show-recipients [model]="export"/>
		</ng-template>
	}

	<ng-template #editModeContent let-control>
		<portal-export-edit-next-at [form]="control"/>
		<portal-export-edit-standard-delay [form]="control"/>
		<portal-export-edit-recipients [control]="control"/>
	</ng-template>

</portal-model-editable-box>
