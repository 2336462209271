import {Level8Error} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
} from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
} from '@angular/forms';
import {SelectFieldEntry} from '@app/main';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector:  'portal-news-edit-importance',
	templateUrl: './news-edit-importance.component.html',
	styleUrls: ['./news-edit-importance.component.scss'],
})
export class NewsEditImportanceComponent {
	@Input({
		required: true,
		alias:    'control',
	}) parent!: UntypedFormGroup;
	readonly options: SelectFieldEntry[] | null = [
		{
			label: this.translateService.instant('news.importanceValues.low'),
			value: 'low',
		},
		{
			label: this.translateService.instant('news.importanceValues.middle'),
			value: 'middle',
		},
		{
			label: this.translateService.instant('news.importanceValues.high'),
			value: 'high',
		},
	];


	constructor(
		private readonly translateService: TranslateService,
	) {
	}

	get control(): UntypedFormControl {
		const fieldName = 'importance';
		const control   = this.parent.get(fieldName);
		if(control instanceof UntypedFormControl)
			return control;

		throw new Level8Error(`Unexpected type for field ${fieldName} - expected '${UntypedFormControl.name}' got '${typeof control}' (${control})`);
	}
}
