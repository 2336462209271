import {Level8Error} from '@angular-helpers/frontend-api';
import {
	COMMA,
	ENTER,
} from '@angular/cdk/keycodes';
import {
	ChangeDetectorRef,
	Component,
	DestroyRef,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {IconService} from '@app/main';
import {
	AnwendungsortModel,
	HmvModel,
	HmvService,
	ProduktartModel,
	ProduktgruppeModel,
	ProduktModel,
	PseudoVersorgungsbereichModel,
	PseudoVersorgungsbereichService,
	UntergruppeModel,
	VersorgungsbereichModel,
} from '@contracts/frontend-api';
import {
	EMPTY,
	from,
	Observable,
	of,
	timer,
} from 'rxjs';
import {
	debounce,
	map,
	mergeMap,
	tap,
} from 'rxjs/operators';

interface VbNameGroups {
	vbName: string;
	version?: string;
}

interface HmvSelectInterface {
	hmvModel?: HmvModel;
	message: string;
}

@Component({
	selector:    'portal-pseudo-versorgungsbereich-edit',
	templateUrl: './pseudo-versorgungsbereich-edit.component.html',
	styleUrls:   ['./pseudo-versorgungsbereich-edit.component.scss'],
})
export class PseudoVersorgungsbereichEditComponent implements OnInit {
	static readonly vbDecompositionRegex = /^(?<group>\d{2}[a-zA-Z])(?<version>\d{0,2})(\/(?<pseuname>[a-zA-Z0-9]*))?/; // regex for vb / version / psVbName
	readonly HMV_ENTRY_REGEX             = /^[0-9]{2}(\.[0-9]{2}(\.[0-9]{2}(\.[0-9]([0-9]{3})?)?)?)?$/;
	@Input({required: true}) fieldControl?: UntypedFormControl;
	@Output() psVbCreated                = new EventEmitter<PseudoVersorgungsbereichModel>();

	mainPsVb?: VbNameGroups;
	selectedList: HmvModel[]                      = [];
	isSaving                                      = false;
	canCreate                                     = false;
	hmvAutoCheckList: HmvSelectInterface[] | null = [];
	@ViewChild('psChipInput') readonly psChipInput!: ElementRef;

	readonly psChipListInputControl       = new UntypedFormControl();
	readonly separatorKeysCodes: number[] = [
		ENTER,
		COMMA,
	];
	readonly newPsVbForm                  = new UntypedFormGroup({
		psVbName:        new UntypedFormControl(
			null,
			[
				Validators.required,
				Validators.min(1),
			],
		),
		psVbDescription: new UntypedFormControl(null, Validators.required),
		psChipList:      this.psChipListInputControl,
	});

	constructor(
			private readonly psVbService: PseudoVersorgungsbereichService,
			private readonly hmvService: HmvService,
			protected readonly iconService: IconService,
			private readonly changeDetection: ChangeDetectorRef,
			protected readonly destroyRef: DestroyRef,
	) {
	}

	ngOnInit(): void {
		PseudoVersorgungsbereichModel.permissionsClass.canCreate({})
									 .then(canCreate => this.canCreate = canCreate);

		if(this.fieldControl != null)
			this.initVbControl(this.fieldControl);
	}

	initVbControl(vbControl: UntypedFormControl): void {
		this.newPsVbForm.disable();

		vbControl.valueChanges.pipe(
			takeUntilDestroyed(this.destroyRef),
			mergeMap(vbModel => {
				if(!(vbModel instanceof VersorgungsbereichModel)) {
					this.mainPsVb = undefined;
					return EMPTY;
				}

				return of(vbModel);
			}),
			tap(vbModel => {
				this.selectedList = [];

				if(!(vbModel instanceof VersorgungsbereichModel)) {
					this.mainPsVb = undefined;
					return;
				}

				return vbModel.name.firstValue.then((name) => {
					if(name == null || this.isValidPsVbName(name)) {
						this.mainPsVb = undefined;
						this.newPsVbForm.disable();
					}

					this.hmvAutoCheckList = [];
					this.newPsVbForm.enable();
					return this.setMainVbPrefix(vbModel);
				});
			}),
		).subscribe(vb => this.filterHmvByVbModel(vb));

		this.psChipListInputControl.valueChanges.pipe(
				takeUntilDestroyed(this.destroyRef),
				tap(() => this.hmvAutoCheckList = null),
				map(search => {
					if(this.fieldControl == null || this.fieldControl.value == null || search == null || typeof search !== 'string' || search.trim() === '')
						return null;

					return from(this.getHmvNumberErrors(this.fieldControl.value, search)
									.then(error => [
										error,
										search,
									]));
			}),
			mergeMap(p => p ?? of(p)),
			map(value => {
				if(value == null)
					return value;

				if(value[0] === null)
					return from(this.hmvService.find(value[1] ?? '').then(result => result ?? 'unknownHmvNumber'));

				return value[0] as string;
			}),
			debounce(value => {
				if(value == null || typeof value === 'string')
					return of();
				return timer(400);
			}),
			mergeMap(p => {
				if(p instanceof Observable)
					return p;
				return of(p);
			}),
			map((solution) => {
				if(solution == null)
					return null;

				if(typeof solution === 'string') {
					return {
						hmvModel: undefined,
						message:  `hmv.errors.${solution}`,
					};
				}

				return {
					hmvModel: solution,
					message:  '',
				};
			}),
		).subscribe(entry => this.hmvAutoCheckList = entry ? [entry] : []);
	}


	setMainVbPrefix(vb: VersorgungsbereichModel): Promise<void> {
		return vb.name.firstValue.then(name => {
			if(name == null || this.fieldControl == null || this.fieldControl.value == null)
				return;

			const matches = name.match(PseudoVersorgungsbereichEditComponent.vbDecompositionRegex);
			if(matches == null || matches.groups == null)
				return;


			this.mainPsVb = {
				vbName:  matches.groups.group,
				version: matches.groups.version,
			};
		});
	}

	filterHmvByVbModel(vb: (PseudoVersorgungsbereichModel | VersorgungsbereichModel)): Promise<void | HmvModel[]> {
		return vb.entries.firstValue
				 .then(async (entries) => {
					 const entriesList: HmvModel[] = [];
					 if(entries == null)
						 return;

					 if(entries.produktarts != null)
						 entriesList.push(...(await this.getHmvByProduktartModelEntries(entries.produktarts)));

					 if(entries.untergruppes != null)
						 entriesList.push(...(await this.getHmvByUntergruppeModelEntries(entries.untergruppes)));

					 if(entries.produktgruppes != null)
						 entriesList.push(...(await this.getHmvByProduktgruppeModelEntries(entries.produktgruppes)));

					 if(entries.produkts != null)
						 entriesList.push(...(await this.getHmvByProduktModelEntries(entries.produkts)));

					 if(entries.anwendungsorts != null)
						 entriesList.push(...(await this.getHmvByAnwendungsortModelEntries(entries.anwendungsorts)));

					 return entriesList;
				 })
				 .then((entries) => {
					 if(entries == null)
						 this.selectedList = [];

					 else {
						 const sortMap = new Map<string, HmvModel>();

						 for(const elem of this.selectedList)
							 sortMap.set(elem.id, elem);


						 for(const elem of entries)
							 sortMap.set(elem.id, elem);


						 this.selectedList = [...sortMap.values()];
					 }
				 });
	}

	getHmvByProduktModelEntries(entries: string[]): ProduktModel[] {
		return entries.map(id => this.hmvService.produktService.getById(id));
	}

	getHmvByProduktartModelEntries(entries: string[]): ProduktartModel[] {
		return entries.map(id => this.hmvService.produktartService.getById(id));
	}

	getHmvByProduktgruppeModelEntries(entries: string[]): ProduktgruppeModel[] {
		return entries.map(id => this.hmvService.produktgruppeService.getById(id));
	}

	getHmvByUntergruppeModelEntries(entries: string[]): UntergruppeModel[] {
		return entries.map(id => this.hmvService.untergruppeService.getById(id));
	}

	getHmvByAnwendungsortModelEntries(entries: string[]): AnwendungsortModel[] {
		return entries.map(id => this.hmvService.anwendungsortService.getById(id));
	}

	isValidPsVbName(psVbName: string): boolean {
		const matches = psVbName.match(PseudoVersorgungsbereichEditComponent.vbDecompositionRegex);

		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		if(matches == null || (matches.groups != null && (matches.groups.group == null || matches.groups.version == null || matches.groups.pseuname == null)))
			return false;

		return true;
	}

	async getHmvNumberErrors(psVbBase: VersorgungsbereichModel, hmvNumber: string): Promise<string | null> {
		for(const hmv of this.selectedList) {
			if(hmvNumber.startsWith(await hmv.hmvNumber.firstValue ?? ''))
				return 'alreadyAddedHmvNumber';
		}

		if(this.HMV_ENTRY_REGEX.test(hmvNumber) === false)
			return 'invalidHmvNumber';

		const list = await psVbBase.entries.firstValue;
		if(list == null)
			throw new Level8Error('missing ');

		const completeList: string[] = [];

		if(list.anwendungsorts != null) {

			for(const hmv of list.anwendungsorts) {
				const hmvModel       = this.hmvService.anwendungsortService.getById(hmv);
				const hmvNumberLocal = await hmvModel.hmvNumber.firstValue;

				if(hmvNumberLocal != null)
					completeList.push(hmvNumberLocal);
			}
		}

		if(list.produktarts != null) {

			for(const hmv of list.produktarts) {
				const hmvModel       = this.hmvService.produktartService.getById(hmv);
				const hmvNumberLocal = await hmvModel.hmvNumber.firstValue;

				if(hmvNumberLocal != null)
					completeList.push(hmvNumberLocal);

			}
		}

		if(list.produktgruppes != null) {

			for(const hmv of list.produktgruppes) {
				const hmvModel       = this.hmvService.produktgruppeService.getById(hmv);
				const hmvNumberLocal = await hmvModel.hmvNumber.firstValue;

				if(hmvNumberLocal != null)
					completeList.push(hmvNumberLocal);
			}
		}

		if(list.untergruppes != null) {

			for(const hmv of list.untergruppes) {
				const hmvModel       = this.hmvService.untergruppeService.getById(hmv);
				const hmvNumberLocal = await hmvModel.hmvNumber.firstValue;

				if(hmvNumberLocal != null)
					completeList.push(hmvNumberLocal);

			}
		}

		if(list.produkts != null) {

			for(const hmv of list.produkts) {
				const hmvModel       = this.hmvService.produktService.getById(hmv);
				const hmvNumberLocal = await hmvModel.hmvNumber.firstValue;

				if(hmvNumberLocal != null)
					completeList.push(hmvNumberLocal);

			}
		}

		for(const entry of completeList) {
			if(hmvNumber.startsWith(entry))
				return null;
		}

		return 'notAllowedHmvNumber';
	}

	async createNewPseudoVb(): Promise<PseudoVersorgungsbereichModel> {
		this.newPsVbForm.markAllAsTouched();
		this.changeDetection.detectChanges();
		if(!this.newPsVbForm.valid)
			throw new Error('Data not Valid');

		const newPsVbData = this.newPsVbForm.value;

		const prefix  = this.getMainVbPrefix();
		const name    = `${prefix}/${newPsVbData.psVbName}`;
		const isValid = this.isValidPsVbName(name);

		if(!isValid)
			throw new Error('psVbName not Valid');

		try {
			this.isSaving = true;

			const newFormData = {
				name,
				description: newPsVbData.psVbDescription,
			};
			const savedPsVb   = await this.psVbService.create(newFormData);

			this.newPsVbForm.reset();

			const hmvAdds = this.selectedList.map(hmvEntry => this.psVbService.addHmv(savedPsVb, hmvEntry));
			await Promise.all(hmvAdds);

			this.psChipListInputControl.setValue(null);
			return savedPsVb;
		} finally {
			this.isSaving = false;
		}
	}

	async onPsVbAdd($event: MouseEvent | Event): Promise<void> {
		$event.preventDefault();
		$event.stopPropagation();

		const psVb = await this.createNewPseudoVb();
		this.psVbCreated.emit(psVb);
	}

	getMainVbPrefix(): string {
		if(this.mainPsVb == null)
			return '';

		return this.mainPsVb.vbName + this.mainPsVb.version;
	}

	removeChip(selectedChip: HmvModel): void {
		this.selectedList = this.selectedList.filter((chip) => !(chip.id === selectedChip.id));
	}

	selectedHmvNumber($event: MatAutocompleteSelectedEvent): void {
		this.psChipInput.nativeElement.value     = '';
		const model: HmvModel | null | undefined = $event.option.value?.hmvModel;
		if(model == null)
			return;


		const elemList = this.selectedList.filter((hmv) => hmv.id !== model.id);
		elemList.push(model);
		this.selectedList = elemList;
	}
}
