<portal-model-editable-box
	[icon]="iconService.CONTACT_ACCESSION_TIMINGS"
	headline="contractAccession.timings"
	[formHelper]="formHelper"
	[hasEditButton]="canUpdate | async"
>
	@if (contractAccession) {
		<ng-template #showModeContent>
			<portal-contract-accession-show-accession-start-at [model]="contractAccession"/>
			<portal-contract-accession-show-accession-end-at [model]="contractAccession"/>
			<portal-contract-accession-joining-delay [model]="contractAccession"/>
			<portal-contract-accession-date-billing-delay [model]="contractAccession"/>
		</ng-template>
	}

	<ng-template #editModeContent let-control>
		<portal-contract-accession-edit-accession-start-at [control]="control"/>
	</ng-template>

</portal-model-editable-box>
