import {
	Component,
	Input,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import {
	ContractModel,
	ContractSectionModel,
	MasterContractModel,
} from '@contracts/frontend-api';
import moment from 'moment';
import {
	combineLatest,
	Observable,
} from 'rxjs';
import {map} from 'rxjs/operators';

export enum ContractState {
	active       = 'baseContract.statusValues.activeSince',
	inactive     = 'baseContract.statusValues.inactiveSince',
	terminated   = 'baseContract.statusValues.terminatedAt',
	valid        = 'baseContract.statusValues.validUntil',
	notYetActive = 'baseContract.statusValues.notYetActive',
}

interface StateData {
	description: string;
	date: Date;
}

@Component({
	selector:    'portal-base-contract-status',
	templateUrl: './base-contract-status.component.html',
	styleUrls:   ['./base-contract-status.component.scss'],
})
export class BaseContractStatusComponent implements OnChanges {
	@Input({required: true}) model!: MasterContractModel | ContractModel | ContractSectionModel;
	status$?: Observable<StateData | null>;
	description: ContractState | undefined = undefined;

	ngOnChanges(changes: SimpleChanges): void {
		let combinedValues = [
			this.model.validityStartAt.value,
			this.model.terminatedAt.value,
			this.model.periodOfValidityAt.value,
		];

		if(this.model instanceof ContractModel || this.model instanceof ContractSectionModel) {
			combinedValues = [
				this.model.validityStartAt.withParent.value,
				this.model.terminatedAt.withParent.value,
				this.model.periodOfValidityAt.withParent.value,
			];
		}

		this.status$ = combineLatest(
			combinedValues,
		).pipe(
			map(([validityStartedAt, terminatedAt, periodOfValidityAt]) => {
				const now = moment();
				let statusData: StateData | null;

				if(validityStartedAt != null && moment(now).isBefore(validityStartedAt)) {
					statusData = {
						description: ContractState.notYetActive,
						date:        moment(validityStartedAt).toDate(),
					};

					return statusData;
				}

				if(periodOfValidityAt != null && moment(now).isAfter(periodOfValidityAt)) {
					statusData = {
						description: ContractState.inactive,
						date:        moment(periodOfValidityAt).toDate(),
					};

					return statusData;
				}

				statusData = null;

				if(validityStartedAt != null) {
					statusData = {
						description: ContractState.active,
						date:        moment(validityStartedAt).toDate(),
					};
				}

				if(terminatedAt != null) {
					statusData = {
						description: ContractState.terminated,
						date:        moment(terminatedAt).toDate(),
					};
				}

				if(periodOfValidityAt != null) {
					statusData = {
						description: ContractState.valid,
						date:        moment(periodOfValidityAt).toDate(),
					};
				}

				return statusData;
			}),
		);
	}
}
