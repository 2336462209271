<portal-model-editable-box
	[icon]="iconService.EMPLOYEE"
	[headline]="label"
	[formHelper]="null"
	[hasEditButton]="false"
>
	@if (employees && employees.length > 0) {
		<ng-template #showModeContent>
			@for (employee of employees;track employee) {
				<a [routerLink]="[environment.employeeFullUrl, employee.id]" class="invisible-link">{{ employee.name.value | async }}</a>
				<mat-chip-listbox>
					@for (role of employee.roles.value | async;track role) {
						<mat-chip-option>{{ role.name.value | async }}</mat-chip-option>
					}
				</mat-chip-listbox>
			}
		</ng-template>
	}
</portal-model-editable-box>
