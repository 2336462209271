<mat-card>
	<mat-card-header class="logo">
		<fa-icon [icon]="iconService.CONTRACT" mat-card-avatar></fa-icon>
		<mat-card-title>{{ label | translate }}</mat-card-title>
	</mat-card-header>
	
	<mat-card-content>
		<portal-base-contract-list [data]="contracts"/>
	</mat-card-content>
</mat-card>
