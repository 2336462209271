import {ResultPageModel} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
} from '@angular/core';
import {IconService} from '@app/main';
import {MedicalStoreModel} from '@contracts/frontend-api';

@Component({
	selector:    'portal-medical-store-card-list',
	templateUrl: './medical-store-card-list.component.html',
	styleUrls:   ['./medical-store-card-list.component.scss'],
})
export class MedicalStoreCardListComponent {
	@Input({required: true}) medicalStores?: MedicalStoreModel[] | ResultPageModel<MedicalStoreModel> | null;
	@Input() label = 'model.medicalStores';

	constructor(
		protected readonly iconService: IconService,
	) {
	}
}
