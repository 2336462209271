import {
	DtoCreationFormHelper,
	Level8Error,
	ModelNameHelper,
} from '@angular-helpers/frontend-api';
import {
	Component,
	DestroyRef,
	inject,
	Input,
	OnInit,
} from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import {Router} from '@angular/router';
import {RequirementEntry} from '@app/contracts';
import {environment} from '@app/environment';
import {combineLatestSafe} from '@app/main';
import {
	AnyBaseContractApiService,
	AnyBaseContractModel,
	BaseContractService,
	ContractingPartyModel,
	ContractModel,
	ContractRequirementService,
	ContractSectionModel,
	ExternalContractNumberType,
	MasterContractModel,
	PseudoVersorgungsbereichModel,
	VersorgungsbereichModel,
} from '@contracts/frontend-api';
import {
	combineLatest,
	of,
	Subject,
	throwError,
} from 'rxjs';
import {
	catchError,
	filter,
	finalize,
	first,
	map,
	mergeMap,
	shareReplay,
	switchMap,
	tap,
} from 'rxjs/operators';

type ParentContractModel<T> = T extends MasterContractModel ? undefined :
                              T extends ContractModel ? MasterContractModel :
                              T extends ContractSectionModel ? ContractModel :
                              never;

@Component({
	selector:    'portal-base-contracts-create',
	templateUrl: './base-contracts-create.component.html',
	styleUrl:    './base-contracts-create.component.scss',
})
export class BaseContractsCreateComponent<Model extends AnyBaseContractModel & (MasterContractModel | ContractModel | ContractSectionModel), Service extends BaseContractService<AnyBaseContractApiService, Model>/*, Service extends MatchingService<Model>, Parent extends ParentContractModel<Model>*/> implements OnInit {
	@Input({required: true}) label!: string;
	@Input({required: true}) parent?: ParentContractModel<Model>;
	protected control?: UntypedFormGroup;
	protected requirements: readonly RequirementEntry<object>[] = [];
	protected masterDataControl?: UntypedFormGroup;
	protected contractingControl?: UntypedFormGroup;
	protected joinedPartiesControl?: UntypedFormGroup;
	protected isSaving                                          = false;
	protected onSave$: Subject<void>                            = new Subject();
	protected errorHasOccurred?: Error;
	protected readonly destroyRef                               = inject(DestroyRef);
	protected readonly externalContractNumberType               = ExternalContractNumberType;
	protected readonly vbSelected                               = new Subject<VersorgungsbereichModel | PseudoVersorgungsbereichModel | null | undefined>();
	private readonly router                                     = inject(Router);
	private readonly contractRequirementService                 = inject(ContractRequirementService);

	private _formHelper?: DtoCreationFormHelper<Model, Service>;

	get formHelper(): DtoCreationFormHelper<Model, Service> | undefined {
		return this._formHelper;
	}

	@Input({required: true})
	set formHelper(value: DtoCreationFormHelper<Model, Service> | undefined) {
		this._formHelper = value;
		value?.control.then(control => this.initForms(control));
	}

	ngOnInit(): void {
		const savedForm$ = of({})
			.pipe(
				mergeMap(() => {
					if(this.formHelper == null)
						throw new Level8Error('Missing form helper');

					return this.formHelper.save();
				}),
				map((savedForm) => {
					if(savedForm === null)
						throw new Error('error while saving');

					return savedForm;
				}),
				shareReplay(),
			);

		const joinedParties = (): ContractingPartyModel[] => {
			if(this.joinedPartiesControl?.valid !== true)
				throw new Level8Error(`Trying to save with invalid joinedPartiesControl`);

			const list = this.joinedPartiesControl.controls.joinedParties.value;

			if(list == null)
				return [];

			if(!Array.isArray(list))
				throw new Level8Error(`Unexpected content in form. Expected Array, got '${typeof list}' (${JSON.stringify(list)})`);

			if(list.length > 0 && !(list[0] instanceof ContractingPartyModel))
				throw new Level8Error(`Unexpected content in form. Expected ContractingPartyModel[] got '${typeof ContractingPartyModel}[]' (${JSON.stringify(list)})`);

			return list;
		};

		const saveContract$ = of({}).pipe(
			mergeMap(() => savedForm$),
			switchMap((contractModel: Model) => {
				let validityStartAt$;
				if(contractModel instanceof MasterContractModel)
					validityStartAt$ = contractModel.validityStartAt.value;
				else
					validityStartAt$ = contractModel.validityStartAt.withParent.value;


				const saveContractingParties$ = validityStartAt$.pipe(
					first(),
					map(validityStartAt => {
						if(validityStartAt == null)
							throw new Level8Error('Missing start date');

						return joinedParties().map(joinedParty => {
							if(this.formHelper == null)
								throw new Level8Error('Missing form helper');

							return this.formHelper.service.addContractingParty(
								contractModel,
								joinedParty,
								validityStartAt,
							);
						});
					}),
					mergeMap(x => combineLatestSafe(x)),
				);

				const saveRequirements$ = combineLatestSafe(
					this.requirements.map(requirement =>
						this.contractRequirementService.create({
							contractId:            contractModel.id,
							contractType:          ModelNameHelper.fromObject(contractModel).asBaseName(),
							requirementFunctionId: requirement.requirementFunction.id,
							parameters:            requirement.parameters,
						}),
					),
				);

				return combineLatest([saveContractingParties$, saveRequirements$]).pipe(
					map(() => contractModel),
				);
			}),
			mergeMap(contractModel =>
				this.router.navigate([
					this.getRedirectUrl(),
					contractModel.id,
				])),
			catchError((error) => {
				this.errorHasOccurred = error;
				return throwError(error);
			}),
		);


		this.onSave$.pipe(
			filter(() => !this.isSaving),
			tap(() => {
				this.errorHasOccurred = undefined;
				this.isSaving         = true;
			}),
			switchMap(() => saveContract$),
			finalize(() => this.isSaving = false),
		).subscribe();
	}

	saveForm(): void {
		this.onSave$.next();
	}

	onVbChange($event: VersorgungsbereichModel | PseudoVersorgungsbereichModel | null | undefined): void {
		this.vbSelected.next($event);
	}

	protected getRedirectUrl(): string {
		if(this.isEditingMasterContract())
			return environment.masterContractsFullUrl;

		if(this.isEditingContract())
			return environment.contractsFullUrl;

		return environment.contractSectionsFullUrl;
	}

	protected isEditingMasterContract(): boolean {
		return this.parent === undefined;
	}

	protected isEditingContract(): boolean {
		return this.parent instanceof MasterContractModel;
	}

	protected isEditingContractSection(): boolean {
		return this.parent instanceof ContractModel;
	}

	protected isMasterContract(model: unknown = this.parent): model is MasterContractModel {
		return model instanceof MasterContractModel;
	}

	protected isContract(model: unknown = this.parent): model is ContractModel {
		return model instanceof ContractModel;
	}

	protected isContractSection(model: unknown = this.parent): model is ContractSectionModel {
		return model instanceof ContractSectionModel;
	}

	private initForms(control: UntypedFormGroup): void {
		this.control               = control;
		const masterDataControl    = new UntypedFormGroup({});
		const contractingControl   = new UntypedFormGroup({});
		const joinedPartiesControl = new UntypedFormGroup({
			joinedParties: new UntypedFormControl(
				null,
				this.isEditingMasterContract() ? [Validators.required] : [],
			),
		});

		Object.keys(control.controls).forEach(ctrlName => {
			switch(ctrlName) {
				case 'name':
				case 'validityStartAt':  				// Gültig ab
				case 'minimumTermAt': 					// Mindestlaufzeit
				case 'noticePeriodNegotiationDelay': 	// Kündigungsfrist
				case 'negotiators':
				case 'mandateGrantor':
				case 'initialSigner':
				case 'masterContract':
				case 'contract':
				case 'versorgungsbereich':
				case 'pseudoVersorgungsbereich':
					masterDataControl.addControl(ctrlName, control.controls[ctrlName]);
					break;

				case 'noticePeriodDelay':				// Kündigungsfrist (SH)
				case 'billingDelay':					// Abrechnungsvorlaufzeit
				case 'joiningDelay':					// Beitrittsvorlaufzeit
				case 'maximumBackDatingDelay':			// Maximale Beitrittsrückdatierung
					contractingControl.addControl(ctrlName, control.controls[ctrlName]);
					break;

				case 'version':
				case 'periodOfValidityAt':				// Weitergeltungsfrist
				case 'terminatedAt':  					// Gekündigt zum
				case 'export':							// todo https://git.biv.to/contracts/api/-/issues/166
					break;

				default:
					throw new Level8Error(`Unmapped input: ${ctrlName}`);
			}
		});

		this.masterDataControl    = masterDataControl;
		this.contractingControl   = contractingControl;
		this.joinedPartiesControl = joinedPartiesControl;
	}
}
