@if (permissions.canCreate) {
	<div [class.isDragging]="isDragging" [class.dropError]="dropError" [class.uploading]="uploadingFiles.length > 0" id="dropzone">
		<input type="file" [accept]="acceptedFileTypes" #fileDropRef id="fileDropRef" multiple (change)="addFiles(fileDropRef.files)" (click)="fileDropRef.value = ''"/>
		<label class="hint dropNow" for="fileDropRef">{{ 'general.dropzone.dropNow' | translate }}</label>
		<label class="hint dropHere" for="fileDropRef">{{ 'general.dropzone.dropHere' | translate }}</label>
		@for (error of (errorToMap(dropError) | keyvalue);track error) {
			<label class="hint dropError" for="fileDropRef">{{ 'general.dropzone.error.' + error.key | translate: isObject(error.value) ? error.value : undefined }}</label>
		}
		<fa-icon class="background" [icon]="iconService.UPLOAD" size="2x"></fa-icon>
		@for (file of uploadingFiles;track file) {
			<div class="upload">
				<fa-icon [icon]="iconService.LOADING_SPINNER" [spin]="true"></fa-icon>
				{{ file.name }}
			</div>
		}
	</div>
}

<ng-template #editModeTemplateFiles let-control>
	<portal-file-edit-is-publishable [control]="control"/>
	<portal-file-edit-name [control]="control"/>
	<portal-file-edit-description [control]="control"/>
	<portal-file-edit-validity-start-at [control]="control"/>
</ng-template>
