import {
	Component,
	Input,
	OnInit,
} from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
} from '@angular/forms';

@Component({
	selector:  'portal-news-edit-display-start-at',
	templateUrl: './news-edit-display-start-at.component.html',
	styleUrls: ['./news-edit-display-start-at.component.scss'],
})
export class NewsEditDisplayStartAtComponent implements OnInit {
	@Input({
		required: true,
		alias:    'control',
	}) parent!: UntypedFormGroup;
	control?: UntypedFormControl;

    ngOnInit(): void {
        const field = this.parent.get('displayStartAt');
        if(field instanceof UntypedFormControl)
            this.control = field;
    }
}
