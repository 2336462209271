<portal-model-editable-box
	[icon]="iconService.CONTRACT"
	headline="medicalStore.contractInformation"
	[formHelper]="formHelper"
	[hasEditButton]="canUpdate | async"
>

	<ng-template #editModeContent let-control>
		<portal-medical-store-edit-mip [control]="control"/>
		<portal-medical-store-edit-psa [control]="control"/>
		<portal-medical-store-edit-proprioceptive-deposits [control]="control"/>
		<portal-medical-store-edit-iso9001 [control]="control"/>
		<portal-medical-store-edit-iso13485 [control]="control"/>
		<portal-medical-store-edit-supply-area [control]="control"/>
		<portal-medical-store-edit-hmm-participation [control]="control"/>
	</ng-template>

	@if (medicalStore) {
		<ng-template #showModeContent>
			<portal-medical-store-show-mip [model]="medicalStore"/>
			<portal-medical-store-show-psa [model]="medicalStore"/>
			<portal-medical-store-show-proprioceptive-deposits [model]="medicalStore"/>
			<portal-medical-store-show-iso9001 [model]="medicalStore"/>
			<portal-medical-store-show-iso13485 [model]="medicalStore"/>
			<portal-medical-store-show-supply-area [model]="medicalStore"/>
			<portal-medical-store-show-hmm-participation [model]="medicalStore"/>
		</ng-template>
	}
</portal-model-editable-box>
