import {
	Component,
	Input,
} from '@angular/core';
import {MedicalStoreModel} from '@contracts/frontend-api';

@Component({
	selector:    'portal-medical-store-show-biv-number',
	templateUrl: './medical-store-show-biv-number.component.html',
	styleUrls:   ['./medical-store-show-biv-number.component.scss'],
})
export class MedicalStoreShowBivNumberComponent {
	@Input({required: true}) model!: MedicalStoreModel;
}
