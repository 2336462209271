<portal-table-client-side-searchable
		[headers]="tableHeaders"
		[searchFilter]="searchFilter"
		[columnLink]="environment.preQualificationCertificateFullUrl"
		[data]="data"
>
	<ng-template column="employee" let-model="model" [data]="data">
		<portal-skeleton-field [content]="$any((model.employee.value | async))?.name?.value"/>
	</ng-template>
	<ng-template column="institutionskennzeichen" let-model="model" [data]="data">
		<portal-skeleton-field [content]="$any((model.institutionskennzeichen.value | async))?.number?.value | formatInstitutionskennzeichen"/>
	</ng-template>
</portal-table-client-side-searchable>
