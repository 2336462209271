<ng-container *ngLet="(nextAt$ | async) as entries">
	<portal-showable
		[label]="'export.nextExportAt'"
		[value]="entries && entries.length > 0"
	>
		<div class="wrapper">
			@for (entry of entries;track entry) {
				<a class="invisible-link" [routerLink]="[environment.exportFullUrl, entry.model.id]">
					{{ entry.nextAt  | date }}
				</a>
			}
		</div>
	</portal-showable>
</ng-container>
