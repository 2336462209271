@if (legalFormsFiltered) {
	<portal-editable label="medicalStore.legalForm" [validator]="control">
		<mat-form-field>
			<portal-input-status-icon matSuffix [validator]="control"/>
			<input matInput [formControl]="control" [matAutocomplete]="legalFormAutocomplete"/>
			@for (error of formHelperService.getErrors(control) | keyvalue;track error) {
				<mat-error>
					{{ 'input.error.' + error.key | translate: error.value }}
				</mat-error>
			}
			<mat-autocomplete #legalFormAutocomplete="matAutocomplete">
				@for (option of legalFormsFiltered;track option) {
					<mat-option [value]="option">
						{{ option }}
					</mat-option>
				}
			</mat-autocomplete>
		</mat-form-field>
	</portal-editable>
}
