<portal-model-editable-box
	[icon]="iconService.PRE_QUALIFICATION_CERTIFICATE"
	headline="preQualificationCertificate.owner"
	[formHelper]="null"
	[hasEditButton]="false"
>
	@if (preQualificationCertificate) {
		<ng-template #showModeContent>
			<portal-employee-show label="preQualificationCertificate.employee" [employee]="preQualificationCertificate.employee.value | async"/>
			<portal-institutionskennzeichen-show [model]="preQualificationCertificate.institutionskennzeichen.value | async"/>
		</ng-template>
	}
</portal-model-editable-box>
