import {
	Component,
	Input,
} from '@angular/core';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {IconService} from '../../services/icon.service';

@Component({
	selector:    'portal-showable',
	templateUrl: './showable.component.html',
	styleUrls:   ['./showable.component.scss'],
})
export class ShowableComponent {
	@Input({required: true}) label!: string | null;
	/**
	 * @todo: remove field
	 * @deprecated use `hidden` instead
	 */
	@Input() value?: unknown;
	@Input() hidden          = false;
	@Input() icon?: IconDefinition;
	@Input() showValueAlways = false;
	@Input() description?: string;

	constructor(
		public iconService: IconService,
	) {}

	isVisible(): boolean {
		if(this.showValueAlways)
			return true;

		if(this.value !== undefined)
			return Boolean(this.value).valueOf();

		return !this.hidden;
	}
}
