import {ResultPageModel} from '@angular-helpers/frontend-api';
import {
	Component,
	OnInit,
} from '@angular/core';
import {environment} from '@app/environment';
import {IconService} from '@app/main';
import {
	MedicalStoreModel,
	MedicalStoreService,
} from '@contracts/frontend-api';


@Component({
	templateUrl: './medical-store-list-page.component.html',
	styleUrls:   ['./medical-store-list-page.component.scss'],
})
export class MedicalStoreListPageComponent implements OnInit {
	medicalStores?: Promise<ResultPageModel<MedicalStoreModel>>;
	iconService          = IconService;
	canCreate            = false;
	readonly environment = environment;

	constructor(
		protected medicalStoreService: MedicalStoreService,
	) {
	}

	ngOnInit(): void {
		this.medicalStores = this.medicalStoreService.getPage(0, {pageSize: 15});
		this.medicalStores.then(page => page.loadNextPage());
		MedicalStoreModel.permissionsClass.canCreate({})
						 .then(allowed => this.canCreate = allowed);
	}
}
