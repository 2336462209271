import {Level8Error} from '@angular-helpers/frontend-api';
import {
	Component,
	ContentChildren,
	ElementRef,
	inject,
	Input,
	OnInit,
	QueryList,
	TemplateRef,
	ViewChildren,
} from '@angular/core';
import {
	ActivatedRoute,
	Router,
} from '@angular/router';
import {
	Column,
	SearchOptions,
	StringHelper,
	TableColumnData,
	TableColumnTemplateDirective,
	TableHeaderData,
	TableHeaderTemplateDirective,
	TableSearchHeaderData,
	TableSearchHeaderTemplateDirective,
} from '@app/main';

export interface MinimalColumn<DataType> extends Omit<Partial<Column<DataType>>, 'onSearch' | 'filter'> {
	label: string;
	prepareSearch?: ((value: unknown, searchOptions: SearchOptions) => unknown | Promise<unknown>);
	isSearchable?: boolean;
}

export type MinimalColumns<DataType> = Record<string, MinimalColumn<DataType> | undefined>;

@Component({
	template: '',
})
export abstract class AbstractTableComponent<TableEntry> implements OnInit {
	protected static readonly DEFAULT_PAGE_SIZE: number                    = 5;
	@Input() storeSearch = true;
	@ContentChildren(TableColumnTemplateDirective) columnContentContentTemplates?: QueryList<TableColumnTemplateDirective<TableEntry>>;
	@ViewChildren(TableColumnTemplateDirective) columnContentViewTemplates?: QueryList<TableColumnTemplateDirective<TableEntry>>;
	@ContentChildren(TableHeaderTemplateDirective) columnHeaderContentTemplates?: QueryList<TableHeaderTemplateDirective<TableEntry>>;
	@ViewChildren(TableHeaderTemplateDirective) columnHeaderViewTemplates?: QueryList<TableHeaderTemplateDirective<TableEntry>>;
	@ContentChildren(TableSearchHeaderTemplateDirective) columnSearchHeaderContentTemplates?: QueryList<TableSearchHeaderTemplateDirective<TableEntry, unknown>>;
	@ViewChildren(TableSearchHeaderTemplateDirective) columnSearchHeaderViewTemplates?: QueryList<TableSearchHeaderTemplateDirective<TableEntry, unknown>>;
	protected readonly router                                              = inject(Router);
	protected readonly activatedRoute                                      = inject(ActivatedRoute);
	protected tableHeaders: Record<string, Column<TableEntry> | undefined> = {};
	protected searchingData: Record<string, unknown>                       = {};
	protected elementRef = inject(ElementRef);
	private _tableId?: string;
	protected static readonly PAGE_PARAMETER = '_page';

	protected _displayPage = 0;

	protected get displayPage(): number {
		return this._displayPage;
	}

	protected set displayPage(value: number) {
		if(this._displayPage === value)
			return;

		this._displayPage = value;
		this.search((value > 0) ? value : undefined, {id: AbstractTableComponent.PAGE_PARAMETER});
	}

	ngOnInit(): void {
		if(this.storeSearch) {
			this.activatedRoute.queryParamMap.subscribe(queryMap => {
				let changed = false;
				for(const [key, column] of Object.entries(this.tableHeaders)) {
					if(column == null)
						continue;

					const id = `${this.tableId}.${key}`;

					let value = queryMap.get(id);
					if(value == null) {
						if(column.filter == null || column.filter === '')
							continue;
						else
							value ??= '';
					}

					const realValue = (column.deserialize != null) ? column.deserialize(value) : value;

					// eslint-disable-next-line eqeqeq
					if(column.filter != realValue) {
						this.search(realValue, {id: key});
						changed = true;
					}
				}

				const pageKey = `${this.tableId}.${AbstractTableComponent.PAGE_PARAMETER}`;
				const page = Number.parseInt(queryMap.get(pageKey) ?? '0');
				if(page !== 0)
					this.goToPage(page);

				if(changed) {
					//trigger instand search
					this.executeSearch(undefined, {
						searchNow: true,
						id:        'id',
						page,
					});
				}
			});
		}
	}

	protected get tableId(): string {
		if(this._tableId == null)
			this._tableId = this.getTableId();

		return this._tableId;
	}

	async search(value: unknown, options: SearchOptions): Promise<void> {
		const column = this.tableHeaders[options.id];
		if(column != null)
			column.filter = value;

		if(options.id !== AbstractTableComponent.PAGE_PARAMETER) {
			value = this._prepareSearch(value, options);

			this.executeSearch(value, options);
		} else {
			const page = value ?? 0;
			if(typeof page !== 'number')
				throw new Level8Error(`unexpected page format. Expected "number" got "${typeof page}"`);

			this.goToPage(page);
		}

		if(this.storeSearch) {
			const queryParams = {...this.activatedRoute.snapshot.queryParams};
			const id          = `${this.tableId}.${options.id}`;
			if(value === '' || value == null) {
				delete this.searchingData[id];
				delete queryParams[id];
			} else {
				value = column?.serialize?.(value) ?? value;
				this.searchingData[id] = value;
				queryParams[id]        = value;
			}

			await this.router.navigate(
				[],
				{
					relativeTo:  this.activatedRoute,
					replaceUrl:  true,
					queryParams,
				},
			);
		}
	}

	get baseLink(): string | undefined {
		if(typeof this.columnLink === 'string')
			return this.columnLink;

		return undefined;
	}

	get getBaseLinkFunction(): ((entry: TableEntry) => string | undefined) | undefined {
		if(typeof this.baseLink === 'function')
			return this.baseLink;

		return undefined;
	}

	protected abstract get columnLink(): string | ((entry: TableEntry) => string | undefined) | undefined;

	protected abstract goToPage(page: number): void;

	getHeaderTemplate(id: string): TemplateRef<TableHeaderData<TableEntry>> | undefined {
		let template = this.columnHeaderViewTemplates?.find(e => e.header === id)?.template;
		template ??= this.columnHeaderContentTemplates?.find(e => e.header === id)?.template;

		return template;
	}

	getSearchHeaderTemplate(id: string): TemplateRef<TableSearchHeaderData<TableEntry, unknown>> | undefined {
		let template = this.columnSearchHeaderViewTemplates?.find(e => e.searchHeader === id)?.template;
		template ??= this.columnSearchHeaderContentTemplates?.find(e => e.searchHeader === id)?.template;

		return template;
	}

	getContentTemplate(id: string): TemplateRef<TableColumnData<TableEntry>> | undefined {
		let template = this.columnContentViewTemplates?.find(e => e.column === id)?.template;
		template ??= this.columnContentContentTemplates?.find(e => e.column === id)?.template;

		return template;
	}

	protected getTableId(): string {
		const element = this.elementRef.nativeElement.parentNode;
		const name    = ((element.id != null && element.id !== '') ? element.id : element.tagName).toLowerCase();

		return StringHelper.fromKebabCase(name).toCamelCase();
	}

	protected parseHeaders(headers: Record<string, MinimalColumn<TableEntry>>): Record<string, Column<TableEntry>> {
		const headersOut: Record<string, Column<TableEntry>> = {};

		for(const [id, column] of Object.entries(headers)) {
			const defaultValues           = {
				inMenu:               true,
				isVisible:            true,
				contentTemplate:      (() => this.getContentTemplate(id)),
				headerTemplate:       (() => this.getHeaderTemplate(id)),
				searchHeaderTemplate: (() => this.getSearchHeaderTemplate(id)),
			};
			const out: Column<TableEntry> = {...defaultValues, ...column};
			if(column.isSearchable ?? true) {
				out.onSearch = async (value, options) => this.search(
					(column.prepareSearch != null) ? await column.prepareSearch(value, options) : value, options,
				);
			}

			headersOut[id] = out;
		}

		return headersOut;
	}

	protected abstract _prepareSearch(value: unknown, options: SearchOptions): unknown;

	protected abstract executeSearch(value: unknown, options: SearchOptions): void | Promise<void>;
}
