import {
	Component,
	Input,
} from '@angular/core';
import {
	combineLatestSafe,
	notNull,
} from '@app/main';
import {MedicalStoreModel} from '@contracts/frontend-api';
import {Observable} from 'rxjs';
import {
	map,
	mergeMap,
} from 'rxjs/operators';

@Component({
	selector:    'portal-medical-store-show-iqz-categories[model]',
	templateUrl: './medical-store-show-iqz-categories.component.html',
	styleUrls:   ['./medical-store-show-iqz-categories.component.scss'],
})
export class MedicalStoreShowIqzCategoriesComponent {
	@Input() model!: MedicalStoreModel;

	get categories(): Observable<string[] | undefined> {
		return this.model.iqzCategories.value.pipe(
			mergeMap(categories => combineLatestSafe(
				categories?.map(category => category.name.value),
			)),
			map(names => names?.filter(notNull)),
		);
	}
}
