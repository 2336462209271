<portal-headline
	label="general.modelList"
	[labelArgs]="{model: 'contractingParties'}"
	[menu]="menu"
>
	<mat-menu #menu>
		@if (canCreateParty) {
			<button mat-menu-item [matMenuTriggerFor]="menuCreate">
				<fa-icon matMenuItemIcon [icon]="iconService.CREATE"></fa-icon>
				<span>{{ 'general.menu.create' | translate }}</span>
			</button>
		}
	</mat-menu>

	<mat-menu #menuCreate>
		@if (canCreateParty) {
			<a
				mat-menu-item
				[routerLink]="['create']"
			>
				<fa-icon matMenuItemIcon [icon]="iconService.CONTRACTING_PARTY"></fa-icon>
				<span>{{ 'model.contractingParty' | translate }}</span>
			</a>
		}
	</mat-menu>
</portal-headline>

<mat-card>
	<mat-card-content>
		<portal-contracting-party-list [data]="contractingParties | async"/>
	</mat-card-content>
</mat-card>
