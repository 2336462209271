import {
	DtoCreationFormHelper,
	ResultPageModel,
} from '@angular-helpers/frontend-api';
import {HttpErrorResponse} from '@angular/common/http';
import {
	Component,
	DestroyRef,
	Input as RouteInput,
	OnInit,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {UntypedFormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {environment} from '@app/environment';
import {
	AuthService,
	ConfirmDialogConfig,
	DialogService,
	IconService,
} from '@app/main';
import {
	MedicalStoreModel,
	MedicalStoreService,
	MedicalStoreUserDtoModel,
	MedicalStoreUserModel,
	MedicalStoreUserService,
} from '@contracts/frontend-api';
import {
	BehaviorSubject,
	combineLatest,
	from,
	Observable,
	of,
	Subject,
	throwError,
} from 'rxjs';
import {
	catchError,
	concatMap,
	delay,
	filter,
	map,
	mergeMap,
	take,
	tap,
} from 'rxjs/operators';
import {ElementOperationDataInterface} from '../../../components/medical-store/properties/medical-store-user/edit/medical-store-user-edit.component';
import {MedicalStoreUserDeleteDialogComponent} from '../medical-store-user-delete-dialog/medical-store-user-delete-dialog.component';

interface MedData {
	index?: number;
	length?: number;
	medicalStore: MedicalStoreModel;
	canEdit: boolean;
	remove: boolean;
	update: boolean;
	create: boolean;
}

@Component({
	templateUrl: './medical-store-user-edit-create-page.component.html',
	styleUrls:   ['./medical-store-user-edit-create-page.component.scss'],
})
export class MedicalStoreUserEditCreatePageComponent implements OnInit {
	protected selectedMedicalStores: MedData[] = [];
	protected localForm = new UntypedFormGroup({});
	protected medUserForm: DtoCreationFormHelper<MedicalStoreModel, MedicalStoreService>;
	protected isSaving                         = false;
	protected isDeletingAll                    = false;
	protected errorHasOccurred?: Error;
	protected save$                            = new Subject<void>();
	protected reset$                           = new Subject<void>();
	protected reInitialize$                    = new Subject<void>();
	protected medUserFormControl?: UntypedFormGroup;
	protected selectedUser?: MedicalStoreUserModel;
	protected currentUserId?: string;
	protected backLink                         = environment.medicalStoreUsersFullUrl;
	protected disableEdit                      = false;
	protected hasChanges                       = false;
	protected onSave$: Observable<MedData>;
	protected saveProcessing$                  = new BehaviorSubject<boolean>(false);
	protected canEdit                          = false;
	protected permissionSearchFinished         = false;

	constructor(
		private readonly router: Router,
		private readonly medicalStoreService: MedicalStoreService,
		private readonly medicalStoreUserService: MedicalStoreUserService,
		private readonly dialog: MatDialog,
		private readonly dialogService: DialogService,
		protected readonly iconService: IconService,
		private readonly authService: AuthService,
		private readonly destroyRef: DestroyRef,
	) {
		this.medUserForm = DtoCreationFormHelper.create(
			MedicalStoreUserDtoModel,
			this.medicalStoreService,
			{},
		);
		this.medUserForm.control.then((control) => {
			this.medUserFormControl = control;
		});

		this.onSave$ = this.save$.pipe(
			takeUntilDestroyed(),
			filter(x => this.selectedMedicalStores.length > 0),
			tap(() => {
				this.isSaving = true;
				this.saveProcessing$.next(true);
			}),
			mergeMap(() => {
				const list       = this.selectedMedicalStores;
				const sortedList = list.sort((left, right) => {
					// create users first to not run into a running condition/request error
					if(left.create !== right.create)
						return left.create ? -1 : 1;

					if(left.update !== right.update)
						return left.update ? -1 : 1;

					if(left.remove !== right.remove)
						return left.remove ? -1 : 1;

					return 0;
				});

				const name  = this.localForm.get('name')?.value?.trim();
				const email = this.localForm.get('email')?.value?.trim();

				return from(sortedList).pipe(
					concatMap((medicalStoreData, indexT) => {
						medicalStoreData.index = indexT;
						medicalStoreData.length = list.length;

						return combineLatest([
							of(medicalStoreData),
							from(
								this.medUserForm.fill({
									name,
									email,
									canEdit: medicalStoreData.canEdit,
								}),
							).pipe(
								mergeMap(() => this.medUserForm.isInvalid()),
							),
							of({
								name,
								email,
								canEdit: medicalStoreData.canEdit,
							}),
						]);
					}),
				);
			}),
			filter(([, formIsInvalid]) => !formIsInvalid),
			tap(([medicalStoreData, , formDto]) => {
				if(medicalStoreData.remove && this.selectedUser != null) {
					return this.medicalStoreService.removeMedicalStoreUser(
						medicalStoreData.medicalStore,
						this.selectedUser,
					);
				}

				if(
					medicalStoreData.update &&
					!medicalStoreData.create &&
					this.selectedUser != null
				) {
					return this.medicalStoreService.updateMedicalStoreUser(
						medicalStoreData.medicalStore,
						this.selectedUser,
						formDto.canEdit,
					);
				}

				return this.medicalStoreService.saveMedicalStoreUser(
					medicalStoreData.medicalStore,
					formDto,
				);
			}),
			map(x => x[0]),
		);

		this.onSave$
		    .pipe(
			    delay(500),
			    filter(value => value.index != null && value.length != null && value.length === value.index + 1),
			    catchError((error, observable) => {
				    this.isSaving = false;

				    if(error instanceof HttpErrorResponse) {
					    const errorMessage = error.error?.message;
					    if(typeof errorMessage === 'string' && errorMessage.includes('user already linked to medicalStore'))
						    return this.findAndRerouteToUser$();


					    this.errorHasOccurred = error;
					    return observable;
				    }

				    return throwError(error);
			    }),
			    takeUntilDestroyed(),
		    )
		    .subscribe(() => {
			    const routedAction = (routeSuccess: boolean): boolean => {
				    this.reInitialize$.next();
				    this.isSaving      = false;
				    this.isDeletingAll = false;
				    this.saveProcessing$.next(false);

				    return routeSuccess;
			    };

			    if(this.selectedUser == null) {
				    return this.findAndRerouteToUser$().pipe(
					    map(routedAction),
				    );
			    }

			    if(this.isDeletingAll) {
				    return router.navigate([
					    environment.medicalStoreUsersFullUrl,
					    this.selectedUser.id,
				    ]).then(routedAction);
			    }

			    return router.navigate([
				    environment.medicalStoreUsersFullUrl,
			    ]).then(routedAction);
		    });

		this.reInitialize$.pipe(takeUntilDestroyed()).subscribe(() => {
			this.selectedMedicalStores = [];
		});

		const permissionSearch = (page: ResultPageModel<MedicalStoreModel>): Promise<ResultPageModel<MedicalStoreModel> | undefined> => {
			return Promise.all(page.data.map(store => store.users.permissions.canUpdate)).then((permissions) => {
				if(permissions.includes(true)) {
					this.canEdit;
					this.permissionSearchFinished = true;

					return;
				}

				if(page.hasNextPage())
					return page.loadNextPage().then((page) => permissionSearch(page));


				if(this.selectedUser?.id != null && this.canEdit)
					this.userDoesNotExist();

				return;
			});
		};
		this.medicalStoreService.find({
			pageSize: 10,
		}).then(page => permissionSearch(page));
		this.reInitialize$
		    .pipe(takeUntilDestroyed())
		    .subscribe(() => {
			    this.selectedMedicalStores = [];
		    });
	}

	@RouteInput()
	set id(id: unknown) {
		if(id === undefined) {
			this.onUserDetected(id);
			return;
		}

		if(typeof id === 'string') {
			this.onUserDetected(this.medicalStoreUserService.getById(id));
			return;
		}

		throw new Error(`Invalid id: ${id}`);
	}

	findAndRerouteToUser$(): Observable<boolean> {
		const mailValue = this.localForm.get('email')?.value;
		if(mailValue == null || typeof mailValue !== 'string')
			return of(false);


		return from(this.medicalStoreUserService.find({
			column:     'email',
			comparator: '=',
			value:      mailValue,
		})).pipe(
			take(1),
			map(result => result.data),
			mergeMap((users) => combineLatest(users.map(user => combineLatest([
				of(user),
				user.email.value,
			])))),
			mergeMap((mailList) => {
				let userId = '';
				mailList.find(([userm, mail]) => {
					const res = mail === mailValue;
					if(res)
						userId = userm.id;

					return res;
				});

				if(userId.length < 1) {
					return this.router.navigate([
						environment.medicalStoreUsersFullUrl,
					]);
				}

				return this.router.navigate([
					environment.medicalStoreUsersFullUrl,
					userId,
				]);
			}),
		);
	}

	ngOnInit(): void {
		if(this.medUserFormControl == null)
			throw new Error('FormControl expected, cannot initialize fields');

		const dtoName = this.medUserFormControl.get('name');
		const dtoEmail = this.medUserFormControl.get('email');

		if(dtoName == null || dtoEmail == null)
			throw new Error('expected name/email Fields. Input validation cannot be established');

		this.localForm.addControl('name', dtoName);
		this.localForm.addControl('email', dtoEmail);

		this.authService.user$
		    .pipe(
			    takeUntilDestroyed(this.destroyRef),
			    map((user) => {
				    if(this.id != null)
					    this.currentUserId = user?.getSubject();


				    return user;
			    }),
		    )
		    .subscribe();
	}

	public saveForm(): void {
		if(!this.localForm.valid && !this.localForm.disabled) {
			window.scroll({
				top: 0,
			});
			return;
		}

		if(this.hasChanges && this.notSelf())
			this.save$.next();
	}

	onAbort(): void {
		const confirmOptions: ConfirmDialogConfig = {
			labelNegative: 'system.abortChangesDialog.labelNegative',
			labelPositiv: 'system.abortChangesDialog.labelPositiv',
			title:        'system.abortChangesDialog.title',
			message:      'system.abortChangesDialog.message',
			icon:         this.iconService.DIALOG_ATTENTION,
		};

		of({})
			.pipe(
				mergeMap(() => {
					if(this.hasChanges || this.localForm.touched) {
						return this.dialogService.openConfirmDialog(confirmOptions)
						           .afterClosed();
					}

					return of(1);
				}),
				take(1),
				map((result) => {
					if(Boolean(result)) {
						this.reset$.next();
						return this.router.navigate([
							environment.medicalStoreUsersFullUrl,
						]);
					}

					return result;
				}),
			)
			.subscribe();
	}

	onListUpdate(medicalStoreMap: Map<string, ElementOperationDataInterface>): void {
		const operations = [...medicalStoreMap.entries()];
		this.hasChanges = operations.length > 0;

		this.selectedMedicalStores = operations.map(([, state]) => ({
			medicalStore: state.model,
			canEdit:      state.state.canEdit,
			remove:       state.removed,
			update:       state.updated,
			create:       state.created,
		}));
	}

	onUserDetected(user: MedicalStoreUserModel | undefined): void {
		this.selectedUser   = user;
		const nameField  = this.localForm.get('name');
		const emailField = this.localForm.get('email');

		if(user == null)
			return;


		if(emailField == null || nameField == null)
			return;


		user.onError.subscribe((value) => {
			this.userDoesNotExist(value);
		});

		combineLatest([
			user.email.value.pipe(
				take(1),
				map((email) => {
					if(email == null)
						throw new Error('request error, no email detected');


					emailField.setValue(email);
					emailField.disable();
				}),
			),
			user.name.value.pipe(
				take(1),
				map((name) => {
					if(name == null)
						throw new Error('request error, no name detected');


					nameField.setValue(name);
					nameField.disable();
				}),
			),
		])
			.pipe(
				catchError((error) => {
					return this.userDoesNotExist(error);
				}),
			)
			.subscribe();

		this.disableEdit = !this.notSelf();
	}

	openDialog(): void {
		if(this.selectedUser == null)
			return;


		this.dialog.open(MedicalStoreUserDeleteDialogComponent, {
			minWidth: 'max-content',
			data: {
				userId: this.selectedUser.id,
			},
		});
	}

	notSelf(): boolean {
		if(this.selectedUser == null)
			return true;


		return this.selectedUser.id !== this.currentUserId;
	}

	onRemoveAll(isDelete: boolean): void {
		this.isDeletingAll = isDelete;
	}

	userDoesNotExist(error?: unknown): Promise<boolean> {
		if(error instanceof HttpErrorResponse) {
			return this.router.navigate([
				'errors',
				error.status,
			], {skipLocationChange: true});
		}

		return this.router.navigate(['errors'], {skipLocationChange: true});
	}
}
