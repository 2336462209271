<portal-model-editable-box
	[icon]="iconService.CONTRACT"
	headline="baseContract.contractingData"
	[formHelper]="formHelper"
	[hasEditButton]="canUpdate | async"
>

	@if (contract) {
		<ng-template #showModeContent>
			<portal-base-contract-show-notice-period [model]="contract"/>
			<portal-base-contract-show-billing-date [model]="contract"/>
			<portal-base-contract-show-maximum-back-dating-delay [model]="contract"/>
			<portal-base-contract-show-external-contract-number [model]="contract" [type]="externalContractNumberType.mip"/>
			<portal-base-contract-show-external-contract-number [model]="contract" [type]="externalContractNumberType.legs"/>
			<portal-base-contract-show-exports [model]="contract"/>
		</ng-template>
	}

	<ng-template #editModeContent let-control>
		<portal-base-contract-edit-notice-period [control]="control" [model]="contract"/>
		<portal-base-contract-edit-billing-delay [control]="control" [model]="contract"/>
		<portal-base-contract-edit-maximum-back-dating-delay [control]="control" [model]="contract"/>
		<portal-contract-external-contract-number-list-edit [control]="control" [model]="contract" [formHelper]="formHelper" [type]="externalContractNumberType.legs"/>
		<portal-contract-external-contract-number-list-edit [control]="control" [model]="contract" [formHelper]="formHelper" [type]="externalContractNumberType.mip"/>
	</ng-template>

</portal-model-editable-box>
