import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlSerializer, UrlTree } from '@angular/router';
import {AuthService} from '@app/main';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class LoginStateGuard  {
	static readonly REDIRECT_IF_LOGGED_IN     = 'redirectLoggedIn';
	static readonly REDIRECT_IF_NOT_LOGGED_IN = 'redirectLoggedOut';

	constructor(
		private readonly authService: AuthService,
		private readonly urlSerializer: UrlSerializer,
	) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
		return this.authService.isLoggedIn$.pipe(
			map(loggedIn => route.data[loggedIn ? LoginStateGuard.REDIRECT_IF_LOGGED_IN : LoginStateGuard.REDIRECT_IF_NOT_LOGGED_IN]),
			map(redirect => (redirect != null) ? this.urlSerializer.parse(redirect) : true),
		);
	}

	canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): ReturnType<LoginStateGuard['canActivate']> {
		return this.canActivate(childRoute, state);
	}
}
