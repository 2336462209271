import {
	Component,
	Input,
} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {IconService} from '@app/main';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';

declare type Status = 'valid' | 'invalid' | 'required' | 'validating';

@Component({
	selector:    'portal-input-status-icon',
	templateUrl: './input-status-icon.component.html',
	styleUrls:   ['./input-status-icon.component.scss'],
})
export class InputStatusIconComponent {
	@Input({required: true}) validator?: AbstractControl;

	constructor(
		public iconService: IconService,
	) {
	}

	get status(): Status {
		if(this.validator == null || this.validator.valid)
			return 'valid';

		if(this.validator.status === 'PENDING')
			return 'validating';

		if(this.validator.errors?.required != null)
			return 'required';

		if(this.validator.touched)
			return 'invalid';

		return 'invalid';
	}

	get isVisible(): boolean {
		if(!this.validator)
			return false;

		if(this.validator.disabled)
			return false;

		if(this.validator.dirty)
			return true;

		if(this.validator.valid === false)
			return true;

		return false;
	}

	get icon(): IconDefinition {
		switch(this.status) {
			case 'invalid':
				return this.iconService.INPUT_ERROR;

			case 'required':
				return this.iconService.INPUT_REQUIRED;

			case 'valid':
				return this.iconService.INPUT_VALID;

			case 'validating':
				return this.iconService.LOADING_SPINNER;
		}
	}

	get isRequired(): boolean {
		return this.validator?.errors?.required != null;
	}
}
