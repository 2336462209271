<div mat-dialog-title class="dialog-title logo">
	<fa-icon class="dialog-icon" [icon]="iconService.DELETE"></fa-icon>
	<div class="title-text">{{ 'deleteDialog.title' | translate }}</div>
</div>
<div mat-dialog-content class="scroll-shadows dialog-content">
	<p>{{ 'deleteDialog.question' | translate }}</p>
	<div class="grid-2">
		<strong>{{ 'deleteDialog.name' | translate }}:</strong>
		<span>{{ data.modelName }}</span>
		<strong>{{ 'deleteDialog.model' | translate }}:</strong>
		<span>{{ data.modelType | translate }}</span>
	</div>
</div>
<div mat-dialog-actions class="actionbar">
	<div class="actions">
		@if (isDeleting) {
			@if (isDeleting) {
				<portal-spinning-button [processing]="isDeleting" color="warn">{{ 'actions.delete' | translate }}</portal-spinning-button>
			} @else {
				<button #default mat-stroked-button (click)="delete()" color="warn">{{ 'actions.delete' | translate }}</button>
			}
		} @else {
			<button #default mat-stroked-button (click)="delete()" color="warn">{{ 'actions.delete' | translate }}</button>
		}
		<ng-template #default>
			<button #default mat-stroked-button (click)="delete()" color="warn">{{ 'actions.delete' | translate }}</button>
		</ng-template>
		<button
        #abort class="right" mat-raised-button color="primary"
				(document:keydown.escape)="abort.focus();$event.preventDefault();" [mat-dialog-close]="false">
			{{ 'actions.cancel' | translate }}
		</button>
	</div>
</div>
