import {
	AbstractService,
	ResultPageModel,
} from '@angular-helpers/frontend-api';
import {AbstractApiService} from '@angular-helpers/frontend-api/lib/models/abstract-model/abstract.api-service';
import {
	Directive,
	Input,
	TemplateRef,
} from '@angular/core';
import {AnyModel} from '../../Helper/any';
import {Column} from './table.component';

export interface FullColumn<ModelType, RawType> extends Column<ModelType, RawType> {
	id: string;
}

export interface TableSearchHeaderData<ModelType, RawModel> {
	column: FullColumn<ModelType, RawModel>;
}

@Directive({
	selector: 'ng-template[searchHeader][data],ng-template[searchHeader][service]',
})
export class TableSearchHeaderTemplateDirective<ModelType, RawModel> {
	@Input({required: true}) searchHeader!: string;
	@Input() data?: ModelType[] | ResultPageModel<ModelType> | undefined | null;
	@Input() service?: AbstractService<AbstractApiService, ModelType & AnyModel>;

	constructor(public readonly template: TemplateRef<TableSearchHeaderData<ModelType, RawModel>>) {
	}

	static ngTemplateContextGuard<ModelType, RawModel>(dir: TableSearchHeaderTemplateDirective<ModelType, RawModel>, ctx: unknown): ctx is TableSearchHeaderData<ModelType, RawModel> {
		return true;
	}
}
