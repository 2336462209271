import {
	Component,
	ElementRef,
	HostListener,
	ViewChild,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormControl} from '@angular/forms';
import {environment} from '@app/environment';
import {UserService} from '@authentication/frontend-api';
import {faAngleDown} from '@fortawesome/free-solid-svg-icons';
import {TranslateService} from '@ngx-translate/core';
import {map} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import {ConfigService} from '../../services/config.service';
import {DialogService} from '../../services/dialog.service';
import {IconService} from '../../services/icon.service';
import {ThemeServiceService} from '../../services/theme-service.service';

@Component({
	selector:    'portal-header',
	templateUrl: './header.component.html',
	styleUrls:   ['./header.component.scss'],
})
export class HeaderComponent {
	@ViewChild('avatarMenu') avatarMenu?: ElementRef;
	readonly ICONS       = {
		avatar: faAngleDown,
	};
	menuShown            = false;
	readonly environment = environment;
	readonly token$      = this.authService.user$;
	readonly user$       = this.token$.pipe(map(token => (token == null) ? null : this.userService.getById(token.getSubject())));
	protected readonly darkMode = new FormControl(false);

	constructor(
			private readonly authService: AuthService,
			private readonly userService: UserService,
			protected readonly iconService: IconService,
			protected readonly translateService: TranslateService,
			public readonly configService: ConfigService,
			public readonly dialogService: DialogService,
			protected readonly themeServiceService: ThemeServiceService,
	) {
		this.darkMode.valueChanges.pipe(takeUntilDestroyed()).subscribe(value => this.themeServiceService.darkMode = value ?? false);
		this.themeServiceService.darkMode$.pipe(takeUntilDestroyed()).subscribe(value => this.darkMode.setValue(value));
	}

	@HostListener('document:click', ['$event'])
	globalClickListener(event: MouseEvent): void {
		if(!this.menuShown)
			return;

		if(this.avatarMenu?.nativeElement.contains(event.target) === false)
			this.menuShown = false;
	}

	toggleMenu(): void {
		this.menuShown = !this.menuShown;
	}

	openStageDialog(): Promise<void> {
		return this.dialogService.getResult({
			labelPositiv: 'actions.ok',
			message:      `system.stage.${environment.stage}.message`,
			title:        `system.stage.${environment.stage}.title`,
		}).then();
	}

	hasMessageHint(): boolean {
		const msg = this.translateService.instant(`system.stage.${environment.stage}`);
		return typeof msg != 'string';
	}
}
