import {
	Component,
	Input,
} from '@angular/core';
import {ContractAccessionModel} from '@contracts/frontend-api';

@Component({
	selector:    'portal-contract-accession-show-accession-start-at',
	templateUrl: './contract-accession-show-accession-start-at.component.html',
	styleUrls:   ['./contract-accession-show-accession-start-at.component.scss'],
})
export class ContractAccessionShowAccessionStartAtComponent {
	@Input({required: true}) model!: ContractAccessionModel;
}
