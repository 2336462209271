import {HttpClient} from '@angular/common/http';
import {Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChange, SimpleChanges} from '@angular/core';

@Directive({
// eslint-disable-next-line @angular-eslint/directive-selector
	selector: 'svg[inlineSVG]',
})
export class SvgInlineDirective implements OnChanges {
	@Input() inlineSVG!: string;
	
	constructor(
		private readonly httpClient: HttpClient,
		private readonly hostRef: ElementRef,
		private readonly renderer: Renderer2,
	) {}
	
	ngOnChanges(changes: SimpleChanges): void {
		const inlineSVG = changes.inlineSVG as (SimpleChange | undefined);
		
		if((inlineSVG != null && inlineSVG.previousValue !== inlineSVG.currentValue))
			this.insertSVG();
	}
	
	
	private insertSVG(): void {
		if(!this.inlineSVG)
			return;
		
		this.httpClient.get(this.inlineSVG, {responseType: 'text'}).toPromise()
			.then(svg => this.createSvgElement(svg))
			.then(svg => this.insertElement(svg));
	}
	
	private createSvgElement(svgRaw: string): SVGSVGElement {
		const div: HTMLDivElement = this.renderer.createElement('DIV');
		div.innerHTML             = svgRaw;
		
		const svg = div.querySelector('svg');
		if(svg == null)
			throw new Error('No SVG found in loaded contents');
		
		return svg;
	}
	
	private insertElement(svg: SVGElement): void {
		this.hostRef.nativeElement.innerHTML = svg.innerHTML;
		
		for(const attribute of Object.values(svg.attributes))
			this.hostRef.nativeElement.setAttribute(attribute.name, attribute.value);
	}
}
