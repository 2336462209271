import {
	InheritedCoalesceProperty,
	Level8Error,
} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
} from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
} from '@angular/forms';
import {
	ContractModel,
	ContractSectionModel,
	MasterContractModel,
} from '@contracts/frontend-api';
import moment, {Moment} from 'moment';
import {
	Observable,
	of,
} from 'rxjs';
import {mergeMap} from 'rxjs/operators';

@Component({
	selector: 'portal-base-contract-edit-minimum-term',
	templateUrl: './base-contract-edit-minimum-term.component.html',
	styleUrls: ['./base-contract-edit-minimum-term.component.scss'],
})
export class BaseContractEditMinimumTermComponent {
	@Input({
		required: true,
		alias:    'control',
	}) parent!: UntypedFormGroup;
	@Input() model?: MasterContractModel | ContractModel | ContractSectionModel;
	@Input() parentModel?: MasterContractModel | ContractModel;

	get control(): UntypedFormControl {
		const fieldName = 'minimumTermAt';
		const control   = this.parent.get(fieldName);
		if(control instanceof UntypedFormControl)
			return control;

		throw new Level8Error(`Unexpected type for field ${fieldName} - expected '${UntypedFormControl.name}' got '${typeof control}' (${control})`);
	}

	get earliestDate(): Observable<Date | Moment | undefined> {
		return of(this.earliestDateControl)
			.pipe(mergeMap(control => control != null ? of(control) : this.earliestDateParent));
	}

	get earliestDateControl(): Date | moment.Moment | undefined | null {
		const fieldName = 'validityStartAt';
		const control   = this.parent.get(fieldName);
		if(control instanceof UntypedFormControl)
			return control.value;

		throw new Level8Error(`Unexpected type for field ${fieldName} - expected '${UntypedFormControl.name}' got '${typeof control}' (${control})`);
	}

	get earliestDateParent(): Observable<Date | undefined> {
		if(this.model == null)
			return of(undefined);

		if(this.model.validityStartAt instanceof InheritedCoalesceProperty)
			return this.model.validityStartAt.withParent.value;

		return this.model.validityStartAt.value;
	}

	get inheritedValue(): Observable<Date | undefined | null> | undefined {
		if(this.parentModel != null) {
			if(this.parentModel instanceof MasterContractModel)
				return this.parentModel.minimumTermAt.value;
			
			return this.parentModel.minimumTermAt.withParent.value;
		}
		
		if(this.model == null)
			return undefined;
		
		if(this.model instanceof MasterContractModel)
			return undefined;
		
		return this.model.minimumTermAt.inherited;
	}
}
