<portal-model-editable-box
	[icon]="iconService.CONTRACT"
	headline="general.masterData"
	[formHelper]="formHelper"
	[hasEditButton]="canUpdate | async"
>

	@if (contract) {
		<ng-template #showModeContent>
			<portal-base-contract-status [model]="contract"/>
			<portal-base-contract-show-name [model]="contract"/>
			<portal-master-contract-show [model]="contract.masterContract.value | async"/>
			<portal-base-contract-show-validity-start [model]="contract"/>
			<portal-base-contract-show-terminated-at [model]="contract"/>
			<portal-base-contract-show-minimum-term [model]="contract"/>
			<portal-base-contract-show-period-of-validity [model]="contract"/>
			<portal-base-contract-show-notice-period-negotiations [model]="contract"/>
			<portal-base-contract-show-negotiators [model]="contract"/>
			<portal-base-contract-show-initial-signer [model]="contract"/>
			<portal-base-contract-show-awarded-mandates [model]="contract"/>
		</ng-template>
	}

	<ng-template #editModeContent let-control>
		<portal-base-contract-edit-name [control]="control"/>
		<portal-base-contract-edit-validity-start [model]="contract" [control]="control"/>
		<portal-base-contract-edit-terminated-at [model]="contract" [control]="control"/>
		<portal-base-contract-edit-minimum-term [model]="contract" [control]="control"/>
		<portal-base-contract-edit-period-of-validity [model]="contract" [control]="control"/>
		<portal-base-contract-edit-notice-period-negotiations [model]="contract" [control]="control"/>
		<portal-base-contract-edit-negotiators [model]="contract" [control]="control"/>
		<portal-base-contract-edit-initial-signer [model]="contract" [control]="control"/>
		<portal-base-contract-edit-mandate-grantor [model]="contract" [control]="control"/>
	</ng-template>

</portal-model-editable-box>
