import {DtoCreationFormHelper} from '@angular-helpers/frontend-api';
import {
	Component,
	DestroyRef,
	OnInit,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {UntypedFormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {environment} from '@app/environment';
import {IconService} from '@app/main';
import {
	ContractingPartyDtoModel,
	ContractingPartyModel,
	ContractingPartyService,
} from '@contracts/frontend-api';
import {
	Subject,
	throwError,
} from 'rxjs';
import {
	catchError,
	mergeMap,
} from 'rxjs/operators';

@Component({
	selector:    'portal-contracting-party-page-create',
	templateUrl: './contracting-party-page-create.component.html',
	styleUrls:   ['./contracting-party-page-create.component.scss'],
})
export class ContractingPartyPageCreateComponent implements OnInit {
	public isSaving = false;
	private createFormHelper?: DtoCreationFormHelper<ContractingPartyModel, ContractingPartyService>;

	public control?: UntypedFormGroup;
	public onSubmit$ = new Subject<unknown>();
	public errorHasOccurred?: Error;

	constructor(
			protected readonly iconService: IconService,
			private readonly contractingPartyService: ContractingPartyService,
			private readonly router: Router,
			protected readonly destroyRef: DestroyRef,
	) {
	}


	ngOnInit(): void {
		this.onSubmit$.pipe(
				takeUntilDestroyed(this.destroyRef),
				mergeMap(() => this.createContractingParty()),
				catchError(error => {
					this.errorHasOccurred = error;
					return throwError(error);
				}),
		).subscribe();

		this.createFormHelper = DtoCreationFormHelper.create(
			ContractingPartyDtoModel,
			this.contractingPartyService,
			{},
		);

		this.createFormHelper.control.then((control) => {
			this.control = control;
		});
	}

	createContractingParty(): Promise<unknown> {
		if(this.control == null || this.createFormHelper == null)
			return new Promise(() => undefined);

		if(this.control.valid === false)
			return new Promise(() => undefined);


		this.isSaving = true;
		return this.createFormHelper.save().then((contractingPartner) => {
			this.isSaving = false;
			if(contractingPartner == null)
				return;


			return this.router.navigate([
				environment.contractingPartyFullUrl,
				contractingPartner.id,
			]);
		});
	}
}
