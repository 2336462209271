import {DtoEditFormHelper} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
} from '@angular/core';
import {
	IconService,
	ModelHelper,
} from '@app/main';
import {
	EmployeeDtoModel,
	EmployeeModel,
	EmployeeService,
} from '@contracts/frontend-api';
import {EmployeeEditRolesComponent} from '../../properties/roles/edit/employee-edit-roles.component';

@Component({
	selector:    'portal-employee-card-master-data',
	templateUrl: './employee-master-data-card.component.html',
	styleUrls:   ['./employee-master-data-card.component.scss'],
})
export class EmployeeMasterDataCardComponent {
	formHelper!: DtoEditFormHelper<EmployeeModel, EmployeeService>;
	private _employee!: EmployeeModel;
	public canUpdate?: Promise<boolean>;

	constructor(
		protected employeeService: EmployeeService,
		public iconService: IconService,
	) {
	}

	get employee(): EmployeeModel {
		return this._employee;
	}

	@Input({required: true}) set employee(value: EmployeeModel) {
		if(this._employee === value)
			return;

		this._employee  = value;
		this.formHelper = DtoEditFormHelper.create(EmployeeDtoModel, this.employee, this.employeeService);
		this.formHelper.registerBeforeSaveCallback(this.beforeSaveCallback.bind(this));

		this.canUpdate = ModelHelper.findEditableProperty([
			this.employee.salutation,
			this.employee.name,
			this.employee.email,
			this.employee.phone,
			this.employee.roles,
		]);
	}

	protected async beforeSaveCallback(): Promise<void> {
		const control = await this.formHelper.control;
		return EmployeeEditRolesComponent.saveRoles(this.employee, control, this.employeeService);
	}
}
