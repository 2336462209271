import {DtoEditFormHelper} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
} from '@angular/core';
import {
	IconService,
	ModelHelper,
} from '@app/main';
import {
	ContractAccessionDtoModel,
	ContractAccessionModel,
	ContractAccessionService,
} from '@contracts/frontend-api';

@Component({
	selector:    'portal-contract-accession-card-timings',
	templateUrl: './contract-accession-card-timings.component.html',
	styleUrls:   ['./contract-accession-card-timings.component.scss'],
})
export class ContractAccessionCardTimingsComponent {
	formHelper!: DtoEditFormHelper<ContractAccessionModel, ContractAccessionService>;
	private _contractAccession!: ContractAccessionModel;
	canUpdate?: Promise<boolean>;

	constructor(
		protected contractAccessionService: ContractAccessionService,
		public iconService: IconService,
	) {
	}

	get contractAccession(): ContractAccessionModel {
		return this._contractAccession;
	}

	@Input({required: true}) set contractAccession(value: ContractAccessionModel) {
		if(this.contractAccession === value)
			return;

		this._contractAccession = value;
		this.formHelper         = DtoEditFormHelper.create(
				ContractAccessionDtoModel,
				this.contractAccession,
				this.contractAccessionService,
		);

		this.canUpdate = ModelHelper.findEditableProperty([
			this.contractAccession.accessionStartAt,
		]);
	}
}
