<div class="container">
	<div class="float-button-container">
		@if (editable && canUpdate) {
			<a class="edit-float clickable" (click)="this.editThis()">
				<fa-icon [icon]="iconService.EDIT"></fa-icon>
			</a>
		}
	</div>

	<portal-news-show [news]="news"/>
</div>

<ng-template #editModeTemplate let-control>
	<portal-news-edit-display-start-at [control]="control"/>
	<portal-news-edit-display-end-at [control]="control"/>
	<portal-news-edit-importance [control]="control"/>
	<portal-news-edit-title [control]="control"/>
	<portal-news-edit-summary [control]="control"/>
	<portal-news-edit-text [control]="control"/>
</ng-template>


