import {
	AbstractModel,
	AbstractService,
	Level8Error,
	ModelId,
	ModelNameHelper,
} from '@angular-helpers/frontend-api';
import {AbstractApiService} from '@angular-helpers/frontend-api/lib/models/abstract-model/abstract.api-service';
import {HttpErrorResponse} from '@angular/common/http';
import {
	Directive,
	inject,
	Input as RouteInput,
} from '@angular/core';
import {
	ActivatedRoute,
	Router,
} from '@angular/router';
import {IconService} from '@app/main';
import {Subscription} from 'rxjs';
import {DialogService} from '../../services/dialog.service';


@Directive()
export abstract class AbstractShowComponent<Model extends AbstractModel<ModelService>, ModelService extends AbstractService<AbstractApiService, Model>> {
	protected _model?: Model;
	private errorSubscription?: Subscription;
	protected readonly dialogService = inject(DialogService);
	protected readonly router        = inject(Router);
	protected readonly iconService   = inject(IconService);
	protected readonly activatedRoute = inject(ActivatedRoute);

	protected constructor(
		protected readonly modelService: ModelService,
	) {
	}

	get model(): Model | undefined {
		return this._model;
	}

	set model(model: Model | undefined) {
		if(model === undefined)
			throw new Level8Error('model must be defined!');

		const oldModel = this.model;
		this._model    = model;


		this.errorSubscription?.unsubscribe();
		this.errorSubscription = model.onError.subscribe((error) => {
			if(error instanceof HttpErrorResponse) {
				this.router.navigate([
					'errors',
					error.status,
				], {skipLocationChange: true});
			}
		});

		if(model !== oldModel)
			this.onNewModel(model, oldModel);
	}

	@RouteInput()
	set id(id: unknown) {
		if(typeof id !== 'string')
			throw new Error(`Invalid id: ${id}`);

		this.model = this.modelService.getById(id);
	}

	protected static redirectToPageOrList(router: Router, baseUrl: string | unknown[], id: ModelId | undefined): Promise<void> {
		if(!Array.isArray(baseUrl))
			baseUrl = [baseUrl];

		if(id != null)
			baseUrl.push(id);

		return router.navigate(baseUrl).then();
	}

	private static toCamelCase(input: string): string {
		return input.replace(/^(.)/, firstChar => firstChar.toLowerCase());
	}

	/**
	 * Get the current model and ensure it's set
	 */
	getModel(): Model {
		if(this.model == null)
			throw new Level8Error('model must be defined!');

		return this.model;
	}

	async showDeleteDialog(): Promise<void> {
		const name      = await this.getModelName() ?? 'Unbekannt';
		const modelType = `model.${AbstractShowComponent.toCamelCase(ModelNameHelper.fromObject(this.getModel())
																					.asBaseName())}`;
		const dialog    = this.dialogService.openDeleteDialog(
			name,
			modelType,
		);

		const shouldDelete = await dialog.afterClosed().toPromise();
		if(shouldDelete === true) {
			await this.delete();
			await this.postDeleteActions();
			await this.postDeleteRedirect();
		}
	}

	protected onNewModel(newModel: Model, oldModel?: Model): Promise<void> {
		return Promise.resolve();
	}

	protected delete(): Promise<void> {
		return this.modelService.delete(this.getModel());
	}

	protected postDeleteActions(): Promise<void> {
		return Promise.resolve();
	}

	protected postDeleteRedirect(): Promise<void> {
		return this.router.navigate(['..'], {relativeTo: this.activatedRoute}).then();
	}

	protected abstract getModelName(): Promise<string | undefined>;
}
