import {
	Component,
	Input,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import {environment} from '@app/environment';
import {combineLatestSafe} from '@app/main';
import {
	ContractModel,
	ContractSectionModel,
	ExportModel,
	MasterContractModel,
} from '@contracts/frontend-api';
import {Observable} from 'rxjs';
import {
	map,
	mergeMap,
} from 'rxjs/operators';

interface Entry {
	nextAt: Date;
	model: ExportModel;
}

@Component({
	selector:    'portal-base-contract-show-exports',
	templateUrl: './base-contract-show-exports.component.html',
	styleUrls:   ['./base-contract-show-exports.component.scss'],
})
export class BaseContractShowExportsComponent implements OnChanges {
	@Input({required: true}) model!: MasterContractModel | ContractModel | ContractSectionModel;
	nextAt$?: Observable<Entry[] | undefined>;

	get environment(): typeof environment {
		return environment;
	}

	ngOnChanges(changes: SimpleChanges): void {
		const exports$ = (this.model instanceof MasterContractModel) ?
						 this.model.exports.value :
						 this.model.exports.withParent.value;

		this.nextAt$ = exports$.pipe(
			map(exports => exports?.map(model => model.nextAt.value.pipe(map(nextAt => ({
				nextAt,
				model,
			}))))),
			mergeMap(x => combineLatestSafe(x)),
			map(x => x?.filter((y): y is Entry => y.nextAt instanceof Date)),
		);
	}
}
