import {AngularEditorConfig} from '@kolkov/angular-editor';

export declare type Stage = 'LOCAL' | 'DEVELOPMENT' | 'BETA' | 'PRODUCTION';

export class Environment {
	readonly homeBaseUrl                               = '/';
	readonly homeFullUrl                               = `/${this.homeBaseUrl}`;
	readonly loginBaseUrl                              = 'login';
	readonly loginFullUrl                              = `/${this.loginBaseUrl}`;
	readonly logoutBaseUrl                             = 'logout';
	readonly logoutFullUrl                             = `/${this.logoutBaseUrl}`;
	readonly dashboardBaseUrl                          = 'dashboard';
	readonly dashboardFullUrl                          = `/${this.dashboardBaseUrl}`;
	readonly termsAndConditionsBaseUrl                 = 'terms-and-conditions';
	readonly termsAndConditionsFullUrl                 = `/${this.termsAndConditionsBaseUrl}`;
	readonly acceptTermsAndConditionsBaseUrl           = 'accept-terms-and-conditions';
	readonly acceptTermsAndConditionsFullUrl           = `/${this.acceptTermsAndConditionsBaseUrl}`;
	readonly impressumBaseUrl                          = 'impressum';
	readonly impressumFullUrl                          = `/${this.impressumBaseUrl}`;
	readonly privacyBaseUrl                            = 'privacy';
	readonly privacyFullUrl                            = `/${this.privacyBaseUrl}`;
	readonly dependenciesBaseUrl                       = 'dependencies';
	readonly dependenciesFullUrl                       = `/${this.dependenciesBaseUrl}`;
	readonly baseUrl                                   = 'contracts';
	readonly medicalStoreUsersUrl                      = 'medical-store-users';
	readonly medicalStoreUsersFullUrl                  = `/${this.baseUrl}/${this.medicalStoreUsersUrl}`;
	readonly calculationManualsBaseUrl                 = 'calculation-manuals';
	readonly calculationManualsFullUrl                 = `/${this.baseUrl}/${this.calculationManualsBaseUrl}`;
	readonly medicalStoresBaseUrl                      = 'medical-store';
	readonly medicalStoresFullUrl                      = `/${this.baseUrl}/${this.medicalStoresBaseUrl}`;
	readonly masterContractsBaseUrl                    = 'master-contract';
	readonly masterContractsFullUrl                    = `/${this.baseUrl}/${this.masterContractsBaseUrl}`;
	readonly contractsBaseUrl                          = 'contract';
	readonly contractsFullUrl                          = `/${this.baseUrl}/${this.contractsBaseUrl}`;
	readonly contractSectionsBaseUrl                   = 'contract-section';
	readonly contractSectionsFullUrl                   = `/${this.baseUrl}/${this.contractSectionsBaseUrl}`;
	readonly contractingPartyBaseUrl = 'contracting-party';
	readonly contractingPartyFullUrl = `/${this.baseUrl}/${this.contractingPartyBaseUrl}`;
	readonly contractAccessionBaseUrl = 'contract-accession';
	readonly contractAccessionFullUrl = `/${this.baseUrl}/${this.contractAccessionBaseUrl}`;
	readonly landesinnungBaseUrl = 'landesinnung';
	readonly landesinnungFullUrl                       = `/${this.baseUrl}/${this.landesinnungBaseUrl}`;
	readonly professionalAssociationBaseUrl            = 'professional-association';
	readonly professionalAssociationFullUrl            = `/${this.baseUrl}/${this.professionalAssociationBaseUrl}`;
	readonly contractAccessingBaseUrl                  = `${this.contractAccessionBaseUrl}/accessing`;
	readonly contractAccessingFullUrl                  = `/${this.baseUrl}/${this.contractAccessingBaseUrl}`;
	readonly employeeBaseUrl                           = 'employee';
	readonly employeeFullUrl                           = `/${this.baseUrl}/${this.employeeBaseUrl}`;
	readonly preQualificationCertificateBaseUrl        = 'pre-qualification-certificate';
	readonly preQualificationCertificateFullUrl        = `/${this.baseUrl}/${this.preQualificationCertificateBaseUrl}`;
	readonly institutionskennzeichenBaseUrl            = 'institutionskennzeichen';
	readonly institutionskennzeichenFullUrl            = `/${this.baseUrl}/${this.institutionskennzeichenBaseUrl}`;
	readonly downloadSectionBaseUrl                    = 'download-section';
	readonly downloadSectionFullUrl                    = `/${this.baseUrl}/${this.downloadSectionBaseUrl}`;
	readonly newsletterBaseUrl                         = 'newsletter';
	readonly newsletterFullUrl                         = `/${this.newsletterBaseUrl}`;
	readonly newsBaseUrl                               = 'news';
	readonly newsFullUrl                               = `/${this.baseUrl}/${this.newsBaseUrl}`;
	readonly exportBaseUrl                             = 'export';
	readonly exportFullUrl                             = `/${this.baseUrl}/${this.exportBaseUrl}`;
	readonly infoPageMdrBaseUrl                        = 'member-information/mdr';
	readonly infoPageMdrFullUrl                        = `/${this.infoPageMdrBaseUrl}`;
	readonly infoPageImageCampaignCraftsmanshipBaseUrl = 'member-information/image-campaign-craftsmanship';
	readonly infoPageImageCampaignCraftsmanshipFullUrl = `/${this.infoPageImageCampaignCraftsmanshipBaseUrl}`;
	readonly maintenanceUrlBaseUrl                     = `maintenance`;
	readonly maintenanceUrlFullUrl                     = `/${this.maintenanceUrlBaseUrl}`;
	readonly downloadRawContentId                      = '271401d8-86d2-438e-8832-d6714996e673';
	readonly calculationManualsRawContentId            = '9ca09e76-13ec-4465-b319-37276db06bf8';
	readonly calculationManualsPreviewsRawContentId    = 'c683d636-3cb7-4fed-85c4-fbf358c33b1d';
	readonly newsletterRawContentId                    = '1b24d184-833e-4fde-bc4b-a41a617d205e';
	readonly termsAndConditionsNewsId                  = 'a11f4277-fc9a-4af0-b3b3-dca323104591';
	readonly employeeMedicalStoreLeaderRoleId          = '2a456309-71c7-4b74-8b3a-60c3d9f5ae99';
	readonly maintenanceNewsId                         = 'c28b965f-6640-4325-9d99-720849f1790b';
	readonly memberInformationPageRawContentId = '058b53d5-dc04-4889-855b-56e4519a5c8b';
	readonly iqzCategoryBasicId               = 'aec6ede2-f70e-4421-b0a7-9e465d5ea607';
	readonly IQZ_POINTS_VALID_PERIOD_YEARS    = 3;
	readonly IQZ_REQUIRED_POINTS_PER_CATEGORY = 20;
	readonly externalContactUrl               = 'https://biv-ot.org/vertraege/kontakt/';
	// eslint-disable-next-line @typescript-eslint/no-var-requires
	readonly version: string                           = require('../../package.json').version;
	// eslint-disable-next-line @typescript-eslint/no-var-requires
	readonly name: string                              = require('../../package.json').name;
	readonly config: AngularEditorConfig               = {
		editable:                  true,
		translate:                 'yes',
		spellcheck:                true,
		minHeight:                 '10em',
		maxHeight:                 '50vh',
		defaultParagraphSeparator: 'p',
		toolbarHiddenButtons:      [
			[
				'fontName',
				'fontSize',
				'textColor',
				'backgroundColor',
				'customClasses',
				'insertVideo',
				'insertHorizontalRule',
				'toggleEditorMode',
				'insertImage',
			],
		],
	};

	constructor(
		public readonly stage: Stage,
		public readonly authServer: string,
		public readonly authClientId: string,
		public readonly authRedirectUrl: string,
		public readonly contractsApiUrl: string,
	) {
	}

	get isOnServer(): boolean {
		return this.stage !== 'LOCAL';
	}

	get redirectUrl(): string {
		return `${document.location.protocol}//${document.location.host}${this.authRedirectUrl}`;
	}
}
