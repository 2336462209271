import {ResultPageModel} from '@angular-helpers/frontend-api';
import {
	Component,
	OnInit,
} from '@angular/core';
import {environment} from '@app/environment';
import {
	LandesinnungModel,
	LandesinnungService,
} from '@contracts/frontend-api';

@Component({
	selector:    'portal-page-landesinnung',
	templateUrl: './landesinnung-page-list.component.html',
	styleUrls:   ['./landesinnung-page-list.component.scss'],
})
export class LandesinnungPageListComponent implements OnInit {
	landesinnungsPage?: Promise<ResultPageModel<LandesinnungModel>>;
	readonly environment = environment;

	constructor(
		private readonly landesinnungService: LandesinnungService,
	) { }

	ngOnInit(): void {
		this.landesinnungsPage = this.landesinnungService.getPage(0, {pageSize: 20});
	}

}
