import {Level8Error} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
} from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
} from '@angular/forms';
import {
	ContractModel,
	ContractSectionModel,
	Delay,
	MasterContractModel,
} from '@contracts/frontend-api';
import {Observable} from 'rxjs';

@Component({
	selector: 'portal-base-contract-edit-notice-period-negotiations',
	templateUrl: './base-contract-edit-notice-period-negotiations.component.html',
	styleUrls: ['./base-contract-edit-notice-period-negotiations.component.scss'],
})
export class BaseContractEditNoticePeriodNegotiationsComponent {
	@Input({
		required: true,
		alias:    'control',
	}) parent!: UntypedFormGroup;
	@Input() model?: MasterContractModel | ContractModel | ContractSectionModel;
	@Input() parentModel?: MasterContractModel | ContractModel;

	get control(): UntypedFormControl {
		const fieldName = 'noticePeriodNegotiationDelay';
		const control   = this.parent.get(fieldName);
		if(control instanceof UntypedFormControl)
			return control;

		throw new Level8Error(`Unexpected type for field ${fieldName} - expected '${UntypedFormControl.name}' got '${typeof control}' (${control})`);
	}

	get inheritedValue(): Observable<Delay | undefined | null> | undefined {
		if(this.parentModel != null) {
			if(this.parentModel instanceof MasterContractModel)
				return this.parentModel.noticePeriodNegotiationDelay.value;
			
			return this.parentModel.noticePeriodNegotiationDelay.withParent.value;
		}
		
		if(this.model == null)
			return undefined;
		
		if(this.model instanceof MasterContractModel)
			return undefined;
		
		return this.model.noticePeriodNegotiationDelay.inherited;
	}
}
