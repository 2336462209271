@if (model;as address) {
	<portal-showable
		label="common.address"
		[value]="model"
	>
		{{ address.name }}
		<br/>
		{{ address.street }} {{ address.houseNumber }}
		<br/>
		{{ address.zip }} {{ address.town }}
	</portal-showable>
}
