<portal-headline label="general.modelList" [labelArgs]="{model: 'news'}" [menu]="menu">
	<mat-menu #menu>
		@if (canCreate) {
			<a mat-menu-item [routerLink]="[environment.newsFullUrl,'create']">
				<fa-icon matMenuItemIcon [icon]="iconService.CREATE"></fa-icon>
				<span>{{ 'news.create' |translate }}</span>
			</a>
		}
	</mat-menu>
</portal-headline>

<mat-card>
	<mat-card-content>
		<portal-table-client-side-searchable
			*ngLet="newsDataPage | async as data"
			[data]="data"
			[headers]="headers"
			[pageSize]="25"
			[searchFilter]="searchFilter"
			[columnLink]="environment.newsFullUrl"
		>
			<ng-template column="title" let-model="model" [data]="data">
				<portal-skeleton-field [content]="model.title.value">
					<ng-template let-number skeletonContent>
						{{ number }}
					</ng-template>
				</portal-skeleton-field>
			</ng-template>
			<ng-template column="text" let-model="model" [data]="data">
				<portal-skeleton-field [content]="model.text.value">
					<ng-template let-text skeletonContent>
						<div [innerHTML]="text | section: textPreviewLength"></div>
					</ng-template>
				</portal-skeleton-field>
			</ng-template>
			<ng-template column="importance" let-model="model" [data]="data">
				<portal-skeleton-field [content]="model.importance.value">
					<ng-template let-importance skeletonContent>
						{{ ('news.importanceValues.' + importance) |translate }}
					</ng-template>
				</portal-skeleton-field>
			</ng-template>
		</portal-table-client-side-searchable>
	</mat-card-content>
</mat-card>
