import {Injectable} from '@angular/core';
import {
	MatDialog,
	MatDialogConfig,
	MatDialogRef,
} from '@angular/material/dialog';
import {
	BaseDialogComponent,
	BaseDialogData,
} from '../components/base-dialog/base-dialog.component';
import {
	ConfirmDialogAnswer,
	ConfirmDialogComponent,
	ConfirmDialogConfig,
} from '../components/confirm-dialog/confirm-dialog.component';
import {DeleteModelDialogComponent} from '../components/delete-model-dialog/delete-model-dialog.component';

@Injectable({
	providedIn: 'root',
})
export class DialogService {
	constructor(
			protected readonly dialog: MatDialog,
	) {}

	openConfirmDialog(data: ConfirmDialogConfig): MatDialogRef<ConfirmDialogComponent, ConfirmDialogAnswer> {
		return this.dialog.open(ConfirmDialogComponent, {
			minWidth:     'max-content',
			autoFocus:    false,
			disableClose: true,
			data,
		});
	}

	getResult(data: ConfirmDialogConfig): Promise<ConfirmDialogAnswer> {
		return this.openConfirmDialog(data)
				   .afterClosed()
				   .toPromise()
				   .then((answer) => answer ?? ConfirmDialogAnswer.unknown);
	}

	openDeleteDialog(modelName: string, modelType: string, deleteCallback?: () => Promise<unknown>): MatDialogRef<DeleteModelDialogComponent, boolean> {
		return this.dialog.open(DeleteModelDialogComponent, {
			data:      {
				modelType,
				modelName,
				deleteCallback,
			},
			autoFocus: false,
		});
	}

	openBaseDialog(dialogConfig: MatDialogConfig<BaseDialogData> | BaseDialogData): MatDialogRef<BaseDialogComponent> {
		if('icon' in dialogConfig) {
			dialogConfig = {
				data: dialogConfig,
			};
		}

		// add default values
		dialogConfig.autoFocus ??= false;
		dialogConfig.disableClose ??= true;
		dialogConfig.minWidth ??= '66vw';
		dialogConfig.maxHeight ??= '90vh';

		return this.dialog.open(BaseDialogComponent, dialogConfig);
	}
}
