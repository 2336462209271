import {Level8Error} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
	OnInit,
} from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
} from '@angular/forms';
import {
	combineLatestSafe,
	SelectFieldEntry,
} from '@app/main';
import {
	ContractingPartyModel,
	ContractingPartyService,
	ContractModel,
	MasterContractModel,
} from '@contracts/frontend-api';
import {
	Observable,
	of,
} from 'rxjs';
import {
	filter,
	map,
	mergeMap,
} from 'rxjs/operators';

@Component({
	selector: 'portal-base-contract-edit-joined-parties',
	templateUrl: './base-contract-edit-joined-parties.component.html',
	styleUrls: ['./base-contract-edit-joined-parties.component.scss'],
})
export class BaseContractEditJoinedPartiesComponent implements OnInit {
	@Input({
		required: true,
		alias:    'control',
	}) parent!: UntypedFormGroup;
	@Input() model?: MasterContractModel | ContractModel; // todo #93
	@Input() parentModel?: MasterContractModel | ContractModel;
	entries?: ContractingPartyModel[];

	constructor(
		private readonly contractingPartyService: ContractingPartyService,
	) {
	}

	get inheritedValues(): Observable<ContractingPartyModel[]> | undefined {
		const getValues = () => {
			if(this.parentModel != null) {
				if(this.parentModel instanceof MasterContractModel)
					return this.parentModel.joinedParties.value;

				return this.parentModel.joinedParties.withParent.value;
			}

			if(this.model == null)
				return undefined;

			if(this.model instanceof MasterContractModel)
				return undefined;

			return this.model.joinedParties.inherited;
		};

		return getValues()?.pipe(
			map(entries => entries ?? []),
			map(entries => entries.map(entry => entry.contractingParty)),
		);
	}

	get options(): Observable<SelectFieldEntry[]> | undefined {
		if(this.entries == null)
			return this.entries;

		return of(this.entries).pipe(
				map(parties => parties.map(party => party.name.value.pipe(
						filter((name): name is string => typeof name === 'string'),
						map(name => ({
							label: name,
							value: party,
						})),
				))),
				mergeMap(entries$ => combineLatestSafe(entries$)),
		);
	}

	get control(): UntypedFormControl {
		const fieldName = 'joinedParties';
		const control   = this.parent.get(fieldName);
		if(control instanceof UntypedFormControl)
			return control;

		throw new Level8Error(`Unexpected type for field ${fieldName} - expected '${UntypedFormControl.name}' got '${typeof control}' (${control})`);
	}

	ngOnInit(): void {
		this.contractingPartyService.getAllModels().then(models$ => {
			of(models$).pipe(
				mergeMap(models => combineLatestSafe(models.map(model => model.name.value.pipe(map(name => ({
					name,
					model,
				})))))),
				map(entries => entries.sort((a, b) => (a.name ?? '') < (b.name ?? '') ? -1 : 1)),
				map(sortedEntries => sortedEntries.map(entry => entry.model)),
			).subscribe(entries => this.entries = entries);
		});
	}

	isInherited(entry: ContractingPartyModel): Observable<boolean> {
		if(this.model == null)
			return of(false);

		return this.model.joinedParties.value.pipe(
			map(joinedParties => joinedParties?.some((joinedParty => joinedParty.contractingParty === entry)) ?? false),
		);
	}
}
