import {
	Component,
	Input,
	OnDestroy,
} from '@angular/core';
import {
	FileType,
	IconService,
} from '@app/main';
import {FileModel} from '@contracts/frontend-api';

@Component({
	selector:    'portal-show-file',
	templateUrl: './show-file.component.html',
	styleUrls:   ['./show-file.component.scss'],
})
export class ShowFileComponent implements OnDestroy {
	@Input({required: true}) file?: FileModel | null;
	@Input() label: string | null = 'file.file';
	@Input() showValidityStartAt  = false;
	private objectUrl?: string;

	constructor(
		public iconService: IconService,
	) {
	}

	ngOnDestroy(): void {
		this.cleanObjectUrl();
	}

	showFile(): Promise<void> {
		return this.prepareFile(false);
	}

	downloadFile(): Promise<void> {
		return this.prepareFile(true);
	}

	protected async prepareFile(download: boolean): Promise<void> {
		this.cleanObjectUrl();

		const file = await this.createFile();
		if(!file)
			return;

		this.objectUrl = URL.createObjectURL(file);

		const link  = document.createElement('a');
		link.href   = this.objectUrl;
		link.target = '_blank';
		link.type   = 'hidden';
		if(download || !this.browserCanDisplayFile(file))
			link.download = file.name;

		link.click();
		link.remove();
	}

	protected browserCanDisplayFile(file: File): boolean {
		if(file.type !== FileType.pdf)
			return false;

		// eslint-disable-next-line compat/compat
		return navigator.pdfViewerEnabled === true;
	}

	private cleanObjectUrl(): void {
		if(this.objectUrl == null)
			return;

		URL.revokeObjectURL(this.objectUrl);
		this.objectUrl = undefined;
	}

	private async createFile(): Promise<File | null> {
		if(!this.file)
			return null;

		const content       = await this.file.content;
		const fileName      = (await this.file.name.firstValue) ?? 'unbekannt';
		const fileExtension = (await this.file.extension.firstValue) ?? '';
		return new File([content], `${fileName}.${fileExtension}`, {type: content.type});
	}
}
