import {
	Component,
	Input,
} from '@angular/core';
import {MedicalStoreModel} from '@contracts/frontend-api';

@Component({
	selector:    'portal-medical-store-show-mip',
	templateUrl: './medical-store-show-mip.component.html',
	styleUrls:   ['./medical-store-show-mip.component.scss'],
})
export class MedicalStoreShowMipComponent {
	@Input({required: true}) model?: MedicalStoreModel;
}
