@if (hasEntries) {
	<div class="grid-container">
		<div class="headers">
			<div class="name">{{ 'common.name' | translate }}</div>
			<div class="email">{{ 'common.email' | translate }}</div>
			<div class="permission">{{ 'medicalStoreUser.permission' | translate }}</div>
		</div>
		@if (users$) {
			<div class="contents">
				@for (user of (users$ | async);track user) {
					<div class="grid-row">
						<div class="name">{{ user.user.name.value | async }}</div>
						<div class="email">{{ user.user.email.value | async }}</div>
						<div class="canEdit">
							<fa-icon [icon]="user.canEdit ? iconService.EDIT : iconService.MENU_SHOW"></fa-icon>
						</div>
					</div>
				}
				@for (user of (parentUsers$ | async);track user) {
					<div class="grid-row">
						<div class="name">{{ user.user.name.value | async }}</div>
						<div class="email">{{ user.user.email.value | async }}</div>
						<div class="canEdit">
							<fa-icon [icon]="user.canEdit ? iconService.EDIT : iconService.MENU_SHOW"></fa-icon>
						</div>
					</div>
				}
			</div>
		}
	</div>
}
