import {Level8Error} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
} from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
} from '@angular/forms';

@Component({
	selector: 'portal-medical-store-edit-biv-number',
	templateUrl: './medical-store-edit-biv-number.component.html',
	styleUrls: ['./medical-store-edit-biv-number.component.scss'],
})
export class MedicalStoreEditBivNumberComponent {
	@Input({
		required: true,
		alias:    'control',
	}) parent!: UntypedFormGroup;

	get control(): UntypedFormControl {
		const fieldName = 'bivNumber';
		const control   = this.parent.get(fieldName);
		if(control instanceof UntypedFormControl)
			return control;

		throw new Level8Error(`Unexpected type for field ${fieldName} - expected '${UntypedFormControl.name}' got '${typeof control}' (${control})`);
	}
}
