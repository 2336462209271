@if (model) {
	<portal-headline
		label="model.contract"
		[menu]="menu"
		[backlink]="environment.masterContractsFullUrl + '/' +( model.masterContract.value | async)?.id"
	>
		<mat-menu #menu>
			@if (canCreateContracts) {
				<button mat-menu-item [matMenuTriggerFor]="menuCreate">
					<fa-icon matMenuItemIcon [icon]="iconService.CREATE"></fa-icon>
					<span>{{ 'general.menu.create' | translate }}</span>
				</button>
			}
			@if ((hasNoChildren$ | async) === true && (model.permissions.canDelete | async)) {
				<button mat-menu-item (click)="showDeleteDialog()">
					<fa-icon matMenuItemIcon [icon]="iconService.DELETE"></fa-icon>
					<span>{{ 'actions.delete' | translate }}</span>
				</button>
			}
		</mat-menu>
		<mat-menu #menuCreate>
			@if (canCreateContracts) {
				<a
					mat-menu-item
					[routerLink]="[environment.contractsFullUrl, model.id, 'create-contract-section']"
				>
					<fa-icon matMenuItemIcon [icon]="iconService.CONTRACT"></fa-icon>
					<span>{{ 'model.contractSection' | translate }}</span>
				</a>
			}
		</mat-menu>
	</portal-headline>
	<div class="flex-container">
		<portal-contract-card-master-data [contract]="model"/>
		<portal-contract-card-contracting [contract]="model"/>
		<portal-base-contract-requirements-card [contract]="model"/>
		<portal-base-contract-card-joined-parties [data]="model.joinedParties.withParent.value | async"/>
		<portal-base-contract-card-files [contract]="model"/>
		<portal-contract-section-card-list [contractSections]="model.contractSections.value | async"/>
	</div>
}
