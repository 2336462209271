@if (model) {
	<portal-headline
		label="model.preQualificationCertificate"
		[menu]="menu"
		[backlink]="[environment.institutionskennzeichenFullUrl, (model.institutionskennzeichen.value | async)?.id ?? '']"
	>
		<mat-menu #menu>
			@if (model.permissions.canDelete | async) {
				<button mat-menu-item (click)="showDeleteDialog()">
					<fa-icon matMenuItemIcon [icon]="iconService.DELETE"></fa-icon>
					<span>{{ 'actions.delete' | translate }}</span>
				</button>
			}
		</mat-menu>
	</portal-headline>
	<div class="flex-container">
		<portal-pre-qualification-certificate-card-master-data [preQualificationCertificate]="model"/>
		<portal-pre-qualification-certificate-card-owner [preQualificationCertificate]="model"/>
		<portal-pre-qualification-certificate-card-validity [preQualificationCertificate]="model"/>
	</div>
}
