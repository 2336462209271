import {DtoEditFormHelper} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
} from '@angular/core';
import {
	IconService,
	ModelHelper,
} from '@app/main';
import {
	ContractingPartyDtoModel,
	ContractingPartyModel,
	ContractingPartyService,
} from '@contracts/frontend-api';

@Component({
	selector:    'portal-contracting-party-card-master-data',
	templateUrl: './contracting-party-card-master-data.component.html',
	styleUrls:   ['./contracting-party-card-master-data.component.scss'],
})
export class ContractingPartyCardMasterDataComponent {
	formHelper!: DtoEditFormHelper<ContractingPartyModel, ContractingPartyService>;
	private _contractingParty!: ContractingPartyModel;
	public canUpdate?: Promise<boolean>;

	constructor(
		private readonly contractingPartyService: ContractingPartyService,
		public iconService: IconService,
	) {
	}

	get contractingParty(): ContractingPartyModel {
		return this._contractingParty;
	}

	@Input({required: true}) set contractingParty(value: ContractingPartyModel) {
		if(this.contractingParty === value)
			return;

		this._contractingParty = value;
		this.formHelper        = DtoEditFormHelper.create(
				ContractingPartyDtoModel,
				this.contractingParty,
				this.contractingPartyService,
		);

		this.canUpdate = ModelHelper.findEditableProperty([
			this.contractingParty.name,
			this.contractingParty.insuranceCount,
			this.contractingParty.contact,
		]);
	}
}
