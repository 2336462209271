import {
	Pipe,
	PipeTransform,
} from '@angular/core';
import {
	InstitutionskennzeichenModel,
	MedicalStoreModel,
} from '@contracts/frontend-api';
import {
	combineLatest,
	Observable,
	of,
} from 'rxjs';
import {
	map,
	mergeMap,
} from 'rxjs/operators';

@Pipe({
	name: 'storeIks',
})
export class StoreIksPipe implements PipeTransform {
	transform(store: MedicalStoreModel): Observable<string[]> {
		return store.institutionskennzeichens.value.pipe(
			mergeMap(iks => {
				const list: InstitutionskennzeichenModel[] = iks ?? [];
				const numbers = list.map(number => number.number.value.pipe());

				if(numbers.length<1)
					return of([])
				
				return combineLatest(numbers);
			}),
			map((numbers) => {
				const numberList: string[] = [];
				for(const number of numbers){
					if(number != null)
						numberList.push(number);
					
				}
				return numberList;
			}),
		);
	}

}
