<portal-table-client-side-searchable
	[columnLink]="columnLink"
	[data]="data"
	[headers]="tableHeaders"
	[searchFilter]="searchFilter"
>
	<ng-template column="joinedParties" let-model="model" [data]="data">
		<portal-skeleton-field [content]="getJoinedParties$(model)">
			<ng-template skeletonContent let-joinedParties>
				@for (joinedParty of joinedParties;track joinedParty) {
					<span class="line">{{ joinedParty }}</span>
				}
			</ng-template>
		</portal-skeleton-field>
	</ng-template>
	<ng-template column="billingDelay" let-model="model" [data]="data">
		{{ getRelativeDateValue(model, 'billingDelay') | async | TranslateRelativeDate: 'units' }}
	</ng-template>
	<ng-template column="joiningDelay" let-model="model" [data]="data">
		{{ getRelativeDateValue(model, 'joiningDelay') | async | TranslateRelativeDate: 'options' }}
	</ng-template>
	<ng-template column="maximumBackDatingDelay" let-model="model" [data]="data">
		{{ getRelativeDateValue(model, 'maximumBackDatingDelay') | async | TranslateRelativeDate: 'units' }}
	</ng-template>
	<ng-template column="noticePeriodDelay" let-model="model" [data]="data">
		{{ getRelativeDateValue(model, 'noticePeriodDelay') | async | TranslateRelativeDate: 'units' }}
	</ng-template>
	<ng-template column="noticePeriodNegotiationDelay" let-model="model" [data]="data">
		{{ getRelativeDateValue(model, 'noticePeriodNegotiationDelay') | async | TranslateRelativeDate: 'options' }}
	</ng-template>
	<ng-template column="legs" let-model="model" [data]="data">
		<portal-skeleton-field *ngLet="getLegs$(model) as numbers" [content]="numbers" loadingContent="12 34 567 | lorem ipsum dolorem">
			<portal-external-contract-number-token-list [numbers]="numbers | async" [type]="externatContractNumberType.legs"/>
		</portal-skeleton-field>
	</ng-template>
</portal-table-client-side-searchable>
