@if ((this.initialTerminatedAt$ | async) == null) {
	<portal-edit-field-date
		label="baseContract.terminatedAt"
		[earliestDate]="earliestDate | async"
		[validator]="control"
		[latestDate]="latestDateControl ?? (latestDateParent | async)"
		[placeholder]="value | async | date"
	>
	</portal-edit-field-date>
} @else {
	<p>{{ 'baseContract.terminatedAt' | translate }}</p>
	<mat-form-field>
		<input matInput [value]="(this.initialTerminatedAt$ | async | date) ?? ''" disabled/>
		<mat-datepicker #datepicker></mat-datepicker>
		<mat-datepicker-toggle matPrefix matSuffix [for]="datepicker"></mat-datepicker-toggle>
	</mat-form-field>
}
