<ng-container *ngLet="model | asArray as joinedParties">
	<portal-showable
		label="contractAccession.joinedParties"
		[value]="joinedParties">
		<div class="wrapper">
			@for (joinedParty of joinedParties;track joinedParty;let last = $last) {
				@if (joinedParty != null) {
					<portal-show-field-url
						[label]="null"
						[urlType]="urlType.internal"
						[url]="environment.contractingPartyFullUrl+'/'+joinedParty.contractingParty.id"
						[value]="joinedParty.contractingParty.name.value | async"
					/>
					@if (!last) {
						,
					}
				}
			}
		</div>
	</portal-showable>
</ng-container>
