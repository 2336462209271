import {ResultPageModel} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
} from '@angular/core';
import {environment} from '@app/environment';
import {
	MinimalColumns,
	SearchFilter,
} from '@app/main';
import {PreQualificationCertificateModel} from '@contracts/frontend-api';


@Component({
	selector:    'portal-pre-qualification-certificate-list',
	templateUrl: './pre-qualification-certificate-list.component.html',
	styleUrls:   ['./pre-qualification-certificate-list.component.scss'],
})
export class PreQualificationCertificateListComponent {
	@Input({required: true}) data: PreQualificationCertificateModel[] | ResultPageModel<PreQualificationCertificateModel> | undefined | null;
	environment                                                             = environment;
	readonly searchFilter = new CSearchFilter();
	readonly tableHeaders: MinimalColumns<PreQualificationCertificateModel> = {
		accreditationBody:       {
			isVisible: false,
			label:     'preQualificationCertificate.accreditationBody',
			index:     0,
		},
		certificateIdentifier:   {
			isVisible: false,
			label:     'preQualificationCertificate.certificateIdentifier',
			index:     1,
		},
		employee:                {
			label: 'model.employee',
			index: 2,
		},
		institutionskennzeichen: {
			label:         'model.institutionskennzeichen',
			index:         3,
			prepareSearch: (value) => {
				if(typeof value === 'string')
					return value.replace(/\s/g, '');
				return value;
			},
		},
		validityStartAt:         {
			isVisible: false,
			label:     'common.validityStartAt',
			index:     4,
		},
		validityEndAt:           {
			label: 'common.validityEndAt',
			index: 5,
		},
	};

}

class CSearchFilter extends SearchFilter<PreQualificationCertificateModel> {
	protected async getModelValue(field: string, model: PreQualificationCertificateModel): Promise<unknown> {
		switch(field) {
			case 'employee':
				const employee = await model.employee.firstValue;
				return employee?.name.firstValue;

			case 'institutionskennzeichen':
				const ik = await model.institutionskennzeichen.firstValue;
				return ik?.number.firstValue;

			default:
				return super.getModelValue(field, model);
		}
	}
}
