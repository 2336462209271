import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'section',
})
export class SectionPipe implements PipeTransform {

  transform(textContent: string, amount?: number): unknown {
    amount = typeof amount === 'number'?amount:10;
    const listed = this.htmlToText(textContent, amount);
    const text = listed.length >= amount ? `<div>${listed}...</div>`: listed;

    return text;
  }
  
  htmlToText(html: string, breakAfter?: number): string {
    const tempDivElement = document.createElement('div');

    tempDivElement.innerHTML = html;

    const fullText = tempDivElement.textContent ?? tempDivElement.innerText;

    return fullText.slice(0, breakAfter);
  }
}
