import {
	DtoEditFormHelper,
	Level8Error,
} from '@angular-helpers/frontend-api';
import {KeyValue} from '@angular/common';
import {
	Component,
	Input,
} from '@angular/core';
import {environment} from '@app/environment';
import {
	combineLatestSafe,
	IconService,
} from '@app/main';
import {
	IqzCategoryModel,
	IqzCategoryService,
	IqzParticipationModel,
	MedicalStoreDtoModel,
	MedicalStoreModel,
	MedicalStoreService,
} from '@contracts/frontend-api';
import moment from 'moment';
import {
	combineLatest,
	Observable,
	of,
} from 'rxjs';
import {
	map,
	mergeMap,
	shareReplay,
} from 'rxjs/operators';

type Entry = {
	points: number;
	participations: IqzParticipationModel[];
}

@Component({
	selector:    'portal-medical-store-iqz-participations-card',
	templateUrl: './medical-store-participations-card.component.html',
	styleUrls:   ['./medical-store-participations-card.component.scss'],
})
export class MedicalStoreParticipationsCardComponent {
	protected readonly REQUIRED_POINTS = environment.IQZ_REQUIRED_POINTS_PER_CATEGORY;
	protected iqzPoints!: Observable<ReadonlyMap<IqzCategoryModel, Entry> | undefined>;
	protected certifiedTill!: Observable<Date | undefined>;
	protected formHelper!: DtoEditFormHelper<MedicalStoreModel, MedicalStoreService>;
	private readonly iqzCategoryBasic: IqzCategoryModel;
	private _medicalStore!: MedicalStoreModel;

	constructor(
		protected readonly iconService: IconService,
		protected readonly iqzCategoryService: IqzCategoryService,
		protected readonly medicalStoreService: MedicalStoreService,
	) {
		this.iqzCategoryBasic = iqzCategoryService.getById(environment.iqzCategoryBasicId);
	}

	get medicalStore(): MedicalStoreModel {
		return this._medicalStore;
	}

	@Input({required: true})
	set medicalStore(medicalStore: MedicalStoreModel) {
		this._medicalStore = medicalStore;
		this.formHelper = DtoEditFormHelper.create(
			MedicalStoreDtoModel,
			this.medicalStore,
			this.medicalStoreService,
		);

		const latestValidCertificateDate = moment().subtract(environment.IQZ_POINTS_VALID_PERIOD_YEARS, 'years').add(1, 'day').toDate();
		const participations$ = medicalStore.iqzParticipations.value.pipe(
			mergeMap(participations => combineLatestSafe(
				participations?.map(participation =>
					combineLatest([
						of(participation),
						participation.iqzCategory.value,
						participation.iqzTrainingCourse.value,
					]),
				),
			)),
			mergeMap((participations) => combineLatestSafe(
				participations?.map(([participation, category, trainingCourse]) =>
					// workaround for max 6 entries in combineLatest (see https://stackoverflow.com/questions/57188016/combinelatest-throws-ts2349-with-6-or-more-streams)
					combineLatest([
						combineLatest([
							of(participation),
							of(category),
							trainingCourse?.iqzTrainingCoursePoints.value ?? of(undefined),
							trainingCourse?.iqzTrainingCoursePointsExam.value ?? of(undefined),
							trainingCourse?.iqzWorkshopPoints.value ?? of(undefined),
							trainingCourse?.startAt.value ?? of(undefined),
						]),
						combineLatest([
							trainingCourse?.endAt.value.pipe(map(endAt => (endAt == null) ? endAt : (endAt < latestValidCertificateDate))) ?? of(undefined),
						]),
					]).pipe(
						map(
							([
								 [
									 participation,
									 category,
									 coursePoints,
									 examPoints,
									 workshopPoints,
									 startAt,
								 ], [
									isOutdated,
								],
							 ]) => ({
								participation,
								category,
								coursePoints,
								examPoints,
								workshopPoints,
								startAt,
								isOutdated,
							})),
					),
				),
			)),
		);

		this.iqzPoints = participations$.pipe(
			map(participations => {
				if(participations == null)
					return participations;

				const results = new Map<IqzCategoryModel, Entry>();
				for(const participation of participations) {
					if(participation.category == null)
						continue;

					let valueOld = results.get(participation.category);
					if(valueOld == null) {
						valueOld = {
							points: 0,
							participations: [],
						};
					}

					valueOld.participations.push(participation.participation);
					if(participation.isOutdated !== true)
						valueOld.points += this.sumPoints(participation.coursePoints, participation.examPoints, participation.workshopPoints);

					results.set(participation.category, valueOld);
				}

				return results;
			}),
			mergeMap(points => combineLatest([
				medicalStore.iqzCategories.value,
				of(points),
			])),
			map(([categories, points]) => {
				if(points == null || categories == null)
					return points;

				categories = [...categories];

				// keep "allgemeine Fortbildung"
				categories.push(this.iqzCategoryBasic);

				const pointsKeys = Array.from(points.keys());

				// remove too much points
				for(const key of pointsKeys) {
					if(categories.includes(key))
						continue;

					points.delete(key);
				}

				for(const category of categories) {
					if(pointsKeys.some(key => key === category))
						continue;

					points.set(
						category,
						{
							points: 0,
							participations: [],
						},
					);
				}


				return points;
			}),
			shareReplay(1),
			// make deep copy to prevent changing real data
			map(original => {
				if(original == null)
					return original;

				const copy = new Map();
				for(const [key, value] of original.entries())
					copy.set(key, {...value});

				return copy;
			}),
		);

		this.certifiedTill = participations$.pipe(
			map(p => p?.sort((a, b) => {
				const startA = a.startAt;
				const startB = b.startAt;

				if(startA === startB)
					return 0;

				if(startA == null)
					return -1;

				if(startB == null)
					return 1;

				return startA > startB ? 1 : -1;
			})),
			mergeMap(p => combineLatest([
				of(p),
				this.iqzPoints,
			])),
			mergeMap(([participations, points]) => {
				if(participations == null || points == null || this.isCertified(points) === false)
					return of(undefined);

				points = new Map(points);

				for(const participation of participations) {
					if(participation.isOutdated ?? false)
						continue;

					const course = participation.category;
					if(course == null)
						continue;

					const entry = points.get(course);
					if(entry == null)
						continue;

					entry.points -= this.sumPoints(participation.workshopPoints, participation.coursePoints, participation.examPoints);
					if(entry.points < this.REQUIRED_POINTS)
						return participation.participation.iqzTrainingCourse.value;
				}

				throw new Level8Error('Could not detect certificated end date');
			}),
			mergeMap(course => course?.endAt.value ?? of(undefined)),
			map(end => {
				if(end == null)
					return end;

				return moment(end)
					.add(environment.IQZ_POINTS_VALID_PERIOD_YEARS, 'years')
					.subtract(1, 'day')
					.toDate();
			}),
			shareReplay(1),
		);
	}

	protected isCertified(points: ReadonlyMap<IqzCategoryModel, Entry>): boolean {
		const entries                        = Array.from(points.values());
		const hasEntryWithInsufficientPoints = entries.some(entry => entry.points < this.REQUIRED_POINTS);
		return !hasEntryWithInsufficientPoints;
	}

	protected sumPoints(...values: (number | undefined)[]): number {
		return values.reduce((last: number, curr) => last + (curr ?? 0), 0);
	}

	protected sort(a: KeyValue<IqzCategoryModel, Entry>, b: KeyValue<IqzCategoryModel, Entry>): -1 | 0 | 1 {
		const l = a.key.name.currentValue;
		const r = b.key.name.currentValue;

		if(l === r)
			return 0;

		if(l == null)
			return -1;

		if(r == null)
			return 1;

		const result = l.localeCompare(r);
		switch(result) {
			case 0:
			case 1:
			case -1:
				return result;

			default:
				throw new Level8Error('Unexpected result');
		}
	}
}