import {
	Component,
	DestroyRef,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {IconService} from '@app/main';
import {
	MedicalStoreModel,
	MedicalStoreUserPermissionInterface,
} from '@contracts/frontend-api';
import {
	combineLatest,
	Observable,
	of,
} from 'rxjs';
import {
	map,
	mergeMap,
} from 'rxjs/operators';

@Component({
	selector:    'portal-medical-store-users-show',
	templateUrl: './medical-store-user-show.component.html',
	styleUrls:   ['./medical-store-user-show.component.scss'],
})
export class MedicalStoreUserShowComponent implements OnChanges, OnInit {
	@Input({required: true}) medicalStore!: MedicalStoreModel;
	public users$?: Observable<MedicalStoreUserPermissionInterface[] | undefined>;
	public hasEntries = false;
	public parentUsers$?: Observable<MedicalStoreUserPermissionInterface[] | undefined>;
	public allUsers$?: Observable<MedicalStoreUserPermissionInterface[] | undefined>;

	constructor(
			protected readonly iconService: IconService,
			protected readonly destroyRef: DestroyRef,
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		this.setupUserList();
	}

	private setupUserList() {
		this.users$ = this.medicalStore.users.value;
		this.parentUsers$ = this.medicalStore.parent.value.pipe(
			mergeMap((parent) => {
				if (parent != null)
					return parent.users.value;

				return of([]);
			}),
		);

		this.allUsers$ = this.users$.pipe(
				takeUntilDestroyed(this.destroyRef),
				mergeMap((users) => {
					if(this.parentUsers$ == null)
						throw new Error('interface has changed and code is putdated');


					return combineLatest([
						of(users),
						this.parentUsers$,
					]);
				}),
			map(([users, parentUsers]) => {

				const allUsers: MedicalStoreUserPermissionInterface[] = [];
				if (users != null)
					users.forEach((user) => allUsers.push(user));

				if (parentUsers != null)
					parentUsers.forEach((user) => allUsers.push(user));


				return allUsers;
			}),
		);
	}

	ngOnInit(): void {
		this.setupUserList();
		this.allUsers$?.subscribe((users) => {
			if(users != null&& users.length > 0)
				this.hasEntries = true;

		});
	}
}
