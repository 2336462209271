import {
	Component,
	Input,
} from '@angular/core';
import {IconDefinition} from '@fortawesome/fontawesome-common-types/';

@Component({
	selector:    'portal-show-field',
	templateUrl: './show-field.component.html',
	styleUrls:   ['./show-field.component.scss'],
})
export class ShowFieldComponent {
	@Input({required: true}) label!: string;
	@Input({required: true}) value: undefined | null | string | string[] | number | number[];
	@Input() icon?: IconDefinition;
	@Input() showValueAlways = false;
	@Input() sortable        = true;
	@Input() description?: string;

	get valueString(): string | number | undefined {
		if(this.value == null)
			return undefined;

		if(Array.isArray(this.value)) {
			let values = this.value;
			if(this.sortable)
				values = values.sort();
			return values.join(', ');
		}

		return this.value;
	}

	get isHidden(): boolean {
		if(this.value == null)
			return true;

		if(Array.isArray(this.value))
			return this.value.length === 0;

		return false;
	}
}
