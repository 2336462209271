<portal-showable
	*ngLet="model.recipients.value | async as recipients"
	label="export.recipients"
	[hidden]="recipients === undefined"
>
	<div class="wrapper">
		@for (recipient of recipients;track recipient) {
			<portal-copyable>
				<a class="invisible-link" [href]="'mailto:'+recipient">{{ recipient }}</a>
			</portal-copyable>
		}
	</div>
</portal-showable>
