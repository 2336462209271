@if (this.data.dialogTypeDelete) {
	<div mat-dialog-title class="dialog-title logo">
		<fa-icon class="dialog-icon" [icon]="iconService.RESCIND"></fa-icon>
		<div class="title-text">{{ 'contractAccession.rescindTitle' | translate }}</div>
	</div>
	<div mat-dialog-content class="scroll-shadows dialog-content">
		<div class="dialog-content" [class.grid-2]="true">
			<portal-institutionskennzeichen-show
				label="model.institutionskennzeichen"
				[model]="this.institutionsKennzeichen$ | async"
			/>
			<portal-contract-section-show
				[model]="this.contractSection$ | async"
			/>
		</div>
	</div>
	<div mat-dialog-actions class="actionbar">
		<div class="actions">
			@if (!isLoading) {
				<button mat-stroked-button (click)="delete()" color="warn">
					{{ 'contractAccession.actions.rescind' | translate }}
				</button>
			} @else {
				<button mat-stroked-button [disabled]="isLoading">
					<fa-icon [icon]="iconService.LOADING_SPINNER" [spin]="true"></fa-icon>
				</button>
			}
			<button mat-raised-button (click)="decline()" color="primary" class="right">{{ 'actions.abort' | translate }}</button>
		</div>
	</div>
} @else {
	<div mat-dialog-title class="dialog-title logo">
		<fa-icon class="dialog-icon" [icon]="iconService.RESIGN"></fa-icon>
		<div class="title-text">{{ 'contractAccession.resignTitle' | translate }}</div>
	</div>
	<div mat-dialog-content class="scroll-shadows dialog-content">
		<div class="dialog-content" [class.grid-2]="true">
			<portal-institutionskennzeichen-show
				label="model.institutionskennzeichen"
				[model]="this.institutionsKennzeichen$ | async"
			/>
			<portal-contract-section-show
				[model]="this.contractSection$ | async"
			/>
			<portal-edit-field-date
				label="contractAccession.resignDate"
				[validator]="accessionEnd"
				[earliestDate]="minResignDate$ | async"
			/>
		</div>
	</div>
	<div mat-dialog-actions class="actionbar">
		<div class="actions">
			@if (!isLoading) {
				<button mat-stroked-button (click)="accept()" color="warn">
					{{ 'contractAccession.actions.resign' | translate }}
				</button>
			}
			<button mat-raised-button (click)="decline()" color="primary" class="right">{{ 'actions.abort' | translate }}</button>
		</div>
	</div>
}
