import {
	Injectable,
	OnDestroy,
} from '@angular/core';
import {ReplaySubject} from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ConfigService implements OnDestroy {
	protected static readonly LOCAL_STORAGE_PREFIX = 'config_';
	private readonly developerMode$                = new ReplaySubject<boolean>(1);
	readonly developerMode                         = this.developerMode$.asObservable();

	constructor() {
		const developerMode = this.loadConfig('developerMode');
		this.developerMode$.next((typeof developerMode === 'boolean') ? developerMode : false);
		window.addEventListener('storage', this.handleStorageEvent.bind(this));
	}

	ngOnDestroy(): void {
		window.removeEventListener('storage', this.handleStorageEvent.bind(this));
	}

	setDeveloperMode(developerMode: boolean): void {
		this.developerMode$.next(developerMode);
		this.saveConfig('developerMode', developerMode);
	}

	protected loadConfig(key: string): unknown {
		const value = window.localStorage.getItem(ConfigService.LOCAL_STORAGE_PREFIX + key);
		if(value == null)
			return value;

		try {
			return JSON.parse(value);
		} catch(e) {
			return undefined;
		}
	}

	protected saveConfig(key: string, value: unknown): void {
		const lsKey = ConfigService.LOCAL_STORAGE_PREFIX + key;

		if(value === undefined)
		localStorage.removeItem(lsKey);
		else
		localStorage.setItem(lsKey, JSON.stringify(value));
	}


	protected handleStorageEvent(event: StorageEvent): void {
		if(event.oldValue === event.newValue)
			return;

		switch(event.key) {
			case `${ConfigService.LOCAL_STORAGE_PREFIX}developerMode`:
				const developerMode = this.loadConfig('developerMode');
				this.developerMode$.next((typeof developerMode === 'boolean') ? developerMode : false);
				break;

			default:
				break;
		}
	}
}
