<portal-headline label="general.modelList" [labelArgs]="{model: 'medicalStoreUsers'}" [menu]="menu">
	<mat-menu #menu>
		@if (canCreate === true) {
			<a mat-menu-item [routerLink]="[environment.medicalStoreUsersFullUrl, 'create']">
				<fa-icon matMenuItemIcon [icon]="iconService.CREATE"></fa-icon>
				<span>{{ 'medicalStoreUser.create' | translate }}</span>
			</a>
		}
	</mat-menu>
</portal-headline>
<mat-card>
	<mat-card-content>
		<portal-table-server-side-searchable
			[headers]="userTableHeaders"
			[columnLink]="environment.medicalStoreUsersFullUrl"
			[service]="medicalStoreUserService"
			[withMenu]="false"
		/>
	</mat-card-content>
</mat-card>
