import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {environment} from '@app/environment';
import * as sentry from '@sentry/angular';

import {AppModule} from './app/app.module';

if(environment.isOnServer) {
	sentry.init({
		dsn:                      'https://49bbf0b417a1a486e48dc418aa746f1e@sentry.biv.to/2',
		integrations:             [
			sentry.browserTracingIntegration(),
			sentry.replayIntegration(),
		],
		tracesSampleRate:         0, //  Capture 100% of the transactions
		tracePropagationTargets:  [],
		replaysSessionSampleRate: 0,
		replaysOnErrorSampleRate: 1,
	});
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.error(err));
