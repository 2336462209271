import {
	Component,
	Input,
} from '@angular/core';
import {IconService} from '@app/main';
import {MasterContractModel} from '@contracts/frontend-api';

@Component({
	selector:    'portal-master-contract-card-list',
	templateUrl: './master-contract-card-list.component.html',
	styleUrls:   ['./master-contract-card-list.component.scss'],
})
export class MasterContractCardListComponent {
	@Input({required: true}) masterContracts?: MasterContractModel[] | null;
	@Input() label = 'model.masterContracts';

	constructor(
		public iconService: IconService,
	) {
	}
}
