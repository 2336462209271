import {ResultPageModel} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
} from '@angular/core';
import {environment} from '@app/environment';
import {MinimalColumns} from '@app/main';
import {ContractingPartyModel} from '@contracts/frontend-api';

@Component({
	selector:    'portal-contracting-party-list',
	templateUrl: './contracting-party-list.component.html',
	styleUrls:   ['./contracting-party-list.component.scss'],
})
export class ContractingPartyListComponent {
	@Input({required: true}) data: ContractingPartyModel[] | ResultPageModel<ContractingPartyModel> | undefined | null;
	environment                                                  = environment;
	readonly tableHeaders: MinimalColumns<ContractingPartyModel> = {
		name:           {
			label: 'contractingParty.name',
			index: 0,
		},
		insuranceCount: {
			isVisible: false,
			label:     'contractingParty.insuranceCount',
			index:     1,
		},
		// don't show this field since it's not visible to medical store users
		// contact: {
		// 	isVisible: false,
		// 	label:     'common.contact',
		// },
	};
}
