import {KeyValue} from '@angular/common';
import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
} from '@angular/core';
import {
	FormControl,
	UntypedFormControl,
} from '@angular/forms';
import {MatSelectChange} from '@angular/material/select';
import {
	EditableComponent,
	FormHelperService,
	IconService,
} from '@app/main';
import {TranslateService} from '@ngx-translate/core';
import {
	combineLatest,
	Observable,
	ReplaySubject,
} from 'rxjs';
import {
	map,
	startWith,
} from 'rxjs/operators';

export interface ValueSelectFieldEntry {
	value: string;
	filter?: ((searchValue: string) => boolean);
}

export interface LabelSelectFieldEntry {
	label: string;
	value: unknown;
	filter?: ((searchValue: string) => boolean);
}

export type SelectFieldEntry = ValueSelectFieldEntry | LabelSelectFieldEntry;

@Component({
	selector:    'portal-edit-field-select',
	templateUrl: './edit-field-select.component.html',
	styleUrls:   ['./edit-field-select.component.scss'],
})
export class EditFieldSelectComponent implements OnInit, OnChanges {
	@Input({required: true}) label: EditableComponent['label'];
	@Input({required: true}) control?: UntypedFormControl;
	@Input() initialEntry?: SelectFieldEntry;
	@Input() nullable = true;
	@Input() inherited?: SelectFieldEntry | null;
	@Input({required: true}) options?: SelectFieldEntry[] | null;
	@Input() filter   = true;
	@Output() change  = new EventEmitter<MatSelectChange>();

	protected readonly filterControl = new FormControl('');
	protected readonly options$      = new ReplaySubject<SelectFieldEntry[] | null>(1);
	protected readonly filteredOptions$: Observable<ReadonlyMap<unknown, string> | null>;

	ngOnChanges(): void {
		if(this.options)
			this.options$.next(this.options);
	}

	constructor(
		protected readonly formHelperService: FormHelperService,
		protected readonly translateService: TranslateService,
		protected readonly iconService: IconService,
	) {
		this.filteredOptions$ = combineLatest([
			this.filterControl.valueChanges.pipe(
				startWith(null),
				map(start => {
					if(start != null)
						return start;

					return this.filterControl.value ?? '';
				}),
			),
			this.options$,
		]).pipe(
			map(([input, options]) => {
				if(options == null)
					return null;

				if(!this.filter) {
					return new Map(options.map(option => {
						if('label' in option) {
							return [
								option.value,
								option.label,
							];
						}
						return [
							option.value,
							option.value,
						];
					}));
				}

				const defaultFilter = (value: string, entry: SelectFieldEntry) => {
					const compare = ('label' in entry) ? entry.label.toLowerCase() : entry.value;
					return compare.toLowerCase().includes(value.toLowerCase());
				};

				const inputCompare                         = (typeof input === 'string' ? input : '');
				const filteredOptions: [unknown, string][] = options
					.filter(option => option.filter?.(inputCompare) ?? defaultFilter(inputCompare, option))
					.map(option => {
						if('label' in option) {
							return [
								option.value,
								option.label,
							];
						}
						return [
							option.value,
							option.value,
						];
					});

				return new Map(filteredOptions);
			}),
		);
	}

	ngOnInit(): void {
		if(this.options)
			this.options$.next(this.options);
	}

	protected trackOptions(index: number, item: SelectFieldEntry): unknown {
		return item.value;
	}

	get placeholder(): string | undefined {
		if(this.inherited != null && ('label' in this.inherited))
			return this.inherited.label;

		if(this.control == null)
			return this.control;

		if(this.control.value === null)
			return this.translateService.instant('general.nullOption');

		return undefined;
	}

	get isEmpty(): boolean {
		return (this.options?.length ?? 0) < 1;
	}

	sortByValue(a: KeyValue<unknown, string>, b: KeyValue<unknown, string>): number {
		return a.value.localeCompare(b.value);
	}

	protected get initialEntryData(): LabelSelectFieldEntry | undefined {
		if(this.initialEntry == null)
			return this.initialEntry;

		if('label' in this.initialEntry)
			return this.initialEntry;

		return {
			label:  this.initialEntry.value,
			value:  this.initialEntry,
			filter: this.initialEntry.filter,
		};
	}

	public onSelected(event: MatSelectChange): void {
		this.change.emit(event);
	}
}
