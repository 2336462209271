import {
	Component,
	Input,
} from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
} from '@angular/forms';
import {
	FormHelperService,
	IconService,
} from '@app/main';

@Component({
	selector:    'portal-address-create',
	templateUrl: './address-create.component.html',
	styleUrls:   ['./address-create.component.scss'],
})
export class AddressCreateComponent {
	@Input({required: true}) control!: UntypedFormGroup;

	constructor(public iconService: IconService, public formHelperService: FormHelperService) {}

	get nameControl(): UntypedFormControl {
		return this.control.get('name') as UntypedFormControl;
	}

	get streetControl(): UntypedFormControl {
		return this.control.get('street') as UntypedFormControl;
	}

	get houseNumberControl(): UntypedFormControl {
		return this.control.get('houseNumber') as UntypedFormControl;
	}

	get zipControl(): UntypedFormControl {
		return this.control.get('zip') as UntypedFormControl;
	}

	get townControl(): UntypedFormControl {
		return this.control.get('town') as UntypedFormControl;
	}
}
