@if (isVisible()) {
	@if (label) {
		<label>
			@if (icon) {
				<fa-icon [icon]="icon"></fa-icon>
			}
			{{ label | translate }}
			@if (description) {
				<portal-info [description]="description"/>
			}
		</label>
	}
	<ng-content></ng-content>
}
