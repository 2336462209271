import {
	Component,
	ContentChildren,
	Input,
	QueryList,
	TemplateRef,
} from '@angular/core';
import {LoadingLazyDirective} from './loading-lazy.directive';

@Component({
	selector:    'portal-loading',
	templateUrl: './loading.component.html',
	styleUrls:   ['./loading.component.scss'],
})
export class LoadingComponent {
	@Input() loading = true;
	@Input() content?: TemplateRef<unknown>;
	@ContentChildren(LoadingLazyDirective) columnContentTemplates?: QueryList<LoadingLazyDirective>;
}
