import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlSerializer, UrlTree } from '@angular/router';
import {environment} from '@app/environment';
import {NewsService} from '@contracts/frontend-api';
import {
	Observable,
	of,
	Subject,
} from 'rxjs';
import {
	catchError,
	map,
	sampleTime,
	tap,
} from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class MaintenanceGuard  {
	readonly canActivate$: Observable<boolean | UrlTree>;
	readonly CACHE_TIMER = 30_000;
	readonly maintenanceActive$: Observable<boolean>;

	constructor(
		protected urlSerializer: UrlSerializer,
		protected newsService: NewsService,
		protected http: HttpClient,
	) {

		const cacheClearer$ = new Subject<void>();
		cacheClearer$.pipe(
			sampleTime(this.CACHE_TIMER),
			tap(() => this.newsService.getById(environment.maintenanceNewsId).title.reload()),
		).subscribe();

		this.maintenanceActive$ = this.newsService.getById(environment.maintenanceNewsId).title.value.pipe(
			map((createdAt) => createdAt != null),
			catchError(() => of(false)),
		);

		this.canActivate$ = this.maintenanceActive$.pipe(
				map((maintenanceActive) => {
					if(maintenanceActive)
						return this.urlSerializer.parse(environment.maintenanceUrlFullUrl);

					return true;
				}),
				tap(() => cacheClearer$.next()),
		);
	}


	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.canActivate$;
	}

	canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.canActivate(childRoute, state);
	}
}

