import {PropertyInterface} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
} from '@angular/core';
import {UrlType} from '@app/main';

interface FaxInterface {
	fax: PropertyInterface<string | null | undefined>;
}

@Component({
	selector:    'portal-common-show-fax',
	templateUrl: './common-show-fax.component.html',
	styleUrls:   ['./common-show-fax.component.scss'],
})
export class CommonShowFaxComponent<T extends FaxInterface> {
	@Input({required: true}) model!: T;
	urlType = UrlType;
}
