<p></p> <!-- info feld element weil sonst die complette form struktur kaputt ist -->
<span>
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ ('pseudoVersorgungsbereich.create' | translate) }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <span class="create-new-pseudo-vb">
        <form (submit)="onPsVbAdd($event)" [formGroup]="newPsVbForm" class="psvb-create grid-container">
          <mat-label class="gird-label1">{{ 'model.versorgungsbereich' | translate }}</mat-label>
          <mat-form-field class="grid-field2">
            @if (getMainVbPrefix().length > 0) {
	            <span matPrefix>{{ getMainVbPrefix() + '/' }}</span>
            }
	          <input formControlName="psVbName" matInput type="text"/>
	          @if (this.newPsVbForm.controls.psVbName.hasError('required')) {
		          <mat-error>{{ 'input.error.required' | translate }}</mat-error>
	          }
          </mat-form-field>
          <mat-label class="grid-label3">{{ 'pseudoVersorgungsbereich.description' | translate }}</mat-label>
          <mat-form-field class="grid-field3"><input formControlName="psVbDescription" matInput type="text"/>
	          @if (this.newPsVbForm.controls.psVbDescription.hasError('required')) {
		          <mat-error>{{ 'input.error.required' | translate }}</mat-error>
	          }
          </mat-form-field>
          <mat-form-field [floatLabel]="'auto'" class="chip-list">
            <mat-label>{{ 'pseudoVersorgungsbereich.hmvSearch' | translate }}</mat-label>
            <mat-chip-grid #hmvchiplist class="chip-list">
              @if (fieldControl && fieldControl.value) {
	              @for (hmv of this.selectedList;track hmv) {
		              <mat-chip-row
			              (removed)="removeChip(hmv)"
		              >
                    {{ (hmv.hmvNumber.value | async) + ' (' + (hmv.name.value | async) + ')' }}
			              <button matChipRemove>
                      <fa-icon [icon]="iconService.CANCEL"></fa-icon>
                    </button>
                  </mat-chip-row>
	              }
              }
	            <input
		            #psChipInput
		            [formControl]="psChipListInputControl"
                [matAutocomplete]="auto"
		            [matChipInputFor]="hmvchiplist"
		            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
		            [pattern]="HMV_ENTRY_REGEX"
		            [placeholder]="'pseudoVersorgungsbereich.hmvSearch' | translate"
		            matInput
	            />
            </mat-chip-grid>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedHmvNumber($event)">
              @if (hmvAutoCheckList == null) {
	              <mat-option value="" disabled>
                  <div class="loading">
                    <mat-spinner class="spinner" diameter="20"></mat-spinner>
                    <span>{{ 'general.loading' | translate }}</span>
                  </div>
                </mat-option>
              }
	            @for (hmv of hmvAutoCheckList;track hmv) {
		            <mat-option [value]="hmv">
                  @if (hmv.hmvModel != null) {
	                  {{ (hmv.hmvModel.hmvNumber.value | async) + ' (' + (hmv.hmvModel.name.value | async) + ')' }}
                  } @else {
	                  {{ hmv.message | translate }}
                  }
                </mat-option>
	            }
            </mat-autocomplete>

          </mat-form-field>
	        @if ((this.getMainVbPrefix().length > 0)) {
		        <portal-spinning-button class="save-button" [processing]="isSaving" (click)="onPsVbAdd($event)" [disabled]="!this.canCreate || this.isSaving || (this.fieldControl != null && this.fieldControl.disabled) || (this.selectedList.length < 1)"> {{ 'pseudoVersorgungsbereich.save' | translate }} </portal-spinning-button>
	        } @else {
		        {{ 'pseudoVersorgungsbereich.noVbSelected' | translate }}
	        }
        </form>
      </span>
    </mat-expansion-panel>
  </mat-accordion>
</span>
