import {
	Component,
	Input,
} from '@angular/core';
import {EmployeeModel} from '@contracts/frontend-api';

@Component({
	selector:    'portal-employee-show-roles',
	templateUrl: './employee-show-roles.component.html',
	styleUrls:   ['./employee-show-roles.component.scss'],
})
export class EmployeeShowRolesComponent {
	@Input({required: true}) model!: EmployeeModel;
}
