<portal-editable
	[validator]="validator"
	[icon]="icon"
	[label]="label"
	[showAlways]="showAlways"
>
	<mat-form-field>
		<portal-input-status-icon matSuffix [validator]="validator"/>
		<input type="number" [placeholder]="placeholder ?? ''" (change)="change.emit($event)" matInput [formControl]="validator"/>
		@if (suffix) {
			<span matSuffix>{{ suffix }}</span>
		}
		@for (error of formHelperService.getErrors(validator) | keyvalue;track error) {
			<mat-error>
				{{ 'input.error.' + error.key | translate: error.value }}
			</mat-error>
		}
	</mat-form-field>
</portal-editable>
