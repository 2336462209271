import {Level8Error} from '@angular-helpers/frontend-api';
import {
	Pipe,
	PipeTransform,
} from '@angular/core';
import {RelativeDateService} from '../services/relative-date.service';

export type ListType = 'units' | 'options';
export type TranslationDirection = 'descToValue' | 'valueToDesc';

@Pipe({
	name: 'TranslateRelativeDate',
	pure: false,
})
export class TranslateRelativeDatePipe implements PipeTransform {
	constructor(
		public relativeDateService: RelativeDateService,
	) {
		this.relativeDateService = relativeDateService;
	}

	transform(value: null, listTyp?: ListType, translationDirection?: TranslationDirection): null;
	transform(value: undefined, listTyp?: ListType, translationDirection?: TranslationDirection): undefined;
	transform(value: string, listTyp?: ListType, translationDirection?: TranslationDirection): string;
	transform(value: string | null | undefined, listTyp?: ListType, translationDirection?: TranslationDirection): string | null | undefined;
	transform(value: string | null | undefined, listTyp: ListType = 'options', translationDirection: TranslationDirection = 'valueToDesc'): string | null | undefined {
		if(value == null)
			return null;

		let valueTranslation = null;

		switch(listTyp) {
			case 'units':
				const portalDate = this.relativeDateService.valueToDateObject(value);
				if(portalDate == null)
					break;
				switch(translationDirection) {
					case 'descToValue':
						valueTranslation = this.relativeDateService.dateObjectToValue(portalDate);
						break;
					
					case 'valueToDesc':
						valueTranslation = this.relativeDateService.dateObjectToTranslatedDescription(portalDate);
						break;
					
					default:
						throw new Level8Error(`Unknown TranslationDirection '${translationDirection}'`);
				}
				break;

			case 'options':
				switch(translationDirection) {
					case 'descToValue':
						valueTranslation = this.relativeDateService.optionDescriptionToValue(value);
						break;

					case 'valueToDesc':
						valueTranslation = this.relativeDateService.optionValueToDescription(value);
						break;

					default:
						throw new Level8Error(`Unknown TranslationDirection '${translationDirection}'`);
				}
				break;

			default:
				throw new Level8Error(`Unknown ListType '${listTyp}'`);
		}

		return valueTranslation;
	}
}
