/* eslint-disable @typescript-eslint/member-ordering */
import {Injectable} from '@angular/core';
import {
	faClock,
	faCopy,
	faEnvelope,
	faEye,
	faEyeSlash,
	faFilePdf,
	faHandshake,
	faMoon,
	faQuestionCircle,
	faSquare,
	faSquareCheck,
	faSun,
	faUser,
} from '@fortawesome/free-regular-svg-icons';
import {
	faAngleDown,
	faAngleLeft,
	faAngleRight,
	faAsterisk,
	faBook,
	faBoxOpen,
	faBug,
	faCalendarAlt,
	faCertificate,
	faCheck,
	faCircleHalfStroke,
	faCloudUploadAlt,
	faColumns,
	faComment,
	faDiamond,
	faDownload,
	faEllipsisV,
	faEraser,
	faExclamation,
	faExclamationTriangle,
	faFax,
	faFile,
	faFileContract,
	faFileInvoiceDollar,
	faFolderOpen,
	faGlobe,
	faHandshakeSlash,
	faHome,
	faHouseUser,
	faInfoCircle,
	faMapMarked,
	faMapMarkedAlt,
	faMapMarkerAlt,
	faNewspaper,
	faPen,
	faPencilAlt,
	faPhoneAlt,
	faPlus,
	faReceipt,
	faSearch,
	faSignInAlt,
	faSignOutAlt,
	faSpinner,
	faStamp,
	faSync,
	faTimes,
	faTimesCircle,
	faTrash,
	faTrashRestore,
} from '@fortawesome/free-solid-svg-icons';

@Injectable({
	providedIn: 'root',
})
export class IconService {
	static readonly SEARCH                        = faSearch;
	static readonly SYNC                          = faSync;
	static readonly ICON_ALERT                    = faExclamationTriangle;
	static readonly MASTER_CONTRACT               = faFileContract;
	static readonly CONTRACT                      = faFileContract;
	static readonly CONTRACT_SECTION              = faFileContract;
	static readonly CONTACT                       = faComment;
	static readonly MEDICAL_STORE                 = faHome;
	static readonly LOCATION                      = faMapMarkerAlt;
	static readonly MAIL                          = faEnvelope;
	static readonly FAX                           = faFax;
	static readonly PHONE                         = faPhoneAlt;
	static readonly WEBSITE                       = faGlobe;
	static readonly QUESTION                      = faQuestionCircle;
	static readonly LOADING_SPINNER               = faSpinner;
	static readonly EDITED_AT                     = faClock;
	static readonly CREATE                        = faPlus;
	static readonly EDIT                          = faPen;
	static readonly DELETE                        = faTrash;
	static readonly STOP_DELETE                   = faTrashRestore;
	static readonly CANCEL                        = faTimesCircle;
	static readonly MENU_SHOW                     = faEye;
	static readonly COLUMN_CONFIG         = faColumns;
	static readonly MENU_HIDDEN                   = faEyeSlash;
	static readonly MENU                          = faEllipsisV;
	static readonly BOOLEAN_TRUE                  = faCheck;
	static readonly HELP                          = faQuestionCircle;
	static readonly DIALOG_ATTENTION              = faExclamationTriangle;
	static readonly DIALOG_UPDATE                 = faSync;
	static readonly LOGIN                         = faSignInAlt;
	static readonly LOGOUT                        = faSignOutAlt;
	static readonly STEPPER_EDIT                  = faPencilAlt;
	static readonly STEPPER_DONE_ALL              = faCheck;
	static readonly REMOVE                        = faTimesCircle;
	static readonly FILE_SHOW                     = faEye;
	static readonly FILE_DOWNLOAD                 = faDownload;
	static readonly FILE_SELECT                   = faFolderOpen;
	static readonly FILE_PDF                      = faFilePdf;
	static readonly EMPLOYEE                      = faHouseUser;
	static readonly CONTACT_ACCESSION             = faHandshake;
	static readonly CONTACT_ACCESSION_TIMINGS     = faCalendarAlt;
	static readonly CONTRACTING_PARTY             = faFileInvoiceDollar;
	static readonly PRE_QUALIFICATION_CERTIFICATE = faCertificate;
	static readonly LANDESINNUNG                  = faMapMarked;
	static readonly PROFESSIONAL_ASSOCIATION      = faMapMarkedAlt;
	static readonly INPUT_ERROR                   = faExclamation;
	static readonly INPUT_VALID                   = faCheck;
	static readonly INPUT_REQUIRED                = faAsterisk;
	static readonly NO_DATA                       = faBoxOpen;
	static readonly INSTITUTIONSKENNZEICHEN       = faReceipt;
	static readonly ERROR                         = faBug;
	static readonly ANGLE_RIGHT                   = faAngleRight;
	static readonly ANGLE_DOWN                    = faAngleDown;
	static readonly BACK                          = faAngleLeft;
	static readonly VISIBLE                       = faEye;
	static readonly HIDDEN                        = faEyeSlash;
	static readonly UPLOAD                        = faCloudUploadAlt;
	static readonly RESIGN                = faHandshakeSlash;
	static readonly RESCIND               = faEraser;
	static readonly FIELD_DESCRIPTION     = faInfoCircle;
	static readonly CALCULATION_HANDBOOK  = faBook;
	static readonly MEMBER_INFO           = faInfoCircle;
	static readonly NEWS                  = faNewspaper;
	static readonly HOME                  = faHome;
	static readonly USER                  = faUser;
	static readonly CLOSE                 = faTimes;
	static readonly COPY                  = faCopy;
	static readonly FILES                 = faFile;
	static readonly CHECKBOX              = faSquare;
	static readonly CHECKBOX_CHECKED      = faSquareCheck;
	static readonly IQZ_PARTICIPATION     = faStamp;
	static readonly REQUIREMENTS          = faCircleHalfStroke;
	static readonly DARK_THEME            = faMoon;
	static readonly LIGHT_THEME           = faSun;
	protected static readonly PLACEHOLDER = faDiamond;
	readonly USER                         = IconService.USER;
	readonly NEWS                         = IconService.NEWS;
	readonly HOME                         = IconService.HOME;
	readonly ICON_ALERT                   = IconService.ICON_ALERT;
	readonly SEARCH                       = IconService.SEARCH;
	readonly SYNC                         = IconService.SYNC;
	readonly CONTACT_ACCESSION            = IconService.CONTACT_ACCESSION;
	readonly CONTACT_ACCESSION_TIMINGS    = IconService.CONTACT_ACCESSION_TIMINGS;
	readonly MASTER_CONTRACT              = IconService.MASTER_CONTRACT;
	readonly CONTRACT                     = IconService.CONTRACT;
	readonly CONTRACT_SECTION                     = IconService.CONTRACT_SECTION;
	readonly CONTACT                              = IconService.CONTACT;
	readonly MEDICAL_STORE                        = IconService.MEDICAL_STORE;
	readonly CONTRACTING_PARTY                    = IconService.CONTRACTING_PARTY;
	readonly LOCATION                             = IconService.LOCATION;
	readonly MAIL                                 = IconService.MAIL;
	readonly FAX                                  = IconService.FAX;
	readonly PHONE                                = IconService.PHONE;
	readonly WEBSITE                              = IconService.WEBSITE;
	readonly QUESTION                             = IconService.QUESTION;
	readonly LOADING_SPINNER                      = IconService.LOADING_SPINNER;
	readonly EDITED_AT                            = IconService.EDITED_AT;
	readonly CREATE                               = IconService.CREATE;
	readonly EDIT                                 = IconService.EDIT;
	readonly DELETE                               = IconService.DELETE;
	readonly STOP_DELETE                          = IconService.STOP_DELETE;
	readonly CANCEL                               = IconService.CANCEL;
	readonly MENU_SHOW                            = IconService.MENU_SHOW;
	readonly COLUMN_CONFIG                = IconService.COLUMN_CONFIG;
	readonly MENU_HIDDEN                          = IconService.MENU_HIDDEN;
	readonly MENU                                 = IconService.MENU;
	readonly BOOLEAN_TRUE                         = IconService.BOOLEAN_TRUE;
	readonly HELP                                 = IconService.HELP;
	readonly DIALOG_ATTENTION                     = IconService.DIALOG_ATTENTION;
	readonly DIALOG_UPDATE                        = IconService.DIALOG_UPDATE;
	readonly LOGIN                                = IconService.LOGIN;
	readonly LOGOUT                               = IconService.LOGOUT;
	readonly STEPPER_EDIT                         = IconService.STEPPER_EDIT;
	readonly STEPPER_DONE_ALL                     = IconService.STEPPER_DONE_ALL;
	readonly EMPLOYEE                             = IconService.EMPLOYEE;
	readonly REMOVE                               = IconService.REMOVE;
	readonly PRE_QUALIFICATION_CERTIFICATE        = IconService.PRE_QUALIFICATION_CERTIFICATE;
	readonly FILE_SHOW                            = IconService.FILE_SHOW;
	readonly FILE_DOWNLOAD                        = IconService.FILE_DOWNLOAD;
	readonly LANDESINNUNG                         = IconService.LANDESINNUNG;
	readonly PROFESSIONAL_ASSOCIATION             = IconService.PROFESSIONAL_ASSOCIATION;
	readonly FILE_SELECT                          = IconService.FILE_SELECT;
	readonly FILE_PDF                             = IconService.FILE_PDF;
	readonly INPUT_ERROR                          = IconService.INPUT_ERROR;
	readonly INPUT_VALID                          = IconService.INPUT_VALID;
	readonly INPUT_REQUIRED                       = IconService.INPUT_REQUIRED;
	readonly NO_DATA                              = IconService.NO_DATA;
	readonly INSTITUTIONSKENNZEICHEN              = IconService.INSTITUTIONSKENNZEICHEN;
	readonly ERROR                                = IconService.ERROR;
	readonly ANGLERIGHT                           = IconService.ANGLE_RIGHT;
	readonly BACK                                 = IconService.BACK;
	readonly ANGLEDOWN                            = IconService.ANGLE_DOWN;
	readonly VISIBLE                              = IconService.VISIBLE;
	readonly HIDDEN                               = IconService.HIDDEN;
	readonly UPLOAD                               = IconService.UPLOAD;
	readonly PLACEHOLDER                          = IconService.PLACEHOLDER;
	readonly CHECKBOX                     = IconService.CHECKBOX;
	readonly CHECKBOX_CHECKED             = IconService.CHECKBOX_CHECKED;
	readonly REQUIREMENTS                         = IconService.REQUIREMENTS;
	readonly FILES                                = IconService.FILES;
	readonly RESIGN                               = IconService.RESIGN;
	readonly RESCIND                              = IconService.RESCIND;
	readonly CALCULATION_HANDBOOK                 = IconService.CALCULATION_HANDBOOK;
	readonly FIELD_DESCRIPTION                    = IconService.FIELD_DESCRIPTION;
	readonly MEMBER_INFO                          = IconService.MEMBER_INFO;
	readonly COPY                                 = IconService.COPY;
	readonly CLOSE                                = IconService.CLOSE;
	readonly IQZ_PARTICIPATION            = IconService.IQZ_PARTICIPATION;
	readonly DARK_THEME                   = IconService.DARK_THEME;
	readonly LIGHT_THEME                  = IconService.LIGHT_THEME;
}
