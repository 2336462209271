import {
	Component,
	Input,
} from '@angular/core';
import {
	HmmParticipation,
	MedicalStoreModel,
} from '@contracts/frontend-api';

@Component({
	selector: 'portal-medical-store-show-hmm-participation[model]',
	templateUrl: './medical-store-show-hmm-participation.component.html',
	styleUrls: ['./medical-store-show-hmm-participation.component.scss'],
})
export class MedicalStoreShowHmmParticipationComponent {
	@Input() model!: MedicalStoreModel;
	protected readonly enum = HmmParticipation;
}
