import {
	Component,
	Input,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import {RelativeDateService} from '@app/main';
import {ContractAccessionModel} from '@contracts/frontend-api';
import {
	combineLatest,
	Observable,
} from 'rxjs';
import {
	catchError,
	map,
	mergeMap,
} from 'rxjs/operators';

@Component({
	selector:    'portal-contract-accession-date-billing-delay',
	templateUrl: './contract-accession-date-billing-delay.component.html',
	styleUrls:   ['./contract-accession-date-billing-delay.component.scss'],
})
export class ContractAccessionDateBillingDelayComponent implements OnChanges {
	@Input({required: true}) model?: ContractAccessionModel;
	billingDelayDate$?: Observable<Date | null>;

	constructor(
		private readonly relativeDateService: RelativeDateService,
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		if(this.model == null)
			return;

		this.billingDelayDate$ = this.model.contractSection.value.pipe(
			mergeMap((contractSection) => {
				if(contractSection == null || this.model == null)
					throw new Error('could not load contractSection data');

				return combineLatest([
					contractSection.billingDelay.withParent.value,
					this.model.accessionStartAt.value,
				]);
			}),
			map(([billingDelay, joinedAt]) => {
				if(billingDelay == null || joinedAt == null)
					throw new Error('could not load billingInformation data');

				return this.relativeDateService.calcNewDate(joinedAt, billingDelay);
			}),
			catchError((error, caughtObservable) => caughtObservable),
		);
	}
}
