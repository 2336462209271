import {
	Component,
	Input,
	OnInit,
} from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
} from '@angular/forms';

@Component({
	selector:  'portal-news-edit-title',
	templateUrl: './news-edit-title.component.html',
	styleUrls: ['./news-edit-title.component.scss'],
})
export class NewsEditTitleComponent implements OnInit {
	@Input({
		required: true,
		alias:    'control',
	}) parent!: UntypedFormGroup;
	fieldName = 'title';
	control?: UntypedFormControl;

	ngOnInit(): void {
		const field = this.parent.get(this.fieldName);
		if(field instanceof UntypedFormControl)
			this.control = field;
	}
}
