import {Level8Error} from '@angular-helpers/frontend-api';
import {
	COMMA,
	ENTER,
	SPACE,
} from '@angular/cdk/keycodes';
import {
	Component,
	Input,
} from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
} from '@angular/forms';
import {
	CustomValidators,
	FormHelperService,
	IconService,
} from '@app/main';

@Component({
	selector: 'portal-export-edit-recipients',
	templateUrl: './export-edit-recipients.component.html',
	styleUrls: ['./export-edit-recipients.component.scss'],
})
export class ExportEditRecipientsComponent {
	@Input({
		required: true,
		alias:    'control',
	}) parent!: UntypedFormGroup;
	readonly separatorKeysCodes = [
		ENTER,
		COMMA,
		SPACE,
	];
	readonly inputControl       = new UntypedFormControl('', CustomValidators.isValidEMail);

	constructor(
			public readonly formHelperService: FormHelperService,
			protected readonly iconService: IconService,
	) {
	}

	get control(): UntypedFormControl {
		const fieldName = 'recipients';
		const control   = this.parent.get(fieldName);
		if(control instanceof UntypedFormControl)
			return control;

		throw new Level8Error(`Unexpected type for field ${fieldName} - expected '${UntypedFormControl.name}' got '${typeof control}' (${control})`);
	}

	get recipients(): readonly string[] {
		const recipients = this.control.value;
		if(!Array.isArray(recipients))
			return [];

		return recipients;
	}

	set recipients(recipients: readonly string[]) {
		this.control.markAsDirty();
		this.control.markAsTouched();
		this.control.setValue(recipients);
	}

	addRecipient(): void {
		if(!this.inputControl.valid)
			return;

		const value = this.inputControl.value;
		if(value == null || value.length === 0)
			return;


		this.add(value);
		this.inputControl.reset();
	}

	add(recipient: string): void {
		this.recipients = [
			...this.recipients,
			recipient,
		];
	}

	remove(recipient: string): void {
		this.recipients = this.recipients.filter(r => r !== recipient);
	}
}
