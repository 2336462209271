import {
	Directive,
	TemplateRef,
} from '@angular/core';

@Directive({
	selector: 'ng-template[lazyLoad]',
})
export class LoadingLazyDirective {
	constructor(public template: TemplateRef<unknown>) { }
}
