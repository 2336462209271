import {
	Component,
	Input,
} from '@angular/core';
import {NewsModel} from '@contracts/frontend-api';

@Component({
	selector:    'portal-news-show-field-text',
	templateUrl: './news-show-field-text.component.html',
	styleUrls:   ['./news-show-field-text.component.scss'],
})
export class NewsShowFieldTextComponent {
	@Input({required: true}) news!: NewsModel;
}
