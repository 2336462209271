import {Level8Error} from '@angular-helpers/frontend-api';
import {
	Component,
	DestroyRef,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import {SelectFieldEntry} from '@app/main';
import {
	ContractModel,
	PseudoVersorgungsbereichModel,
	PseudoVersorgungsbereichService,
	VersorgungsbereichModel,
	VersorgungsbereichService,
} from '@contracts/frontend-api';
import moment from 'moment';
import {
	EMPTY,
	Subscription,
} from 'rxjs';
import {
	map,
	tap,
} from 'rxjs/operators';
import {PseudoVersorgungsbereichEditComponent} from '../../pseudo-versorgungsbereich-edit/pseudo-versorgungsbereich-edit.component';

@Component({
	selector:    'portal-contract-section-edit-versorgungsbereich',
	templateUrl: './contract-section-edit-versorgungsbereich.component.html',
	styleUrls:   ['./contract-section-edit-versorgungsbereich.component.scss'],
})
export class ContractSectionEditVersorgungsbereichComponent implements OnChanges, OnInit {
	@Input({
		required: true,
		alias:    'control',
	}) parent?: UntypedFormGroup;
	@Output() valueChanges                     = new EventEmitter<VersorgungsbereichModel | PseudoVersorgungsbereichModel | null | undefined>();
	@ViewChild('advancedOptions') advancedOptions?: PseudoVersorgungsbereichEditComponent;
	possibleValues?: SelectFieldEntry[];
	sub?: Subscription;
	vbList?: VersorgungsbereichModel[];
	psVbList?: PseudoVersorgungsbereichModel[];
	vbDataControl: UntypedFormControl          = new UntypedFormControl('', Validators.required);
	showPsVbField                              = false;


	constructor(
			private readonly versorgungsbereichService: VersorgungsbereichService,
			private readonly pseudoVersorgungsbereichService: PseudoVersorgungsbereichService,
			protected readonly destroyRef: DestroyRef,
	) {
		this.vbDataControl.setValue(undefined);
	}

	private _parentModel?: ContractModel;

	get parentModel(): ContractModel | undefined {
		return this._parentModel;
	}

	@Input({required: true})
	set parentModel(model: ContractModel | undefined) {
		this._parentModel = model;
		this.updateData();
	}

	ngOnInit(): void {
		this.loadAllVbs().then(() => {
			this.updateData();
		});
		this.vbDataControl.valueChanges.pipe(
				takeUntilDestroyed(this.destroyRef),
				tap((vbModel) => {
					this.valueChanges.emit(vbModel);
				}),
				map((vbSelected) => {
					const isPsVb       = vbSelected instanceof PseudoVersorgungsbereichModel;
					const vbFieldLocal = this.control;

					const psVbField = this.parent?.get('pseudoVersorgungsbereich');
					if(psVbField == null)
						return EMPTY;

				if(isPsVb) {
					psVbField.setValue(vbSelected);
					vbFieldLocal.setValue(null);
				} else {
					psVbField.setValue(null);
					vbFieldLocal.setValue(vbSelected);
				}

				this.showPsVbField = !isPsVb;

				if(vbSelected == null)
					this.showPsVbField = false;


				return vbSelected;
			}),
		).subscribe();
	}

	addPsVb(psVb: PseudoVersorgungsbereichModel): void {
		this.psVbList?.push(psVb);
		this.updateData().then(() => {
			this.vbDataControl.setValue(psVb);
		});
	}

	async loadAllVbs(): Promise<void> {
		const allVbsPromised   = this.versorgungsbereichService.getAllModels();
		const allPsVbsPromised = this.pseudoVersorgungsbereichService.getAllModels();

		this.vbList   = await allVbsPromised;
		this.psVbList = await allPsVbsPromised;
	}

	get control(): UntypedFormControl {
		const fieldName = 'versorgungsbereich';
		const control   = this.parent?.get(fieldName);
		if(control instanceof UntypedFormControl)
			return control;

		throw new Level8Error(`Unexpected type for field ${fieldName} - expected '${UntypedFormControl.name}' got '${typeof control}' (${control})`);
	}

	get currentValue(): SelectFieldEntry | undefined {
		const vb = this.control.value as VersorgungsbereichModel | null | undefined;
		if(vb == null)
			return undefined;

		if(vb.name.currentValue == null)
			return undefined;

		return {
			label: vb.name.currentValue,
			value: vb,
		};
	}

	get allVBs(): (VersorgungsbereichModel | PseudoVersorgungsbereichModel)[] | undefined {
		if(this.vbList == null || this.psVbList == null)
			return undefined;

		return [
			...this.vbList,
			...this.psVbList,
		];
	}

	async getContractsValidityStart(): Promise<Date> {
		let date = this.parent?.get('validityStartAt')?.value;
		if(date == null)
			date = await this.parentModel?.validityStartAt.withParent.firstValue;

		if(moment.isMoment(date))
			return date.toDate();

		if(date instanceof Date)
			return date;

		throw new Level8Error('Unexpected date');
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.updateData();

		if(this.sub)
			this.sub.unsubscribe();

		this.sub = this.parent?.get('validityStartAt')?.valueChanges
					   .pipe(takeUntilDestroyed(this.destroyRef))
					   .subscribe(() => this.updateData());
	}

	async updateData(): Promise<void> {
		if(!this.parentModel)
			return;

		const currentVBs           = [];
		const contractValidityDate = await this.getContractsValidityStart();
		const usedVB               = (await Promise.all(
				(await this.parentModel.contractSections.firstValue)
					?.map(contract => contract.versorgungsbereich.firstValue) ?? [])
		).filter((vb): vb is VersorgungsbereichModel => vb != null);

		const inheritedParentsVbList  = await this.resolveVbsPredecessors(usedVB);
		const inheritedChildrenVbList = await this.resolveVbsSuccessors(usedVB);
		const includedChildVbList     = [
			...usedVB,
			...inheritedParentsVbList,
			...inheritedChildrenVbList,
		];

		const vbList = this.allVBs;
		if(vbList == null)
			return;

		for(const model of vbList) {
			if(model instanceof VersorgungsbereichModel) {
				// todo #94 muss auch für PVB passieren!
				if(includedChildVbList.includes(model))
					continue;

				const validityEndAt = await model.validityEndAt.firstValue;
				if(validityEndAt != null && validityEndAt < contractValidityDate)
					continue;

				const validityStartAt = await model.validityStartAt.firstValue;
				if(validityStartAt == null || validityStartAt > contractValidityDate)
					continue;
			}

			currentVBs.push(model);
		}

		this.possibleValues = [];
		for(const vb of currentVBs) {
			const label = await vb.name.firstValue;
			if(label == null)
				continue;

			this.possibleValues.push({
				value: vb,
				label,
			});
		}

		if(this.vbDataControl.value != null && !this.possibleValues.includes(this.vbDataControl.value)) {
			this.control.setValue(null);
			this.vbDataControl.setValue(null);
			this.control.updateValueAndValidity();
			this.vbDataControl.updateValueAndValidity();
		}

	}

	protected async resolveVbsSuccessors(vbList: VersorgungsbereichModel[]): Promise<VersorgungsbereichModel[]> {
		return this.resolveVbsFamily(vbList, vb => vb.successor.firstValue);
	}

	protected async resolveVbsPredecessors(vbList: VersorgungsbereichModel[]): Promise<VersorgungsbereichModel[]> {
		return this.resolveVbsFamily(vbList, (vb) => vb.predecessor.firstValue);
	}

	protected async resolveVbsFamily(vbList: VersorgungsbereichModel[], next: (vb: VersorgungsbereichModel) => Promise<VersorgungsbereichModel | null | undefined>): Promise<VersorgungsbereichModel[]> {
		const family = new Map<string, VersorgungsbereichModel>();
		const follow = async function(vb: VersorgungsbereichModel) {
			const predecessor = await next(vb);
			if(predecessor == null)
				return;

			family.set(predecessor.id, predecessor);

			await follow(predecessor);
		};

		await Promise.all(vbList.map((vb) => follow(vb)));

		return [...family.values()];
	}
}
