import {
	InheritedCoalesceProperty,
	Level8Error,
} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
} from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
} from '@angular/forms';
import {
	ContractModel,
	ContractSectionModel,
	MasterContractModel,
} from '@contracts/frontend-api';
import moment from 'moment';
import {
	Observable,
	of,
} from 'rxjs';

@Component({
	selector:    'portal-base-contract-edit-period-of-validity',
	templateUrl: './base-contract-edit-period-of-validity.component.html',
	styleUrls:   ['./base-contract-edit-period-of-validity.component.scss'],
})
export class BaseContractEditPeriodOfValidityComponent {
	@Input({
		required: true,
		alias:    'control',
	}) parent!: UntypedFormGroup;
	@Input() model?: MasterContractModel | ContractModel | ContractSectionModel;
	@Input() parentModel?: MasterContractModel | ContractModel;
	public earliestDate$: Observable<Date> = of(new Date());

	get control(): UntypedFormControl {
		const fieldName = 'periodOfValidityAt';
		const control   = this.parent.get(fieldName);
		if(control instanceof UntypedFormControl)
			return control;

		throw new Level8Error(`Unexpected type for field ${fieldName} - expected '${UntypedFormControl.name}' got '${typeof control}' (${control})`);
	}

	get inheritedValue(): Observable<Date | undefined | null> | undefined {
		if(this.parentModel != null) {
			if(this.parentModel instanceof MasterContractModel)
				return this.parentModel.periodOfValidityAt.value;

			return this.parentModel.periodOfValidityAt.withParent.value;
		}

		if(this.model == null)
			return undefined;

		if(this.model instanceof MasterContractModel)
			return undefined;

		return this.model.periodOfValidityAt.value;
	}

	get earliestDateControl(): Date | moment.Moment | undefined | null {
		const fieldName = 'terminatedAt';
		const control   = this.parent.get(fieldName);
		if(control instanceof UntypedFormControl)
			return control.value;

		throw new Level8Error(`Unexpected type for field ${fieldName} - expected '${UntypedFormControl.name}' got '${typeof control}' (${control})`);
	}

	get earliestDateParent(): Observable<Date | null | undefined> {
		if(this.model == null)
			return of(undefined);

		if(this.model.terminatedAt instanceof InheritedCoalesceProperty)
			return this.model.terminatedAt.withParent.value;

		return this.model.terminatedAt.value;
	}
}
