@if (model) {
	<portal-headline
		label="model.employee"
		[menu]="menu"
		[backlink]="environment.medicalStoresFullUrl +'/'+ (model.medicalStore.value | async)?.id"
	>
		<mat-menu #menu>
			@if (canCratePreQualificationCertificate) {
				<button mat-menu-item [matMenuTriggerFor]="menuCreate">
					<fa-icon matMenuItemIcon [icon]="iconService.CREATE"></fa-icon>
					<span>{{ 'general.menu.create' | translate }}</span>
				</button>
			}
			<mat-menu #menuCreate>
				@if (canCratePreQualificationCertificate) {
					<a
						mat-menu-item
						[routerLink]="[environment.preQualificationCertificateFullUrl, 'create']"
					>
						<fa-icon matMenuItemIcon [icon]="iconService.PRE_QUALIFICATION_CERTIFICATE"></fa-icon>
						<span>{{ 'model.preQualificationCertificate' | translate }}</span>
					</a>
				}
			</mat-menu>
			@if ((model.preQualificationCertificates.value | async)?.length === 0 && (model.permissions.canDelete | async)) {
				<button mat-menu-item (click)="showDeleteDialog()">
					<fa-icon matMenuItemIcon [icon]="iconService.DELETE"></fa-icon>
					<span>{{ 'actions.delete' | translate }}</span>
				</button>
			}
		</mat-menu>
	</portal-headline>
	<div class="flex-container">
		<portal-employee-card-master-data [employee]="model"/>
		<portal-pre-qualification-certificate-list-card [prefillData]="pqcPrefillData$ | async" [preQualificationCertificates]="model.preQualificationCertificates.value | async"/>
	</div>
}
