<portal-model-editable-box
	[icon]="iconService.CONTRACT_SECTION"
	headline="general.masterData"
	[formHelper]="formHelper"
	[hasEditButton]="canUpdate | async"
>

	@if (contractSection) {
		<ng-template #showModeContent>
			<portal-base-contract-status [model]="contractSection"/>
			<portal-base-contract-show-name [model]="contractSection"/>
			<portal-contract-show [model]="contractSection.contract.value | async"/>
			<portal-base-contract-show-validity-start [model]="contractSection"/>
			<portal-base-contract-show-terminated-at [model]="contractSection"/>
			<portal-base-contract-show-minimum-term [model]="contractSection"/>
			<portal-base-contract-show-period-of-validity [model]="contractSection"/>
			<portal-base-contract-show-notice-period-negotiations [model]="contractSection"/>
			<portal-base-contract-show-negotiators [model]="contractSection"/>
			<portal-base-contract-show-initial-signer [model]="contractSection"/>
			<portal-base-contract-show-awarded-mandates [model]="contractSection"/>
		</ng-template>
	}

	<ng-template #editModeContent let-control>
		<portal-base-contract-edit-validity-start [model]="contractSection" [control]="control"/>
		<portal-base-contract-edit-terminated-at [model]="contractSection" [control]="control"/>
		<portal-base-contract-edit-minimum-term [model]="contractSection" [control]="control"/>
		<portal-base-contract-edit-period-of-validity [model]="contractSection" [control]="control"/>
		<portal-base-contract-edit-notice-period-negotiations [model]="contractSection" [control]="control"/>
		<portal-base-contract-edit-negotiators [model]="contractSection" [control]="control"/>
		<portal-base-contract-edit-initial-signer [model]="contractSection" [control]="control"/>
		<portal-base-contract-edit-mandate-grantor [model]="contractSection" [control]="control"/>
	</ng-template>

</portal-model-editable-box>
