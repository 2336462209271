import {
	Component,
	Input,
} from '@angular/core';
import {IqzTrainingCourseModel} from '@contracts/frontend-api';

@Component({
	selector: 'portal-iqz-training-course-show-end-at[model]',
	templateUrl: './iqz-training-course-show-end-at.component.html',
	styleUrls: ['./iqz-training-course-show-end-at.component.scss'],
})
export class IqzTrainingCourseShowEndAtComponent {
	@Input({required: true}) model!: IqzTrainingCourseModel;
}
