import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {
	Observable,
	throwError,
} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class RequestErrorLoggerInterceptor implements HttpInterceptor {

	constructor(
		private readonly router: Router,
	) {
	}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(request).pipe(
			catchError((error: unknown) => {
				if(!(error instanceof HttpErrorResponse))
					return throwError(error);
				
				if(request.body != null && this.shouldLogError(error))
					error.error.requestBody = request.body;
				
				if(error.status === 0){
					this.router.navigate(['errors',error.status], {skipLocationChange: true});
					return throwError(error);
				}

				return throwError(error);
			}),
		);
	}

	protected shouldLogError(error: unknown): boolean {
		if(!(error instanceof HttpErrorResponse))
			return false;

		if(error.status === 0)
			return false;

		if(error.status === 422){
			const errorMessage: unknown = error.error?.message;
			if(typeof errorMessage === 'string') {
				if(errorMessage.startsWith('The given Version is outdated'))
				return false;

				if(errorMessage.startsWith('The \'email\' must be a valid email address'))
				return false;
			}
		}

		return true;
	}
}
