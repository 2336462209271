<portal-table
		[headers]="tableHeaders"
		[data]="_data"
		[baseLink]="baseLink"
		[getRowLink]="getBaseLinkFunction"
		[(currentPage)]="displayPage"
		[hasNextPage]="hasNextPage"
		[isLoading]="isLoading || searching !== null || filteredModels == null"
		[withMenu]="withMenu"
		[customMenu]="customMenu"
/>
