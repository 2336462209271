import {
	Component,
	Input,
} from '@angular/core';
import {environment} from '@app/environment';
import {UrlType} from '@app/main';
import {MasterContractModel} from '@contracts/frontend-api';

@Component({
	selector:    'portal-master-contract-show',
	templateUrl: './master-contract-show.component.html',
	styleUrls:   ['./master-contract-show.component.scss'],
})
export class MasterContractShowComponent {
	@Input({required: true}) model?: MasterContractModel | null;
	readonly urlType     = UrlType;
	readonly environment = environment;
}
