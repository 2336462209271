import {Level8Error} from '@angular-helpers/frontend-api';
import {
	ChangeDetectorRef,
	Component,
	DestroyRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormControl} from '@angular/forms';
import {
	deepEquals,
	FormHelperService,
	IconService,
} from '@app/main';
import {
	EmployeeHasDataCheckInterface,
	MedicalStorePropertyHasValueOfValuesCheckInterface,
	PossibleStates,
	PreQualificationCertificateExistsCheckInterface,
	PreQualificationCertificateOneOfExistsCheck,
	PseudoVersorgungsbereichModel,
	RequirementFunctionModel,
	RequirementFunctionName,
	RequirementFunctionService,
	RoleModel,
	RoleService,
	VersorgungsbereichModel,
	VersorgungsbereichService,
} from '@contracts/frontend-api';
import {
	combineLatest,
	EMPTY,
	forkJoin,
	from,
	Observable,
	of,
} from 'rxjs';
import {
	filter,
	map,
	mergeMap,
	take,
} from 'rxjs/operators';

export interface RequirementEntry<T extends object> {
	requirementFunction: RequirementFunctionModel;
	parameters: T;
}

@Component({
	selector:    'portal-requirement-create-requirement',
	templateUrl: './requirement-create-requirement.component.html',
	styleUrls:   ['./requirement-create-requirement.component.scss'],
})
export class RequirementCreateRequirementComponent implements OnInit {

	@Output() requirementsChange                                               = new EventEmitter<readonly RequirementEntry<object>[]>();
	@Input({required: true}) requirements: readonly RequirementEntry<object>[] = [];
	@Input() currentVB?: Observable<VersorgungsbereichModel | PseudoVersorgungsbereichModel | null | undefined>;

	isAddingRequirement     = false;
	requirementFunctionName = RequirementFunctionName;

	possibleRequirements: RequirementFunctionModel[] = [];
	selectedRequirementFunction?: RequirementFunctionModel;

	selectedVersorgungsbereich?: VersorgungsbereichModel;
	selectedVersorgungsbereichList?: VersorgungsbereichModel[];
	possibleVersorgungsbereichs?: VersorgungsbereichModel[];
	
	selectedRole?: RoleModel;
	possibleRoles?: RoleModel[];

	selectedProperty?: string;
	possibleProperties = [
		'address.state',
	];

	propertyField = new FormControl<RequirementCreateRequirementComponent['stateList']>([]);
	selectVersorgungsBereichField = new FormControl<VersorgungsbereichModel[]>([]);
	stateList     = Object.values(PossibleStates);

	constructor(
			protected readonly iconService: IconService,
			private readonly requirementFunctionService: RequirementFunctionService,
			private readonly versorgungsbereichService: VersorgungsbereichService,
			private readonly roleService: RoleService,
			private readonly changeDetection: ChangeDetectorRef,
			protected readonly formHelperService: FormHelperService,
			protected readonly destroyRef: DestroyRef,
	) { }

	addRequirement(): void {
		this.isAddingRequirement = true;
	}

	getVbByEntry(entry: RequirementEntry<PreQualificationCertificateExistsCheckInterface>): VersorgungsbereichModel {
		return this.versorgungsbereichService.getById(entry.parameters.versorgungsbereich_id);
	}

	getRoleByEntry(entry: RequirementEntry<EmployeeHasDataCheckInterface>): RoleModel {
		if(typeof entry.parameters.value !== 'string')
			throw new Error(`Invalid value. Expected string, got '${typeof entry.parameters.value}'`);

		return this.roleService.getById(entry.parameters.value);
	}

	ngOnInit(): void {
		this.loadPossibleRequirementFunctions();
		this.loadVersorgungsbereichs();
		this.loadRoles();

		if(this.currentVB != null)
			this.initFieldTracking(this.currentVB);

	}

	initFieldTracking(track: Observable<VersorgungsbereichModel | PseudoVersorgungsbereichModel | null | undefined>): void {
		let oldValue: undefined | RequirementEntry<object>;

		track.pipe(
				takeUntilDestroyed(this.destroyRef),
				mergeMap((trackValue) => {
					if(trackValue == null)
						return EMPTY;

					return trackValue.name.firstValue;
				}),
				mergeMap((name) => {
					let evalName: undefined | string = undefined;
					if(typeof name === 'string')
						evalName = name.match(/(?<prefix>[0-9]{0,2}[a-zA-Z]{1}[0-9]{0,2})\/?/)?.groups?.prefix;

				if(evalName == null)
					return EMPTY;

				return forkJoin({
					name:      of(evalName),
					allModels: this.versorgungsbereichService.getAllModels(),
				});
			}),
			map((selectVbData) => from(selectVbData.allModels).pipe(
				map((vbModel) => forkJoin({
					selectedName: of(selectVbData.name),
					currentModel: of(vbModel),
					vbName:       vbModel.name.firstValue,
				})),
			)),
			mergeMap((requirementFunction) => requirementFunction),
			mergeMap((requirementFunction) => requirementFunction),
			filter((resolvedModel => resolvedModel.vbName === resolvedModel.selectedName)),
			mergeMap((resolvedObject) => from(this.possibleRequirements).pipe(
				map((reqModel) => forkJoin({
					selectedVb:       of(resolvedObject.currentModel),
					requirementName:  reqModel.name.firstValue,
					requirementModel: of(reqModel),
				})),
			)),
			mergeMap(reqObj => reqObj),
			filter((requirement) => (requirement.requirementName === RequirementFunctionName.preQualificationCertificateExistsCheck || requirement.requirementName === RequirementFunctionName.preQualificationCertificateOneOfExistsCheck)),
			map((resolvedModel) => {
				const ids: string[] = [resolvedModel.selectedVb.id];
				const entry: RequirementEntry<PreQualificationCertificateExistsCheckInterface|PreQualificationCertificateOneOfExistsCheck> = {
					requirementFunction: resolvedModel.requirementModel,
					parameters:          {
						// eslint-disable-next-line @typescript-eslint/naming-convention
						versorgungsbereich_id: resolvedModel.selectedVb.id,
						// eslint-disable-next-line @typescript-eslint/naming-convention
						versorgungsbereich_ids: ids,
					},
				};

				if(oldValue != null)
					this.removeRequirementFromSave(oldValue);
				
				oldValue = entry;
				this.saveEntry(entry);
				this.reset();
				this.changeDetection.markForCheck();

				return EMPTY;
			}),
		).subscribe();
	}

	removeRequirementFromSave(selectedRequirement: RequirementEntry<object>): void {
		this.requirements = this.requirements.filter(requirement => requirement !== selectedRequirement);
		this.requirementsChange.next(this.requirements);
	}

	reset(): void {
		this.isAddingRequirement         = false;
		this.selectedVersorgungsbereich  = undefined;
		this.selectedRequirementFunction = undefined;
		this.propertyField.setValue([])
		this.selectVersorgungsBereichField.setValue([])
	}

	savePqcRequirement(): void {
		if(this.selectedVersorgungsbereich == null)
			return;

		const parameter = {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			versorgungsbereich_id: this.selectedVersorgungsbereich.id,
		};

		this.saveRequirement(parameter);
	}

	saveNegateEmployeeRoleRequirement(): void {
		this.saveEmployeeRoleRequirement();
	}

	saveEmployeeRoleRequirement(): void {
		this.saveSomethingRequirement();
	}

	protected saveRequirement(parameters: object): void {
		if(this.selectedRequirementFunction == null)
			return;

		this.saveEntry({
			requirementFunction: this.selectedRequirementFunction,
			parameters,
		});
		this.reset();
	}

	protected saveSomethingRequirement(): void {
		if(this.selectedRole == null)
			return;

		const parameter = {
			relation: 'roles',
			property: 'id',
			value: this.selectedRole.id,
		};

		this.saveRequirement(parameter);
	}
	
	protected saveMedicalStorePropertyHasValueOfValuesRequirement(fieldValue: string[], property: string): void {
		const parameter: MedicalStorePropertyHasValueOfValuesCheckInterface = {
			property,
			values: fieldValue,
		};

		this.saveRequirement(parameter);
	}

	saveMedicalStoreHasStateOfStatesRequirement(): void {
		const values = this.propertyField.value;
		if(!Array.isArray(values))
			throw new Level8Error('expected an Array, but component returned other type');

		if(!(values.length > 0))
			return;

		this.saveMedicalStorePropertyHasValueOfValuesRequirement(values, 'address.state');
	}

	private saveEntry(entry: RequirementEntry<object>): void {
		const inList = this.requirements.some(requirement => deepEquals(entry, requirement));
		if(!inList) {
			this.requirements = [
				...this.requirements,
				entry,
			];

			this.requirementsChange.next(this.requirements);
		}
	}

	private async loadPossibleRequirementFunctions(): Promise<void> {
		const models                                           = await this.requirementFunctionService.getAllModels();
		const promises: Promise<unknown>[]                     = [];
		const possibleRequirements: RequirementFunctionModel[] = [];

		for(const model of models) {
			promises.push(model.name.firstValue.then(name => {
				if(name != null && Object.keys(RequirementFunctionName).includes(name))
					possibleRequirements.push(model);
				
			}));
		}

		await Promise.all(promises);
		this.possibleRequirements = possibleRequirements;
	}

	private async loadRoles(): Promise<void> {
		this.possibleRoles = await this.roleService.getAllModels();
	}

	private async loadVersorgungsbereichs(): Promise<void> {
		// const resultPage = await this.versorgungsbereichService.find({column:'validityEndAt',value:null, comparator:'='});
		// this.possibleVersorgungsbereichs = resultPage.data;
		// workaround for missing 'is null' request
		const currentVBs = [];
		const models     = await this.versorgungsbereichService.getAllModels();

		// TODO filter for only valid at contracts validityStartAt

		for(const model of models) {
			if((await model.validityEndAt.firstValue) == null)
				currentVBs.push(model);
		}

		this.possibleVersorgungsbereichs = currentVBs.sort((a, b) => (a.name.currentValue ?? '') > (b.name.currentValue ?? '') ? 1 : -1);
	}

	public savePQZOneOfRequirement(): void { //TODO remove shortcuts
		const selected = this.selectVersorgungsBereichField.value;

		if(selected == null || selected.length < 1)
			return;
		

		const parameter = {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			versorgungsbereich_ids: selected.map(selected => selected.id),
		};

		this.saveRequirement(parameter);
	}

	public getVersorgungsBereicheList$(requirement: unknown): Observable<string> {
		if((requirement == null) || !(typeof requirement == 'object')) return of();

		if(!('versorgungsbereich_ids' in requirement))
			throw new Level8Error('wrong requirement type for this function');
		

		if(!Array.isArray(requirement.versorgungsbereich_ids))
			throw new Level8Error('wrong requirement type for this function');
        

		const vbs: VersorgungsbereichModel[] = []
		for (const vbId of requirement.versorgungsbereich_ids)
			vbs.push(this.versorgungsbereichService.getById(vbId));
		

		const vbNames = combineLatest(vbs.map(vb => vb.name.value));
		const nameList = vbNames.pipe(
			take(1),
			map((names) => names.join(', ')),
		)

		return nameList;
	}
}
