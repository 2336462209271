import {Level8Error} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
} from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
} from '@angular/forms';

@Component({
	selector: 'portal-common-edit-contact',
	templateUrl: './common-edit-contact.component.html',
	styleUrls: ['./common-edit-contact.component.scss'],
})
export class CommonEditContactComponent {
	@Input({
		required: true,
		alias:    'control',
	}) parent!: UntypedFormGroup;

	get control(): UntypedFormControl {
		const fieldName = 'contact';
		const control   = this.parent.get(fieldName);
		if(control instanceof UntypedFormControl)
			return control;

		throw new Level8Error(`Unexpected type for field ${fieldName} - expected '${UntypedFormControl.name}' got '${typeof control}' (${control})`);
	}
}
