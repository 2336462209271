import {KeyValue} from '@angular/common';
import {
	Pipe,
	PipeTransform,
} from '@angular/core';

declare type Comparable = string | number | boolean;

@Pipe({
	name: 'sort',
})
export class SortPipe implements PipeTransform {

	transform<T extends null | undefined | string[] | KeyValue<Comparable, unknown>[]>(value: T): T {
		if(value == null || !Array.isArray(value))
			return value;

		if(this.isKeyValue(value))
			return value.sort((a, b) => (a.key < b.key ? -1 : (a.key > b.key ? 1 : 0))) as T;
		else
			return value.sort((a, b) => (a < b ? -1 : (a > b ? 1 : 0))) as T;

	}

	isKeyValue(array: unknown[]): array is KeyValue<unknown, unknown>[] {
		const value = array.values().next().value;
		if(value == null || typeof value != 'object')
			return false;

		return Reflect.has(value, 'key') && Reflect.has(value, 'key');
	}

}
