@if (isVisible && validator) {
	<fa-icon
		[icon]="icon"
		[class.touched]="validator.touched"
		[class]="status"
		[class.dirty]="validator.dirty"
		[class.visible]="isVisible"
		[spin]="status === 'validating'"
	></fa-icon>
}
