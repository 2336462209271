<portal-headline
	[label]="selectedUser == null ? 'medicalStoreUser.create' : 'medicalStoreUser.edit'"
	[backlink]="backLink"
	[menu]="menu"
>
	<mat-menu #menu>
		@if (selectedUser && notSelf()) {
			<button mat-menu-item (click)="openDialog()">
				<fa-icon matMenuItemIcon [icon]="iconService.DELETE"></fa-icon>
				<span>{{ 'actions.delete' | translate }}</span>
			</button>
		}
	</mat-menu>
</portal-headline>
<mat-card>
	<mat-card-content>
		<div class="biv-form grid-2">
			<portal-common-edit-name [control]="localForm"/>
			<portal-common-edit-email [control]="localForm"/>
		</div>
		<div class="medical-stores">
			<portal-medical-store-edit-user-permission [user]="selectedUser" [control]="localForm" (isRemovingAll)="onRemoveAll($event)" (userDetected)="onUserDetected($event)" (listChange)="onListUpdate($event)" [reset]="reset$" [reInitialize]="reInitialize$" [outerProcess]="saveProcessing$" [disable]="disableEdit"/>
		</div>
	</mat-card-content>
	<mat-card-actions>
		@if (errorHasOccurred) {
			<portal-form-error [error]="errorHasOccurred" [control]="localForm"/>
		}
		<div class="save-operations">
			<button class="discard" mat-raised-button (click)="onAbort()">{{ 'actions.cancel' | translate }}</button>
			<portal-spinning-button
				class="save" (click)="saveForm()"
				[processing]="isSaving" [disabled]="!notSelf() || !hasChanges"
        #tooltip="matTooltip"
				[matTooltip]="notSelf() ? undefined: ('medicalStoreUser.missingPermissionForSelf' | translate)"
				matTooltipPosition="right"
			>
				{{ isDeletingAll && !isSaving && hasChanges ? ('medicalStoreUser.delete' | translate) : ('actions.save' | translate) }}
			</portal-spinning-button>
		</div>
	</mat-card-actions>
</mat-card>
