<portal-model-editable-box
	[icon]="iconService.CONTACT"
	headline="medicalStore.contactInformation"
	[formHelper]="formHelper"
	[hasEditButton]="canUpdate | async"
>

	<ng-template #editModeContent let-control>
		<portal-common-edit-email [control]="control"/>
		<portal-common-edit-phone [control]="control"/>
		<portal-common-edit-fax [control]="control"/>
		<portal-common-edit-website [control]="control"/>
	</ng-template>

	@if (medicalStore) {
		<ng-template #showModeContent>
			<portal-common-show-email [model]="medicalStore"/>
			<portal-common-show-phone [model]="medicalStore"/>
			<portal-common-show-fax [model]="medicalStore"/>
			<portal-common-show-website [model]="medicalStore"/>
		</ng-template>
	}
</portal-model-editable-box>
