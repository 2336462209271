import {
	Component,
	Input,
} from '@angular/core';
import {
	BaseContractApiService,
	BaseContractModel,
	BaseContractService,
	ContractModel,
	ContractSectionModel,
} from '@contracts/frontend-api';
import {Observable} from 'rxjs';

@Component({
	selector: 'portal-base-contract-show-negotiators[model]',
	templateUrl: './base-contract-show-negotiators.component.html',
	styleUrls: ['./base-contract-show-negotiators.component.scss'],
})
export class BaseContractShowNegotiatorsComponent<Model extends BaseContractModel<BaseContractService<BaseContractApiService, Model>>> {
	@Input() model?: Model;

	get value(): Observable<string[] | null | undefined> | undefined {
		if(this.model == null)
			return this.model;

		if(this.model instanceof ContractModel || this.model instanceof ContractSectionModel)
			return this.model.negotiators.withParent.value;

		return this.model.negotiators.value;
	}
}
