import {
	Component,
	Input,
} from '@angular/core';
import {IqzTrainingCourseModel} from '@contracts/frontend-api';

@Component({
	selector: 'portal-iqz-training-course-show-attendance[model]',
	templateUrl: './iqz-training-course-show-attendance.component.html',
	styleUrls: ['./iqz-training-course-show-attendance.component.scss'],
})
export class IqzTrainingCourseShowAttendanceComponent {
	@Input({required: true}) model!: IqzTrainingCourseModel;
}
