import {Level8Error} from '@angular-helpers/frontend-api';
import {
	Component,
	ElementRef,
	Input,
	ViewChild,
} from '@angular/core';
import {
	AbstractControl,
	UntypedFormControl,
	UntypedFormGroup,
} from '@angular/forms';
import {
	FileHelper,
	FormHelperService,
	IconService,
} from '@app/main';
import {FileChildDtoModel} from '@contracts/frontend-api';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'portal-file-create',
	templateUrl: './file-create.component.html',
	styleUrls: ['./file-create.component.scss'],
})
export class FileCreateComponent {
	@Input({
		required: true,
		alias:    'control',
	}) parent!: UntypedFormGroup;
	@Input({required: true}) label!: string;
	@Input({required: true}) acceptedFileTypes: string[] = [];
	@ViewChild('matInput') fakeInputElement!: ElementRef;
	@ViewChild('fileInput') fileInputElement!: ElementRef;

	readonly FILE_SIZE_LIMIT = FileChildDtoModel.FILE_SIZE_LIMIT;
	_file?: File;

	constructor(
			public formHelperService: FormHelperService,
			public iconService: IconService,
			private readonly translateService: TranslateService,
	) {
		// todo load data from control if prefilled
	}

	get control(): UntypedFormGroup {
		return this.getControl('file');
	}

	get file(): File | undefined {
		return this._file;
	}

	set file(file: File | undefined) {
		this.fileNameControl.markAsTouched();
		this.fileNameControl.markAsDirty();

		if(!file)
			return;

		this._file = file;

		if(!this.acceptedFileTypes.includes(file.type)) {
			const errors    = this.control.errors ?? {};
			errors.fileType = {
				types: this.acceptedFileTypes.map(type => this.translateService.instant(`fileTypes.${type}`))
						   .join(', '),
			};
			this.control.setErrors(errors, {emitEvent: true});
			return;
		}

		if(file.size >= this.FILE_SIZE_LIMIT) {
			const errors       = this.control.errors ?? {};
			errors.maxFileSize = {limit: this.formatFileSize(this.FILE_SIZE_LIMIT)};
			this.control.setErrors(errors, {emitEvent: true});
			return;
		}

		const fileReader = new FileReader();
		fileReader.readAsDataURL(file);
		fileReader.onloadend = (event) => {
			this.fileContentControl.setValue(fileReader.result);
			this.fileNameControl.setValue(file.name);
		};
	}

	get fileContentControl(): UntypedFormControl {
		return this.getControl('file', this.control);
	}

	get fileNameControl(): UntypedFormControl {
		return this.getControl('name', this.control);
	}

	formatFileSize(bytes: number): string {
		return FileHelper.formatFileSize(bytes);
	}

	fileSelected(event: Event): void {
		if(!(event.target instanceof HTMLInputElement))
			throw new Level8Error(`Unexpected event target: ${event.target?.constructor.name}`);

		this.file = event.target.files?.[0];
	}

	selectFile(): void {
		this.fakeInputElement.nativeElement.focus();
		this.fileInputElement.nativeElement.click();
	}

	private getControl<T extends AbstractControl>(fieldName: string, parent = this.parent): T {
		const control = parent.get(fieldName);
		if(control)
			return control as T;

		throw new Level8Error(`Unexpected type for field ${fieldName} - expected '${UntypedFormControl.name}' got '${typeof control}' (${control})`);
	}
}
