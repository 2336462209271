import {
	Component,
	Input,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import {RelativeDateService} from '@app/main';
import {ContractAccessionModel} from '@contracts/frontend-api';
import {
	combineLatest,
	Observable,
} from 'rxjs';
import {
	catchError,
	map,
	mergeMap,
} from 'rxjs/operators';

@Component({
	selector:    'portal-contract-accession-joining-delay',
	templateUrl: './contract-accession-joining-delay.component.html',
	styleUrls:   ['./contract-accession-joining-delay.component.scss'],
})
export class ContractAccessionJoiningDelayComponent implements OnChanges {
	@Input({required: true}) model?: ContractAccessionModel;

	protected joiningDelayDate$?: Observable<Date>;

	constructor(
		private readonly relativeDateService: RelativeDateService,
	) {
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(this.model == null)
			return;

		this.joiningDelayDate$ = this.model.contractSection.value.pipe(
			mergeMap((contractSection) => {
				if(contractSection == null || this.model == null)
					throw new Error('could not load contractSection data');

				return combineLatest([
					contractSection.joiningDelay.withParent.value,
					this.model.accessionStartAt.value,
				]);
			}),
			map(([joiningDelay, joinedAt]) => {
				if(joiningDelay == null || joinedAt == null)
					throw new Error('could not load joiningDelay data');

				return this.relativeDateService.calcNewDateFromOption(joinedAt, joiningDelay);
			}),
			catchError((error, caughtObservable) => caughtObservable),
		);

	}
}
