import {
	Component,
	Input,
	OnChanges,
	OnDestroy,
	Output,
	SimpleChanges,
} from '@angular/core';
import {
	UntypedFormControl,
	Validators,
} from '@angular/forms';
import {
	DateUnit,
	FormHelperService,
	RelativeDateObject,
	RelativeDateService,
} from '@app/main';
import {Delay} from '@contracts/frontend-api';
import {
	merge,
	Observable,
	Subject,
} from 'rxjs';
import {
	catchError,
	map,
	takeUntil,
	tap,
} from 'rxjs/operators';

@Component({
	selector:    'portal-edit-relative-date',
	templateUrl: './edit-relative-date.component.html',
	styleUrls:   ['./edit-relative-date.component.scss'],
})
export class EditRelativeDateComponent implements OnDestroy, OnChanges {
	@Input({required: true}) label!: string;
	@Input() prefix: '+' | '-'             = '+';
	@Input() description?: string;
	readonly relativeDateUnits: DateUnit[] = Object.values(DateUnit);
	readonly amountFormControl             = new UntypedFormControl(null, [Validators.min(1)]);
	readonly unitFormControl               = new UntypedFormControl(null, []);
	inheritedObject?: RelativeDateObject;
	onDestroy$                             = new Subject<void>();
	onChange$                              = new Subject<SimpleChanges>();
	@Output() changes: Observable<string | null | undefined>;
	isUpdating                             = false;

	constructor(
		public readonly relativeDateService: RelativeDateService,
		public readonly formHelperService: FormHelperService,
	) {
		this.changes = merge(
			this.amountFormControl.valueChanges,
			this.unitFormControl.valueChanges,
		).pipe(
			tap(() => this.isUpdating = true),
			map(() => {
				let dateString = null;
				let amount;
				let unit;

				if(this.inheritedObject != null) {
					unit   = this.inheritedObject.unit;
					amount = this.inheritedObject.amount;
				} else {
					unit   = this.unitFormControl.value;
					amount = Number.parseInt(this.amountFormControl.value, 10);
				}

				if(unit == null)
					throw new Error('unit is undefined. unit not selected');

				dateString = this.relativeDateService.dateObjectToValue({
					prefix: this.prefix,
					amount,
					unit,
				});

				return dateString;
			}),
			map((dateString) => {
				this.control.markAsDirty();
				this.control.markAsTouched();
				this.control.setValue(dateString);

				return dateString;
			}),
			tap(() => this.isUpdating = false),
			catchError((error, observable) => observable),
		);
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.onChange$.next(changes);
		this.updateValue();

		this.changes.pipe(
			takeUntil(this.onChange$),
		).subscribe();

		this.control.valueChanges.pipe(
			takeUntil(merge(
				this.onChange$,
				this.onDestroy$,
			)),
			map((valueChange) => {
				this.updateValue();
				return valueChange;
			}),
		).subscribe();
	}

	@Input({required: true}) control!: UntypedFormControl;
	private _inherited?: Delay | null;
	@Input() get inherited(): Delay | null | undefined {
		return this._inherited;
	}

	set inherited(value: Delay | null | undefined) {
		this._inherited = value;
		if(value == null)
			return;

		this.inheritedObject = this.relativeDateService.valueToDateObject(value);
	}

	updateValue(): void {
		const value = this.control.value;
		if(typeof value === 'string' && !this.isUpdating) {
			const dateObj = this.relativeDateService.valueToDateObject(value);

			if(dateObj != null) {
				this.amountFormControl.setValue(dateObj.amount);
				this.unitFormControl.setValue(dateObj.unit);
			}
		}
	}

	ngOnDestroy(): void {
		this.onDestroy$.next();
	}
}
