import {Injectable} from '@angular/core';
import {
	NavigationCancel,
	NavigationEnd,
	NavigationStart,
	Router,
} from '@angular/router';
import {
	filter,
	tap,
} from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class NavigationService {
	protected readonly historyStack: NavigationEnd[] = [];
	protected redirectStack: NavigationStart[]       = [];
	
	constructor(
		protected readonly router: Router,
	) {
		let navigationStart: NavigationStart | null = null;

		let redirectStack: NavigationStart[] = [];
		router.events.pipe(filter(event => event instanceof NavigationCancel)).subscribe(() => {
			if(navigationStart != null)
				redirectStack.push(navigationStart);
		});
		router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
			this.redirectStack = redirectStack;
			redirectStack      = [];
		});

		router.events
			  .pipe(filter((event): event is (NavigationStart | NavigationEnd) => event instanceof NavigationStart || event instanceof NavigationEnd))
			  .pipe(tap(event => {
				  if(event instanceof NavigationStart)
					  navigationStart = event;
			  }))
			  .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
			  .subscribe(event => {
				  switch(navigationStart?.navigationTrigger) {
					  case 'imperative':
					  case 'hashchange':
					  case undefined:
						  if(event.urlAfterRedirects !== this.currentHistory?.urlAfterRedirects) {
							  if(event.urlAfterRedirects.split('?')[0] === this.currentHistory?.urlAfterRedirects.split('?')[0])
								  this.historyStack.pop();
							  this.historyStack.push(event);
						  }
						  break;
				
					  case 'popstate':
						  this.historyStack.pop();
						  break;
				  }
			  });
	}

	get currentHistory(): NavigationEnd | null {
		if(this.historyStack.length < 1)
			return null;

		return this.historyStack[this.historyStack.length - 1];
	}

	get previousHistory(): NavigationEnd | null {
		if(this.historyStack.length < 2)
			return null;

		return this.historyStack[this.historyStack.length - 2];
	}

	get history(): readonly NavigationEnd[] {
		return this.historyStack;
	}

	get redirects(): readonly NavigationStart[] {
		return this.redirectStack;
	}

	get currentRedirect(): string | null {
		if(this.redirectStack.length < 1)
			return null;

		return this.redirectStack[this.redirectStack.length - 1].url;
	}

	get previousRedirect(): string | null {
		if(this.redirectStack.length < 2)
			return null;

		return this.redirectStack[this.redirectStack.length - 2].url;
	}
}
