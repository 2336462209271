<portal-model-editable-box
	[icon]="iconService.CONTACT_ACCESSION"
	headline="general.masterData"
	[formHelper]="null"
	[hasEditButton]="false"
>
	@if (contractAccession) {
		<ng-template #showModeContent>
			<portal-medical-store-show [model]="(contractAccession.institutionskennzeichen.value | async)?.medicalStore?.value | async"/>
			<portal-institutionskennzeichen-show [model]="contractAccession.institutionskennzeichen.value | async"/>
			<portal-contract-section-show [model]="contractAccession.contractSection.value | async"/>
			<portal-contract-accession-joined-party [model]="(contractAccession.contractSection.value | async)?.joinedParties?.withParent?.value | async"/>
			@if ((contractAccession.contractSection.value | async);as model) {
				<portal-base-contract-show-external-contract-number [model]="model" [type]="ecnType.mip"/>
			}
			@if ((contractAccession.contractSection.value | async);as model) {
				<portal-base-contract-show-external-contract-number [model]="model" [type]="ecnType.legs"/>
			}
		</ng-template>
	}

</portal-model-editable-box>
