<div class="wrapper" #content>
	<ng-content></ng-content>
	<ng-container>
		<div
			class="copy clickable"
			[class.copied]="isCopied"
      #matTooltip="matTooltip"
			(click)="copy()"
			[matTooltip]="tooltip | translate" matTooltipPosition="above">
			<fa-icon [icon]="iconService.COPY"></fa-icon>
		</div>
	</ng-container>
</div>