import {
	Component,
	Input,
} from '@angular/core';
import {environment} from '@app/environment';
import {IconService} from '@app/main';
import {EmployeeModel} from '@contracts/frontend-api';

@Component({
	selector:    'portal-employee-list-short-data-card',
	templateUrl: './employee-list-short-data-card.component.html',
	styleUrls:   ['./employee-list-short-data-card.component.scss'],
})
export class EmployeeListShortDataCardComponent {
	@Input({required: true}) label!: string;
	@Input({required: true}) employees: EmployeeModel[] | null | undefined;
	readonly environment = environment;

	constructor(
			public iconService: IconService,
	) {
	}
}
