<portal-editable
	[validator]="control"
	[label]="label"
	[description]="description"
>
	<div class="content-wrapper">
		<mat-form-field class="fill">
			<input matInput [formControl]="amountFormControl" type="number" [placeholder]="inheritedObject?.amount?.toString() ?? ''"/>
			@for (error of formHelperService.getErrors(amountFormControl) | keyvalue;track error) {
				<mat-error>
					{{ 'input.error.' + error.key | translate: error.value }}
				</mat-error>
			}
		</mat-form-field>
		<mat-form-field class="fill">
			<mat-select [formControl]="unitFormControl" [placeholder]="(inheritedObject != undefined) ? (relativeDateService.unitValueToDescription(inheritedObject.unit) | translate) : ''">
				@for (unit of relativeDateUnits;track unit) {
					<mat-option [value]="unit">
						{{ relativeDateService.unitValueToDescription(unit) | translate }}
					</mat-option>
				}
			</mat-select>

			@for (error of formHelperService.getErrors(unitFormControl) | keyvalue;track error) {
				<mat-error>
					{{ 'input.error.' + error.key | translate: error.value }}
				</mat-error>
			}
			<portal-input-status-icon matSuffix [validator]="control"/>
		</mat-form-field>
	</div>
</portal-editable>
