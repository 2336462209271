import {
	DtoCreationFormHelper,
	Level8Error,
	PageCache,
} from '@angular-helpers/frontend-api';
import {FlatTreeControl} from '@angular/cdk/tree';
import {
	Component,
	OnInit,
	ViewChild,
} from '@angular/core';
import {
	UntypedFormControl,
	Validators,
} from '@angular/forms';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MatStepper} from '@angular/material/stepper';
import {
	MatTreeFlatDataSource,
	MatTreeFlattener,
} from '@angular/material/tree';
import {environment} from '@app/environment';
import {
	combineLatestSafe,
	IconService,
	notNull,
	RelativeDateService,
} from '@app/main';
import {
	ContractAccessionDtoModel,
	ContractAccessionService,
	ContractingPartyModel,
	ContractingPartyService,
	ContractModel,
	ContractSectionModel,
	ContractSectionService,
	ContractService,
	EmployeeHasDataCheckInterface,
	InstitutionskennzeichenModel,
	InstitutionskennzeichenService,
	JoinedPartyInterface,
	MasterContractModel,
	MasterContractService,
	MedicalStorePropertyHasValueOfValuesCheckInterface,
	PreQualificationCertificateExistsCheckInterface,
	PreQualificationCertificateOneOfExistsCheck,
	PseudoVersorgungsbereichModel,
	PseudoVersorgungsbereichService,
	RequirementFunctionName,
	RoleService,
	VersorgungsbereichModel,
	VersorgungsbereichService,
} from '@contracts/frontend-api';
import {TranslatePipe} from '@ngx-translate/core';
import moment from 'moment';
import {
	combineLatest,
	Observable,
	of,
} from 'rxjs';
import {
	catchError,
	map,
	mergeMap,
	throttleTime,
} from 'rxjs/operators';

export interface ContractEntry {
	parent?: string;
	contractSection: ContractSectionModel;
	party: ContractingPartyModel;
}

interface JoinableEntry {
	parent?: ContractModel;
	contractSection: ContractSectionModel;
	errors?: RequirementError[];
}

interface JoinableVbEntry extends JoinableEntry {
	vb: VersorgungsbereichModel;
}

interface JoinablePartyEntry extends JoinableEntry {
	party: ContractingPartyModel;
}

interface RequirementError {
	error: string;
	args: object;
}

interface ContractIkRelation {
	contract: ContractSectionModel;
	ik: InstitutionskennzeichenModel;
}

interface AnyJoinableTreeEntry {
	party?: ContractingPartyModel | JoinedPartyInterface;
	children: JoinableContractSectionTreeEntry[];
	level: number;
	expandable: boolean;
	errors?: RequirementError[];
}

interface JoinableContractTreeEntry extends AnyJoinableTreeEntry {
	contract: ContractModel;
}


interface JoinableContractSectionTreeEntry extends JoinableContractTreeEntry {
	contractSection: ContractSectionModel;
}

type JoinableTreeEntry = JoinableContractSectionTreeEntry | JoinableContractTreeEntry;

@Component({
	templateUrl: './contract-accessing-page.component.html',
	styleUrls:   ['./contract-accessing-page.component.scss'],
})
export class ContractAccessingPageComponent implements OnInit {
	protected static cachePrepared                                             = false;
	@ViewChild('stepper') private readonly stepper!: MatStepper;
	joiningDates                                                               = new Map<string, UntypedFormControl>();
	isContractSaved                                                            = new Map<string, boolean>();
	accessionStartAtControl: UntypedFormControl                                = new UntypedFormControl(
		undefined,
		[Validators.required],
	);
	ikSelected: InstitutionskennzeichenModel | null                            = null;
	iks?: Map<string, InstitutionskennzeichenModel>;
	contractingPartySelected: ContractingPartyModel | null                     = null;
	contractingPartys?: Map<string, ContractingPartyModel>;
	vbSelected: VersorgungsbereichModel | PseudoVersorgungsbereichModel | null = null;
	vbs?: Map<string, VersorgungsbereichModel>;
	masterContracts?: MasterContractModel[];
	possibleVbContracts?: JoinablePartyEntry[];
	possiblePartyContracts?: JoinableVbEntry[]; // VbFiltered
	joiningContracts: ContractEntry[]                                          = [];
	joinedContracts?: ContractSectionModel[];
	contractIkRelations?: ContractIkRelation[];
	isSaving                                                                   = false;
	environment                                                                = environment;
	isSaved                                                                    = false;
	joinableContractSectionsNestedTreeData: Map<string, JoinableTreeEntry>     = new Map();
	joinableContractSectionsFlatTreeData: Map<string, JoinableTreeEntry>       = new Map();

	treeController  = new FlatTreeControl<JoinableTreeEntry>(
		node => node.level,
		node => node.expandable,
	);
	treeFlattener   = new MatTreeFlattener<JoinableTreeEntry, JoinableTreeEntry>(
		this.transFormNode,
		node => node.level,
		node => node.expandable,
		node => node.children,
	);
	treeDataSource  = new MatTreeFlatDataSource(this.treeController, this.treeFlattener, []);

	private readonly requirementsResolved = new Map<string, Promise<RequirementError[]>>();
	public errorOccurred?: Error;

	constructor(
		protected contractAccessionService: ContractAccessionService,
		protected masterContractService: MasterContractService,
		protected contractService: ContractService,
		protected contractSectionService: ContractSectionService,
		protected contractingPartyService: ContractingPartyService,
		protected institutionskennzeichenService: InstitutionskennzeichenService,
		protected versorgungsbereichService: VersorgungsbereichService,
		protected pseudoVersorgungsBereichService: PseudoVersorgungsbereichService,
		public iconService: IconService,
		private readonly roleService: RoleService,
		private readonly translationPipe: TranslatePipe,
		private readonly relativeDateService: RelativeDateService,
	) {
	}

	ngOnInit(): void {
		this.reload();
	}

	getEarliestStartDate$(contracts: ContractEntry | ContractEntry[]): Observable<Date> {
		if(!Array.isArray(contracts))
			contracts = [contracts];

		const earliestPossible$ = of(contracts).pipe(
			mergeMap((contratcSectionList) =>
				combineLatest(
					contratcSectionList.map((contractSection) =>
						combineLatest([
							contractSection.contractSection.validityStartAt.withParent.value,
							contractSection.contractSection.joiningDelay.withParent.value,
						]).pipe(
							map(([validityStartAt, joiningDelay]) => {

								if(validityStartAt == null || joiningDelay == null)
									throw new Error('values could not be read, validityStartAt, joiningDelay');

								return this.relativeDateService.calcNewDateFromOption(
									validityStartAt,
									joiningDelay,
								);
							}),
							catchError((error) => of(new Date()).pipe(throttleTime(1_000))),
						),
					),
				),
			),
			map((value) => {
				let biggest: null | Date = null;
				for (const date of value) {
					if (biggest == null) {
						biggest = date;
						continue;
					}
					biggest = biggest > date ? biggest : date;
				}

				return biggest;
			}),
		);

		const earliestAllowed$ = of(moment().startOf('day').toDate());

		return combineLatestSafe([
			earliestPossible$,
			earliestAllowed$,
		]).pipe(
			map(([wanted, earliest]) => {
				// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
				if(wanted == null || wanted <= earliest)
					return earliest;

				return wanted;
			}),
		);
	}

	getLatestStartDate$(contracts: ContractEntry | ContractEntry[]): Observable<Date> {
		if(!Array.isArray(contracts))
			contracts = [contracts];

		const lastestPossible$ = of(contracts).pipe(
			map(cs => cs.map(c => c.contractSection.periodOfValidityAt.withParent.value.pipe(mergeMap(validityAt => validityAt != null ? of(
				validityAt) : c.contractSection.terminatedAt.withParent.value)))),
			mergeMap(l => combineLatestSafe(l)),
			map(startAts => startAts.reduce(
				(prev, curr) => ((curr != null && prev != null && prev < curr) ? curr : (prev ?? curr)),
					null,
			)),
		);

		const latestAllowed$ = of(moment().add(1, 'years').startOf('day').toDate()); // arbitrary limit of 1 year to lower change of incorrect user inputs

		return combineLatest([
			lastestPossible$,
			latestAllowed$,
		]).pipe(
			map(([wanted, latest]) => {
				if(wanted == null || wanted >= latest)
					return latest;

				return wanted;
			}),
		);
	}

	isExpandable(num: number, node: JoinableTreeEntry): boolean {
		return node.expandable && Boolean(node.children) && node.children.length > 0;
	}

	getRequirementErrorsForSelectedIk(contractSection: ContractSectionModel): Promise<RequirementError[]> {
		if(this.ikSelected == null)
			throw new Level8Error('Missing selected IK');

		const mapKey = this.getMapKey(this.ikSelected, contractSection);
		let subject  = this.requirementsResolved.get(mapKey);

		if(subject == null) {
			subject = this.getContractSectionErrorsForSelected(contractSection);
			this.requirementsResolved.set(mapKey, subject);
		}
		return subject;
	}

	getMapKey(ik: InstitutionskennzeichenModel, contract: ContractSectionModel): string {
		return ik.id + contract.id;
	}

	ikHasAlreadyJoined(contract: ContractSectionModel): boolean {
		if(this.ikSelected == null)
			throw new Level8Error('Missing selected IK');

		if(this.contractIkRelations == null)
			throw new Level8Error('Missing contractIkRelations');

		const filteredRelations = this.contractIkRelations
									  .filter((relation) => (contract === relation.contract) && (relation.ik === this.ikSelected));
		return filteredRelations.length > 0;
	}

	transFormNode(node: JoinableTreeEntry, level: number): JoinableTreeEntry {
		node.expandable = node.children.length > 0;
		return node;
	}

	async ikSelectionChange(selectValue: InstitutionskennzeichenModel | null): Promise<void> {
		this.ikSelected = selectValue;
		await this.selected(null);
		this.requirementsResolved.clear();
		this.joiningContracts = [];
		this.loadJoinedContracts(); // preload data async
		await this.stepperProcess();
	}

	private static filterForDuplicates(contractSections: JoinableContractSectionTreeEntry[]): JoinableContractSectionTreeEntry[] {
		const filterMap = new Map<string, JoinableContractSectionTreeEntry>();

		for(const section of contractSections)
			filterMap.set(section.contractSection.id, section);

		return [...filterMap.values()];
	}

	getPartyName$(contract: ContractModel | ContractSectionModel): Observable<string> {
		return contract.joinedParties.withParent
					   .mapToObservable(jp => jp.map(party => party.contractingParty.name.value))
					   .pipe(map(names => names.join(' + ')));
	}

	getNodeVbName$(node: JoinableTreeEntry): Observable<string | undefined> {
		if(!('contractSection' in node))
			throw new Level8Error('Unexpected value');

		const pseudoVb$ = node.contractSection.pseudoVersorgungsbereich.mapToObservable(pvb => pvb?.name.value);
		const vb$       = node.contractSection.versorgungsbereich.mapToObservable(pseudoVersorgungsbereich => pseudoVersorgungsbereich?.name.value);

		return combineLatest([
			pseudoVb$,
			vb$,
		]).pipe(map(([pseudoVb, vb]) => pseudoVb ?? vb));
	}

	getEntryVbName$(entry: ContractEntry): Observable<string | undefined> {
		const pseudoVb$ = entry.contractSection.pseudoVersorgungsbereich.mapToObservable(pvb => pvb?.name.value);
		const vb$       = entry.contractSection.versorgungsbereich.mapToObservable(pseudoVersorgungsbereich => pseudoVersorgungsbereich?.name.value);

		return combineLatest([
			pseudoVb$,
			vb$,
		]).pipe(map(([pseudoVb, vb]) => pseudoVb ?? vb));
	}

	allChildrenAreJoined(node: JoinableTreeEntry): boolean {
		return !this.getChildNodesJoined(node).some(result => !result);
	}

	getChildNodesJoined(node: JoinableTreeEntry): boolean[] {
		const joinedResults = [];

		for(const child of node.children)
			joinedResults.push(this.ikHasAlreadyJoined(child.contractSection));

		return joinedResults;
	}

	async selected(target: ContractingPartyModel | PseudoVersorgungsbereichModel | VersorgungsbereichModel | null | unknown): Promise<void> {
		this.joiningContracts = [];

		if(target == null) {
			this.vbSelected               = null;
			this.contractingPartySelected = null;
			return;
		}

		if(target instanceof ContractingPartyModel) {
			this.vbSelected               = null;
			this.contractingPartySelected = target as unknown as ContractingPartyModel;
		} else {
			if(target instanceof PseudoVersorgungsbereichModel || target instanceof VersorgungsbereichModel) {
				this.contractingPartySelected = null;
				this.vbSelected               = target;
			} else
				throw new Level8Error(`Unexpected selection: ${(target as unknown)?.constructor.name}`);
		}


		this.requirementsResolved.clear();
		await this.updateFilter();
		await this.stepperProcess();
	}

	getIsContractSaving(entry: ContractEntry): boolean {
		return this.isContractSaved.get(entry.contractSection.id) ?? false;
	}

	async joinSelected(): Promise<void> {
		if(this.ikSelected == null)
			return;

		if(this.joiningContracts.length > 1 && this.accessionStartAtControl.valid === false)
			return;

		const defaultStartDate = this.accessionStartAtControl.value;
		const joiningValues = this.joiningContracts
		                          .map(section => {
			                          const joiningDateForm = this.joiningDates.get(section.contractSection.id);
			                          if(joiningDateForm == null)
				                          throw new Level8Error(`Missing joining date for contract section ${section.contractSection.id}`);

			                          if(joiningDateForm.valid === false)
				                          return null;

			                          const joiningDate = joiningDateForm.value ?? defaultStartDate;
			                          if(!(joiningDate != null && (moment.isMoment(joiningDate) || joiningDate instanceof Date)))
				                          throw new Level8Error(`Invalid joining date: '${joiningDate}'`);

			                          return [
				                          section.contractSection,
				                          joiningDate,
			                          ] as const;
		                          });

		if(joiningValues.includes(null))
			return;

		const aSaveList = joiningValues.filter(notNull).map(params => this.createContractAccession(...params));

		this.errorOccurred = undefined;
		this.isSaving      = true;
		Promise.all(aSaveList)
		       .then(() => {
			       this.isSaving = false;
			       this.isSaved  = true;
			       PageCache.remove(this.contractAccessionService); // todo: this should happen in the library
		       })
		       .catch((error) => {
			       this.isSaving      = false;
			       this.isSaved       = false;
			       this.errorOccurred = error;
		       });
	}

	async createContractAccession(contractSection: ContractSectionModel, date: Date | moment.Moment): Promise<void> {
		if(this.ikSelected == null)
			return;

		if(moment.isMoment(date))
			date = date.toDate();

		const form = DtoCreationFormHelper.create(ContractAccessionDtoModel, this.contractAccessionService, {
			institutionskennzeichen: this.ikSelected,
		});
		await form.fill({
			contractSection: contractSection,
			accessionStartAt: date,
		});
		await form.save();

		this.isContractSaved.set(contractSection.id, true);
	}

	selectContract(contract: ContractSectionModel, party: ContractingPartyModel, add: boolean): void {
		// reset dates to force a rebuild of the forms after a step back.
		// required to set the RequiredValidators correct 😓
		this.joiningDates.clear();
		const included = this.isContractSelected(contract);
		if(add && !included) {
			this.joiningContracts.push({
				contractSection: contract,
				party,
			});
		}

		if(!add && included)
			this.joiningContracts = this.joiningContracts.filter(entry => entry.contractSection.id !== contract.id);
	}

	async reload(): Promise<void> {
		await Promise.all([
			this.loadIKs(),
			this.loadContracts(),
		]);
	}

	assertContract(model: unknown): ContractModel {
		if(model instanceof ContractModel)
			return model;

		if(typeof model === 'object')
			throw new Level8Error(`Unexpected model type: ${model?.constructor.name}`);

		throw new Level8Error(`Unexpected model type: ${typeof model}`);
	}

	isContractSelected(contract: ContractSectionModel): boolean {
		const alreadyJoining = this.joiningContracts.filter(entry => entry.contractSection.id === contract.id).length > 0;
		if(alreadyJoining)
			return true;

		if(this.joinedContracts == null || this.joinedContracts.length < 1)
			return alreadyJoining;

		return this.joinedContracts.filter(entry => entry.id === contract.id).length > 0;
	}

	async stepperProcess(): Promise<void> {
		// await 1ms to make sure stepper validation is refreshed
		await new Promise(resolve => setTimeout(resolve, 1));

		let i: number;
		for(i = 0; i < this.stepper.steps.length; i++) {
			const step = this.stepper.steps.get(i);
			if(!step || !step.completed)
				break;
		}

		this.stepper.selectedIndex = i;
	}

	getJoiningDateControl(contractSection: ContractSectionModel): UntypedFormControl {
		let control = this.joiningDates.get(contractSection.id);
		if(control == null) {
			control = new UntypedFormControl(null, this.joiningContracts.length > 1 ? [] : [Validators.required]);
			this.joiningDates.set(contractSection.id, control);
		}

		return control;
	}

	getErrorString(errors: RequirementError[]): string {
		return errors.map((error) => this.translationPipe.transform(error.error, error.args)).join('.\n');
	}

	allChildrenSelected(node: JoinableTreeEntry): boolean {
		const checkList = [];
		for(const child of node.children)
			checkList.push(this.isContractSelected(child.contractSection));

		return !(checkList.indexOf(false) > -1);
	}

	someChildrenSelected(node: JoinableTreeEntry): boolean {
		const checkList = [];
		for(const child of node.children)
			checkList.push(this.isContractSelected(child.contractSection));

		return checkList.indexOf(true) > -1;
	}

	async todoItemSelectionToggle(node: JoinableTreeEntry, $event: MatCheckboxChange): Promise<void> {
		for(const child of node.children) {
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
			if(child.contractSection == null)
				continue;

			const errors                                        = await this.getRequirementErrorsForSelectedIk(child.contractSection);
			let party: ContractingPartyModel | undefined | null = this.contractingPartySelected;
			party ??= (child.party instanceof ContractingPartyModel) ? child.party : null;
			party ??= (await child.contractSection.joinedParties.firstValue)?.reduce(
				(prev: ContractingPartyModel | undefined, curr) => prev ?? curr.contractingParty,
				undefined,
			);

			if(party == null)
				return;

			if(errors.length < 1)
				this.selectContract(child.contractSection, party, $event.checked);

			if(this.ikHasAlreadyJoined(child.contractSection) && errors.length > 0)
				this.selectContract(child.contractSection, party, false);

		}
	}

	allChildrenHaveErrors(node: JoinableTreeEntry): boolean {
		const hasError = this.getChildNodeErrors(node);
		return (hasError.length > 0) && !(hasError.filter(elem => elem === false).length > 0);
	}

	getChildNodeErrors(node: JoinableTreeEntry): boolean[] {
		const hasError = [];

		for(const child of node.children) {
			if(child.errors && child.errors.length > 0)
				hasError.push(true);
			else
				hasError.push(false);

		}
		return hasError;
	}

	allChilrenAreNotSelectable(node: JoinableTreeEntry): boolean {
		const alreadyJoinedList = this.getChildNodesJoined(node);
		const hasErrorsList     = this.getChildNodeErrors(node);
		const unselectableList  = [];

		for(let i = 0; i < node.children.length; i++) {
			if(alreadyJoinedList[i] || hasErrorsList[i])
				unselectableList.push(true);

		}

		return unselectableList.length === node.children.length;
	}

	getNodePartyName$(node: JoinableTreeEntry): Observable<string> {
		if('contractSection' in node)
			return this.getPartyName$(node.contractSection);
		else
			return this.getPartyName$(node.contract);
	}

	async selectAllContracts(): Promise<void> {
		for(const contractEntry of this.joinableContractSectionsFlatTreeData.values()) {
			if(!('contractSection' in contractEntry))
				throw new Level8Error('Unexpected value');

			if(this.ikHasAlreadyJoined(contractEntry.contractSection))
				continue;

			const party = ('party' in contractEntry) ? contractEntry.party : this.contractingPartySelected;
			if(party == null)
				throw new Level8Error('Missing parameter');

			const errors = await this.getRequirementErrorsForSelectedIk(contractEntry.contractSection);
			if(errors.length > 0)
				continue;

			if(party instanceof ContractingPartyModel)
				this.selectContract(contractEntry.contractSection, party, true);
			else {
				const partyErrors = await this.getRequirementErrorsForSelectedIk(contractEntry.contractSection);
				if(partyErrors.length > 0)
					continue;

				this.selectContract(
					contractEntry.contractSection,
					party.contractingParty,
					true,
				);
			}
		}
	}

	private async contractSectionHasVb(contractSection: ContractSectionModel, vb: PseudoVersorgungsbereichModel | VersorgungsbereichModel): Promise<boolean> {
		let value: PseudoVersorgungsbereichModel | VersorgungsbereichModel | null | undefined;
		if(this.vbSelected == null)
			return false;


		if(this.vbSelected instanceof PseudoVersorgungsbereichModel)
			value = await contractSection.pseudoVersorgungsbereich.firstValue;


		if(this.vbSelected instanceof VersorgungsbereichModel)
			value = await contractSection.versorgungsbereich.firstValue;


		if(value == null)
			return false;


		if(value.id === this.vbSelected.id)
			return true;


		return false;
	}

	private async updateFilter(): Promise<void> {
		this.possibleVbContracts                    = undefined;
		this.possiblePartyContracts                 = undefined;
		this.joinableContractSectionsFlatTreeData   = new Map();
		this.joinableContractSectionsNestedTreeData = new Map();

		if(this.masterContracts == null)
			return;

		let allContracts: ContractModel[] = [];

		for(const masterContract of this.masterContracts) {
			const contracts = await masterContract.contracts.firstValue;
			if(contracts == null)
				continue;

			allContracts = [
				...allContracts,
				...contracts,
			];
		}
		await this.buildContractsTreeData(allContracts);
		this.treeDataSource.data = [...this.joinableContractSectionsNestedTreeData.values()];
	}

	private async getContractSectionErrorsForSelected(contractSection: ContractSectionModel): Promise<RequirementError[]> {
		if(this.ikSelected == null)
			return [];

		const promiseResults: RequirementError[] = [];
		const requirements                       = await contractSection.requirements.withParent.firstValue;

		for(const requirement of requirements) {
			const isRequirementResolved = await requirement.getResult(this.ikSelected);
			if(isRequirementResolved)
				continue;

			const requirementFunction = await requirement.requirementFunction.firstValue;
			if(requirementFunction == null)
				continue;


			const functionName = await requirementFunction.name.firstValue;
			if(functionName == null)
				continue;

			switch(functionName) {
				case RequirementFunctionName.preQualificationCertificateExistsCheck:
					const vbId   = ((await requirement.parameters.firstValue) as PreQualificationCertificateExistsCheckInterface).versorgungsbereich_id;
					const vb     = this.versorgungsbereichService.getById(vbId);
					const vbName = await vb.name.firstValue;

					const error1: RequirementError = {
						error: 'contractRequirement.error.preQualificationCertificateExistsCheck',
						args:  {vb: vbName},
					};
					promiseResults.push(error1);
					break;

				case RequirementFunctionName.employeeHasDataCheck:
				case RequirementFunctionName.negateEmployeeHasDataCheck:
					const relation = ((await requirement.parameters.firstValue) as EmployeeHasDataCheckInterface).relation;
					if(relation !== 'roles')
						throw new Level8Error(`You forgot to implement an error string for '${functionName}' for relation ${relation}`);

					const roleId   = ((await requirement.parameters.firstValue) as EmployeeHasDataCheckInterface).value;
					if(typeof roleId !== 'string')
						throw new Error(`Unexpected type, string expected, got ${typeof roleId}`);

					const role     = this.roleService.getById(roleId);
					const roleName = await role.name.firstValue;

					const error2: RequirementError = {
						error: `contractRequirement.error.${functionName}.${relation}`,
						args:  {role: roleName},
					};
					promiseResults.push(error2);
					break;

				case RequirementFunctionName.medicalStorePropertyIsOneOfValueCheck:
					const parameters = (await requirement.parameters.firstValue) as MedicalStorePropertyHasValueOfValuesCheckInterface;

					const error3: RequirementError = {
						error: `contractRequirement.error.${functionName}.state`,
						args:  {role: parameters},
					};

					promiseResults.push(error3);
					break;

				case RequirementFunctionName.preQualificationCertificateOneOfExistsCheck:
					const vbsId   = ((await requirement.parameters.firstValue) as PreQualificationCertificateOneOfExistsCheck).versorgungsbereich_ids;
					const vbs     = vbsId.map(vb => this.versorgungsbereichService.getById(vb));
					const vbNames = await Promise.all(vbs.map(vb => vb.name.firstValue));

					const error4: RequirementError = {
						error: 'contractRequirement.error.preQualificationCertificateOneOfExistsCheck',
						args:  {vbs: vbNames.join(', ')},
					};
					promiseResults.push(error4);
					break;


				default:
					throw new Level8Error(`You forgot to implement an error string for '${functionName}'`);
			}
		}
		return promiseResults;
	}

	private async loadIKs(): Promise<void> {
		const iks = new Map<string, InstitutionskennzeichenModel>();

		for(const ik of await this.institutionskennzeichenService.getAllModels()) {
			if(!(await ik.contractAccessions.permissions.canCreate))
				continue;

			const number = await ik.number.firstValue;
			if(number != null)
				iks.set(number, ik);
		}

		this.iks = iks;

		let newIk: InstitutionskennzeichenModel | null | undefined;

		if(iks.size === 1)
			newIk = [...iks][0][1]; // first (and only) VALUE of the map

		if(this.ikSelected !== null && !iks.has(await this.ikSelected.number.firstValue ?? ''))
			newIk = null;

		if(newIk !== undefined)
			this.ikSelectionChange(newIk);

	}

	hasRequirementErrors(node: JoinableTreeEntry): boolean {
		if(node.errors == null)
			return true;

		return node.errors.length > 0;
	}

	private async loadJoinedContracts(): Promise<void> {
		if(this.ikSelected == null)
			throw new Error('no ik was selected');

		const joinedContracts: ContractSectionModel[]         = [];
		const joinedContractIkRelations: ContractIkRelation[] = [];

		const contractAccessions = (await this.contractAccessionService.find({
			column:     'institutionskennzeichenId',
			comparator: '=',
			value:      this.ikSelected.id,
		})).data;

		for(const contractAccession of contractAccessions) {
			const endAt = await contractAccession.accessionEndAt.firstValue;
			if(endAt != null && endAt < new Date())
				continue;

			const contract = await contractAccession.contractSection.firstValue;
			if(!contract) // TODO Error checking
				continue;

			joinedContracts.push(contract);

			const ik = await contractAccession.institutionskennzeichen.firstValue;
			if(!ik)
				continue;

			joinedContractIkRelations.push({
				contract,
				ik,
			});
		}

		this.joinedContracts     = joinedContracts;
		this.contractIkRelations = joinedContractIkRelations;
	}

	private async loadContracts(): Promise<void> {
		await this.preloadIntoCache();

		const addJoinedParties = async (parties: Map<string, ContractingPartyModel>, joinedPartiesPromise?: Promise<JoinedPartyInterface[] | undefined>) => {
			const joinedParties = await joinedPartiesPromise;

			if(joinedParties === undefined)
				return;

			for(const joinedParty of joinedParties) {
				if(joinedParty.leftAt != null)
					continue;

				const partyName = await joinedParty.contractingParty.name.firstValue;
				if(partyName == null)
					continue;

				parties.set(partyName, joinedParty.contractingParty);
			}
		};

		const addVb = async (mapFn: Map<string, VersorgungsbereichModel>, vbsPromise: Promise<VersorgungsbereichModel | undefined | null>) => {
			const vb = await vbsPromise;
			if(vb == null)
				return;

			const name = await vb.name.firstValue;
			if(name === undefined || mapFn.has(name))
				return;

			mapFn.set(name, vb);
		};


		const addPsVb = async (mapFn: Map<string, PseudoVersorgungsbereichModel>, vbsPromise: Promise<PseudoVersorgungsbereichModel | undefined | null>) => {
			const vb = await vbsPromise;
			if(vb == null)
				return;

			const name = await vb.name.firstValue;
			if(name === undefined || mapFn.has(name))
				return;

			mapFn.set(name, vb);
		};

		this.masterContracts    = undefined;
		const masterContracts   = [];
		const contractingPartys = new Map();
		const vbs               = new Map();
		const now               = new Date();

		for(const masterContract of (await this.masterContractService.getAllModels())) {
			const periodOfValidityAt = await masterContract.periodOfValidityAt.firstValue;
			if(periodOfValidityAt != null && periodOfValidityAt < now)
				continue;

			const terminatedAt = await masterContract.terminatedAt.firstValue;
			if(periodOfValidityAt == null && terminatedAt != null && terminatedAt < now)
				continue;

			masterContracts.push(masterContract);
		}

		const contractSections = await this.contractSectionService.getAllModels();
		for(const contractSection of contractSections) {
			const periodOfValidityAt = await contractSection.periodOfValidityAt.firstValue;
			if(periodOfValidityAt != null && periodOfValidityAt < now)
				continue;

			const terminatedAt = await contractSection.terminatedAt.firstValue;

			if(periodOfValidityAt == null && terminatedAt != null && terminatedAt < now)
				continue;

			await addJoinedParties(contractingPartys, contractSection.joinedParties.withParent.firstValue);
			await addVb(vbs, contractSection.versorgungsbereich.firstValue);
			await addPsVb(vbs, contractSection.pseudoVersorgungsbereich.firstValue);
		}

		this.masterContracts   = masterContracts;
		this.contractingPartys = contractingPartys;
		this.vbs               = vbs;
	}

	private async preloadIntoCache(): Promise<void> {
		if(ContractAccessingPageComponent.cachePrepared)
			return;

		await Promise.all([
			this.masterContractService.getAllPages(),
			this.contractService.getAllPages(),
			this.contractSectionService.getAllPages(),
			this.contractingPartyService.getAllPages(),
			this.versorgungsbereichService.getAllPages(),
			this.pseudoVersorgungsBereichService.getAllPages(),
		]);

		ContractAccessingPageComponent.cachePrepared = true;
	}

	private async buildContractsTreeData(allContracts: ContractModel[]): Promise<JoinableTreeEntry[]> {
		const treeData: JoinableTreeEntry[] = [];
		for(const contract of allContracts) {
			const entry = await this.buildContractsSectionTreeData(contract);
			if(entry == null)
				continue;

			treeData.push(entry);
		}
		return treeData;
	}

	private async buildContractsSectionTreeData(contract: ContractModel): Promise<JoinableTreeEntry | null> {
		const contractSections = await contract.contractSections.firstValue;
		if(contractSections == null)
			return null;

		let contractSectionsEntrys: JoinableContractSectionTreeEntry[] = [];
		for(const section of contractSections) {
			const entrys = await this.sectionToJoinableEntries(section, contract);
			if(entrys == null)
				continue;


			const filteredEntries  = ContractAccessingPageComponent.filterForDuplicates(entrys);
			contractSectionsEntrys = [
				...contractSectionsEntrys,
				...filteredEntries,
			];
		}


		const getVbName        = (s: ContractSectionModel) => s.pseudoVersorgungsbereich.currentValue?.name.currentValue ?? s.versorgungsbereich.currentValue?.name.currentValue ?? '';
		contractSectionsEntrys = contractSectionsEntrys.sort((a, b) => getVbName(a.contractSection) < getVbName(b.contractSection) ? -1 : 1);

		if(contractSectionsEntrys.length < 1)
			return null;

		for(const section of contractSectionsEntrys)
			this.joinableContractSectionsFlatTreeData.set(section.contractSection.id, section);

		const newContractJoinable: JoinableTreeEntry = {
			contract,
			children:   contractSectionsEntrys,
			expandable: true,
			level:      0,
		};

		this.joinableContractSectionsNestedTreeData.set(newContractJoinable.contract.id, newContractJoinable);
		return newContractJoinable;
	}

	private async sectionToJoinableEntries(section: ContractSectionModel, contract: ContractModel): Promise<JoinableContractSectionTreeEntry[] | null> {
		const newTreeEntries: JoinableContractSectionTreeEntry[] = [];
		if(this.vbSelected == null && this.contractingPartySelected == null)
			throw new Error('contract-accession: no filter selected');

		if(this.vbSelected != null) {
			if(!(await this.contractSectionHasVb(section, this.vbSelected)))
				return null;

			const parties = await section.joinedParties.withParent.firstValue;
			for(const party of parties) {
				if(party.leftAt != null)
					continue;

				const elem: JoinableContractSectionTreeEntry = {
					contract,
					contractSection: section,
					level:           1,
					expandable:      false,
					party:           party.contractingParty,
					children:        [],
				};

				this.getRequirementErrorsForSelectedIk(section).then((errors) => {
					elem.errors = errors;
				});

				newTreeEntries.push(elem);
			}
		}

		if(this.contractingPartySelected) {
			const parties = await section.joinedParties.withParent.firstValue;

			for(const party of parties) {
				if(this.contractingPartySelected.id === party.contractingParty.id) {
					const elem: JoinableTreeEntry = {
						contract,
						contractSection: section,
						level:           1,
						expandable:      false,
						party:           party.contractingParty,
						children:        [],
					};

					this.getRequirementErrorsForSelectedIk(section).then((errors) => {
						elem.errors = errors;
					});

					newTreeEntries.push(elem);
				}
			}
		}

		return newTreeEntries;
	}
}
