import {Level8Error} from '@angular-helpers/frontend-api';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import moment from 'moment';
import DurationConstructor = moment.unitOfTime.DurationConstructor;

export interface RelativeDateObject {
	prefix: '-' | '+';
	unit: DurationConstructor;
	amount: number;
}

export interface RelativeDateObjectPlaceHolder {
	unit: string;
	amount: string;
}

export interface DateSelect {
	description: string;
	value: string | undefined;
	argument?: DateSelectArguments;
}

export interface DateSelectArguments {
	amount?: number;
	unit?: string;
}

export enum DateUnit {
	day   = 'day',
	week  = 'week',
	month = 'month',
	quarter = 'quarter',
	year  = 'year',
}

export enum DateTranslation {
	day     = 'relativeDates.day',
	week    = 'relativeDates.week',
	month   = 'relativeDates.month',
	quarter = 'relativeDates.quarter',
	year    = 'relativeDates.year',
}

export enum DateTranslationSingle {
	day     = 'relativeDates.days',
	week    = 'relativeDates.weeks',
	month   = 'relativeDates.months',
	quarter = 'relativeDates.quarters',
	year    = 'relativeDates.years',
}

export enum DateTranslationAmbiguous {
	day     = 'relativeDates.dayS',
	week    = 'relativeDates.weekS',
	month   = 'relativeDates.monthS',
	quarter = 'relativeDates.quarterS',
	year    = 'relativeDates.yearS',
}

export enum RelativeDateTimeAnchour {
	endOf       = 'end of',
	beginningOf = 'beginning of next',
}

export const relativeDateBaseDelayOptions: DateSelect[] = [
	{
		description: 'relativeDates.immediately',
		value: '+0 days',
	},
];

type RelativeDateBaseDelayOptions = typeof relativeDateBaseDelayOptions;

export const relativeDateOptions: DateSelect[] = [
	{
		description: 'relativeDates.beginningOfNextYearAnd',
		value:       '6 months + first day of next year',
		argument:    {
			amount: 6,
			unit:   'relativeDates.months',
		},
	},
	{
		description: 'relativeDates.beginningOfNextHalfyearAnd',
		value:       '6 months + first day of next half-year',
		argument:    {
			amount: 6,
			unit:   'relativeDates.months',
		},
	},
	{
		description: 'relativeDates.beginningOfNextHalfyearAnd',
		value:       '3 months + first day of next half-year',
		argument:    {
			amount: 3,
			unit:   'relativeDates.months',
		},
	},
	{
		description: 'relativeDates.beginningOfNextQuarterAnd',
		value:       '3 months + first day of next quarter',
		argument:    {
			amount: 3,
			unit:   'relativeDates.months',
		},
	},
	{
		description: 'relativeDates.beginningOfNextQuarterAnd',
		value:       '6 weeks + first day of next quarter',
		argument:    {
			amount: 6,
			unit:   'relativeDates.weeks',
		},
	},
	{
		description: 'relativeDates.beginningOfNextMonthAnd',
		value:       '3 months + first day of next month',
		argument:    {
			amount: 3,
			unit:   'relativeDates.months',
		},
	},
	{
		description: 'relativeDates.beginningOfNextMonthAnd',
		value:       '2 weeks + first day of next month',
		argument:    {
			amount: 2,
			unit:   'relativeDates.weeks',
		},
	},
	{
		description: 'relativeDates.beginningOfNextMonthAnd',
		value:       '4 weeks + first day of next month',
		argument:    {
			amount: 4,
			unit:   'relativeDates.weeks',
		},
	},
];

type RelativeDateOptions = typeof relativeDateOptions;

@Injectable({
	providedIn: 'root',
})
export class RelativeDateService {

	constructor(
		protected readonly translateService: TranslateService,
	) {
	}

	unitValueToDescription(unit: string): string {
		if(unit.includes(DateUnit.day))
			return DateTranslationAmbiguous.day;

		if(unit.includes(DateUnit.week))
			return DateTranslationAmbiguous.week;

		if(unit.includes(DateUnit.month))
			return DateTranslationAmbiguous.month;

		if(unit.includes(DateUnit.quarter))
			return DateTranslationAmbiguous.quarter;

		if(unit.includes(DateUnit.year))
			return DateTranslationAmbiguous.year;

		throw new Level8Error(`Unexpected value '${unit}'`);
	}

	unitDescriptionToValue(unit: string): DurationConstructor {

		let baseUnit: DurationConstructor = 'day';

		if(unit.includes(DateUnit.day)) {
			baseUnit = 'day';
			return baseUnit;
		}

		if(unit.includes(DateUnit.week)) {
			baseUnit = 'week';
			return baseUnit;
		}

		if(unit.includes(DateUnit.month)) {
			baseUnit = 'month';
			return baseUnit;
		}

		if(unit.includes(DateUnit.quarter)) {
			baseUnit = 'quarter';
			return baseUnit;
		}

		if(unit.includes(DateUnit.year)) {
			baseUnit = 'year';
			return baseUnit;
		}

		throw new Level8Error(`Unexpected value '${unit}'`);
	}

	optionValueToDescription(value: string): string {
		const element = this.getAllSelectOptions().find(select => value === select.value);
		if(element == null) {
			// todo log to backend
			console.warn(`Unexpected relative date value '${value}'`);
			return value;
		}

		return this.dateOptionToTranslatedDescription(element);
	}

	optionDescriptionToValue(value: string): string {
		const element = this.getAllSelectOptions().find((select) => value === select.description);
		if(element == null) {
			// todo log to backend
			console.warn(`Unexpected relative date description '${value}'`);
			return value;
		}

		if(element.value == null)
			throw new Level8Error(`missing value in option '${element}'`);


		return element.value;
	}

	valueExists(relativeDate: string): boolean {
		const dropValue = relativeDateOptions.find((select) => relativeDate === select.value);
		return dropValue != null;
	}

	optionDescriptionExists(relativeDate: string): boolean {
		const dropValue = relativeDateOptions.find((select) => relativeDate === select.description);
		return dropValue != null;
	}

	optionValueExists(relativeDate: string): boolean {
		const dropValue = this.getAllSelectOptions().find((select) => relativeDate === select.value);
		return dropValue != null;
	}

	delayOptionValueExists(relativeDate: string): boolean {
		return relativeDateOptions.find((select) => relativeDate === select.value) != null;
	}

	getAllSelectOptions(): RelativeDateOptions {
		return [
			...relativeDateOptions,
			...relativeDateBaseDelayOptions,
		];
	}

	getBaseDelayOptions(): RelativeDateBaseDelayOptions {
		return relativeDateBaseDelayOptions;
	}

	getDelayOptions(): RelativeDateOptions {
		return relativeDateOptions;
	}

	valueToDateObject(setValue: string): RelativeDateObject | undefined {
		const regexAmount = /[+,\-]?(\d+)/;
		const regexUnit   = /\s?(\S+)$/;

		let amount: number | undefined   = undefined;
		let unit: DurationConstructor = DateUnit.day;

		const trimmedValue = setValue.trim();
		const amountMatch  = trimmedValue.match(regexAmount);
		const unitMatch    = trimmedValue.match(regexUnit);
		const pMatch       = trimmedValue.match(/\+/);
		const prefix       = (pMatch != null) && (pMatch.length > 0) ? '+' : '-';

		if(amountMatch == null || unitMatch == null)
			return undefined;

		amount = Number.parseInt(amountMatch[1], 10);
		unit   = this.unitDescriptionToValue(unitMatch[1]);

		return {
			prefix,
			amount,
			unit,
		};
	}

	dateObjectToDescription(date: RelativeDateObject): string {
		return `${date.prefix}${date.amount} ${this.unitValueToDescription(date.unit)}`;
	}

	dateObjectToTranslatedDescription(date: RelativeDateObject): string {
		return `${date.amount} ${this.translateService.instant(this.unitValueToDescription(date.unit))}`;
	}

	dateOptionToTranslatedDescription(dateOption: DateSelect): string {
		return this.translateService.instant(dateOption.description, dateOption.argument);
	}

	dateObjectToValue(date: RelativeDateObject): string | null {
		if(Number.isNaN(date.amount))
			return null;

		try {
			return `${date.prefix}${date.amount} ${this.unitDescriptionToValue(date.unit)}`;
		} catch(e) {
			return null;
		}
	}

	calcNewDateFromOption(date: moment.Moment | Date | string, relativeDate: string): Date {
		if(relativeDate === 'today')
			return new Date(moment(date).toString());

		const parts = relativeDate.split(' + ');

		// fallback solution until implementation // TODO remove

		let startDate;
		let nextUnitChapter;

		if (parts[0].includes('first day')){
			startDate = parts[1];
			nextUnitChapter = parts[0];
		}
		if (parts[1].includes('first day')){
			nextUnitChapter = parts[1];
			startDate = parts[0];
		}

		if(startDate == null || nextUnitChapter == null)
			throw new Error(`option doesn't exist (${relativeDate})`);


		const durationObj = this.valueToDateObject(startDate);

		if(durationObj == null)
			throw new Error(`could not evaluate Date (${relativeDate})`);


		const baseDate = moment(date).add(durationObj.amount, durationObj.unit);
		
		if(nextUnitChapter.includes(DateUnit.week)) {
			const newBaseDate = baseDate.startOf(DateUnit.week);
			const relative = moment.duration(1, DateUnit.week);

			const newDate = newBaseDate.add(relative);
			return new Date(newDate.toString());
		}

		if(nextUnitChapter.includes(DateUnit.month)) {
			const newBaseDate = baseDate.startOf(DateUnit.month);
			const relative = moment.duration(1, DateUnit.month);

			const newDate = newBaseDate.add(relative);
			return new Date(newDate.toString());
		}

		if(nextUnitChapter.includes(DateUnit.quarter)) {
			const newBaseDate = baseDate.startOf(DateUnit.quarter);
			const relative = moment.duration(1, DateUnit.quarter);

			const newDate = newBaseDate.add(relative);
			return new Date(newDate.toString());
		}

		if(nextUnitChapter.includes(DateUnit.year)) {
			const newBaseDate = baseDate.startOf(DateUnit.year);
			const relative = moment.duration(1, DateUnit.year);

			const newDate = newBaseDate.add(relative);
			return new Date(newDate.toString());
		}


		throw new Error(`options not implemented yet (${relativeDate})`);
	}


	calcNewDate(date: moment.Moment | Date | string, relativeDate: string): Date {
		const relativeDateObject = this.valueToDateObject(relativeDate);

		if(relativeDateObject == null)
			throw new Error(`could not evaluate date (${relativeDate})`);


		const number: moment.DurationInputArg1 = relativeDateObject.prefix === '+' ? relativeDateObject.amount : -relativeDateObject.amount;
		const duration                         = moment.duration(number, relativeDateObject.unit);
		return new Date(moment(date).add(duration).toString());
	}
}
