import {
	Component,
	Input,
} from '@angular/core';
import {environment} from '@app/environment';
import {UrlType} from '@app/main';
import {JoinedPartyInterface} from '@contracts/frontend-api';

@Component({
	selector:    'portal-contract-accession-joined-party',
	templateUrl: './contract-accession-joined-party.component.html',
	styleUrls:   ['./contract-accession-joined-party.component.scss'],
})
export class ContractAccessionJoinedPartyComponent {
	@Input({required: true}) model: JoinedPartyInterface[] | JoinedPartyInterface | null | undefined;

	readonly urlType     = UrlType;
	readonly environment = environment;
}
