import {
	Directive,
	TemplateRef,
} from '@angular/core';

@Directive({
	selector: 'ng-template[skeletonContent]',
})
export class SkeletonContentDirective {
	constructor(public template: TemplateRef<unknown>) { }
}
