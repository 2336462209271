<portal-editable [label]="label" [validator]="validator">
	<mat-slide-toggle [formControl]="validator" (toggle)="change.next(validator.value)">
		@if (validator.value) {
			{{ labelTrue | translate }}
			<ng-content select=".labelTrue"></ng-content>
		}

		@if (!validator.value) {
			{{ labelFalse | translate }}
			<ng-content select=".labelFalse"></ng-content>
		}
	</mat-slide-toggle>
</portal-editable>
