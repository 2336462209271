import {
	combineLatest,
	Observable,
	ObservableInput,
	ObservedValueOf,
	of,
} from 'rxjs';

export function combineLatestSafe<O1 extends ObservableInput<unknown>>(sources: [O1]): Observable<[ObservedValueOf<O1>]>;
export function combineLatestSafe<O1 extends ObservableInput<unknown>, O2 extends ObservableInput<unknown>>(sources: [O1, O2]): Observable<[ObservedValueOf<O1>, ObservedValueOf<O2>]>;
export function combineLatestSafe<O1 extends ObservableInput<unknown>, O2 extends ObservableInput<unknown>, O3 extends ObservableInput<unknown>>(sources: [O1, O2, O3]): Observable<[ObservedValueOf<O1>, ObservedValueOf<O2>, ObservedValueOf<O3>]>;
export function combineLatestSafe<O1 extends ObservableInput<unknown>, O2 extends ObservableInput<unknown>, O3 extends ObservableInput<unknown>, O4 extends ObservableInput<unknown>>(sources: [O1, O2, O3, O4]): Observable<[ObservedValueOf<O1>, ObservedValueOf<O2>, ObservedValueOf<O3>, ObservedValueOf<O4>]>;
export function combineLatestSafe<O1 extends ObservableInput<unknown>, O2 extends ObservableInput<unknown>, O3 extends ObservableInput<unknown>, O4 extends ObservableInput<unknown>, O5 extends ObservableInput<unknown>>(sources: [O1, O2, O3, O4, O5]): Observable<[ObservedValueOf<O1>, ObservedValueOf<O2>, ObservedValueOf<O3>, ObservedValueOf<O4>, ObservedValueOf<O5>]>;
export function combineLatestSafe<O1 extends ObservableInput<unknown>, O2 extends ObservableInput<unknown>, O3 extends ObservableInput<unknown>, O4 extends ObservableInput<unknown>, O5 extends ObservableInput<unknown>, O6 extends ObservableInput<unknown>>(sources: [O1, O2, O3, O4, O5, O6]): Observable<[ObservedValueOf<O1>, ObservedValueOf<O2>, ObservedValueOf<O3>, ObservedValueOf<O4>, ObservedValueOf<O5>, ObservedValueOf<O6>]>;
export function combineLatestSafe<O extends ObservableInput<unknown>>(sources: O[]): Observable<ObservedValueOf<O>[]>;
export function combineLatestSafe<O1 extends ObservableInput<unknown>>(sources: [O1] | undefined): Observable<[ObservedValueOf<O1>]> | Observable<undefined>;
export function combineLatestSafe<O1 extends ObservableInput<unknown>, O2 extends ObservableInput<unknown>>(sources: [O1, O2] | undefined): Observable<[ObservedValueOf<O1>, ObservedValueOf<O2>]> | Observable<undefined>;
export function combineLatestSafe<O1 extends ObservableInput<unknown>, O2 extends ObservableInput<unknown>, O3 extends ObservableInput<unknown>>(sources: [O1, O2, O3] | undefined): Observable<[ObservedValueOf<O1>, ObservedValueOf<O2>, ObservedValueOf<O3>]> | Observable<undefined>;
export function combineLatestSafe<O1 extends ObservableInput<unknown>, O2 extends ObservableInput<unknown>, O3 extends ObservableInput<unknown>, O4 extends ObservableInput<unknown>>(sources: [O1, O2, O3, O4] | undefined): Observable<[ObservedValueOf<O1>, ObservedValueOf<O2>, ObservedValueOf<O3>, ObservedValueOf<O4>]> | Observable<undefined>;
export function combineLatestSafe<O1 extends ObservableInput<unknown>, O2 extends ObservableInput<unknown>, O3 extends ObservableInput<unknown>, O4 extends ObservableInput<unknown>, O5 extends ObservableInput<unknown>>(sources: [O1, O2, O3, O4, O5] | undefined): Observable<[ObservedValueOf<O1>, ObservedValueOf<O2>, ObservedValueOf<O3>, ObservedValueOf<O4>, ObservedValueOf<O5>]> | Observable<undefined>;
export function combineLatestSafe<O1 extends ObservableInput<unknown>, O2 extends ObservableInput<unknown>, O3 extends ObservableInput<unknown>, O4 extends ObservableInput<unknown>, O5 extends ObservableInput<unknown>, O6 extends ObservableInput<unknown>>(sources: [O1, O2, O3, O4, O5, O6] | undefined): Observable<[ObservedValueOf<O1>, ObservedValueOf<O2>, ObservedValueOf<O3>, ObservedValueOf<O4>, ObservedValueOf<O5>, ObservedValueOf<O6>]> | Observable<undefined>;
export function combineLatestSafe<O extends ObservableInput<unknown>>(sources: O[] | undefined): Observable<ObservedValueOf<O>[]> | Observable<undefined>;
export function combineLatestSafe(sources: ObservableInput<unknown>[] | undefined): unknown {
	if(sources == null)
		return of(sources);

	if(sources.length < 1)
		return of([]);

	return combineLatest(sources);
}
