<div mat-dialog-title class="dialog-title logo">
	<fa-icon class="dialog-icon" [icon]="iconService.ICON_ALERT"></fa-icon>
	<div class="title-text">{{ 'system.failedVersionSave.title' | translate }}</div>
</div>
<div mat-dialog-content class="scroll-shadows dialog-content">
	<div class="dialog-content">
		@for (content of ('system.failedVersionSave.content' | translate | asArray);track content) {
			<p>{{ content }}</p>
		}
	</div>
</div>
<div mat-dialog-actions class="actionbar">
	<div class="actions">
		<button mat-stroked-button (click)="reloadPage()">{{ 'system.failedVersionSave.actions.negative' | translate }}</button>
		<portal-spinning-button
			(clicked)="this.confirmAction.emit()"
			[processing]="isSaving"
			class="right"
			text="system.failedVersionSave.actions.positive"
		/>
	</div>
</div>
