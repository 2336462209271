<portal-editable
	label="export.recipients"
	[validator]="control"
>
	<mat-form-field>
		<portal-input-status-icon matSuffix [validator]="control"/>
		<mat-chip-grid #chipList>
			@for (recipient of recipients;track recipient) {
				<mat-chip-row
					(removed)="remove(recipient)"
				>
					{{ recipient }}
					<button matChipRemove>
						<fa-icon [icon]="iconService.REMOVE"></fa-icon>
					</button>
				</mat-chip-row>
			}
			<input
				[formControl]="inputControl"
				[matChipInputFor]="chipList"
				[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
				(matChipInputTokenEnd)="addRecipient()"
				(focusout)="addRecipient()"
				type="email"
			/>
		</mat-chip-grid>
		@for (error of formHelperService.getErrors(inputControl) | keyvalue;track error) {
			<mat-error>
				{{ 'input.error.' + error.key | translate: error.value }}
			</mat-error>
		}
		@for (error of formHelperService.getErrors(control) | keyvalue;track error) {
			<mat-error>
				{{ 'input.error.' + error.key | translate: error.value }}
			</mat-error>
		}
	</mat-form-field>
</portal-editable>
