import {
	Component,
	Input,
} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {IconService} from '../../services/icon.service';

@Component({
	selector:    'portal-editable',
	templateUrl: './editable.component.html',
	styleUrls:   ['./editable.component.scss'],
})
export class EditableComponent {
	@Input() icon?: IconDefinition | undefined;
	@Input({required: true}) label: string | null | undefined;
	@Input({required: true}) validator?: AbstractControl | undefined;
	@Input() showAlways = false;
	@Input() description?: string | undefined;

	constructor(
			protected readonly iconService: IconService,
	) {
	}

	get visible(): boolean {
		if(this.showAlways)
			return true;

		if(!this.validator)
			return false;

		if(this.validator.enabled)
			return true;

		if(this.validator.value != null)
			return true;

		return false;
	}
}
