import {
	InheritedCoalesceProperty,
	InheritMergedProperty,
} from '@angular-helpers/frontend-api';
import {DatePipe} from '@angular/common';
import {
	Component,
	EventEmitter,
	Input,
	Output,
	TemplateRef,
	TrackByFunction,
} from '@angular/core';
import {
	FullColumn,
	IconService,
	notNull,
	TableColumnData,
	TableHeaderData,
	TableSearchHeaderData,
} from '@app/main';
import {
	faChevronLeft,
	faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import {
	Observable,
	of,
} from 'rxjs';
import {map} from 'rxjs/operators';

export interface SearchOptions {
	id: string;
	searchNow?: boolean;
	page?: number;
}

export interface Column<ModelType, RawType = unknown> {
	isVisible: boolean;
	inMenu?: boolean;
	label: string;
	filter?: RawType;
	contentTemplate: (() => TemplateRef<TableColumnData<ModelType>> | undefined);
	headerTemplate: (() => TemplateRef<TableHeaderData<ModelType>> | undefined);
	searchHeaderTemplate?: (() => TemplateRef<TableSearchHeaderData<ModelType, RawType>> | undefined);
	index?: number;
	onSearch?: ((value: RawType, options: SearchOptions) => void | Promise<void>);
	serialize?: (value: RawType) => string,
	deserialize?: (value: string) => RawType,
}

@Component({
	selector:    'portal-table',
	templateUrl: './table.component.html',
	styleUrls:   ['./table.component.scss'],
})
export class TableComponent<T extends object> {
	@Input() customMenu?: TemplateRef<unknown>;
	@Input({required: true}) headers!: Record<string, Column<T> | undefined>;
	@Input({required: true}) data?: T[];
	@Input() trackBy: TrackByFunction<unknown> = (index, value) => (value != null && typeof value == 'object' && 'id' in value) ? value.id : index;
	@Input() baseLink?: string;
	@Input() isLoading                         = false;
	@Input() hasNextPage                       = false;
	@Input() currentPage                       = 0;
	@Output() readonly currentPageChange       = new EventEmitter<number>();
	@Input() getRowLink?: ((entry: T) => string | undefined);
	@Input() withMenu                          = true;

	@Output() readonly previousPage = new EventEmitter<void>();
	@Output() readonly nextPage     = new EventEmitter<void>();

	protected readonly SEARCH_COLUMN_SUFFIX = '_search';
	protected readonly MENU_COLUMN_ID       = 'menu';

	readonly DEFAULT_ROW_LINK_FUNCTION = (entry: T): string | undefined => {
		if(this.baseLink == null)
			return undefined;

		if(!('id' in entry) || typeof entry.id !== 'string')
			return undefined;

		return `${this.baseLink}/${entry.id}`;
	};

	readonly pageNext = faChevronRight; // todo: move to icon service
	readonly pageBack = faChevronLeft;

	constructor(
			protected readonly iconService: IconService,
			protected readonly datePipe: DatePipe,
	) {
	}

	get columnIds(): string[] {
		return Object.keys(this.headers)
					 .sort((keyA, keyB) => TableComponent.compareColumn(this.headers[keyA], this.headers[keyB]));
	}
	protected readonly console = console;

	get visibleColumnIds(): string[] {
		return this.columnIds.filter(key => this.headers[key]?.isVisible);
	}

	get visibleHeaderColumnIds(): string[] {
		const ids = this.columnIds.filter(key => this.headers[key]?.isVisible);
		if(this.withMenu)
			ids.push(this.MENU_COLUMN_ID);

		return ids;
	}

	get columns(): Column<T>[] {
		return Object.values(this.headers)
		             .filter(notNull)
		             .sort(TableComponent.compareColumn);
	}

	get visibleSearchColumnIds(): string[] {
		return this.visibleColumnIds.map(x => `${x}${this.SEARCH_COLUMN_SUFFIX}`);
	}

	private static compareColumn<T>(a: Column<T> | undefined, b: Column<T> | undefined): -1 | 0 | 1 {
		if(a == null && b == null)
			return 0;

		if(a == null)
			return 1;

		if(b == null)
			return -1;

		if(a.index === b.index)
			return 0;

		if(a.index == null)
			return 1;

		if(b.index == null)
			return -1;

		if(a.index < b.index)
			return -1;

		return 1;
	}

	_getRowLink(entry: T): string | undefined {
		if(this.getRowLink != null)
			return this.getRowLink(entry);

		return this.DEFAULT_ROW_LINK_FUNCTION(entry);
	}

	displayPreviousPage(): void {
		const newPage = this.currentPage - 1;
		if(newPage < 0)
			return;

		this.previousPage.emit();
		this.currentPageChange.emit(newPage);
	}

	displayNextPage(): void {
		if(!this.hasNextPage)
			return;

		const newPage = this.currentPage + 1;
		this.previousPage.emit();
		this.currentPageChange.emit(newPage);
	}

	getValue(column: string, entry: T): Observable<unknown> {
		let value: unknown = Reflect.get(entry, column);

		if(value instanceof InheritedCoalesceProperty || value instanceof InheritMergedProperty)
			value = value.withParent;

		if(value != null && typeof value == 'object' && 'value' in value)
			value = value.value;

		const value$ = (value instanceof Observable) ? value : of(value);
		return value$.pipe(map(v => {
			if(v instanceof Date)
				return this.datePipe.transform(v);

			return v;
		}));
	}

	search(id: string, column: Column<T>, search: unknown, searchNow = false): void {
		column.filter = search;
		column.onSearch?.(
				search,
				{
					id,
					searchNow,
				},
		);
	}

	isLastVisibleColumn(id: string): boolean {
		const visibleColumns = this.visibleColumnIds;
		if(visibleColumns.length < 1)
			return false;

		const lastColumn = visibleColumns[visibleColumns.length - 1];
		return lastColumn === id;
	}

	asFullColumn<V>(value: Column<T, V>, id: string): FullColumn<T, V> {
		return {
			...value,
			id,
		};
	}
}
