import {
	DtoEditFormHelper,
	DtoFormHelper,
	Level8Error,
	SearchEntry,
} from '@angular-helpers/frontend-api';
import {AnyApiService} from '@angular-helpers/frontend-api/lib/models/abstract-model/abstract.api-service';
import {AbstractService} from '@angular-helpers/frontend-api/lib/models/abstract-model/abstract.service';
import {HttpErrorResponse} from '@angular/common/http';
import {
	Component,
	DestroyRef,
	ElementRef,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	QueryList,
	SimpleChanges,
	ViewChild,
	ViewChildren,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
	AbstractControl,
	UntypedFormArray,
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
} from '@angular/forms';
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {
	combineLatestSafe,
	FormHelperService,
	IconService,
	notNull,
} from '@app/main';
import {
	ContractModel,
	ContractSectionModel,
	ContractSectionService,
	ContractService,
	ContractValidators,
	ExternalContractNumberDtoModel,
	ExternalContractNumberModel,
	ExternalContractNumberService,
	ExternalContractNumberType,
	MasterContractModel,
	MasterContractService,
} from '@contracts/frontend-api';
import {
	combineLatest,
	forkJoin,
	from,
	Observable,
	of,
	onErrorResumeNext,
	Subject,
	throwError,
} from 'rxjs';
import {
	debounceTime,
	filter,
	first,
	map,
	mergeMap,
	takeUntil,
	tap,
} from 'rxjs/operators';

export interface EcnTableItemInterface {
	id?: string;
	number: string | number;
	type: 'mip' | 'legs';
	description: string;
}

export interface ChangeData {
	deleting: boolean;
	editing: boolean;
	editable: boolean;
	deletable: boolean;
}

type ParentContractModel<T> = T extends MasterContractModel ? undefined :
                              T extends ContractModel ? MasterContractModel :
                              T extends ContractSectionModel ? ContractModel :
                              never;


@Component({
	selector: 'portal-contract-external-contract-number-list-edit[formHelper][type][model],' +
	          'portal-contract-external-contract-number-list-edit[formHelper][type][parent]',
	templateUrl: './contract-external-contract-number-list-edit.component.html',
	styleUrls:   ['./contract-external-contract-number-list-edit.component.scss'],
})
export class ContractExternalContractNumberListEditComponent<Model extends (MasterContractModel | ContractModel | ContractSectionModel), Service extends AbstractService<AnyApiService, Model>, Parent extends ParentContractModel<Model>> implements OnInit, OnChanges, OnDestroy {
	private static readonly INPUT_DEBOUNCE_TIME         = 500;
	@Input({required: true}) type!: ExternalContractNumberType;
	@Input() control?: UntypedFormGroup;
	protected errorHasOccurred?: Error;
	protected canEditExternalContractNumber             = false;
	@ViewChild(MatAutocompleteTrigger) protected readonly searchEcnTrigger?: MatAutocompleteTrigger;
	@ViewChildren('autoFocus') protected readonly autoFocus?: QueryList<ElementRef>;
	protected readonly searchField                      = new UntypedFormControl(
		undefined,
		{
			updateOn: 'change',
			validators: [Validators.minLength(3)],
		},
	);
	protected readonly form                             = new UntypedFormGroup({
		entries: new UntypedFormArray([]),
	});
	protected inheritedNumbers: EcnTableItemInterface[] = [];
	protected isSearching                               = false;
	protected searchResults$: Observable<(ExternalContractNumberModel | string)[]>;
	protected adding                                    = false;
	protected primaryList: ExternalContractNumberModel[] = [];
	protected readonly delete$                          = new Subject<void>();
	protected readonly onDeleted$                       = new Subject<void>();
	protected readonly create$                          = new Subject<void>();
	protected readonly onSaved$                         = new Subject<void>();
	protected readonly update$                          = new Subject<void>();
	protected readonly onUpdated$                       = new Subject<void>();
	protected readonly changeMap                         = new Map<string, ChangeData>();
	protected isDeleted                                  = false;
	protected readonly modelChanged$                    = new Subject<void>();

	constructor(
		protected readonly iconService: IconService,
		protected readonly formHelperService: FormHelperService,
		private readonly externalContractNumberService: ExternalContractNumberService,
		private readonly masterContractService: MasterContractService,
		private readonly contractService: ContractService,
		private readonly contractSectionService: ContractSectionService,
		protected readonly destroyRef: DestroyRef,
	) {
		this.delete$.pipe(
			debounceTime(100),
			map(_ => {
				const deleteList = this.primaryList.filter((elem) => this.changeMap.get(elem.id)?.deleting === true);
				return deleteList.map(deleteItem => {
					this.setChange(deleteItem.id, 'deleting', false);

					if(this.model instanceof MasterContractModel)
						return this.masterContractService.removeExternalContractNumber(this.model, deleteItem);

					if(this.model instanceof ContractModel)
						return this.contractService.removeExternalContractNumber(this.model, deleteItem);

					if(this.model instanceof ContractSectionModel)
						return this.contractSectionService.removeExternalContractNumber(this.model, deleteItem);

					return throwError(new Error(`unknown model type: ${typeof this.model}`));
				});
			}),
			mergeMap(x => combineLatestSafe(x)),
			takeUntilDestroyed(),
		).subscribe(() => this.onDeleted$.next());

		this.create$.pipe(
			debounceTime(100),
			map(() => {
				return this.value.map((number, index) => {
					if(number.id != null) {
						const ecnID = number.id;
						return of(this.model?.externalContractNumbers.currentValue).pipe(
							map(currentECN => currentECN?.some(ecn => ecn.id === ecnID) ?? false),
							map(found => {
								if(found)
									return undefined;

								return this.externalContractNumberService.getById(ecnID);
							}),
						);
					}

					const ecnDto: ExternalContractNumberDtoModel = {
						number:      number.number,
						type:        number.type,
						description: number.description,
					};
					return from(this.externalContractNumberService.create(ecnDto)).pipe(
						tap((result) => {
							const form = this.entriesForm.at(index);
							form.get('id')!.setValue(result.id);
						}),
					);

				});
			}),
			mergeMap(x => combineLatestSafe(x)),
			map(numbers =>
				numbers.filter(notNull).map(externalContractNumber => {
					if(this.model instanceof MasterContractModel)
						return this.masterContractService.addExternalContractNumber(this.model, externalContractNumber);

					if(this.model instanceof ContractModel)
						return this.contractService.addExternalContractNumber(this.model, externalContractNumber);

					if(this.model instanceof ContractSectionModel)
						return this.contractSectionService.addExternalContractNumber(this.model, externalContractNumber);

					throw new Level8Error('unknown model type');
				})),
			mergeMap(x => combineLatestSafe(x)),
			takeUntilDestroyed(),
		).subscribe(() => this.onSaved$.next());

		this.update$.pipe(
			debounceTime(100),
			map(() => {
				return this.value.map((number, index) => {
					const changes = this.changeMap.get(number.id ?? '');
					if(changes?.editing === true)
						return of(this.saveUpdate(index));

					return of(undefined);
				});
			}),
			mergeMap(x => combineLatestSafe(x)),
			takeUntilDestroyed(),
		).subscribe(() => this.onUpdated$.next());

		this.searchResults$ = this.searchField.valueChanges.pipe(
			tap(() => (this.isSearching = true)),
			debounceTime(ContractExternalContractNumberListEditComponent.INPUT_DEBOUNCE_TIME),
			map((searchValue) => {
				if(!this.searchField.valid)
					return of(undefined);

				if(typeof searchValue !== 'string' || searchValue === '')
					return of(undefined);

				searchValue = searchValue.replace(/\s/g, '');

				// todo extend search for type === this.type
				const searchElem: SearchEntry = {
					column: 'number',
					comparator: 'like',
					value:  `%${searchValue}%`,
				};

				return forkJoin({
					searchValue: of(searchValue),
					results: this.externalContractNumberService.find(searchElem),
				});
			}),
			mergeMap((search) => onErrorResumeNext(search)),
			mergeMap((numbers) => {
				if(numbers == null)
					return of(numbers);

				return combineLatestSafe(
					numbers.results.data.map((number) =>
						number.type.value.pipe(
							map((type) => ({
								type,
								number,
							})),
						),
					),
				).pipe(
					map((numbersLoaded) => ({
						numbers: numbersLoaded,
						searchValue: numbers.searchValue,
					})),
				);
			}),
			map((numbers) => {
				if(numbers == null)
					return numbers;

				// todo replace by server search
				const results = numbers.numbers
				                       .filter((number) => number.type === this.type)
				                       .map((number) => number.number);

				return {
					searchValue: numbers.searchValue,
					results,
				};
			}),
			mergeMap((numbers) => {
				if(numbers == null)
					return of(numbers);

				return combineLatestSafe(
					numbers.results.map((number) =>
						number.number.value.pipe(
							map((numberValue) => ({
								numberValue,
								number,
							})),
						),
					),
				).pipe(
					map((numbersLoaded) => ({
						numbers: numbersLoaded,
						searchValue: numbers.searchValue,
					})),
				);
			}),
			map((data) => {
				if(data == null)
					return [];

				const included = data.numbers.some((ecn) => ecn.numberValue === data.searchValue);
				const numbers = data.numbers.map((number) => number.number);
				if(!included)
					return [data.searchValue, ...numbers];


				return numbers;
			}),
			tap(() => {
				this.isSearching = false;
			}),
		);
	}

	private _formHelper?: DtoFormHelper<Model, Service>;

	get formHelper(): DtoFormHelper<Model, Service> | undefined {
		return this._formHelper;
	}

	@Input({required: true})
	set formHelper(value: DtoFormHelper<Model, Service>) {
		this._formHelper = value;
	}

	private _model?: Model;

	get model(): Model | undefined {
		return this._model;
	}

	@Input()
	set model(value: Model | undefined) {
		if(value === this._model)
			return;

		this._model = value;
	}

	protected _parent?: Parent;

	@Input()
	get parent(): Observable<Parent | undefined> {
		if(this._parent != null)
			return of(this._parent);

		if(this.model == null)
			throw new Level8Error('Missing data: model or parent must be given!');

		if(this.model instanceof MasterContractModel)
			return of(undefined);

		// @ts-expect-error linter doesn't get the type
		return this.model.parent.value;
	}

	set parent(model: Parent) {
		this._parent = model;
	}

	get workingOnParent(): boolean {
		return this.model == null;
	}

	get entriesForm(): UntypedFormArray {
		return this.form.controls.entries as UntypedFormArray;
	}

	get value(): EcnTableItemInterface[] {
		const list = this.entriesForm.getRawValue();
		const entriesList: EcnTableItemInterface[] = [];
		for(const entry of list) {
			if(
				entry.number != null &&
				entry.type != null &&
				entry.description != null
			)
				entriesList.push(entry);

		}
		return entriesList;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if('parent' in changes || 'model' in changes) {
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
			const newModel: Model | Parent = (changes['parent'] ?? changes['model']).currentValue;
			this.modelChanged$.next();
			this.changeMap.clear();
			this.control?.addControl('entries', this.entriesForm);
			newModel?.externalContractNumbers.permissions.canUpdate
			        .then(canUpdate => this.canEditExternalContractNumber = canUpdate);

			newModel?.externalContractNumbers.value
			        .pipe(
				        takeUntilDestroyed(this.destroyRef),
				        takeUntil(this.modelChanged$),
			        )
			        .subscribe(ecnList => this.primaryList = ecnList ?? []);

			this.parent.pipe(
				mergeMap(contract => ((contract instanceof MasterContractModel) ? contract.externalContractNumbers.value : contract?.externalContractNumbers.withParent.value) ?? of(
					undefined)),
				tap(() => this.inheritedNumbers = []),
				mergeMap(externalContractNumbers => from(externalContractNumbers ?? [])),
				mergeMap((externalContractNumber) => combineLatest([
					of(externalContractNumber.id),
					externalContractNumber.number.value,
					externalContractNumber.type.value,
					externalContractNumber.description.value,
				])),
				filter(([, , type]) => type === this.type),
				map(([id, number, type, description]) => {
					if(number == null || type == null || description == null)
						return null;

					const ecnModel: EcnTableItemInterface = {
						id,
						number,
						type,
						description,
					};

					return ecnModel;
				}),
				takeUntilDestroyed(this.destroyRef),
				takeUntil(this.modelChanged$),
			).subscribe(value => {
				if(value != null && !this.inheritedNumbers.some((number) => number.id === value.id))
					this.inheritedNumbers.push(value);
			});


			if(this.workingOnParent !== true) {
				this.model?.externalContractNumbers.value
				    .pipe(
					    takeUntilDestroyed(this.destroyRef),
					    takeUntil(this.modelChanged$),
				    )
				    .subscribe(ecnList => ecnList?.forEach((ecn) => this.add(ecn)));
			}
		}
	}

	isFormGroup(ac: AbstractControl): ac is UntypedFormGroup {
		return ac instanceof UntypedFormGroup;
	}

	async add(ecn: ExternalContractNumberModel | string, markAsDirty = false): Promise<void> {
		const form  = await this.createForm(ecn);
		this.adding = false;

		if(form == null)
			return;

		if(markAsDirty) {
			this.formHelper?.control.then(control => control.markAsDirty());
			form.markAsDirty();
			form.markAsTouched();
		}

		let fieldContent = form.get('number')?.value ?? '';
		if(fieldContent === '')
			this.autoFocus?.last.nativeElement.querySelector('.ecn-number input')?.focus();

		fieldContent = form.get('description')?.value ?? '';
		if(fieldContent === '')
			this.autoFocus?.last.nativeElement.querySelector('.ecn-description input')?.focus();
	}

	startEditing(control: AbstractControl): void {
		const id = control.get('id')?.value;
		if(id == null)
			throw new Error('Ecn Data could not be found in Form');

		this.setChange(id, 'editing', true);
	}

	setDeleting(event: MouseEvent, entryControl: AbstractControl, deleting: boolean): void {
		if(event.detail === 0)
			return;

		const id = entryControl.get('id')?.value;
		if(id == null) {
			this.entriesForm.removeAt(this.entriesForm.controls.indexOf(entryControl));
			return;
		}

		this.formHelper?.control.then(control => control.markAsDirty());
		this.setChange(id, 'deleting', deleting);
	}

	ngOnDestroy(): void {
		this.isDeleted = true;
	}

	ngOnInit(): void {
		this.formHelper?.registerAfterSaveCallback(async model => {
			if(this.isDeleted)
				return;

			if(this._model !== model)
				this.model = model;

			const returnCallback = combineLatest([
				this.onDeleted$,
				this.onSaved$,
				this.onUpdated$,
			]).pipe(
				first(),
			);

			this.create$.next();
			this.update$.next();
			this.delete$.next();

			await returnCallback.toPromise();
		});
	}

	async saveUpdate(index: number): Promise<void> {
		const ecnGroup = this.entriesForm.at(index);
		if(this.isDeleting(ecnGroup))
			throw new Error('model is going to be deleted');

		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		if(ecnGroup == null || !(ecnGroup instanceof UntypedFormGroup))
			throw new Error('Ecn Data not found');

		ecnGroup.updateValueAndValidity();

		if(!ecnGroup.valid || !ecnGroup.dirty)
			throw new Error('Ecn Data Not Valid');

		const ecn = ecnGroup.value;
		if(ecn.id == null)
			throw new Error('ecn has no Id');

		const ecnModel = this.externalContractNumberService.getById(ecn.id);
		if(!(await ecnModel.permissions.canUpdate))
			throw new Error('update is not permitted');

		const formHelper = DtoEditFormHelper.create(
			ExternalContractNumberDtoModel,
			ecnModel,
			this.externalContractNumberService,
		);

		const ecnDto: Record<string, unknown> = {
			id: ecn.id,
		};

		if(ecnGroup.get('number')?.dirty === true)
			ecnDto.number = ecn.number;

		if(ecnGroup.get('type')?.dirty === true)
			ecnDto.type = ecn.type;

		if(ecnGroup.get('description')?.dirty === true)
			ecnDto.description = ecn.description;

		await formHelper.fill(ecnDto);

		this.setChange(ecn.id, 'editing', false);
		try {
			const saveResult = await formHelper.save();

			if(saveResult == null)
				throw new Error('Can\'t save');

			this.errorHasOccurred = undefined;
		} catch(error) {
			if(error instanceof Error || error instanceof HttpErrorResponse || error === undefined)
				this.errorHasOccurred = error;
			else
				this.errorHasOccurred = new Error(`${error}`);
			throw error;
		}
	}

	isExternalContractNumber(value: unknown): value is ExternalContractNumberModel {
		return value instanceof ExternalContractNumberModel;
	}

	isEditing(control: AbstractControl): boolean {
		const id = control.get('id')?.value;
		if(id == null)
			return true;

		const state = this.changeMap.get(id);
		if(state == null)
			return false;

		if(state.deleting)
			return false;

		return state.editing;
	}

	isDeleting(control: AbstractControl): boolean {
		const id = control.get('id')?.value;
		if(id == null)
			return false;

		return this.changeMap.get(id)?.deleting ?? false;
	}

	protected setChange(id: string, change: 'editing' | 'deleting', status: boolean): void {
		let entry = this.changeMap.get(id);
		if(entry == null) {
			if(status === false)
				return;

			entry = {
				editing:   false,
				editable:  true,
				deleting:  false,
				deletable: true,
			};
			this.changeMap.set(id, entry);
		}

		switch(change) {
			case 'deleting':
				entry.deleting = status;
				break;

			case 'editing':
				entry.editing = status;
				break;
		}

		if(entry.editing === false && entry.deleting === false)
			this.changeMap.delete(id);
	}

	protected addEntryToList(newEntry: EcnTableItemInterface): UntypedFormGroup | null {
		const currentEntries = [
			...this.entriesForm.getRawValue(),
			...this.inheritedNumbers,
		];

		const isAlreadyInList = currentEntries.find(currentEntry => {
			if(newEntry.number !== currentEntry.number)
				return false;

			if(newEntry.id == null)
				return false;

			return newEntry.id === currentEntry.id;
		});

		if(isAlreadyInList != null)
			return (isAlreadyInList instanceof UntypedFormGroup) ? isAlreadyInList : null;

		const control = this.createFormGroup(newEntry);
		this.entriesForm.controls.push(control);

		return control;
	}

	protected async createForm(externalContractNumber: ExternalContractNumberModel | string): Promise<UntypedFormGroup | null> {
		if(typeof externalContractNumber === 'string') {
			return this.addEntryToList({
				number:      externalContractNumber,
				type:        this.type,
				description: '',
			});
		}

		if(await externalContractNumber.type.firstValue !== this.type)
			return null;

		const currentEntry = this.entriesForm.controls.find(control => control.get('id')?.value === externalContractNumber.id);
		if(currentEntry != null)
			return currentEntry as UntypedFormGroup;

		for(const listEntry of this.entriesForm.controls) {
			if(await externalContractNumber.number.firstValue === listEntry.get('number')?.value)
				return (listEntry instanceof UntypedFormGroup) ? listEntry : null;
		}

		for(const listEntry of this.inheritedNumbers) {
			if(await externalContractNumber.number.firstValue === listEntry.number)
				return null;
		}

		const entry: EcnTableItemInterface = {
			id:          externalContractNumber.id,
			number:      await externalContractNumber.number.firstValue ?? '',
			type:        await externalContractNumber.type.firstValue ?? this.type,
			description: await externalContractNumber.description.firstValue ?? '',
		};

		const formGroup = this.createFormGroup(entry);
		this.entriesForm.controls.push(formGroup);

		return formGroup;
	}

	protected createFormGroup(data?: EcnTableItemInterface): UntypedFormGroup {
		const id          = new UntypedFormControl(data?.id);
		const type        = new UntypedFormControl(data?.type, Validators.required);
		const number      = new UntypedFormControl(
			data?.number,
			[
				Validators.required,
				Validators.minLength(3),
			],
		);
		const description = new UntypedFormControl(data?.description,
			[
				Validators.required,
				Validators.minLength(3),
			],
		);
		const ecnForm     = new UntypedFormGroup({
			id,
			number,
			type,
			description,
		});
		ecnForm.markAsDirty();
		ecnForm.markAsTouched();

		number.addAsyncValidators([
			ContractValidators.checkExternalContractNumberValidity(ecnForm, 'number', 'type'),
			ContractValidators.externalContractNumberExists(this.externalContractNumberService, number),
		]);

		ecnForm.statusChanges
		       .pipe(takeUntilDestroyed(this.destroyRef))
		       .subscribe(() => {
			       this.formHelper?.control.then(control => {
				       this.entriesForm.updateValueAndValidity();
				       control.markAsDirty();
			       });
		       });

		return ecnForm;
	}
}
