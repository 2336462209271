<portal-showable
	label="employee.roles"
	[value]="(model.roles.value | async)?.length"
>
	<mat-chip-listbox>
		@for (role of model.roles.value | async;track role) {
			<mat-chip-option>{{ role.name.value | async }}</mat-chip-option>
		}
	</mat-chip-listbox>
</portal-showable>
