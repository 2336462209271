import {
	Component,
	Input,
} from '@angular/core';
import {ExternalContractNumberModel} from '@contracts/frontend-api';

@Component({
	selector:    'portal-external-contract-number-token',
	templateUrl: './external-contract-number-token.component.html',
	styleUrls:   ['./external-contract-number-token.component.scss'],
})
export class ExternalContractNumberTokenComponent {
	@Input({required: true}) number!: ExternalContractNumberModel;
}
