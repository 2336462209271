import {
	Component,
	Input,
} from '@angular/core';
import {MatMenu} from '@angular/material/menu';
import {Params} from '@angular/router';
import {
	IconService,
	NavigationService,
} from '@app/main';

@Component({
	selector:    'portal-headline',
	templateUrl: './headline.component.html',
	styleUrls:   ['./headline.component.scss'],
})
export class HeadlineComponent {
	@Input({required: true}) label?: string | null | undefined;
	@Input() labelArgs?: object;
	@Input() menu?: MatMenu;
	@Input() backlink?: string | string[] | boolean;
	@Input() automaticBacklink?: boolean;
	@Input() historyBacklink?: boolean;

	constructor(
		protected readonly iconService: IconService,
		protected readonly navigationService: NavigationService,
	) {
	}

	get isMenuEmpty(): boolean {
		if(this.menu == null)
			return true;

		return this.menu._allItems.length < 1;
	}

	get backlinkUrl(): string | string[] | null {
		const enabled = this.automaticBacklink ?? (typeof this.backlink === 'boolean' ? this.backlink : false);
		if(enabled)
			return './..';

		if(this.backlink == null || typeof this.backlink === 'boolean')
			return null;

		return this.backlink;
	}

	get url(): string | string[] | null {
		return this.historyBackUrl ?? this.backlinkUrl;
	}

	get historyBackUrl(): string | null {
		const url = this.getHistoryUrl();
		if(url == null)
			return null;

		return url.pathname;
	}

	get queryParameters(): null | Params {
		const url = this.getHistoryUrl();
		if(url == null)
			return null;

		return [...url.searchParams.entries()].reduce(
			(last: Params, [key, value]) => {
				last[key] = value;
				return last;
			}, {});
	}

	get canHistoryBack(): boolean {
		return this.navigationService.previousHistory?.url != null;
	}

	get historyBackEnabled(): boolean {
		const enabled = this.historyBacklink ?? (typeof this.backlink === 'boolean' ? this.backlink : false);

		const canBack = this.canHistoryBack;
		if(enabled && canBack)
			return true;

		return false;
	}

	protected getHistoryUrl(): URL | null {
		if(!this.historyBackEnabled)
			return null;

		const previousHistory = this.navigationService.previousHistory?.url ?? null;
		if(previousHistory == null)
			return null;

		return new URL(`http://localhost${previousHistory}`);
	}

	protected checkFallbackHistory(event: MouseEvent): void {
		// when window got reloaded the history is corrupted
		if(this.canHistoryBack === false && (window.history.length > 1)) {
			event.stopPropagation();
			event.preventDefault();
			window.history.back();
		}
	}
}
