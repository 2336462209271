<portal-showable
	label="medicalStore.supplyArea.label"
	[value]="model.supplyArea.value | async"
>
	<mat-chip-listbox>
		@for (area of (areas$ | async);track area) {
			<mat-chip-option [selectable]="false">
				{{ area ?? ('medicalStore.supplyArea.defaultValue' | translate) }}
			</mat-chip-option>
		}
	</mat-chip-listbox>
</portal-showable>
