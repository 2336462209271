import {
	Component,
	Input,
} from '@angular/core';
import {environment} from '@app/environment';
import {IconService} from '@app/main';
import {
	PreQualificationCertificateDtoInterface,
	PreQualificationCertificateModel,
} from '@contracts/frontend-api';

@Component({
	selector:    'portal-pre-qualification-certificate-list-card',
	templateUrl: './pre-qualification-certificate-list-card.component.html',
	styleUrls:   ['./pre-qualification-certificate-list-card.component.scss'],
})
export class PreQualificationCertificateListCardComponent {
	@Input({required: true}) preQualificationCertificates?: PreQualificationCertificateModel[] | null;
	@Input() label = 'model.preQualificationCertificates';
	@Input() prefillData?: Partial<PreQualificationCertificateDtoInterface> | null;
	environment    = environment;

	constructor(
		public iconService: IconService,
	) {
	}
}
