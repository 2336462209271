<portal-editable label="medicalStore.supplyArea.label" [validator]="control">
	<mat-form-field>
		<input type="text" matInput [formControl]="control" [placeholder]="'medicalStore.supplyArea.defaultValue' | translate"/>
		@for (error of formHelperService.getErrors(control) | keyvalue;track error) {
			<mat-error>
				{{ 'input.error.' + error.key | translate: error.value }}
			</mat-error>
		}
		<mat-hint>{{ 'formats.supplyArea' | translate }}</mat-hint>
	</mat-form-field>
</portal-editable>
