import {Component} from '@angular/core';
import {environment} from '@app/environment';
import {AbstractShowComponent} from '@app/main';
import {
	PreQualificationCertificateModel,
	PreQualificationCertificateService,
} from '@contracts/frontend-api';

@Component({
	templateUrl: './pre-qualification-certificate-show-page.component.html',
	styleUrls:   ['./pre-qualification-certificate-show-page.component.scss'],
})
export class PreQualificationCertificateShowPageComponent extends AbstractShowComponent<PreQualificationCertificateModel, PreQualificationCertificateService> {
	readonly environment = environment;

	constructor(
		preQualificationCertificateService: PreQualificationCertificateService,
	) {
		super(preQualificationCertificateService);
	}

	protected getModelName(): Promise<string | undefined> {
		return this.getModel().certificateIdentifier.firstValue;
	}


	protected async postDeleteRedirect(): Promise<void> {
		const employee = await this.getModel().employee.firstValue;
		return AbstractShowComponent.redirectToPageOrList(this.router, environment.employeeFullUrl, employee?.id);
	}
}
