import {
	Directive,
	HostBinding,
	Input,
	OnChanges,
	SimpleChanges,
} from '@angular/core';

@Directive({
	selector: 'a[href]',
})
export class ExternalLinkDirective implements OnChanges {
	@HostBinding('rel') relAttr       = '';
	@HostBinding('target') targetAttr = '';
	@HostBinding('href') hrefAttr: string | undefined;
	@HostBinding('class') classAttr   = '';
	@Input() href?: string;
	@Input() rel: string | undefined;
	@Input() routerLink: string[] | string | null | undefined;
	@Input() target: string | undefined;
	@Input() class: string | undefined;

	ngOnChanges(changes: SimpleChanges): void {
		if(this.isLinkExternal())
			this.setExternal();

		this.setOutput();
	}

	protected setExternal(): void {
		const classAdd = 'externalLink';
		if(this.class?.includes(classAdd) !== true)
			this.class = `${this.class ?? ''} ${classAdd}`;

		const relAdd = 'noopener noreferrer';
		if(this.rel?.includes(relAdd) !== true)
			this.rel = `${this.rel ?? ''} ${relAdd}`;

		if(this.target === undefined)
			this.target = '_blank';
	}

	protected setOutput(): void {
		if(this.href != null)
			this.hrefAttr = this.href;


		if(this.rel != null)
			this.relAttr = this.rel;

		if(this.target != null)
			this.targetAttr = this.target;

		if(this.class != null)
			this.classAttr = this.class;
	}

	private isLinkExternal(): boolean {
		let link = this.href ?? this.routerLink;
		if(Array.isArray(link))
			link = link[0];

		if(typeof link !== 'string')
			return false;

		const externalPageRegex = /^[a-z]*:/gi;
		return externalPageRegex.test(link);
	}
}
