import {
	Component,
	Input,
} from '@angular/core';
import {ExportModel} from '@contracts/frontend-api';

@Component({
	selector:    'portal-export-show-recipients',
	templateUrl: './export-show-recipients.component.html',
	styleUrls:   ['./export-show-recipients.component.scss'],
})
export class ExportShowRecipientsComponent {
	@Input({required: true}) model!: ExportModel;
}
