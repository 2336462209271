import {Injectable} from '@angular/core';
import {
	ContractAccessionService,
	InstitutionskennzeichenModel,
} from '@contracts/frontend-api';
import {TranslateService} from '@ngx-translate/core';
import moment from 'moment';
import {take} from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class InstitutionskennzeichenDownloadService {
	constructor(
			private readonly contractAccessionsService: ContractAccessionService,
			private readonly translateService: TranslateService,
	) {}

	downloadExport(model: InstitutionskennzeichenModel): Promise<void> {
		const ik = (model.number.value.pipe(take(1)).toPromise());

		return this.contractAccessionsService
				   .createAccessionList(model.id)
				   .then(response => Promise.all([
					   Promise.resolve(response),
					   ik,
				   ]))
				   .then(([response, ikNumber]) => response.arrayBuffer().then((buffer) => {
					   return {
						   buffer: buffer,
						   type:   response.type,
						   number: ikNumber,
					   };
				   }))
				   .then((blob) => new File(
						   [blob.buffer],
						   `${this.translateService.instant('contractAccession.export')} ${blob.number} ${(moment()
								   .format('DD-MM-YYYY'))}.xlsx`,
						   {type: blob.type},
				   ))
		.then((file) => {
			const element = document.createElement('a');
			element.href = URL.createObjectURL(file);
			element.download = file.name;
			return element;
		})
		.then((element) => {
			element.click();
			return;
		})
	}
}
