@if (!hideIfEmpty || (contractAccessions != null && (isArray(contractAccessions) && contractAccessions.length > 0))) {
	<mat-card>
		@if (label != null) {
			<mat-card-header class="logo">
				<fa-icon [icon]="iconService.CONTACT_ACCESSION" mat-card-avatar></fa-icon>
				<mat-card-title>{{ label | translate }}</mat-card-title>
			</mat-card-header>
		}
		<mat-card-content>
			<portal-contract-accession-list [data]="contractAccessions"/>
		</mat-card-content>
	</mat-card>
}
