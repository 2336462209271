import {Enum} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
} from '@angular/core';

@Component({
	selector:    'portal-show-field-enum',
	templateUrl: './show-field-enum.component.html',
	styleUrls:   ['./show-field-enum.component.scss'],
})
export class ShowFieldEnumComponent {
	@Input({required: true}) label!: string;
	@Input({required: true}) value: undefined | null | string | number;
	@Input({required: true}) enum!: Enum;
	@Input({required: true}) valueLabelPrefix!: string;
	@Input() description?: string;

	get outputValue(): string | null | undefined {
		if(this.value == null)
			return this.value;

		return `${this.valueLabelPrefix}.${this.enum[this.value]}`;
	}
}
