import {
	Pipe,
	PipeTransform,
} from '@angular/core';
import {ExternalContractNumberType} from '@contracts/frontend-api';
import {
	isObservable,
	Observable,
} from 'rxjs';
import {map} from 'rxjs/operators';

declare type OptionalString = string | null | undefined;

@Pipe({
	name: 'formatExternalContractNumbers',
})
export class FormatExternalContractNumbersPipe implements PipeTransform {
	static format(value: OptionalString, type: ExternalContractNumberType): OptionalString {
		if(value == null)
			return value;

		switch(type) {
			case ExternalContractNumberType.mip:
				return value;

			case ExternalContractNumberType.legs:
				const parts = [
					value.slice(0, 2),
					value.slice(2, 4),
					value.slice(4),
				];
				return parts.join(' ');
		}
	}

	transform(value: string, type?: ExternalContractNumberType | null): string;
	transform(value: OptionalString, type?: ExternalContractNumberType | null): OptionalString;
	transform(value: Observable<OptionalString>, type?: ExternalContractNumberType | null): Observable<OptionalString>;
	transform(value: OptionalString | Observable<OptionalString>, type?: ExternalContractNumberType | null): OptionalString | Observable<OptionalString> {
		if(type == null)
			return value;

		if(isObservable(value))
			return value.pipe(map(s => FormatExternalContractNumbersPipe.format(s, type)));

		return FormatExternalContractNumbersPipe.format(value, type);
	}

}
