import {
	Component,
	Input,
	OnInit,
} from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
} from '@angular/forms';

@Component({
	selector:  'portal-news-edit-summary',
	templateUrl: './news-edit-summary.component.html',
	styleUrls: ['./news-edit-summary.component.scss'],
})
export class NewsEditSummaryComponent implements OnInit {
	@Input({
		required: true,
		alias:    'control',
	}) parent!: UntypedFormGroup;
	control?: UntypedFormControl;

    ngOnInit(): void {
        const control = this.parent.get('summary');
        if(control instanceof UntypedFormControl) {
            control.setValue('empty');
            this.control = control;
        }
    }
}
