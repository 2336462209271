import {DtoCreationFormHelper} from '@angular-helpers/frontend-api';
import {Component} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ActivatedRoute} from '@angular/router';
import {
	ContractDtoModel,
	ContractModel,
	ContractService,
	MasterContractModel,
	MasterContractService,
} from '@contracts/frontend-api';

@Component({
	selector:    'portal-contracts-page-create',
	templateUrl: './contracts-page-create.component.html',
	styleUrls:   ['./contracts-page-create.component.scss'],
})
export class ContractsPageCreateComponent {
	protected formHelper?: DtoCreationFormHelper<ContractModel, ContractService>;
	protected masterContract?: MasterContractModel;

	constructor(
		private readonly masterContractService: MasterContractService,
		private readonly contractService: ContractService,
		protected readonly activatedRoute: ActivatedRoute,
	) {
		this.activatedRoute.params
		    .pipe(takeUntilDestroyed())
		    .subscribe(params => {
			    if(params.id == null || params.id === this.masterContract?.id)
				    return;

			    this.masterContract = this.masterContractService.getById(params.id);
			    this.formHelper     = DtoCreationFormHelper.create(
				    ContractDtoModel,
				    this.contractService,
				    {
					    masterContract: this.masterContract,
				    },
			    );
		    });
	}
}
