<div mat-dialog-title class="dialog-title logo">
	<fa-icon class="dialog-icon" [icon]="data.icon"></fa-icon>
	<div class="title-text">{{ data.headline | translate }}</div>
</div>
<div mat-dialog-content class="scroll-shadows dialog-content">
	<div [class.grid-2]="data.enableContentGrid">
		@if (data.control !== undefined) {
			<portal-loading [loading]="data.control == null">
				@if (data.control) {
					<ng-container *ngTemplateOutlet="data.content; context: {$implicit: data.control} "></ng-container>
				}
			</portal-loading>
		} @else {
			<ng-container *ngTemplateOutlet="data.content"></ng-container>
		}
	</div>
</div>
<div mat-dialog-actions class="actionbar">
	<div class="actions">
		@if (data.cancelButtonText) {
			<button
          #cancelButton class="left" mat-stroked-button
					(document:keydown.escape)="cancelButton.focus();$event.preventDefault();" (click)="abortEditing()">
				{{ data.cancelButtonText | translate }}
			</button>
		}

		<portal-spinning-button
        #acceptButton [processing]="this.isSaving" [text]="data.acceptText" class="right"
				(document:keydown.control.enter)="acceptButton.focus();$event.preventDefault();"
				(document:keydown.meta.enter)="acceptButton.focus();$event.preventDefault();" (clicked)="save()"/>
	</div>
	@if ((data.error?.() ?? errorHasOccurred);as error) {
		<portal-form-error [error]="error" [control]="data.control" (retrySave)="onErrorResolved($event)"></portal-form-error>
	}
</div>
