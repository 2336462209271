import {DtoCreationFormHelper} from '@angular-helpers/frontend-api';
import {Component} from '@angular/core';
import {
	MasterContractDtoModel,
	MasterContractModel,
	MasterContractService,
} from '@contracts/frontend-api';

@Component({
	selector:    'portal-master-contracts-page-create',
	templateUrl: './master-contracts-page-create.component.html',
	styleUrls:   ['./master-contracts-page-create.component.scss'],
})
export class MasterContractsPageCreateComponent {
	protected formHelper: DtoCreationFormHelper<MasterContractModel, MasterContractService>;

	constructor(
		protected readonly masterContractService: MasterContractService,
	) {
		this.formHelper = DtoCreationFormHelper.create(
			MasterContractDtoModel,
			this.masterContractService,
			{},
		);
	}
}
