import {
	DestroyRef,
	inject,
	Injectable,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {UserLevelService} from '@app/main';
import {MedicalStoreService} from '@contracts/frontend-api';
import {Subject} from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class TaskDetectorService {
	protected readonly destroyRef              = inject(DestroyRef);
	protected readonly medicalStoreService     = inject(MedicalStoreService);
	protected readonly userLevelService        = inject(UserLevelService);
	protected readonly hmmParticipationMissing = new Subject<void>();
	readonly hmmParticipationMissing$          = this.hmmParticipationMissing.asObservable();

	constructor() {
		this.userLevelService.isUser$.pipe(takeUntilDestroyed())
		    .subscribe(async isUser => {
			    if(!isUser)
				    return;

			    if(await this.hasMissingHmmParticipation())
				    this.hmmParticipationMissing.next();
		    });
	}

	protected async hasMissingHmmParticipation(): Promise<boolean> {
		let page = await this.medicalStoreService.getPage(0);
		while(true) {
			const participations = await Promise.all(page.data.map(medicalStore => medicalStore.hmmParticipation.firstValue));
			if(participations.includes(null))
				return true;

			if(!page.hasNextPage())
				return false;

			page = await page.loadNextPage();
		}
	}
}
