import {
	Pipe,
	PipeTransform,
} from '@angular/core';

@Pipe({
	name: 'website',
})
export class WebsitePipe implements PipeTransform {

	transform(value: string): string ;
	transform(value: null): null;
	transform(value: undefined): undefined;
	transform(value: string | null | undefined): string | null | undefined {
		if(value == null)
			return value;

		// required because linter is stupid
		let stringValue: string = value;

		if(stringValue.toLowerCase().startsWith('https://'))
			stringValue = stringValue.substring('https://'.length);
		else if(stringValue.toLowerCase().startsWith('http://'))
			stringValue = stringValue.substring('http://'.length);

		if(stringValue.toLowerCase().startsWith('www.'))
			stringValue = stringValue.substring('www.'.length);

		if(stringValue.includes('?'))
			stringValue = stringValue.substring(0, stringValue.indexOf('?'));

		if(stringValue.includes('#'))
			stringValue = stringValue.substring(0, stringValue.indexOf('#'));

		if(stringValue.endsWith('/'))
			stringValue = stringValue.substring(0, stringValue.length - 1);

		return stringValue;
	}

}
