<portal-headline label="medicalStore.create.title.employee"/>
<mat-card>
	<mat-card-content>
		@if (control) {
			<div class="grid grid-2">
				<portal-medical-store-show [model]="medicalStore" [hasLink]="false"/>
				<portal-employee-edit-salutation [control]="control"/>
				<portal-common-edit-name [control]="control"/>
				<portal-common-edit-email [control]="control"/>
				<portal-common-edit-phone [control]="control"/>
				<portal-employee-edit-roles [control]="rolesForm"/>
			</div>
		}
	</mat-card-content>
	@if (errorHasOccurred) {
		<portal-form-error [error]="errorHasOccurred" [control]="control"/>
	}
	<button mat-raised-button (click)="onAbort()">{{ 'actions.cancel' | translate }}</button>
	<button mat-raised-button class="right" [color]="'primary'" (click)="saveForm()">{{ 'actions.save' | translate }}</button>
</mat-card>
