<ng-container *ngLet="(requirementsList$ | async) as requirements">
	<portal-loading [loading]="requirements == null || isLoading">
		@if (requirements != null) {
			@if (requirements.length < 1) {
				<mat-label>{{ 'general.noData' | translate }}</mat-label>
			}
			@for (requirement of requirements;track requirement) {
				<ng-container class="requirements-list">
					@if (this.requirementMap.get(requirement.id) != null) {
						<mat-label>{{ 'requirementFunction.' + this.requirementMap.get(requirement.id) | translate }}</mat-label>
						<mat-label>{{ this.parameterMap.get(requirement.id) }}</mat-label>
					}
				</ng-container>
			}
		}
	</portal-loading>
</ng-container>
