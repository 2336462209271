import {
	Component,
	Input,
	OnInit,
} from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
} from '@angular/forms';

@Component({
	selector:  'portal-news-edit-display-end-at',
	templateUrl: './news-edit-display-end-at.component.html',
	styleUrls: ['./news-edit-display-end-at.component.scss'],
})
export class NewsEditDisplayEndAtComponent implements OnInit {
	@Input({
		required: true,
		alias:    'control',
	}) parent!: UntypedFormGroup;
	control?: UntypedFormControl;

	ngOnInit(): void {
		const control = this.parent.get('displayEndAt');
		if(control instanceof UntypedFormControl)
			this.control = control;
	}
}
