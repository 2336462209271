import {DtoCreationFormHelper} from '@angular-helpers/frontend-api';
import {Component} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ActivatedRoute} from '@angular/router';
import {
	ContractModel,
	ContractSectionDtoModel,
	ContractSectionModel,
	ContractSectionService,
	ContractService,
} from '@contracts/frontend-api';


@Component({
	selector:    'portal-contract-sections-page-create',
	templateUrl: './contract-sections-page-create.component.html',
	styleUrls:   ['./contract-sections-page-create.component.scss'],
})
export class ContractSectionsPageCreateComponent {
	protected formHelper?: DtoCreationFormHelper<ContractSectionModel, ContractSectionService>;
	protected contract?: ContractModel;

	constructor(
		private readonly contractService: ContractService,
		protected readonly contractSectionService: ContractSectionService,
		protected readonly activatedRoute: ActivatedRoute,
	) {

		this.activatedRoute.params
		    .pipe(takeUntilDestroyed())
		    .subscribe(params => {
			    if(params.id == null || params.id === this.contract?.id)
				    return;

			    this.contract   = this.contractService.getById(params.id);
			    this.formHelper = DtoCreationFormHelper.create(
				    ContractSectionDtoModel,
				    this.contractSectionService,
				    {
					    contract: this.contract,
				    },
			    );
		    });
	}
}
