import {
	Component,
	Input,
} from '@angular/core';
import {Observable} from 'rxjs';

@Component({
	selector:    'portal-export-show-next-at',
	templateUrl: './export-show-next-at.component.html',
	styleUrls:   ['./export-show-next-at.component.scss'],
})
export class ExportShowNextAtComponent {
	@Input({required: true}) nextAt$?: Observable<Date | undefined>;
}
