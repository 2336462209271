<portal-headline
	[label]="'medicalStore.create.title.' + (parent != null ? 'branchOffice' : 'mainHouse')"
	[backlink]="true"
/>


@if (control && masterDataControl && contractControl && contactControl) {
	<mat-card>
		<mat-card-content>
			<mat-stepper orientation="vertical" linear="true" [formGroup]="control" #stepper>
				<ng-template matStepperIcon="edit" let-index="index">{{ index + 1 }}</ng-template>
				<ng-template matStepperIcon="done" let-index="index">{{ index + 1 }}</ng-template>
				<ng-template matStepperIcon="error" let-index="index">{{ index + 1 }}</ng-template>
				<mat-step [stepControl]="masterDataControl">
					<ng-template matStepLabel>{{ 'medicalStore.masterData' | translate }}</ng-template>
					<div class="grid-2">
						<portal-medical-store-show [model]="parent" label="medicalStore.mainHouse" [hasLink]="false"/>
						<portal-common-edit-name [control]="control"/>
						<portal-medical-store-edit-legal-form [control]="control"/>
						<portal-landesinnung-edit [control]="control"/>
						<portal-professional-association-edit [control]="control"/>
						<portal-address-edit-model [control]="$any(control.controls.address)"/>
					</div>
					<ng-container *ngTemplateOutlet="stepperActions"></ng-container>
				</mat-step>
				<mat-step [stepControl]="contactControl">
					<ng-template matStepLabel>{{ 'medicalStore.contactInformation' | translate }}</ng-template>
					<div class="grid-2">
						<portal-common-edit-email error-field fieldName="email" [stepper]="stepper" [stepperIndex]="1" [control]="control"/>
						<portal-common-edit-phone [control]="control"/>
						<portal-common-edit-fax [control]="control"/>
						<portal-common-edit-website [control]="control"/>
					</div>
					<ng-container *ngTemplateOutlet="stepperActions"></ng-container>
				</mat-step>
				<mat-step>
					<ng-template matStepLabel>{{ 'general.create.finish.label' | translate }}</ng-template>
					<p>{{ 'medicalStore.create.finish.text' | translate }}</p>
					<div class="stepper-actions">
						<button mat-button matStepperPrevious>{{ 'actions.previous' | translate }}</button>
						<portal-spinning-button (click)="create()" [processing]="isSaving">{{ 'actions.createNow' | translate }}</portal-spinning-button>
					</div>
				</mat-step>
				<ng-template #stepperActions>
					<div class="stepper-actions">
						<button mat-button matStepperPrevious>{{ 'actions.previous' | translate }}</button>
						<button mat-button color="primary" matStepperNext>{{ 'actions.next' | translate }}</button>
					</div>
				</ng-template>
			</mat-stepper>
		</mat-card-content>
		@if (errorHasOccurred) {
			<portal-form-error [error]="errorHasOccurred" [control]="control" [errorFields]="errorFields"/>
		}
	</mat-card>
} @else {
	<portal-loading/>
}
