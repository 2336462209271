@if (model) {
	<portal-headline
		[label]="model.parent.isLoaded === false ? 'model.medicalStore' : 'medicalStore.modelSpecific'"
		[labelArgs]="{type: model.parent.currentValue ? 'branchOffice' : 'mainHouse'}"
		[menu]="menu"
		[backlink]="true"
	>
		<mat-menu #menu>
			@if (permissions.create.branchOffice || permissions.create.institutionskennzeichens || permissions.create.employees || permissions.create.preQualificationCertificate) {
				<button mat-menu-item [matMenuTriggerFor]="menuCreate">
					<fa-icon matMenuItemIcon [icon]="iconService.CREATE"></fa-icon>
					<span>{{ 'general.menu.create' | translate }}</span>
				</button>
			}
			<mat-menu #menuCreate>
				@if (permissions.create.branchOffice) {
					<a
						mat-menu-item
						[routerLink]="[environment.medicalStoresFullUrl, model.id, 'create-branch-office']"
					>
						<fa-icon matMenuItemIcon [icon]="iconService.MEDICAL_STORE"></fa-icon>
						<span>{{ 'medicalStore.branchOffice' | translate }}</span>
					</a>
				}
				@if (permissions.create.institutionskennzeichens) {
					<a
						mat-menu-item
						[routerLink]="[environment.medicalStoresFullUrl, model.id, 'create-institutionskennzeichen']"
					>
						<fa-icon matMenuItemIcon [icon]="iconService.INSTITUTIONSKENNZEICHEN"></fa-icon>
						<span>{{ 'model.institutionskennzeichen' | translate }}</span>
					</a>
				}
				@if (permissions.create.employees) {
					<a
						mat-menu-item
						[routerLink]="[environment.medicalStoresFullUrl, model.id, 'create-employee']"
					>
						<fa-icon matMenuItemIcon [icon]="iconService.EMPLOYEE"></fa-icon>
						<span>{{ 'model.employee' | translate }}</span>
					</a>
				}
				@if (((model.parent.value | async) == null) && permissions.create.iqzParticipation) {
					<a
						mat-menu-item
						[routerLink]="[environment.medicalStoresFullUrl, model.id, 'create-iqz-participation']"
					>
						<fa-icon matMenuItemIcon [icon]="iconService.IQZ_PARTICIPATION"></fa-icon>
						<span>{{ 'model.iqzParticipation' | translate }}</span>
					</a>
				}
				@if (permissions.create.preQualificationCertificate) {
					<a
						mat-menu-item
						[routerLink]="[environment.preQualificationCertificateFullUrl, 'create']"
					>
						<fa-icon matMenuItemIcon [icon]="iconService.PRE_QUALIFICATION_CERTIFICATE"></fa-icon>
						<span>{{ 'model.preQualificationCertificate' | translate }}</span>
					</a>
				}
			</mat-menu>
			@if (permissions.delete.self) {
				<button mat-menu-item (click)="showDeleteDialog()">
					<fa-icon matMenuItemIcon [icon]="iconService.DELETE"></fa-icon>
					<span>{{ 'actions.delete' | translate }}</span>
				</button>
			}
		</mat-menu>
	</portal-headline>
	<div class="flex-container">
		<portal-medical-store-master-data-card class="col-8" [medicalStore]="model"/>
		<portal-medical-store-contacts-card class="col-4" [medicalStore]="model"/>
		<portal-medical-store-contracts-card class="col-6" [medicalStore]="model"/>
		@if ((model.employees.value | async)?.length) {
			<portal-employee-list-short-data-card class="col-6" [employees]="model.employees.value | async" label="model.employees"/>
		}
		@if ((model.iqzParticipations.value | async)?.length) {
			<portal-medical-store-iqz-participations-card class="col-6" [medicalStore]="model"/>
		}
		@if (preQualificationCertificates?.length) {
			<portal-pre-qualification-certificate-list-card class="col-6" [prefillData]="pqcPrefillData$ | async" [preQualificationCertificates]="preQualificationCertificates"/>
		}
		@if ((model.children.value | async)?.length) {
			<mat-card>
				<mat-card-header class="logo">
					<fa-icon [icon]="iconService.MEDICAL_STORE" mat-card-avatar></fa-icon>
					<mat-card-title>{{ 'medicalStore.branchOffices' | translate }}</mat-card-title>
				</mat-card-header>
				<mat-card-content>
					<portal-medical-store-list [data]="model.children.value | async"/>
				</mat-card-content>
			</mat-card>
		}
	</div>
}
