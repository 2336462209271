import {
	Component,
	Input,
	OnInit,
} from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
} from '@angular/forms';
import {FormHelperService} from '@app/main';

@Component({
	selector:    'portal-medical-store-edit-iso13485',
	templateUrl: './medical-store-edit-iso13485.component.html',
	styleUrls:   ['./medical-store-edit-iso13485.component.scss'],
})
export class MedicalStoreEditIso13485Component implements OnInit {
	@Input({required: true}) control?: UntypedFormGroup;

	fieldNameStart = 'certificateIso13485StartAt';
	fieldNameEnd   = 'certificateIso13485EndAt';

	fieldControlStart?: UntypedFormControl;
	fieldControlEnd?: UntypedFormControl;

	constructor(
		public formHelperService: FormHelperService,
	) { }

	ngOnInit(): void {
		if(this.control != null) {
			let control = this.control.get(this.fieldNameStart);
			if(control instanceof UntypedFormControl)
				this.fieldControlStart = control;
      

      control = this.control.get(this.fieldNameEnd);
			if(control instanceof UntypedFormControl)
				this.fieldControlEnd = control;
      
    }
  }

}
