import {
	Level8Error,
	ResultPageModel,
} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
	OnChanges,
	SimpleChanges,
	TemplateRef,
} from '@angular/core';
import {
	IconService,
	MinimalColumns,
	SearchFilter,
	SearchOptions,
} from '@app/main';
import {AbstractTableComponent} from '../table/abstract-table/abstract-table.component';

@Component({
	selector:    'portal-table-client-side-searchable',
	templateUrl: './table-client-side-searchable.component.html',
	styleUrls:   ['./table-client-side-searchable.component.scss'],

})
export class TableClientSideSearchableComponent<TableEntry extends object> extends AbstractTableComponent<TableEntry> implements OnChanges {
	@Input() customMenu?: TemplateRef<unknown>;
	@Input({required: true}) headers!: MinimalColumns<TableEntry>;
	@Input() searchFilter = new SearchFilter<TableEntry>();
	@Input() columnLink: string | ((entry: TableEntry) => string | undefined) | undefined;
	@Input() withMenu     = true;
	@Input() prepareSearch?: (value: unknown, filter: TableClientSideSearchableComponent<TableEntry>['searchFilter'], searchOptions: SearchOptions) => unknown | undefined;

	protected filteredModels?: TableEntry[];
	protected isLoading                                        = false;
	protected searching: Promise<void> | null                  = null;
	protected _pageSize                            = TableClientSideSearchableComponent.DEFAULT_PAGE_SIZE;
	private _models?: TableEntry[];
	private _pages?: ResultPageModel<TableEntry>[];

	constructor(
		protected readonly iconService: IconService,
	) {
		super();
	}

	protected goToPage(page: number): void {
		this.displayPage = page;
		this.refreshFilters();
	}


	get _data(): TableClientSideSearchableComponent<TableEntry>['filteredModels'] {
		if(this.pageSize != null)
			return this.filteredModels?.slice(0, this.pageSize);

		return this.filteredModels;
	}

	get models(): TableEntry[] | undefined {
		return this._models;
	}

	get pages(): readonly ResultPageModel<TableEntry>[] | undefined {
		return this._pages;
	}

	@Input({required: true}) set data(value: TableEntry[] | ResultPageModel<TableEntry> | undefined | null) {
		if(value == null) {
			this.setPage(null);
			return;
		}

		if(value instanceof ResultPageModel) {
			this.setPage(value);
			return;
		}

		this.setModels(value);
	}

	get columns(): readonly string[] {
		return Object.keys(this.headers);
	}

	get pageSize(): number | undefined {
		return this.firstLoadedPage?.meta.pageSize ?? this._pageSize;
	}

	@Input() set pageSize(value: number | undefined) {
		this._pageSize = value ?? TableClientSideSearchableComponent.DEFAULT_PAGE_SIZE;
	}

	get firstLoadedPage(): ResultPageModel<TableEntry> | undefined {
		return this.pages?.reduce((firstPage, page) => {
			if(firstPage == null)
				return page;
			if(firstPage.meta.page < page.meta.page)
				return firstPage;
			return page;
		}, undefined as (undefined | ResultPageModel<TableEntry>));
	}

	get lastLoadedPage(): ResultPageModel<TableEntry> | undefined {
		return this.pages?.reduce((firstPage, page) => {
			if(firstPage == null)
				return page;
			if(firstPage.meta.page > page.meta.page)
				return firstPage;
			return page;
		}, undefined as (undefined | ResultPageModel<TableEntry>));
	}

	get hasNextPage(): boolean {
		if(this.pageSize == null)
			return false;

		const expectedSize = (this.displayPage + 1) * this.pageSize;

		if(this.filteredModels != null)
			return this.filteredModels.length > this.pageSize;

		if(this.models == null)
			return false;

		return this.models.length >= expectedSize;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if('headers' in changes)
			this.tableHeaders = this.parseHeaders(changes.headers.currentValue);
	}

	protected _prepareSearch(value: unknown, searchOptions: SearchOptions): unknown {
		if(this.prepareSearch != null)
			return this.prepareSearch(value, this.searchFilter, searchOptions);

		return value;
	}

	protected async executeSearch(value: unknown, searchOptions: SearchOptions): Promise<void> {
		const header = this.tableHeaders[searchOptions.id];
		if(header != null)
			header.filter = value;

		if(!(typeof value === 'string' || value == null))
			throw new Level8Error(`Invalid search value type - expected string? got ${typeof value}`);

		this.searchFilter.setEntry(searchOptions.id, value);
		this.displayPage = 0;

		while(this.searching)
			await this.searching;

		const search = async () => {
			const firstPage = await this.getFirstPage();

			// set page to first and refresh filter indirect
			if(firstPage != null)
				return this.setPage(firstPage);

			return this.refreshFilters();
		};

		this.searching = search();
		await this.searching;
		this.searching = null;
	}

	async refreshFilters(): Promise<void> {
		try {
			this.searchFilter.cleanup();

			const models = this.models;
			if(models == null)
				return;

			this.isLoading     = true;
			let filteredModels = await Promise.all(models.map(this.searchFilter.isFiltered.bind(this.searchFilter)))
											  .then((results) => models.filter((_v, index) => results[index]));

			if(this.pageSize != null)
				filteredModels = filteredModels.slice(this.displayPage * this.pageSize);

			this.filteredModels = filteredModels;

			if(this.pageSize == null || filteredModels.length > this.pageSize)
				return;

			if(this.lastLoadedPage?.hasNextPage() !== true)
				return;

			const nextPage = await this.lastLoadedPage.loadNextPage();
			await this.addPage(nextPage);
		} finally {
			this.isLoading = false;
		}
	}

	getFirstPage(): Promise<ResultPageModel<TableEntry>> | undefined {
		const firstLoadedPage = this.firstLoadedPage;
		if(firstLoadedPage == null)
			return undefined;

		if(firstLoadedPage.meta.page === 0)
			return Promise.resolve(firstLoadedPage);

		return firstLoadedPage.loadPage(0);
	}

	protected setModels(value: TableEntry[] | undefined): Promise<void> {
		this._models = value;
		return this.refreshFilters();
	}

	protected setPages(pages: ResultPageModel<TableEntry>[] | undefined | null): Promise<void> {
		this._pages = pages ?? undefined;

		if(pages == null)
			return this.setModels(undefined);

		const models = pages.reduce((last, curr) => [
			...last,
			...curr.data,
		], [] as TableEntry[]);
		return this.setModels(models);
	}

	protected setPage(page: ResultPageModel<TableEntry> | undefined | null): Promise<void> {
		// preload next page
		if(page?.hasNextPage() === true)
			page.loadNextPage().then();

		this.displayPage = page?.meta.page ?? 0;

		const pages = page != null ? [page] : undefined;
		return this.setPages(pages);
	}

	protected addPage(page: ResultPageModel<TableEntry> | undefined | null): Promise<void> {
		if(page == null)
			return Promise.resolve();

		// preload next page
		if(page.hasNextPage())
			page.loadNextPage().then();

		const pages = this.pages ?? [];
		return this.setPages([
			...pages,
			page,
		]);
	}
}
