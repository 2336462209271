import {
	Component,
	Input,
} from '@angular/core';
import {
	BaseContractApiService,
	BaseContractModel,
	BaseContractService,
} from '@contracts/frontend-api';

@Component({
	selector:    'portal-base-contract-show-name',
	templateUrl: './base-contract-show-name.component.html',
	styleUrls:   ['./base-contract-show-name.component.scss'],
})
export class BaseContractShowNameComponent<Model extends BaseContractModel<BaseContractService<BaseContractApiService, Model>>> {
	@Input({required: true}) model?: Model;
}
