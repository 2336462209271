import {
	DestroyRef,
	inject,
	Injectable,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {AuthService} from '@app/main';
import {ContractPermissionService} from '@contracts/frontend-api';
import {Subject} from 'rxjs';
import {repeat} from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class UserLevelService {
	protected readonly destroyRef        = inject(DestroyRef);
	protected readonly permissionService = inject(ContractPermissionService);
	protected readonly authService       = inject(AuthService);
	protected readonly isUser            = new Subject<boolean>();
	readonly isUser$                     = this.isUser.asObservable().pipe(repeat(1));
	protected readonly isAgent           = new Subject<boolean>();
	readonly isAgent$                    = this.isAgent.asObservable().pipe(repeat(1));
	protected readonly isAdmin           = new Subject<boolean>();
	readonly isAdmin$                    = this.isAdmin.asObservable().pipe(repeat(1));

	constructor() {
		this.startUserLevelDetection();
	}


	protected async startUserLevelDetection(): Promise<void> {
		this.authService.isLoggedIn$
		    .pipe(takeUntilDestroyed(this.destroyRef))
		    .subscribe(async isLoggedIn => {
			    let isAdmin = false;
			    let isAgent = false;
			    let isUser  = false;

			    if(isLoggedIn) {
				    let page = await this.permissionService.getOwn();
				    permissionLoop:
					    while(true) {
						    const permissions = await Promise.all(page.data
						                                              .map(permission => [
							                                              permission.model.firstValue,
							                                              permission.property.firstValue,
						                                              ])
						                                              .map(permission$ => Promise.all(permission$))
						                                              .map(permissions => permissions.then(([model, property]) => ({
							                                              property,
							                                              model,
						                                              }))),
						    );
						    for(const permission of permissions) {
							    if(permission.model !== 'MedicalStore' && permission.model != null)
								    continue;

							    if(permission.property !== 'medicalStoreAdmin' && permission.property != null)
								    continue;

							    const isAdminCheck = permission.model == null && permission.property == null;
							    if(isAdminCheck) {
								    isAdmin = true;
								    break permissionLoop;
							    }

							    const isAgentCheck = permission.model == null || permission.property == null;
							    if(isAgentCheck) {
								    isAgent = true;
								    continue;
							    }

							    isUser = true;
						    }

						    if(page.hasNextPage() === false)
							    break;

						    page = await page.loadNextPage();
					    }
			    }

			    if(isAdmin) {
				    isAgent = false;
				    isUser  = false;
			    }

			    if(isAgent)
				    isUser = false;

			    this.isAdmin.next(isAdmin);
			    this.isAgent.next(isAgent);
			    this.isUser.next(isUser);
		    });
	}
}
