import {ResultPageModel} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
} from '@angular/core';
import {environment} from '@app/environment';
import {
	combineLatestSafe,
	MinimalColumns,
	SearchFilter,
} from '@app/main';
import {
	ContractAccessionModel,
	ContractAccessionService,
	ExternalContractNumberModel,
	ExternalContractNumberType,
} from '@contracts/frontend-api';
import {
	Observable,
	of,
} from 'rxjs';
import {
	first,
	map,
	mergeMap,
} from 'rxjs/operators';
import {BaseContractListComponent} from '../../base-contract/list/base-contract-list.component';

@Component({
	selector:    'portal-contract-accession-list',
	templateUrl: './contract-accession-list.component.html',
	styleUrls:   ['./contract-accession-list.component.scss'],
})
export class ContractAccessionListComponent {
	@Input() data: ContractAccessionModel[] | ResultPageModel<ContractAccessionModel> | undefined | null;
	environment                                                   = environment;
	externalContractNumberType                                    = ExternalContractNumberType;
	readonly searchFilter                                         = new CSearchFilter();
	readonly tableHeaders: MinimalColumns<ContractAccessionModel> = {
		institutionskennzeichen: {
			label:         'model.institutionskennzeichen',
			index:         0,
			prepareSearch: (value) => {
				if(typeof value === 'string')
					return value.replace(/\s/g, '');
				return value;
			},
		},
		joinedParties:           {
			label: 'model.contractingParties',
			index: 1,
		},
		masterContract:          {
			label: 'model.masterContract',
			index: 2,
		},
		contract:                {
			label: 'model.contract',
			index: 3,
		},
		contractSection:         {
			label: 'model.contractSection',
			index: 4,
		},
		accessionStartAt:        {
			// isVisible: false,
			label:     'contractAccession.accessionStartAt',
			index:     5,
		},
		accessionEndAt:          {
			isVisible: false,
			label:     'contractAccession.accessionEndAt',
			index:     6,
		},
		externalContractNumbers:          {
			isVisible: false,
			label:     'externalContractNumber.modelType.legs',
			isSearchable: false,
			index:     6,
		},
	};

	constructor(
		protected readonly contractAccessionService: ContractAccessionService,
	) {
	}

	static getMasterContract$(model: ContractAccessionModel): Observable<string> {
		return model.contractSection.value.pipe(
			mergeMap(contractSection => contractSection?.parent.value ?? of(undefined)),
			mergeMap(contract => contract?.parent.value ?? of(undefined)),
			mergeMap(masterContract => masterContract?.name.value ?? of(undefined)),
			map(name => name ?? ''),
		);
	}

	static getContract$(model: ContractAccessionModel): Observable<string> {
		return model.contractSection.value.pipe(
			mergeMap(contractSection => contractSection?.parent.value ?? of(undefined)),
			mergeMap(contract => contract?.name.value ?? of(undefined)),
			map(name => name ?? ''),
		);
	}

	static getContractSection$(model: ContractAccessionModel): Observable<string> {
		return model.contractSection.value.pipe(
			mergeMap(contractSection => contractSection?.name.value ?? of(undefined)),
			map(name => name ?? ''),
		);
	}

	static getInstitutionskennzeichen$(model: ContractAccessionModel): Observable<string> {
		return model.institutionskennzeichen.value.pipe(
			mergeMap(ik => ik?.number.value ?? of(undefined)),
			map(name => name ?? ''),
		);
	}

	static getJoinedParties$(model: ContractAccessionModel): Observable<string[] | undefined> {
		return model.contractSection.value.pipe(
			mergeMap(contractSection => (contractSection) == null ? of(contractSection) : BaseContractListComponent.getJoinedParties$(
				contractSection)),
		);
	}
	
	static getExternalContractNumbers$(model: ContractAccessionModel): Observable<ExternalContractNumberModel[] | undefined> {
		return model.contractSection.value.pipe(
			mergeMap(contractSection => (contractSection) == null ? of(contractSection) : contractSection.externalContractNumbers.withParent.value.pipe(
				map((externalContractNumbers) => {
								
					const ecnList = [];
					for (const ecn of externalContractNumbers) {
						ecnList.push(
							ecn.type.value.pipe(
									map((type) => ({
										type,
										number: ecn,
									})),
							),
						);
					}

					return ecnList;
				}),
					mergeMap((test) => combineLatestSafe(test)),
					map((test) => test.map((number) => number.number)),
			)),
		);
	}

	getJoinedParties$(model: ContractAccessionModel): Observable<string[] | undefined> {
		return ContractAccessionListComponent.getJoinedParties$(model);
	}

	getMasterContract$(model: ContractAccessionModel): Observable<string> {
		return ContractAccessionListComponent.getMasterContract$(model);
	}

	getContract$(model: ContractAccessionModel): Observable<string> {
		return ContractAccessionListComponent.getContract$(model);
	}

	getContractSection$(model: ContractAccessionModel): Observable<string> {
		return ContractAccessionListComponent.getContractSection$(model);
	}

	getInstitutionskennzeichen$(model: ContractAccessionModel): Observable<string> {
		return ContractAccessionListComponent.getInstitutionskennzeichen$(model);
	}

	getExternalContractNumbers$(model: ContractAccessionModel): Observable<ExternalContractNumberModel[] | undefined> {
		return ContractAccessionListComponent.getExternalContractNumbers$(model);
	}
}

class CSearchFilter extends SearchFilter<ContractAccessionModel> {
	protected async getModelValue(field: string, model: ContractAccessionModel): Promise<unknown> {
		switch(field) {
			case 'masterContract':
				return ContractAccessionListComponent.getMasterContract$(model).pipe(first()).toPromise();

			case 'contractSection':
				return ContractAccessionListComponent.getContractSection$(model).pipe(first()).toPromise();

			case 'contract':
				return ContractAccessionListComponent.getContract$(model).pipe(first()).toPromise();

			case 'joinedParties':
				return ContractAccessionListComponent.getJoinedParties$(model).pipe(first()).toPromise();

			case 'institutionskennzeichen':
				return ContractAccessionListComponent.getInstitutionskennzeichen$(model).pipe(first()).toPromise();
			
			case 'externalContractNumbers':
				return ContractAccessionListComponent.getExternalContractNumbers$(model).pipe(first()).toPromise();

			default:
				return super.getModelValue(field, model);
		}
	}
}
