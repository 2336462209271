import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Data, RouterStateSnapshot } from '@angular/router';
import {LoginStateGuard} from './login-state.guard';

@Injectable({
	providedIn: 'root',
})
export class LoginGuard  {
	private static readonly REDIRECT_URL = '/login';

	constructor(
		private readonly loggedInStateGuard: LoginStateGuard,
	) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): ReturnType<LoginStateGuard['canActivate']> {
		route.data = this.prepareData();
		return this.loggedInStateGuard.canActivate(route, state);
	}

	canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): ReturnType<LoginStateGuard['canActivateChild']> {
		childRoute.data = this.prepareData();
		return this.loggedInStateGuard.canActivateChild(childRoute, state);
	}

	protected prepareData(): Data {
		return {[LoginStateGuard.REDIRECT_IF_NOT_LOGGED_IN]: LoginGuard.REDIRECT_URL};
	}
}
