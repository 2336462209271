import {
	Component,
	Input,
} from '@angular/core';
import {ContractAccessionModel} from '@contracts/frontend-api';

@Component({
	selector:    'portal-contract-accession-show-accession-end-at',
	templateUrl: './contract-accession-show-accession-end-at.component.html',
	styleUrls:   ['./contract-accession-show-accession-end-at.component.scss'],
})
export class ContractAccessionShowAccessionEndAtComponent {
	@Input({required: true}) model!: ContractAccessionModel;
}
