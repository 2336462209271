import {
	Component,
	Input,
} from '@angular/core';
import {InstitutionskennzeichenModel} from '@contracts/frontend-api';

@Component({
	selector:    'portal-institutionskennzeichen-show-number',
	templateUrl: './institutionskennzeichen-show-number.component.html',
	styleUrls:   ['./institutionskennzeichen-show-number.component.scss'],
})
export class InstitutionskennzeichenShowNumberComponent {
	@Input({required: true}) model!: InstitutionskennzeichenModel;
}
