import {
	Component,
	Input,
} from '@angular/core';
import {ContractingPartyModel} from '@contracts/frontend-api';

@Component({
	selector:    'portal-contracting-party-show-name',
	templateUrl: './contracting-party-show-name.component.html',
	styleUrls:   ['./contracting-party-show-name.component.scss'],
})
// todo replace by common-show-name
export class ContractingPartyShowNameComponent {
	@Input({required: true}) model!: ContractingPartyModel;
}
