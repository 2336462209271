<portal-model-editable-box
	[icon]="iconService.IQZ_PARTICIPATION"
	headline="model.iqzParticipations"
	[formHelper]="formHelper"
	[hasEditButton]="(medicalStore.parent.value | async ) == null"
	[enableContentGrid]="false"
>
	@if ((iqzPoints | async);as points) {
		<ng-template #showModeContent>
			<div class="wrapper">
				<ng-container *ngLet="certifiedTill | async as till">
					<span>{{ 'iqzParticipation.status.till' | translate }}:</span>
					@if (till == null) {
						<span>
              {{ 'iqzParticipation.status.notCertified' | translate }}
							<ng-container [ngTemplateOutlet]="errorIcon"/>
            </span>
					}
					@if (till != null) {
						<span>{{ till | date }}</span>
					}
				</ng-container>
			</div>
			<mat-accordion>
				@for (entry of (points | keyvalue | sortCustom: sort);track entry) {
					<mat-expansion-panel>
						<mat-expansion-panel-header>
							<mat-panel-title>
								<div>
									<portal-skeleton-field [content]="entry.key.name.value"/>
									@if (entry.value.points < REQUIRED_POINTS) {
										<ng-container
											[ngTemplateOutlet]="errorIcon"/>
									}
								</div>
								<span>{{ entry.value.points }}</span>
							</mat-panel-title>
						</mat-expansion-panel-header>
						<portal-iqz-participation-list [data]="entry.value.participations"></portal-iqz-participation-list>
					</mat-expansion-panel>
				}
			</mat-accordion>
		</ng-template>
	}


	<ng-template #editModeContent let-control>
		@if ((medicalStore.parent.value | async) == null) {
			<portal-medical-store-edit-iqz-categories [control]="control"/>
		}
	</ng-template>
</portal-model-editable-box>

<ng-template #errorIcon>
	<fa-icon
		class="alert"
		[icon]="iconService.ICON_ALERT"
    #tooltip="matTooltip"
		[matTooltip]="'iqzParticipation.status.help' | translate: {limit: REQUIRED_POINTS}"
		matTooltipPosition="above"
	/>
</ng-template>