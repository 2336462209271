import {Level8Error} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
} from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
} from '@angular/forms';
import {
	FormHelperService,
	IconService,
	SelectFieldEntry,
} from '@app/main';
import {EmployeeSalutation} from '@contracts/frontend-api';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'portal-employee-edit-salutation',
	templateUrl: './employee-edit-salutation.component.html',
	styleUrls: ['./employee-edit-salutation.component.scss'],
})
export class EmployeeEditSalutationComponent {
	@Input({
		required: true,
		alias:    'control',
	}) parent!: UntypedFormGroup;
	possibleValues?: SelectFieldEntry[];

	constructor(
		public iconService: IconService,
		public formHelperService: FormHelperService,
		translateService: TranslateService,
	) {
		EmployeeEditSalutationComponent.loadPossibleValues(translateService)
									   .then(values => this.possibleValues = values);
	}

	get control(): UntypedFormControl {
		const fieldName = 'salutation';
		const control   = this.parent.get(fieldName);
		if(control instanceof UntypedFormControl)
			return control;

		throw new Level8Error(`Unexpected type for field ${fieldName} - expected '${UntypedFormControl.name}' got '${typeof control}' (${control})`);
	}

	private static async loadPossibleValues(translateService: TranslateService): Promise<SelectFieldEntry[]> {
		const possibleValues: SelectFieldEntry[] = [];
		for(const salutation of Object.keys(EmployeeSalutation)) {
			const label = translateService.get(`employee.salutations.${salutation}`).toPromise();
			possibleValues.push({
				label: await label,
				value: salutation,
			});
		}

		return possibleValues;
	}

}
