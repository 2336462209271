import {
	Component,
	Input,
} from '@angular/core';
import {AddressInterface} from '@contracts/frontend-api';

@Component({
	selector:    'portal-address-show-model',
	templateUrl: './address-show-model.component.html',
	styleUrls:   ['./address-show-model.component.scss'],
})
export class AddressShowModelComponent {
	@Input({required: true}) model: AddressInterface | undefined | null;
}
