import {
	Component,
	Input,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
	combineLatestSafe,
	IconService,
} from '@app/main';
import {
	ContractRequirementModel,
	DummyRequirementCheckInterface,
	EmployeeHasDataCheckInterface,
	MedicalStorePropertyHasValueOfValuesCheckInterface,
	PreQualificationCertificateExistsCheckInterface,
	PreQualificationCertificateOneOfExistsCheck,
	RequirementFunctionName,
	RoleService,
	VersorgungsbereichService,
} from '@contracts/frontend-api';
import {
	combineLatest,
	EMPTY,
	Observable,
	of,
	ReplaySubject,
} from 'rxjs';
import {
	map,
	mergeMap,
	take,
	tap,
} from 'rxjs/operators';

@Component({
	selector:    'portal-requirement',
	templateUrl: './requirements-show.component.html',
	styleUrls:   ['./requirements-show.component.scss'],
})
export class RequirementsShowComponent {
	readonly requirementsList  = new ReplaySubject<ContractRequirementModel[]>(1);
	readonly requirementsList$ = this.requirementsList.asObservable();
	isLoading                  = false;

	parameterMap   = new Map<string, string>();
	requirementMap = new Map<string, string>();

	constructor(
		public iconService: IconService,
		public roleService: RoleService,
		public versorgungsbereichService: VersorgungsbereichService,
	) {
		this.requirementsList.pipe(
				tap(() => this.isLoading = true),
				mergeMap((requirements) => this.updateRequirements(requirements)),
				tap(() => this.isLoading = false),
				takeUntilDestroyed(),
		).subscribe();
	}

	@Input({required: true}) set requirements(requirements: ContractRequirementModel[] | null | undefined) {
		if(requirements != null)
			this.requirementsList.next(requirements);
	}

	updateRequirements(requirements: ContractRequirementModel[]): Observable<void> {
		const loadingObservables = requirements.map(requirement => this.loadRequirementMap(requirement));
		return combineLatestSafe(loadingObservables).pipe(map(() => {return;}));
	}

	loadRequirementMap(requirement: ContractRequirementModel): Observable<void> {
		const functionName$ = requirement.requirementFunction.value.pipe(
			map(requirementFunction => requirementFunction?.name.value),
			mergeMap(x => x ?? EMPTY),
			tap(name => this.requirementMap.set(requirement.id, name ?? '')),
		);

		const modelName$ = combineLatest([
			functionName$,
			requirement.parameters.value,
		]).pipe(
			map(([name, parameters]) => {
				if(name == null)
					return undefined;

				switch(name) {
					case RequirementFunctionName.preQualificationCertificateExistsCheck:
						const preQualificationCertificateExistsParameter = parameters as PreQualificationCertificateExistsCheckInterface;
						return this.versorgungsbereichService.getById(preQualificationCertificateExistsParameter.versorgungsbereich_id);
					
					case RequirementFunctionName.employeeHasDataCheck:
					case RequirementFunctionName.negateEmployeeHasDataCheck:
						const employeeHasDataParameter = parameters as EmployeeHasDataCheckInterface;
						switch(employeeHasDataParameter.relation) {
							case 'roles':
								if(typeof employeeHasDataParameter.value !== 'string')
									throw new Error('Unexpected type for \'value\'');

								return this.roleService.getById(employeeHasDataParameter.value);

							default:
								throw new Error(`Unknown RequirementFunctionName '${name}' - maybe you forgot to implement it?`);
						}

					case RequirementFunctionName.dummyRequirementCheck:
						const dummyHasParameter = parameters as DummyRequirementCheckInterface;
						return dummyHasParameter.value ? 'true' : 'false';
					
					case RequirementFunctionName.medicalStorePropertyIsOneOfValueCheck:
						const parameter = parameters as MedicalStorePropertyHasValueOfValuesCheckInterface;
						return `${parameter.values.join(', ')}`;
					
					case RequirementFunctionName.preQualificationCertificateOneOfExistsCheck:
						const preQualificationCertificateIsOneOfParameter = parameters as PreQualificationCertificateOneOfExistsCheck;
						return preQualificationCertificateIsOneOfParameter.versorgungsbereich_ids;
					
					default:
						throw new Error(`Unknown RequirementFunctionName '${name}' - maybe you forgot to implement it?`);
				}
			}),
			mergeMap((model) => {
				if(model == null)
					return of(undefined);
				
				if(typeof model == 'string')
					return of(model);

				if(Array.isArray(model))
					return of(model)
				
				return model.name.value;
			}),
		);

		const fillMap = modelName$.pipe(tap((name) => {
			if(Array.isArray(name)){
				if(name.length < 1)
                    return;

				const vbs = name.map((nameL) => this.versorgungsbereichService.getById(nameL));
				const vbNames = combineLatest(vbs.map(vb => vb.name.value));
				vbNames.pipe(
					take(1),
				).subscribe((names) => {
					this.parameterMap.set(requirement.id, names.sort().join(', '))
				})

				return;
			}

			if(typeof name === 'string'){
				this.parameterMap.set(requirement.id, name);
				return;
			}
		}));

		return fillMap.pipe(map(() => {return;}));
	}
}
