<h2>§ 1 Geltungsbereich und Parteien</h2>
<p>(1) Die Allgemeinen Geschäftsbedingungen (nachfolgend AGB genannt) regeln das Vertragsverhältnis zwischen dem Bundesinnungsverband für Orthopädietechnik (nachfolgend BIV-OT) und dem Nutzer des Portals „Mein Sanitätshaus“ (nachfolgend Nutzer).</p>
<p>(2) Auf “Mein Sanitätshaus” bietet der BIV-OT unentgeltlich folgende Dienstleistungen an:</p>
<ul>
	<li>Verwaltung von Verträgen mit Gesetzlichen Krankenkassen</li>
	<li>Zugang zu Kalkulationsgrundlagen</li>
</ul>

<h2>§ 2 Zustandekommen des Vertrages</h2>
<p>(1) Der Zugang zur Nutzung des Mein Sanitätshaus Service setzt die Anmeldung voraus.</p>
<p>(2) Mit der Anmeldung erkennt der Nutzer die vorliegenden AGB an. Mit der Anmeldung entsteht ein Vertragsverhältnis zwischen dem BIV-OT und dem angemeldeten Nutzer, das sich nach den Regelungen dieser AGB richtet.</p>

<h2>§ 3 Anmeldung und Kündigung</h2>
<p>(1) Für die Nutzung des Angebotes ist ein Nutzerkonto erforderlich. Dieses legt der BIV-OT für den jeweiligen Nutzer an. Danach steht es dem Nutzer frei, für sein Konto weitere individuelle Nutzerkonten für Mitarbeiter:innen anzulegen. Für die Registrierung ist eine Mitgliedschaft in einer Landesinnung für Orthopädietechnik oder einem entsprechenden Fachverband erforderlich und nötigenfalls nachzuweisen. Tritt der Betrieb des Nutzers aus der Landesinnung oder dem Fachverband aus, ist der BIV-OT berechtigt, das vorliegende Vertragsverhältnis mit einer Frist von 4 Wochen zum Monatsende zu kündigen.</p>
<p>(2) Eine Weitergabe der Zugangsdaten an betriebsfremde Personen ist schon aus eigenem Interesse zwingend zu unterlassen. Bei Ausscheiden eines Mitarbeiters, dem die Zugangsdaten ausgehändigt worden sind, verpflichtet sich der Nutzer, sein Nutzerkonto gegen unberechtigten Zugang zu sichern.</p>
<p>(3) Der Nutzer, als auch der BIV-OT ist berechtigt, das Vertragsverhältnis ohne Angabe eines Grundes schriftlich per Post, E-Mail mit einer Frist von 4 Wochen zum Monatsende zu kündigen. Das zwischen dem BIV-OT und dem Nutzer geschlossene Vertragsverhältnis über die Teilnahme an „Mein Sanitätshaus“ findet damit seine Beendigung. Die Verträge, dem der Nutzer über die Plattform beigetreten ist bleiben insoweit bestehen. Der BIV-OT wird nach erfolgter Kündigung die Meldung der Teilnahme des betroffenen Betriebes an die Krankenkassen einstellen.</p>
<p>(4) Der BIV-OT kann den Vertrag nach eigenem Ermessen, mit oder ohne vorherige Ankündigung und ohne Angabe von Gründen, zu jeder Zeit kündigen.</p>
<p>(5) Der Nutzer ist verpflichtet, in seinem Profil und sonstigen Bereichen des Portals keine absichtlichen Falschangaben zu machen. Diese können Auswirkungen auf die Wirksamkeit der über das Portal durchgeführten Vertragsbeitritte haben.</p>
<p>(6) Nach Beendigung des Vertragsverhältnisses werden sämtliche Daten des Nutzers von „Mein Sanitätshaus“ gemäß den datenschutzrechtlichen Vorgaben gelöscht.</p>

<h2>§ 4 Hauptleistungspflichten</h2>
<p>(1) Der Nutzer ist nach erfolgter Registrierung zur Nutzung des Portals „Mein Sanitätshaus“ berechtigt.</p>
<p>(2) Der Nutzer hat über „Mein Sanitätshaus“ die Möglichkeit, Verträgen gem. § 127 SGB V mit Gesetzlichen Krankenversicherungen beizutreten. Hierzu hat der Nutzer die Voraussetzungen für die Teilnahme am Vertrag darzulegen und die maßgeblichen Unterlagen und Belege dem BIV-OT zur Verfügung zu stellen. Der BIV-OT wird den Krankenkassen den Beitritt im Rahmen der regelmäßigen Übermittlung der teilnehmenden Betriebe anzeigen.</p>
<p>(3) Die angebotene Dienstleistung erfolgt unentgeltlich im Rahmen der satzungsmäßigen Aufgaben des BIV-OT.</p>

<h2>§ 5 Einwilligung zur Kontaktaufnahme</h2>
<p>Nach erfolgter Registrierung ist der BIV-OT berechtigt, dem Betrieb Informationen über eigene ähnliche Waren und Dienstleistungen und solchen der Confairmed GmbH über die bei der Registrierung verwandten E-Mail Adresse oder andere Kommunikationswege zu übersenden (§ 7 Abs. 3 UWG). Dieser Verwendung Ihrer Kontaktdaten kann der Nutzer jederzeit insgesamt oder für einzelne Maßnahmen, z.B. per E-Mail, Fax, Brief (Kontaktadresse Bundesinnungsverband für Orthopädietechnik, Tel.: 0231 55 70 50 0, Fax: 0231 55 70 50 40, Reinoldistr. 7-9, 44135 Dortmund) widersprechen, ohne dass hierfür andere als die Übermittlungskosten nach den Basistarifen entstehen.</p>

<h2>§ 6 Haftungsbegrenzung</h2>
<p>(1) Der BIV-OT übernimmt keine Verantwortung für den Inhalt und die Vollständigkeit und Richtigkeit der vom Nutzer übermittelten Angaben insbesondere derer, die für den Vertragsbeitritt eines Betriebes relevant sind</p>
<p>(2) Der BIV-OT ist nicht verpflichtet, die Vollständigkeit und Richtigkeit der Angaben des Nutzers zu überprüfen. Eine Haftung für nicht wirksame Vertragsbeitritte und unwirksame Abrechnungen mit gesetzlichen Krankenkassen oder vergleichbare Schäden durch den BIV-OT kommt nicht in Betracht.</p>
<p>(3) Für sonstige Schäden insbesondere Vermögensschäden, haftet der BIV-OT nur, wenn sie auf einer vorsätzlichen oder grob fahrlässigen Pflichtverletzung des BIV-OT oder auf einer vorsätzlichen oder grob fahrlässigen Pflichtverletzung eines gesetzlichen Vertreters oder Erfüllungsgehilfen des BIV-OT beruhen.</p>
<p>(4) Die Schadensersatzansprüche sind, auf den vorhersehbaren, vertragstypischen Schaden begrenzt.</p>

<h2>§ 7 Datenschutz</h2>
<p>(1) Die Erhebung und Verarbeitung von personenbezogenen Daten erfolgt im Rahmen der datenschutzrechtlichen Vorgaben insbesondere der DSGVO und den nationalen Regelungen.</p>
<p>(2) Auf die
	<a [routerLink]="environment.privacyFullUrl">Datenschutzerklärung</a> wird hingewiesen.
</p>
<p>(3) Der Nutzer sichert zu, dass die von Ihm übermittelten Angaben ebenfalls datenschutzkonform erhoben und verarbeitet wurden.</p>


<h2>§ 8 Schlussbestimmungen</h2>
<p>(1) Vertragssprache ist deutsch.</p>
<p>(2) BIV-OT ist zu Änderungen der allgemeinen Geschäftsbedingungen und sonstiger Bedingungen berechtigt. Er wird diese Änderungen nur aus triftigen Gründen durchführen, insbesondere aufgrund neuer technischer Entwicklungen, Änderungen der Rechtsprechung oder sonstigen gleichwertigen Gründen. Wird durch die Änderung das vertragliche Gleichgewicht zwischen den Parteien erheblich gestört, so unterbleibt die Änderung. Im Übrigen bedürfen Änderungen der Zustimmung des Nutzers.</p>
<p>(3) Die Unwirksamkeit einer einzelnen Regelung berührt die Wirksamkeit der anderen Regelungen aus dem Vertrag nicht. Sollte dieser Fall eintreten soll die Bestimmung nach Sinn und Zweck durch eine andere rechtlich zulässige Bestimmung ersetzt werden, die dem Sinn und Zweck der unwirksamen Bestimmung entspricht.</p>
