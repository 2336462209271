import {Component} from '@angular/core';
import {environment} from '@app/environment';
import {AbstractShowComponent} from '@app/main';
import {
	ContractSectionModel,
	ContractSectionService,
} from '@contracts/frontend-api';

@Component({
	selector:    'portal-show',
	templateUrl: './contract-sections-page-show.component.html',
	styleUrls:   ['./contract-sections-page-show.component.scss'],
})
export class ContractSectionsPageShowComponent extends AbstractShowComponent<ContractSectionModel, ContractSectionService> {
	readonly environment = environment;

	constructor(
		contractSectionService: ContractSectionService,
	) {
		super(contractSectionService);
	}

	protected async getModelName(): Promise<string | undefined> {
		return this.getModel().name.firstValue;
	}

	protected async postDeleteRedirect(): Promise<void> {
		const parent = await this.model?.contract.firstValue;
		return AbstractShowComponent.redirectToPageOrList(this.router, environment.contractsFullUrl, parent?.id);
	}
}
