import {Component} from '@angular/core';
import {environment} from '@app/environment';
import {AbstractShowComponent} from '@app/main';
import {
	EmployeeModel,
	EmployeeService,
	PreQualificationCertificateDtoInterface,
} from '@contracts/frontend-api';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
	templateUrl: './employee-page-show.component.html',
	styleUrls:   ['./employee-page-show.component.scss'],
})
export class EmployeePageShowComponent extends AbstractShowComponent<EmployeeModel, EmployeeService> {
	readonly environment                = environment;
	canCratePreQualificationCertificate = false;

	constructor(
		employeeService: EmployeeService,
	) {
		super(employeeService);
	}

	get pqcPrefillData$(): Observable<Partial<PreQualificationCertificateDtoInterface>> {
		return this.getModel()
				   .medicalStore.mapToObservable(medicalStore => medicalStore?.institutionskennzeichens.value)
				   .pipe(map(iks => {
					   const prefillData: Partial<PreQualificationCertificateDtoInterface> = {
						   employee: this.getModel(),
					   };

					   if(iks != null && iks.length === 1)
						   prefillData.institutionskennzeichen = iks[0];

					   return prefillData;
				   }));
	}

	protected getModelName(): Promise<string | undefined> {
		return this.getModel().name.firstValue;
	}

	protected async onNewModel(newModel: EmployeeModel, oldModel?: EmployeeModel): Promise<void> {
		newModel.preQualificationCertificates.permissions.canCreate
				.then(permitted => this.canCratePreQualificationCertificate = permitted);
	}

	protected async postDeleteRedirect(): Promise<void> {
		const medicalStore = await this.getModel().medicalStore.firstValue;
		return AbstractShowComponent.redirectToPageOrList(
			this.router,
			environment.medicalStoresFullUrl,
			medicalStore?.id,
		);
	}
}
