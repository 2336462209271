import {
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {
	FormHelperService,
	IconService,
} from '@app/main';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';

@Component({
  selector:    'portal-edit-number-field',
  templateUrl: './number-field-edit.component.html',
  styleUrls:   ['./number-field-edit.component.scss'],
})
export class NumberFieldEditComponent {
  @Input() icon?: IconDefinition;
  @Input() label      = '';
	@Input({required: true}) validator!: UntypedFormControl;
	@Input() placeholder?: string | null;
  @Input() showAlways = false;
  @Input() suffix?: string;
  @Output() change    = new EventEmitter();

  constructor(
      public iconService: IconService,
      public formHelperService: FormHelperService,
  ) {
  }
}
