import {
	AbstractService,
	Level8Error,
	ResultPageModel,
	SearchEntry,
} from '@angular-helpers/frontend-api';
import {AbstractApiService} from '@angular-helpers/frontend-api/lib/models/abstract-model/abstract.api-service';
import {DatePipe} from '@angular/common';
import {
	Component,
	Input,
	OnChanges,
	SimpleChanges,
	TemplateRef,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
	IconService,
	MinimalColumns,
	SearchOptions,
} from '@app/main';
import {
	Subject,
	timer,
} from 'rxjs';
import {
	debounce,
	first,
	switchMap,
	tap,
} from 'rxjs/operators';
import {AnyModel} from '../../Helper/any';
import {AbstractTableComponent} from '../table/abstract-table/abstract-table.component';

interface SearchingOptions {
	searchNow: boolean;
	page: number;
}

@Component({
	selector:    'portal-table-server-side-searchable',
	templateUrl: './table-server-side-searchable.component.html',
	styleUrls:   ['./table-server-side-searchable.component.scss'],
})
export class TableServerSideSearchableComponent<TableEntry extends AnyModel> extends AbstractTableComponent<TableEntry> implements OnChanges {
	protected static readonly DEFAULT_PAGE_SIZE = 25;
	@Input() customMenu?: TemplateRef<unknown>;
	@Input({required: true}) service!: AbstractService<AbstractApiService, TableEntry>;
	@Input({required: true}) headers!: MinimalColumns<TableEntry>;
	@Input() columnLink: string | ((entry: TableEntry) => string | undefined) | undefined;
	@Input() getTrackingId?: ((value: unknown) => string | undefined);
	@Input() pageSize       = TableServerSideSearchableComponent.DEFAULT_PAGE_SIZE;
	@Input() withMenu       = true;
	@Input() prepareSearch?: (value: unknown, searchingValues: Record<string, unknown>, searchOptions: SearchOptions) => unknown | undefined;
	@Input() useSearchRoute = true;

	protected isLoading = false;

	protected readonly searchValues: Record<string, string | number | boolean | null | undefined> = {};
	protected readonly search$ = new Subject<SearchingOptions>();
	protected readonly searched$ = new Subject<ResultPageModel<TableEntry>>();
	protected readonly pages                                = new Map<number, ResultPageModel<TableEntry>>();

	constructor(
		protected readonly datePipe: DatePipe,
		protected readonly iconService: IconService,
	) {
		super();
		this.search$.pipe(
				tap(() => {
					this.isLoading = true;
					this.pages.clear();
				}),
			debounce(settings => timer(settings.searchNow ? 0 : 1_000)),
			switchMap(settings => this._search(this.searchData(this.useSearchRoute), settings.page)),
				tap((page) => {
					this.pages.clear();
					this.addPage(page);
					this.searched$.next(page);
					this.isLoading = false;
				}),
				takeUntilDestroyed(),
		).subscribe();
	}

	get currentPage(): ResultPageModel<TableEntry> | undefined {
		return this.pages.get(this.displayPage);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if('headers' in changes)
			this.tableHeaders = this.parseHeaders(changes.headers.currentValue);

		if('service' in changes && changes.service.currentValue !== changes.service.previousValue)
			this.showPage(this.displayPage);
	}

	get data(): TableEntry[] | undefined {
		return this.currentPage?.data;
	}

	get columns(): readonly string[] {
		return Object.keys(this.headers);
	}

	async showPage(pageIndex: number): Promise<void> {
		const alreadyLoading = this.isLoading;
		this.isLoading = true;
		this.displayPage     = pageIndex;

		let page = this.pages.values().next().value; //get any page

		if(!(page instanceof ResultPageModel)) {
			await this.executeSearch(undefined, {
				searchNow: true,
				id: 'id',
				page: pageIndex,
			});
			page           = this.pages.values().next().value;
			this.isLoading = true;
		}

		if(!(page instanceof ResultPageModel))
			throw new Level8Error('Missing start page');

		const nextPage = await page.loadPage(pageIndex);
		this.addPage(nextPage);
		if(!alreadyLoading)
			this.isLoading = false;
	}

	protected goToPage(page: number): void {
		this.showPage(page);
	}

	protected _prepareSearch(value: unknown, searchOptions: SearchOptions): unknown {
		if(this.prepareSearch != null)
			return this.prepareSearch(value, this.searchValues, searchOptions);

		return value;
	}

	protected async executeSearch(value: unknown, searchOptions: SearchOptions): Promise<void> {
		const header = this.tableHeaders[searchOptions.id];
		if(header !== undefined)
			header.filter = value;

		if(value === '' || value === undefined)
			delete this.searchValues[searchOptions.id];
		else {
			if(!(typeof (value) === 'string' || typeof (value) === 'number' || typeof (value) === 'boolean' || value === null))
				throw new Level8Error(`Invalid search value type - expected doctor got ${typeof value}`);

			this.searchValues[searchOptions.id] = value;
		}

		searchOptions.page ??= 0;
		this.displayPage = searchOptions.page;
		this.search$.next({searchNow: (searchOptions.searchNow ?? false), page: searchOptions.page});
		await this.searched$.pipe(first()).toPromise();
	}

	protected async _search(search: SearchEntry | SearchEntry[] | Record<string, unknown>, page = 0): Promise<ResultPageModel<TableEntry>> {
		return this.service.getPage(
			page,
			{
				pageSize: this.pageSize,
				search: (Array.isArray(search) && search.length === 0)? undefined: search,
			},
		);
	}

	searchData(hasSearchRoute?: boolean): SearchEntry | SearchEntry[] | Record<string, unknown> {
		if(Boolean(
			hasSearchRoute))
			return this.searchValues;

		return Object.keys(this.searchValues)
		.map((key): SearchEntry => ({
			column:     key,
			comparator: 'ilike',
			value:      `%${this.searchValues[key]}%`,
		}));
	}

	protected addPage(page: ResultPageModel<TableEntry> | undefined | null): void {
		if(page == null)
			return;

		// preload next page
		if(page.hasNextPage())
			page.loadNextPage().then();

		this.pages.set(page.meta.page, page);
	}

}
