<portal-model-editable-box
	[icon]="iconService.LANDESINNUNG"
	headline="general.masterData"
	[formHelper]="formHelper"
	[hasEditButton]="canUpdate | async"
>

	@if (landesinnung) {
		<ng-template #showModeContent>
			<portal-common-show-name [model]="landesinnung"/>
			<portal-address-show-model [model]="landesinnung.address.value | async"/>
			<portal-common-show-phone [model]="landesinnung"/>
			<portal-common-show-fax [model]="landesinnung"/>
			<portal-common-show-email [model]="landesinnung"/>
			<portal-common-show-website [model]="landesinnung"/>
			<!--		<portal-common-show-contact [model]="landesinnung"/>-->
			<!--		 todo #75 -->
		</ng-template>
	}

	<ng-template #editModeContent let-control>
		<portal-common-edit-name [control]="control"/>
		<portal-address-edit-model [control]="control.controls.address"/>
		<portal-common-edit-phone [control]="control"/>
		<portal-common-edit-fax [control]="control"/>
		<portal-common-edit-email [control]="control"/>
		<portal-common-edit-website [control]="control"/>
		<!--		<portal-common-edit-contact [control]="control"/>-->
		<!--		 todo #75-->
	</ng-template>

</portal-model-editable-box>
