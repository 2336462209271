import {
	Component,
	Input,
} from '@angular/core';
import {ContractingPartyModel} from '@contracts/frontend-api';

@Component({
	selector:    'portal-contracting-party-show-insurance-count',
	templateUrl: './contracting-party-show-insurance-count.component.html',
	styleUrls:   ['./contracting-party-show-insurance-count.component.scss'],
})
export class ContractingPartyShowInsuranceCountComponent {
	@Input({required: true}) model!: ContractingPartyModel;
}
