import {DtoCreationFormHelper} from '@angular-helpers/frontend-api';
import {HttpErrorResponse} from '@angular/common/http';
import {
	Component,
	ErrorHandler,
	OnDestroy,
	OnInit,
} from '@angular/core';
import {
	AbstractControl,
	UntypedFormGroup,
	ValidationErrors,
} from '@angular/forms';
import {Router} from '@angular/router';
import {environment} from '@app/environment';
import {
	FileType,
	FormHelperService,
	IconService,
} from '@app/main';
import {
	EmployeeService,
	PreQualificationCertificateDtoModel,
	PreQualificationCertificateModel,
	PreQualificationCertificateService,
	RoleService,
	VersorgungsbereichService,
} from '@contracts/frontend-api';
import {NGXLogger} from 'ngx-logger';
import {Subject} from 'rxjs';

interface PqzErrorResponse {
	accreditationBody?: null;
	certificateIdentifier?: null;
	validityStartAt?: null;
	validityEndAt?: null;
	address?: {
		town?: null;
		name?: null;
		zip?: null;
		street?: null;
	};
	parentMedicalStore?: {
		town?: null;
		name?: null;
		zip?: null;
		street?: null;
		institutionskennzeichen?: null;
	};
	institutionskennzeichen?: null;
	employee?: null;
	versorgungsbereichs?: { [key: string]: null };
}

@Component({
	selector:    'portal-pre-qualification-certificate-page-create',
	templateUrl: './pre-qualification-certificate-page-create.component.html',
	styleUrls:   ['./pre-qualification-certificate-page-create.component.scss'],
})
export class PreQualificationCertificatePageCreateComponent implements OnInit, OnDestroy {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	readonly FileType = FileType;
	formHelper!: DtoCreationFormHelper<PreQualificationCertificateModel, PreQualificationCertificateService>;
	isSaving          = false;
	control?: UntypedFormGroup;
	fileControl?: UntypedFormGroup;
	accreditationControl?: UntypedFormGroup;
	ikControl?: UntypedFormGroup;
	employeeControl?: UntypedFormGroup;
	validityControl?: UntypedFormGroup;
	versorgungsbereichsControl?: UntypedFormGroup;
	destroyed$        = new Subject<void>();
	public errorHasOccurred?: Error;

	constructor(
			private readonly preQualificationCertificateService: PreQualificationCertificateService,
			private readonly employeeService: EmployeeService,
			private readonly router: Router,
			private readonly logger: NGXLogger,
			public readonly roleService: RoleService,
			private readonly errorHandler: ErrorHandler,
			private readonly versorgungsbereichService: VersorgungsbereichService,
			public readonly formHelperService: FormHelperService,
			protected readonly iconService: IconService,
	) {
	}

	ngOnInit(): void {
		this.buildForm();
		// todo add predefined medicalStore / Ik / employee
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
	}

	async create(): Promise<void> {
		this.isSaving = true;
		try {
			const preQualificationCertificate = await this.formHelper.save();
			if(!preQualificationCertificate)
				return;

			const employee = await preQualificationCertificate.employee.firstValue;
			if(employee == null)
				throw new Error('employee not selected');

			const roles = await employee.roles.firstValue;
			if(roles == null)
				throw new Error('roles not loaded');

			if(!roles.some(role => role.id === environment.employeeMedicalStoreLeaderRoleId)) {
				const leaderRole = this.roleService.getById(environment.employeeMedicalStoreLeaderRoleId);
				await this.employeeService.addRole(employee, leaderRole);
			}

			await this.router.navigate([
				environment.preQualificationCertificateFullUrl,
				preQualificationCertificate.id,
			]);
		} catch(error) {
			if(error instanceof HttpErrorResponse && error.status === 422 && error.error.message === 'data differs from file' && this.control != null)
				await this.processWrongInputError(error.error.errors, this.control);
			 else {
				this.errorHandler.handleError(error);
				if(error instanceof Error || error instanceof HttpErrorResponse || error === undefined)
					this.errorHasOccurred = error;
				else
					this.errorHasOccurred = new Error(`${error}`);
			}
		} finally {
			this.isSaving = false;
		}
	}

	protected async processWrongInputError(error: PqzErrorResponse, parentControl: UntypedFormGroup): Promise<void> {
		const formErrors: ValidationErrors = parentControl.errors ?? {};
		const controls: AbstractControl[]  = [];
		const errorPrefix                  = 'preQualificationCertificate.';

		let control: null | AbstractControl = null;

		if(error.accreditationBody === null) {
			formErrors[`${errorPrefix}accreditationBody`] = '';
			control                                       = parentControl.get('accreditationBody');
			if(control != null)
				controls.push(control);
		}

		if(error.certificateIdentifier === null) {
			formErrors[`${errorPrefix}certificateIdentifier`] = '';
			control                                           = parentControl.get('certificateIdentifier');
			if(control != null)
				controls.push(control);
		}

		if(error.validityStartAt === null) {
			formErrors[`${errorPrefix}validityStartAt`] = '';
			control                                     = parentControl.get('validityStartAt');
			if(control != null)
				controls.push(control);
		}

		if(error.validityEndAt === null) {
			formErrors[`${errorPrefix}validityEndAt`] = '';
			control                                   = parentControl.get('validityEndAt');
			if(control != null)
				controls.push(control);
		}

		if(error.institutionskennzeichen === null) {
			formErrors[`${errorPrefix}institutionskennzeichen`] = '';
			control                                             = parentControl.get('institutionskennzeichen');
			if(control != null)
				controls.push(control);
		}

		if(error.employee === null) {
			formErrors[`${errorPrefix}employee`] = '';
			control                              = parentControl.get('employee');
			if(control != null)
				controls.push(control);
		}

		if(error.versorgungsbereichs !== undefined) {
			const vbNames = (await Promise.all(Object.keys(error.versorgungsbereichs)
													 .map(id => this.versorgungsbereichService.getById(id).name.firstValue))
			).filter((vb): vb is string => vb != null)
			 .sort((a, b) => a.localeCompare(b))
			 .join(', ');

			control = parentControl.get('versorgungsbereichs');
			if(control != null) {
				const e                                = control.errors ?? {};
				e[`${errorPrefix}versorgungsbereichs`] = {vbs: vbNames};
				control.setErrors(e);
			}

			formErrors[`${errorPrefix}versorgungsbereichs`] = {vbs: vbNames};
		}

		controls.forEach(c => {
			const e              = c.errors ?? {};
			e.pqzValidationError = '';
			c.setErrors(e);
		});

		parentControl.setErrors(formErrors);
	}

	private buildForm(): void {
		this.formHelper = DtoCreationFormHelper.create(
			PreQualificationCertificateDtoModel,
			this.preQualificationCertificateService,
			{},
		);
		this.formHelper.control.then(async control => {
			this.control = control;

			const fileControl                = new UntypedFormGroup({});
			const accreditationControl       = new UntypedFormGroup({});
			const ikControl                  = new UntypedFormGroup({});
			const employeeControl            = new UntypedFormGroup({});
			const validityControl            = new UntypedFormGroup({});
			const versorgungsbereichsControl = new UntypedFormGroup({});

			const unmappedControls: string[] = [];

			Object.keys(this.control.controls).forEach(ctrlName => {
				switch(ctrlName) {
					case 'file':
						fileControl.addControl(ctrlName, control.controls[ctrlName]);
						break;

					case 'accreditationBody':
					case 'certificateIdentifier':
						accreditationControl.addControl(ctrlName, control.controls[ctrlName]);
						break;

					case 'institutionskennzeichen':
						ikControl.addControl(ctrlName, control.controls[ctrlName]);
						break;

					case 'employee':
						employeeControl.addControl(ctrlName, control.controls[ctrlName]);
						break;

					case 'validityStartAt':
					case 'validityEndAt':
						validityControl.addControl(ctrlName, control.controls[ctrlName]);
						break;

					case 'versorgungsbereichs':
						versorgungsbereichsControl.addControl(ctrlName, control.controls[ctrlName]);
						break;

					default:
						unmappedControls.push(ctrlName);
				}
			});

			if(unmappedControls.length > 0)
				this.logger.warn(`Unmapped inputs`, unmappedControls);

			this.fileControl                = fileControl;
			this.accreditationControl       = accreditationControl;
			this.ikControl                  = ikControl;
			this.employeeControl            = employeeControl;
			this.validityControl            = validityControl;
			this.versorgungsbereichsControl = versorgungsbereichsControl;
		});
	}
}
