import {
	Component,
	Input,
} from '@angular/core';
import {IconService} from '@app/main';
import {PreQualificationCertificateModel} from '@contracts/frontend-api';

@Component({
	selector:    'portal-pre-qualification-certificate-card-owner',
	templateUrl: './pre-qualification-certificate-owner.component.html',
	styleUrls:   ['./pre-qualification-certificate-owner.component.scss'],
})
export class PreQualificationCertificateOwnerComponent {
	@Input({required: true}) preQualificationCertificate!: PreQualificationCertificateModel;

	constructor(
		public iconService: IconService,
	) {
	}

}
