import {UrlMatcher} from '@angular/router';

export class UrlMatchers {
	static uuid(parameterName = 'id'): UrlMatcher {
		return (segments, group, route) => {
			if(segments.length < 1)
				return null;

			if(segments[0].path.match(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i) == null)
				return null;

			return {
				consumed:  [segments[0]],
				posParams: {
					[parameterName]: segments[0],
				},
			};
		};
	}
}
