<portal-model-editable-box
	[icon]="iconService.MEDICAL_STORE"
	headline="general.masterData"
	[formHelper]="formHelper"
	[hasEditButton]="canUpdate | async"
>

	<ng-template #editModeContent let-control>
		<portal-common-edit-name [control]="control"/>
		<portal-medical-store-edit-legal-form [control]="control"/>
		<portal-medical-store-edit-biv-number [control]="control"/>
		<portal-landesinnung-edit [control]="control"/>
		<portal-professional-association-edit [control]="control"/>
		@if (control.controls.address) {
			<portal-address-edit-model [control]="$any(control.controls.address)"/>
		}
	</ng-template>

	@if (medicalStore) {
		<ng-template #showModeContent>
			<portal-common-show-name [model]="medicalStore"/>
			<portal-medical-store-show-legal-form [model]="medicalStore"/>
			<portal-address-show-model [model]="medicalStore.address.value | async"/>
			<portal-medical-store-show-biv-number [model]="medicalStore"/>
			<portal-medical-store-show-institutionskennzeichens [model]="medicalStore"/>
			<portal-landesinnung-show [model]="medicalStore.landesinnung.value | async"/>
			<portal-professional-association-show [model]="medicalStore.professionalAssociation.value | async"/>
			<portal-medical-store-show [model]="medicalStore.parent.value | async" label="medicalStore.mainHouse"/>
		</ng-template>
	}

</portal-model-editable-box>
