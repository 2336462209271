import {Component} from '@angular/core';
import {AbstractShowComponent} from '@app/main';
import {
	MedicalStoreModel,
	MedicalStoreService,
	ProfessionalAssociationModel,
	ProfessionalAssociationService,
} from '@contracts/frontend-api';

@Component({
	templateUrl: './professional-association-page-show.component.html',
	styleUrls:   ['./professional-association-page-show.component.scss'],
})
export class ProfessionalAssociationPageShowComponent extends AbstractShowComponent<ProfessionalAssociationModel, ProfessionalAssociationService> {
	medicalStores?: MedicalStoreModel[];

	constructor(
		modelService: ProfessionalAssociationService,
		private readonly medicalStoreService: MedicalStoreService,
	) {
		super(modelService);
	}

	protected async onNewModel(newModel: ProfessionalAssociationModel, oldModel?: ProfessionalAssociationModel): Promise<void> {
		const page         = await this.medicalStoreService.find({
			column:     'professional_association_id',
			value:      newModel.id,
			comparator: '=',
		});
		this.medicalStores = page.data;
	}

	protected getModelName(): Promise<string | undefined> {
		return this.getModel().name.firstValue;
	}
}
