import {
	Component,
	Input,
} from '@angular/core';
import {ContractingPartyModel} from '@contracts/frontend-api';

@Component({
	selector:    'portal-contracting-party-show-contact',
	templateUrl: './contracting-party-show-contact.component.html',
	styleUrls:   ['./contracting-party-show-contact.component.scss'],
})
// todo replace by portal-common-show-contact
export class ContractingPartyShowContactComponent {
	@Input({required: true}) model!: ContractingPartyModel;
}
