import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '@app/environment';
import {
	ConfigModel,
	PkceAuthService,
	Token,
} from '@authentication/angular';
import {Observable} from 'rxjs';


@Injectable({
	providedIn: 'root',
})
export class AuthService {
	constructor(
		public readonly auth: PkceAuthService,
		protected readonly router: Router,
	) {
		this.auth.setConfig(new ConfigModel(
			environment.redirectUrl,
			environment.authServer,
			environment.authClientId,
		));
	}

	get user$(): Observable<Token | undefined> {
		return this.auth.accessToken$;
	}

	get isLoggedIn$(): Observable<boolean> {
		return this.auth.isLoggedIn$();
	}

	logout$(): Observable<boolean> {
		return this.auth.logout$();
	}
}
