import {
	Component,
	Input,
} from '@angular/core';
import {PreQualificationCertificateModel} from '@contracts/frontend-api';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
	selector:    'portal-pre-qualification-certificate-show-versorgungsbereichs',
	templateUrl: './pre-qualification-certificate-show-versorgungsbereichs.component.html',
	styleUrls:   ['./pre-qualification-certificate-show-versorgungsbereichs.component.scss'],
})
export class PreQualificationCertificateShowVersorgungsbereichsComponent {
	@Input({required: true}) model!: PreQualificationCertificateModel;

	get list(): Observable<string[] | undefined> {
		const names$ = this.model.versorgungsbereichs.mapToObservable(versorgungsbereichs => versorgungsbereichs?.map(vb => vb.name.value));
		return names$.pipe(map(names => names?.filter((entry): entry is string => typeof entry === 'string')));
	}
}
