import {
	Component,
	Input,
} from '@angular/core';
import {environment} from '@app/environment';
import {UrlType} from '@app/main';
import {InstitutionskennzeichenModel} from '@contracts/frontend-api';

@Component({
	selector:    'portal-institutionskennzeichen-show',
	templateUrl: './institutionskennzeichen-show.component.html',
	styleUrls:   ['./institutionskennzeichen-show.component.scss'],
})
export class InstitutionskennzeichenShowComponent {
	@Input({required: true}) model: InstitutionskennzeichenModel[] | InstitutionskennzeichenModel | null | undefined;
	urlType              = UrlType;
	readonly environment = environment;
}
