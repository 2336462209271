<portal-headline
	label="general.modelList"
	[labelArgs]="{model: 'contractAccessions'}"
	[menu]="menu"
>
	<mat-menu #menu>
		@if (canCreateContractAccession | async) {
			<button mat-menu-item [matMenuTriggerFor]="menuCreate">
				<fa-icon matMenuItemIcon [icon]="iconService.CREATE"></fa-icon>
				<span>{{ 'general.menu.create' | translate }}</span>
			</button>
		}
		<a
			(click)="openDialog()"
			mat-menu-item
		>
			<fa-icon matMenuItemIcon [icon]="iconService.FILE_DOWNLOAD"></fa-icon>
			<span>{{ 'contractAccession.actions.export' | translate }}</span>
		</a>
	</mat-menu>

	<mat-menu #menuCreate>
		<a
			[routerLink]="[environment.contractAccessingFullUrl]"
			mat-menu-item
		>
			<fa-icon matMenuItemIcon [icon]="iconService.CONTACT_ACCESSION"></fa-icon>
			<span>{{ 'model.contractAccession' | translate }}</span>
		</a>
	</mat-menu>
</portal-headline>

<portal-contract-accession-card-list
	[hideIfEmpty]="false"
	[contractAccessions]="undefined"
	[label]="null"/>

<ng-template #exportAccessionsDialog>
	<portal-institutionskennzeichen-select (change)="selectedInstitutionsKennzeichen = $event"></portal-institutionskennzeichen-select>
</ng-template>
