import {Component} from '@angular/core';
import {environment} from '@app/environment';
import {AbstractShowComponent} from '@app/main';
import {
	ContractingPartyModel,
	ContractingPartyService,
	MasterContractService,
} from '@contracts/frontend-api';
import {
	combineLatest,
	Observable,
} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
	templateUrl: './contracting-party-page-show.component.html',
	styleUrls:   ['./contracting-party-page-show.component.scss'],
})
export class ContractingPartyPageShowComponent extends AbstractShowComponent<ContractingPartyModel, ContractingPartyService> {
	isEmpty?: Observable<boolean>;

	constructor(
		private readonly masterContractService: MasterContractService,
		modelService: ContractingPartyService,
	) {
		super(modelService);
	}

	protected onNewModel(newModel: ContractingPartyModel, oldModel?: ContractingPartyModel): Promise<void> {
		this.isEmpty = combineLatest([
			newModel.masterContracts.value,
			newModel.contracts.value,
			newModel.contractSections.value,
		])
			.pipe(map(([masterContracts, contracts, contractSections]) => {
				if(masterContracts && masterContracts.length > 0)
					return false;

				if(contracts && contracts.length > 0)
					return false;

				if(contractSections && contractSections.length > 0)
					return false;

				return true;
			}));

		return super.onNewModel(newModel, oldModel);
	}


	protected getModelName(): Promise<string | undefined> {
		return this.getModel().name.firstValue;
	}

	protected async postDeleteRedirect(): Promise<void> {
		await this.router.navigate([environment.contractingPartyFullUrl]);
	}
}
