import {Level8Error} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
} from '@angular/core';
import {
	FormControl,
	FormGroup,
} from '@angular/forms';
import {HmmParticipation} from '@contracts/frontend-api';

@Component({
	selector:    'portal-medical-store-edit-hmm-participation[control]',
	templateUrl: './medical-store-edit-hmm-participation.component.html',
	styleUrls:   ['./medical-store-edit-hmm-participation.component.scss'],
})
export class MedicalStoreEditHmmParticipationComponent {
	@Input('control') parent!: FormGroup;
	protected readonly enum = HmmParticipation;

	get control(): FormControl {
		const fieldName = 'hmmParticipation';
		const control   = this.parent.get(fieldName);
		if(control instanceof FormControl)
			return control;

		throw new Level8Error(`Unexpected type for field ${fieldName} - expected '${FormControl.name}' got '${typeof control}' (${control})`);
	}
}
