<portal-model-editable-box
	[icon]="iconService.INSTITUTIONSKENNZEICHEN"
	headline="general.masterData"
	[formHelper]="null"
	[hasEditButton]="false"
>

	@if (institutionskennzeichen) {
		<ng-template #showModeContent>
			<portal-medical-store-show [model]="institutionskennzeichen.medicalStore.value | async"/>
			<portal-institutionskennzeichen-show-number [model]="institutionskennzeichen"/>
		</ng-template>
	}

</portal-model-editable-box>
