import {
	DtoCreationFormHelper,
	SearchEntry,
} from '@angular-helpers/frontend-api';
import {HttpErrorResponse} from '@angular/common/http';
import {
	Component,
	Input as RouteInput,
	ViewChild,
} from '@angular/core';
import {
	AbstractControl,
	FormControl,
	FormGroup,
	UntypedFormGroup,
	ValidationErrors,
	Validators,
} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {MatStepper} from '@angular/material/stepper';
import {Router} from '@angular/router';
import {environment} from '@app/environment';
import {
	ConfirmDialogAnswer,
	ConfirmDialogComponent,
	CustomValidators,
	DialogService,
	IconService,
} from '@app/main';
import {
	IqzCategoryModel,
	IqzParticipationDtoModel,
	IqzParticipationModel,
	IqzParticipationService,
	IqzTrainingCourseModel,
	IqzTrainingCourseService,
	MedicalStoreModel,
	MedicalStoreService,
} from '@contracts/frontend-api';
import moment, {Moment} from 'moment';
import {take} from 'rxjs/operators';

@Component({
	selector:    'portal-iqz-participation-page-create',
	templateUrl: './iqz-participation-page-create.component.html',
	styleUrls:   ['./iqz-participation-page-create.component.scss'],
})
export class IqzParticipationPageCreateComponent {
	static readonly STEP_SELECT_TRAINING_COURSES = 0;
	static readonly STEP_SELECT_TRAINING_COURSE  = 1;
	static readonly STEP_SELECT_CATEGORY         = 2;
	static readonly STEP_SAVE                    = 2;
	protected control?: UntypedFormGroup;
	protected errorHasOccurred?: Error;
	protected readonly stepperFormGroup          = new FormGroup({
		stepOne:        new FormGroup({
			date:       new FormControl<Moment | null>(null, []),
			externalId: new FormControl<string | null>(
				null,
				[], [
					this.isValidTrainingCourseExternalId.bind(this),
				],
			),
		}, [
			this.atLeastOneChildRequired,
		]),
		trainingCourse: new FormControl<IqzTrainingCourseModel | null>(null, [Validators.required]),
		category:       new FormControl<IqzCategoryModel | null>(null, [Validators.required]),
	});
	protected trainingCourses?: IqzTrainingCourseModel[];
	protected isSaving                           = false;
	private _medicalStore?: MedicalStoreModel; // todo: required?
	private helper?: DtoCreationFormHelper<IqzParticipationModel, IqzParticipationService>;
	private cancelDialogRef?: MatDialogRef<ConfirmDialogComponent, ConfirmDialogAnswer>;
	@ViewChild('stepper') private readonly stepper!: MatStepper;

	constructor(
		private readonly iqzTrainingCourseService: IqzTrainingCourseService,
		private readonly iqzParticipationService: IqzParticipationService,
		private readonly medicalStoreService: MedicalStoreService,
		private readonly router: Router,
		private readonly dialogService: DialogService,
		protected readonly iconService: IconService,
	) {
	}

	@RouteInput()
	set id(id: unknown) {
		if(typeof id !== 'string') {
			this.medicalStore = undefined;
			return;
		}

		const initialValue = {
			medicalStore: this.medicalStoreService.getById(id),
		};
		this.helper        = DtoCreationFormHelper.create(IqzParticipationDtoModel, this.iqzParticipationService, initialValue);
		this.helper.control.then(control => this.control = control);
		this.medicalStore = initialValue.medicalStore;
	}

	get medicalStore(): MedicalStoreModel | undefined {
		return this._medicalStore;
	}

	set medicalStore(ms: MedicalStoreModel | undefined) {
		this._medicalStore = ms;

		if(this.helper == null)
			return;

		this.helper.fill({
			medicalStore: this.medicalStore,
		});
	}

	async saveForm(): Promise<void> {
		if(this.helper == null)
			return;

		try {
			this.isSaving = true;
			await this.helper.fill({
				iqzTrainingCourse: this.stepperFormGroup.controls.trainingCourse.value,
				iqzCategory:       this.stepperFormGroup.controls.category.value,
				medicalStore:      this.medicalStore,
			}, true);
			const savedModel = await this.helper.save();
			if(savedModel == null)
				return;

			await this.backToMedicalStore();
		} catch(error) {
			if(error instanceof Error || error instanceof HttpErrorResponse || error === undefined)
				this.errorHasOccurred = error;
			else
				this.errorHasOccurred = new Error(`${error}`);
			throw error;
		} finally {
			this.isSaving = false;
		}
	}

	async backToMedicalStore(): Promise<boolean> {
		return this.router.navigate([
			environment.medicalStoresFullUrl,
			this.medicalStore?.id,
		]);
	}

	async onAbort(): Promise<unknown> {
		if(!this.control)
			throw new Error('no Form detected');

		if(!this.control.dirty)
			return this.backToMedicalStore();

		this.cancelDialogRef    = await this.openAbortDialog();
		const afterClosedAnswer = await this.cancelDialogRef.afterClosed().pipe(take(1)).toPromise();
		if(afterClosedAnswer === ConfirmDialogAnswer.negative)
			await this.backToMedicalStore();

		return;
	}

	async openAbortDialog(): Promise<MatDialogRef<ConfirmDialogComponent>> {

		return this.dialogService.openConfirmDialog({
			labelNegative: 'system.abortChangesDialog.labelNegative',
			labelPositiv:  'system.abortChangesDialog.labelPositiv',
			title:         'system.abortChangesDialog.title',
			message:       'system.abortChangesDialog.message',
			icon:          this.iconService.DIALOG_ATTENTION,
		});
	}

	async selectTrainingCourse(trainingCourse: IqzTrainingCourseModel): Promise<void> {
		this.stepperFormGroup.controls.trainingCourse.setValue(trainingCourse);
		this.stepper.selectedIndex = IqzParticipationPageCreateComponent.STEP_SELECT_CATEGORY;

		const category = await trainingCourse.category.firstValue;
		if(category != null)
			this.stepperFormGroup.controls.category.setValue(category);
	}

	protected async loadTrainingCourseByExternalId(): Promise<void> {
		this.trainingCourses = undefined;
		this.stepperFormGroup.controls.stepOne.controls.date.setValue(null);
		const form = this.stepperFormGroup.controls.stepOne.controls.externalId;
		if(form.invalid || form.value == null || form.value === '')
			return;

		const trainingCourse = await this.searchTrainingCourseByExternalId(form.value);
		if(trainingCourse == null) {
			const errors   = form.errors ?? {};
			errors.pattern = '';
			form.setErrors(errors);
			return;
		}

		this.trainingCourses       = [trainingCourse];
		this.stepper.selectedIndex = IqzParticipationPageCreateComponent.STEP_SELECT_TRAINING_COURSE;
	}

	protected async loadTrainingCoursesByDate(): Promise<void> {
		this.trainingCourses = undefined;
		this.stepperFormGroup.controls.stepOne.controls.externalId.setValue(null);
		const form = this.stepperFormGroup.controls.stepOne.controls.date;
		if(form.invalid || form.value == null)
			return;

		const date = moment(form.value).format();
		const searchEntry: SearchEntry[] = [
			{
				value:      date,
				column:     'start_at',
				comparator: '<=',
			},
			{
				value:      date,
				column:     'end_at',
				comparator: '>=',
			},
		];
		const searchResult               = await this.iqzTrainingCourseService.find(searchEntry);
		if(searchResult.data.length < 1)
			return;

		this.trainingCourses       = searchResult.data;
		this.stepper.selectedIndex = IqzParticipationPageCreateComponent.STEP_SELECT_TRAINING_COURSE;
	}

	protected async searchTrainingCourseByExternalId(externalId: string): Promise<IqzTrainingCourseModel | undefined> {
		const searchEntry: SearchEntry = {
			value:      externalId,
			column:     'externalId',
			comparator: '=',
		};
		const searchResult             = await this.iqzTrainingCourseService.find(searchEntry);
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		return searchResult.data.find(_ => true);
	}

	protected atLeastOneChildRequired(control: AbstractControl): ValidationErrors | null {
		if(!(control instanceof FormGroup))
			return null;

		for(const child of Object.values(control.controls)) {
			if(child.value !== null)
				return null;
		}

		return {required: true};
	}

	protected async isValidTrainingCourseExternalId(control: AbstractControl): Promise<ValidationErrors | null> {
		const value = control.value;
		if(value == null || value === '')
			return null;

		const formatError = CustomValidators.isValidRegex(/^[0-9]{4,5}$/, 'pattern')(control);
		if(formatError != null)
			return formatError;

		const trainingCourse = await this.searchTrainingCourseByExternalId(control.value);
		if(trainingCourse == null)
			return {pattern: value};

		return null;
	}
}
