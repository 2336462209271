<div class="wrapper" id="medicalStorePermissionEdit">
	<portal-table-server-side-searchable
		[service]="medicalStoreService"
		[headers]="tableController.tableHeaders"
		[pageSize]="tableController.pageSize"
	>
		<ng-template searchHeader="isParent" let-column="column" [service]="medicalStoreService">
			<portal-table-search-select [column]="column" [possibleValues]="tableController.isParentFilter"/>
		</ng-template>
		<ng-template searchHeader="userCanRead" let-column="column" [service]="medicalStoreService">
			<portal-table-search-select [column]="column" [possibleValues]="tableController.hasPermissionFilter"/>
		</ng-template>
		<ng-template searchHeader="userCanEdit" let-column="column" [service]="medicalStoreService">
			<portal-table-search-select [column]="column" [possibleValues]="tableController.hasPermissionFilter"/>
		</ng-template>
		
		<ng-template column="isParent" let-store="model" [service]="medicalStoreService">
			<portal-skeleton-field [content]="store.parent.value">
				<ng-template let-parent skeletonContent>
					<p>{{ (parent == null ? 'medicalStore.mainHouse' : 'medicalStore.branchOffice') | translate }}</p>
				</ng-template>
			</portal-skeleton-field>
		</ng-template>
		<ng-template column="address" let-store="model" [service]="medicalStoreService">
			<portal-skeleton-field [content]="(store?.address?.value | async) |address"/>
		</ng-template>
		<ng-template column="name" let-store="model" [service]="medicalStoreService">
			<portal-skeleton-field [content]="(store?.name?.value | async)"/>
		</ng-template>
		<ng-template column="phone" let-store="model" [service]="medicalStoreService">
			<portal-skeleton-field [content]="(store?.phone?.value | async|phone)"/>
		</ng-template>
		<ng-template column="landesinnung" let-store="model" [service]="medicalStoreService">
			<portal-skeleton-field [content]="$any((store.landesinnung.value | async))?.name?.value" loadingContent="Landesinnung für Orthopädie-Technik Dortmund"/>
		</ng-template>
		<ng-template column="institutionskennzeichens" let-model="model" [service]="medicalStoreService">
			<portal-skeleton-field [content]="model|storeIks">
				<ng-template let-iks skeletonContent>
					@for (ik of iks;let last = $last;track ik) {
						{{ ik | formatInstitutionskennzeichen }}
						@if (!last) {
							<br/>
						}
					}
				</ng-template>
			</portal-skeleton-field>
		</ng-template>
		<ng-template column="userCanRead" let-store="model" [service]="medicalStoreService">
			@if (loadedMedicalStoreStates.get(store.id)) {
				@if ((store|storeToState:loadedMedicalStoreStates:prepareModel$)?.state?.inheritedView || !(store|storeToState:loadedMedicalStoreStates:prepareModel$)?.data?.permissionToEdit) {
					<mat-checkbox
						[color]="(store|storeToState:loadedMedicalStoreStates:prepareModel$)?.state?.touchedByUser ? 'accent' : 'primary'"
						class="view-check" [checked]="(store|storeToState:loadedMedicalStoreStates)?.state?.inheritedView ?? false || (store|storeToState:loadedMedicalStoreStates)?.state?.canView ?? false" [disabled]="true"
            #tooltip="matTooltip"
						[matTooltip]="(store|storeToState:loadedMedicalStoreStates:prepareModel$)?.data?.permissionToEdit ? ('medicalStoreUser.inheritedPermission'|translate): ('medicalStoreUser.missingPermission'|translate)"
						matTooltipPosition="right">&nbsp;
					</mat-checkbox>
				} @else {
					<mat-checkbox
						[color]="(store|storeToState:loadedMedicalStoreStates:prepareModel$)?.state?.touchedByUser ? 'accent' : 'primary'"
						class="view-check" [checked]="(store|storeToState:loadedMedicalStoreStates:prepareModel$)?.state?.canView ?? false"
						(change)="viewChecked($event.checked, store)"
            #tooltip="matTooltip"
						[matTooltip]="'medicalStoreUser.canView' | translate"
						matTooltipPosition="right">&nbsp;
					</mat-checkbox>
				}
			} @else {
				@if ((store|canViewStorePermission:loadedMedicalStoreStates:selectedUserId |async) === false || disable) {
					<mat-checkbox
						[color]="(store|storeToState:loadedMedicalStoreStates:prepareModel$)?.state?.touchedByUser ? 'accent' : 'primary'"
						class="view-check" [checked]="((store|storeToStateIsChecked:prepareModel$:loadedMedicalStoreStates:selectedUserId:'view')|async) ?? false" [disabled]="true"
            #tooltip="matTooltip"
						[matTooltip]=" (store|canViewStorePermission:loadedMedicalStoreStates:selectedUserId |async)  ? ('medicalStoreUser.inheritedPermission'|translate): ('medicalStoreUser.missingPermission'|translate)"
						matTooltipPosition="right">
					</mat-checkbox>
				} @else {
					<mat-checkbox
						[color]="(store|storeToState:loadedMedicalStoreStates:prepareModel$)?.state?.touchedByUser ? 'accent' : 'primary'"
						class="view-check"
						[checked]="((store|storeToStateIsChecked:prepareModel$:loadedMedicalStoreStates:selectedUserId:'view')|async) ?? false"
						(change)="viewChecked($event.checked, store)">&nbsp;
					</mat-checkbox>
				}
			}
		</ng-template>

		<ng-template column="userCanEdit" let-store="model" [service]="medicalStoreService">
			@if (loadedMedicalStoreStates.get(store.id)) {
				@if ((store|storeToState:loadedMedicalStoreStates)?.state?.inheritedEdit || !(store|storeToState:loadedMedicalStoreStates)?.data?.permissionToEdit) {
					<mat-checkbox
							[color]="(store|storeToState:loadedMedicalStoreStates:prepareModel$)?.state?.touchedByUser ? 'accent' : 'primary'"
							class="edit-check" [checked]="(store|storeToState:loadedMedicalStoreStates:prepareModel$)?.state?.inheritedEdit ?? false || (store|storeToState:loadedMedicalStoreStates:prepareModel$)?.state?.canEdit ?? false" [disabled]="true"
              #tooltip="matTooltip"
							[matTooltip]="(store|storeToState:loadedMedicalStoreStates:prepareModel$)?.data?.permissionToEdit ? ('medicalStoreUser.inheritedPermission'|translate): ('medicalStoreUser.missingPermission'|translate)"
							matTooltipPosition="right">
					</mat-checkbox>
				} @else {
					<mat-checkbox
							[color]="(store|storeToState:loadedMedicalStoreStates:prepareModel$)?.state?.touchedByUser ? 'accent' : 'primary'"
							class="edit-check" [checked]="(store|storeToState:loadedMedicalStoreStates:prepareModel$)?.state?.canEdit ?? false"
							(change)="editChecked($event.checked, store)"
              #tooltip="matTooltip"
							[matTooltip]="'medicalStoreUser.canEdit' | translate"
							matTooltipPosition="right">
					</mat-checkbox>
				}
			} @else {
				@if ((store|canEditStorePermission:loadedMedicalStoreStates:selectedUserId |async) === false || disable) {
					<mat-checkbox
							[color]="(store|storeToState:loadedMedicalStoreStates:prepareModel$)?.state?.touchedByUser ? 'accent' : 'primary'"
							class="edit-check" [checked]=" ((store|storeToStateIsChecked:prepareModel$:loadedMedicalStoreStates:selectedUserId:'edit')|async) ?? false" [disabled]="true"
              #tooltip="matTooltip"
							[matTooltip]="'medicalStoreUser.missingPermission'| translate"
							matTooltipPosition="right">
					</mat-checkbox>
				} @else {
					<mat-checkbox
							[color]="(store|storeToState:loadedMedicalStoreStates:prepareModel$)?.state?.touchedByUser ? 'accent' : 'primary'"
							class="edit-check" [checked]="((store|storeToStateIsChecked:prepareModel$:loadedMedicalStoreStates:selectedUserId:'edit')|async) ?? false"
							(change)="editChecked($event.checked, store)"
              #tooltip="matTooltip"
							[matTooltip]="'medicalStoreUser.canEdit' | translate"
							matTooltipPosition="right">
					</mat-checkbox>
				}
			}
		</ng-template>
	</portal-table-server-side-searchable>
</div>
