<portal-table
	[headers]="tableHeaders"
	[data]="data"
	[baseLink]="baseLink"
	[getRowLink]="getBaseLinkFunction"
	[currentPage]="displayPage"
	(currentPageChange)="showPage($event)"
	[hasNextPage]="currentPage?.hasNextPage() === true"
	[isLoading]="isLoading"
	[withMenu]="withMenu"
	[customMenu]="customMenu"
/>
