import {PropertyInterface} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
} from '@angular/core';
import {UrlType} from '@app/main';

interface MailInterface {
	email: PropertyInterface<string | undefined>;
}

@Component({
	selector:    'portal-common-show-email',
	templateUrl: './common-show-email.component.html',
	styleUrls:   ['./common-show-email.component.scss'],
})
export class CommonShowEmailComponent<T extends MailInterface> {
	@Input({required: true}) model!: T;
	urlType = UrlType;
}
