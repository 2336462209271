import {ResultPageModel} from '@angular-helpers/frontend-api';
import {
	ChangeDetectorRef,
	Component,
	OnInit,
} from '@angular/core';
import {environment} from '@app/environment';
import {IconService} from '@app/main';
import {
	ContractingPartyModel,
	ContractingPartyService,
} from '@contracts/frontend-api';

@Component({
	templateUrl: './contracting-party-page-list.component.html',
	styleUrls:   ['./contracting-party-page-list.component.scss'],
})
export class ContractingPartyPageListComponent implements OnInit {
	canCreateParty = false;
	contractingParties?: Promise<ResultPageModel<ContractingPartyModel>>;

	constructor(
		protected contractingPartyService: ContractingPartyService,
		public iconService: IconService,
		private readonly changeDetection: ChangeDetectorRef,
	) {

	}

	get environment(): typeof environment {
		return environment;
	}

	ngOnInit(): void {
		ContractingPartyModel.permissionsClass.canCreate({})
							 .then((canCreate) => {
								 this.canCreateParty = canCreate;
								 this.changeDetection.markForCheck();
							 });

		this.contractingParties = this.contractingPartyService.getPage(0, {pageSize: 50});
	}
}
