@if (model) {
	<portal-headline
		label="model.institutionskennzeichen"
		[menu]="menu"
		[backlink]="environment.medicalStoresFullUrl +'/'+ (model.medicalStore.value | async)?.id"
	>
		<mat-menu #menu>
			@if (permissions.canCreatePreQualificationCertificate) {
				<button mat-menu-item [matMenuTriggerFor]="menuCreate">
					<fa-icon matMenuItemIcon [icon]="iconService.CREATE"></fa-icon>
					<span>{{ 'general.menu.create' | translate }}</span>
					<mat-menu #menuCreate>
						@if (permissions.canCreatePreQualificationCertificate) {
							<a
								mat-menu-item
								[routerLink]="[environment.preQualificationCertificateFullUrl, 'create']"
							>
								<fa-icon matMenuItemIcon [icon]="iconService.PRE_QUALIFICATION_CERTIFICATE"></fa-icon>
								<span>{{ 'model.preQualificationCertificate' | translate }}</span>
							</a>
						}
					</mat-menu>
				</button>
			}
			@if (canRead$ | async) {
				<a
					(click)="createDownload()"
					mat-menu-item
				>
					<fa-icon matMenuItemIcon [icon]="iconService.FILE_DOWNLOAD"></fa-icon>
					<span>{{ 'contractAccession.actions.export' | translate }}</span>
				</a>
			}
			@if ((hasNoDependencies$ | async) === true && permissions.canDelete) {
				<button mat-menu-item (click)="showDeleteDialog()">
					<fa-icon matMenuItemIcon [icon]="iconService.DELETE"></fa-icon>
					<span>{{ 'actions.delete' | translate }}</span>
				</button>
			}
		</mat-menu>
	</portal-headline>
	<div class="flex-container">
		<portal-institutionskennzeichen-card-master-data [institutionskennzeichen]="model"/>
		<portal-pre-qualification-certificate-list-card [prefillData]="pqcPrefillData" [preQualificationCertificates]="model.preQualificationCertificates.value | async"/>
		<portal-contract-accession-card-list [contractAccessions]="model.contractAccessions.value | async"/>
	</div>
}
