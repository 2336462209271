<portal-headline
	label="medicalStore.create.title.iqzParticipation"
	[automaticBacklink]="true"
/>

<portal-loading [loading]="control == null">
	@if (control) {
		<mat-card>
			<mat-card-content>
				<mat-stepper orientation="vertical" [linear]="true" [formGroup]="stepperFormGroup" #stepper>
					<ng-template matStepperIcon="edit" let-index="index">{{ index + 1 }}</ng-template>
					<ng-template matStepperIcon="done" let-index="index">{{ index + 1 }}</ng-template>
					<ng-template matStepperIcon="error" let-index="index">{{ index + 1 }}</ng-template>
					<mat-step [stepControl]="stepperFormGroup.controls.stepOne">
						<ng-template matStepLabel>{{ 'iqzParticipation.create.step1' | translate }}</ng-template>
						<div class="inline">
							<div class="grid-2">
								<portal-edit-field
									fill
									label="iqzParticipation.externalId"
									[showAlways]="true"
									[validator]="stepperFormGroup.controls.stepOne.controls.externalId"
									(change)="loadTrainingCourseByExternalId()"
								/>
							</div>
							<span class="divider"> {{ 'contractAccessing.dimension.divider' | translate }} </span>
							<div class="grid-2">
								<portal-edit-field-date
									label="iqzParticipation.date"
									[showIfNotEditable]="true"
									[validator]="stepperFormGroup.controls.stepOne.controls.date"
									(change)="loadTrainingCoursesByDate()"
								/>
							</div>
						</div>
						<ng-container *ngTemplateOutlet="stepperActions"/>
					</mat-step>
					<mat-step [stepControl]="stepperFormGroup.controls.trainingCourse">
						<ng-template matStepLabel>{{ 'iqzParticipation.create.step2' | translate }}</ng-template>
						@if (trainingCourses == null || trainingCourses.length < 1) {
							<span>{{ 'iqzParticipation.create.noTrainingsFound' | translate }}</span>
						}
						<div class="trainingCourses">
							@for (trainingCourse of trainingCourses;track trainingCourse) {
								<mat-card class="clickable" [class.selected]="stepperFormGroup.controls.trainingCourse.value === trainingCourse" (click)="selectTrainingCourse(trainingCourse)">
									<mat-card-content class="grid-2">
										<portal-iqz-training-course-show-external-id [model]="trainingCourse"/>
										<portal-iqz-training-course-show-title [model]="trainingCourse"/>
										<portal-iqz-training-course-show-start-at [model]="trainingCourse"/>
										<portal-iqz-training-course-show-end-at [model]="trainingCourse"/>
									</mat-card-content>
								</mat-card>
							}
						</div>
						<ng-container *ngTemplateOutlet="stepperActions"/>
					</mat-step>
					<mat-step [stepControl]="stepperFormGroup.controls.category">
						<ng-template matStepLabel>{{ 'iqzParticipation.create.step3' | translate }}</ng-template>
						<div class="grid-2">
							<portal-iqz-training-course-edit-category
								(change)="stepper.next()"
								[control]="stepperFormGroup"/>
						</div>
						<ng-container *ngTemplateOutlet="stepperActions"/>
					</mat-step>
					<mat-step>
						<ng-template matStepLabel>{{ 'iqzParticipation.create.step4' | translate }}</ng-template>
						@if (stepperFormGroup.controls.trainingCourse.value;as trainingCourse) {
							<div class="grid-2">
								<portal-iqz-training-course-show-title [model]="trainingCourse"/>
								<portal-iqz-training-course-show-start-at [model]="trainingCourse"/>
								<portal-iqz-training-course-show-iqz-training-course-points [model]="trainingCourse"/>
								<portal-iqz-training-course-show-iqz-workshop-points [model]="trainingCourse"/>
								<portal-iqz-training-course-show-iqz-training-course-points-exam [model]="trainingCourse"/>
								@if (stepperFormGroup.controls.category.value;as value) {
									<portal-show-field
										label="iqzTrainingCourse.category"
										[value]="value.name.value | async">
									</portal-show-field>
								}
							</div>
						}
						<div class="stepper-actions">
							<button mat-button matStepperPrevious>{{ 'actions.previous' | translate }}</button>
							<portal-spinning-button (click)="saveForm()" [processing]="isSaving">{{ 'actions.createNow' | translate }}</portal-spinning-button>
						</div>
					</mat-step>
					<ng-template #stepperActions let-step>
						<div class="stepper-actions">
							<button mat-button matStepperPrevious>{{ 'actions.previous' | translate }}</button>
							<button [disabled]="step?.valid === false" mat-button color="primary" matStepperNext>{{ 'actions.next' | translate }}</button>
						</div>
					</ng-template>
				</mat-stepper>
			</mat-card-content>
		</mat-card>
	}
</portal-loading>