import {
	Component,
	Input,
} from '@angular/core';
import {PreQualificationCertificateModel} from '@contracts/frontend-api';

@Component({
	selector:    'portal-pre-qualification-certificate-show-certificate-identifier',
	templateUrl: './pre-qualification-certificate-show-certificate-identifier.component.html',
	styleUrls:   ['./pre-qualification-certificate-show-certificate-identifier.component.scss'],
})
export class PreQualificationCertificateShowCertificateIdentifierComponent {
	@Input({required: true}) model!: PreQualificationCertificateModel;
}
