import {Level8Error} from '@angular-helpers/frontend-api';
import {
	Component,
	Input,
} from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
} from '@angular/forms';
import {FormHelperService} from '@app/main';

@Component({
	selector:  'portal-medical-store-edit-supply-area',
	templateUrl: './medical-store-edit-supply-area.component.html',
	styleUrls: ['./medical-store-edit-supply-area.component.scss'],
})
export class MedicalStoreEditSupplyAreaComponent {
	@Input({
		required: true,
		alias:    'control',
	}) parent!: UntypedFormGroup;


	constructor(
		protected readonly formHelperService: FormHelperService,
	) {
	}

	get control(): UntypedFormControl {
		const fieldName = 'supplyArea';
		const control   = this.parent.get(fieldName);
		if(control instanceof UntypedFormControl)
			return control;

		throw new Level8Error(`Unexpected type for field ${fieldName} - expected '${UntypedFormControl.name}' got '${typeof control}' (${control})`);
	}
}
