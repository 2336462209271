<portal-model-editable-box
	[cancelButtonText]="undefined"
	[enableContentGrid]="false"
	[formHelper]="null"
	[hasEditButton]="permissions.canUpdate"
	[hasHeader]="hasHeader"
	[icon]="iconService.FILES"
	headline="baseContract.files"
	saveButtonText="actions.ok"
>
	@if (relationModel) {
		<ng-template #showModeContent>
			<div class="showMode" *ngLet="(publishedFiles$ | async) as publishedFiles">
				<portal-loading [loading]="publishedFiles == null">
					@if (publishedFiles) {
						@for (type of categories;track type) {
							<ng-container *ngLet="publishedFiles.get(type.name) as files">
								@if (files != null && files.length > 0) {
									<section>
										<h4>{{ 'file.types.' + type.name | translate }}</h4>
										@for (file of files;track file) {
											@if (file.isPublishable.value | async) {
												<portal-show-file [showValidityStartAt]="true" [label]="null" [file]="file"/>
											}
										}
									</section>
									<hr/>
								}
							</ng-container>
						}
					}
				</portal-loading>
			</div>
		</ng-template>
	}

	<ng-template #editModeContent let-control>
		<div class="editMode" *ngLet="(filesMap$ | async) as files">
			<portal-loading [loading]="files == null">
				@if (files) {
					@for (type of categories;track type) {
						<section *ngLet="files.get(type.name) as files">
							<h4>{{ 'file.types.' + type.name | translate }}</h4>
							@for (file of files;track file) {
								<div class="entry" [class.disabled]="!publish" *ngLet="file.isPublishable.value | async as publish">
									<span>{{ file.description.value | async }}</span>
									<span class="validityStartAt">{{ file.validityStartAt.value | async | date }}</span>
									@if (permissions.canUpdate) {
										<div class="icon clickable" (click)="editFile(file)">
											<fa-icon [icon]="iconService.EDIT"></fa-icon>
										</div>
									} @else {
										<div></div>
									}
									@if (permissions.canDelete) {
										<div class="icon clickable" (click)="showDeleteDialog(file)">
											<fa-icon [icon]="iconService.DELETE"></fa-icon>
										</div>
									} @else {
										<div></div>
									}
								</div>
							}
							@if (relationModel) {
								<portal-file-create-upload [type]="type.name" [relation]="relationModel"/>
							}
						</section>
						<hr/>
					}
				}
			</portal-loading>
		</div>
	</ng-template>

</portal-model-editable-box>


<ng-template #editModeTemplateFiles let-control>
	<portal-file-edit-is-publishable [control]="control"/>
	<portal-file-edit-name [control]="control"/>
	<portal-file-edit-description [control]="control"/>
	<portal-file-edit-validity-start-at [control]="control"/>
</ng-template>
