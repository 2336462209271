@if (control) {
	<portal-editable [label]="label" [validator]="control">
		<mat-form-field>
			<portal-input-status-icon matSuffix [validator]="control"/>
			<mat-select [formControl]="control" [placeholder]="placeholder ?? ''" (selectionChange)="onSelected($event)">
				@if (filter) {
					<mat-option>
						<ngx-mat-select-search [formControl]="filterControl">
							<fa-icon ngxMatSelectSearchClear [icon]="iconService.CANCEL"/>
						</ngx-mat-select-search>
					</mat-option>
				}
				@if (options != null) {
					@if (inherited) {
						<mat-option [value]="null" class="flex">{{ inherited.value }}
							<span class="hint">{{ 'input.inheritEntry' | translate }}</span>
						</mat-option>
					}
					@if (isEmpty) {
						<mat-option [value]="undefined">{{ ((label != null) ? 'general.noOptions' : 'general.noData') | translate: {model: label} }}</mat-option>
					}
					@if (nullable && inherited == null) {
						<mat-option [value]="null">{{ 'general.nullOption' | translate }}</mat-option>
					}
					@if ((filteredOptions$ | async);as options) {
						@for (option of options | keyvalue : sortByValue;track trackOptions($index, option)) {
							<mat-option [value]="option.key">{{ option.value }}</mat-option>
						}
					}
				} @else {
					@if (initialEntryData;as entry) {
						<mat-option [value]="entry.value">{{ entry.label }}</mat-option>
					}
					<mat-option value="loading" disabled>
						<div class="loading">
							<mat-spinner class="spinner" diameter="20"></mat-spinner>
							<span>{{ 'general.loading' | translate }}</span>
						</div>
					</mat-option>
				}
				<ng-template #loading>
					@if (initialEntryData;as entry) {
						<mat-option [value]="entry.value">{{ entry.label }}</mat-option>
					}
					<mat-option value="loading" disabled>
						<div class="loading">
							<mat-spinner class="spinner" diameter="20"></mat-spinner>
							<span>{{ 'general.loading' | translate }}</span>
						</div>
					</mat-option>
				</ng-template>
			</mat-select>
			@for (error of formHelperService.getErrors(control) | keyvalue;track error) {
				<mat-error>
					{{ 'input.error.' + error.key | translate: error.value }}
				</mat-error>
			}
		</mat-form-field>
	</portal-editable>
}
