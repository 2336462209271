<div class="fixedWidth">
	<h1 class="gradient">{{ 'general.calculationManuals' | translate }}</h1>

	<mat-card>
		<mat-card-content>
			<p>In Zusammenarbeit mit dem Wirtschaftsausschuss und den fachspezifisch gebildeten Arbeitsgruppen hat der BIV-OT Kalkulationshandbücher zu den nachstehenden Produktgruppen erarbeitet und aktualisiert diese laufend unter Berücksichtigung des aktuellen Hilfsmittelverzeichnisses, der am Markt befindlichen Herstellerlisteneinkaufspreise sowie dem aktuellen Stand der Versorgungstechnik.</p>
			<p>
				<em>Bei den Handbüchern handelt es sich immer um Kalkulationsbeispiele, die den Leistungserbringern als Orientierung dienen sollen. Sie können die eigenverantwortliche Kalkulation des Orthopädie-Technikers NICHT ersetzen. Es werden keine bestimmten Preise oder Preisbestandteile empfohlen. Das Handbuch soll dabei helfen, unter Berücksichtigung der besonderen Verhältnisse des Betriebes und des wirtschaftlichen Umfelds individuell richtig zu kalkulieren. Die Handbücher sind zur ausschließlichen Verwendung im GKV-System vorgesehen und sind nicht für das PKV-System gedacht. </em>
			</p>
			<p>Eine Weitergabe an Dritte, Bearbeitung, Vervielfältigung oder Reproduktion, Distribution, Veröffentlichung oder öffentliche Zugänglichmachung, gleichgültig ob in digitaler oder analoger Form, ist Ihnen nicht gestattet.</p>

			<p>
				<strong>Aktuelle Stundenverrechnungssätze für vertraglich nicht geregelte Positionen laut Handbuch</strong>
			</p>
			<ul id="price-list">
				<li>72,60 €/Stunde:</li>
				<ul>
					<li>PG 05 Bandagen</li>
					<li>PG 17 Hilfsmittel zur Kompressionstherapie - phlebologisch und lymphatisch</li>
					<li>PG 23 konfektionierte Orthesen</li>
					<li>PG 37 Brustprothetik</li>
				</ul>
				<li>95,40 €/Stunde:</li>
				<ul>
					<li>PG 08 Einlagen</li>
					<li>PG 09/23 Muskelstimulation</li>
					<li>PG 17 Hilfsmittel zur Kompressionstherapie - Narbenkompression</li>
					<li>PG 23 teilkonfektionierte und individuelle Orthesen</li>
					<li>PG 24 Beinprothetik</li>
					<li>PG 26 Sitzschalen</li>
				</ul>
				<li>109,20 €/Stunde:</li>
				<ul>
					<li>PG 24 Osseointegration</li>
					<li>PG 38 Armprothetik</li>
				</ul>
			</ul>

			<mat-accordion>
				<mat-expansion-panel>
					<mat-expansion-panel-header>
						<mat-panel-title>Hinweise zur Nutzung der Kalkulationshandbücher</mat-panel-title>
					</mat-expansion-panel-header>
					<p>Für die hier erstellten Kalkulationsbeispiele wurden Herstellerlisteneinkaufspreise recherchiert und als Grundlage für die Kalkulationen verwendet. Bei freien Kalkulationen mit dem hinterlegten Kalkulationsschema verweisen wir ebenfalls stets auf die jeweils gültigen Herstellerlisteneinkaufspreise.</p>
					<p>Bitte beachten Sie, dass die hier dargestellten Kalkulationsbeispiele mit dem oben genannten Stundenverrechnungssatz kalkuliert worden sind. Sollten Sie die Produkte über einen bestehenden Vertrag abrechnen wollen, in dem kein Vertragspreis vereinbart wurde, wenden Sie bitte den jeweils für den Vertrag gültigen Stundenverrechnungssatz an.</p>
					<p>Darüber hinaus bitten wir Sie zu berücksichtigen, dass es sich bei den hier dargestellten Positionen ohne offizielle Hilfsmittelnummer um von uns vergebene Pseudonummern handelt. Bitte benutzen Sie zur Abrechnung die im jeweiligen Vertrag mit den Kostenträgern hinterlegte Positionsnummer für freie Kalkulationen. Sollte der jeweilige Vertrag keine Regelung für freie Kalkulationen enthalten, erfragen Sie die Pseudonummer bitte direkt bei dem betroffenen Kostenträger. Sollte der Kostenträger Ihnen keine Positionsnummern bzw. geeignete Vorgehensweise nennen können, reichen Sie bitte Ihren Kostenvoranschlag per Papier ein, damit dieser nicht automatisch elektronisch abgelehnt wird.</p>
				</mat-expansion-panel>
			</mat-accordion>
		</mat-card-content>
	</mat-card>

	@if ((relationModel.permissions.canRead | async) !== false) {
		<portal-model-files-card [relationModel]="relationModel" [categories]="publishedCategories" [hasHeader]="false"/>
	}
	@if ((relationModelPreview.permissions.canRead | async) !== false) {
		<portal-model-files-card [relationModel]="relationModelPreview" [categories]="publishedPreviewCategories" [hasHeader]="false"/>
	}

	<mat-card>
		<mat-card-content>

			<p>
				<strong>Für unser Handbuch PG 24 Osseointegration wenden Sie sich bitte per E-Mail an:
					<a href="mailto:vertraege@biv-ot.org" obfuscate>vertraege&#64;biv-ot.org</a></strong>
			</p>
		</mat-card-content>
	</mat-card>
</div>
