@if (value !== undefined && !(hideIfNull && value === null)) {
	@if (label || icon) {
		<label>
			@if (icon) {
				<fa-icon [icon]="icon"></fa-icon>
			}
			{{ label | translate }}
		</label>
	}
	@if (value === true) {
		<span>
      {{ labelTrue | translate }}
			<ng-content select=".trueLabel"></ng-content>
    </span>
	}
	@if (value === false) {
		<span>
      {{ labelFalse | translate }}
			<ng-content select=".falseLabel"></ng-content>
    </span>
	}
	@if (value === null) {
		<span>
      {{ nullLabel }}
			<ng-content select=".nullLabel"></ng-content>
    </span>
	}
}
