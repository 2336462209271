import {
	Component,
	Input,
} from '@angular/core';
import {EmployeeModel} from '@contracts/frontend-api';
import {TranslateService} from '@ngx-translate/core';
import {
	combineLatest,
	Observable,
	of,
} from 'rxjs';
import {mergeMap} from 'rxjs/operators';

@Component({
	selector:    'portal-employee-show-name',
	templateUrl: './employee-show-name.component.html',
	styleUrls:   ['./employee-show-name.component.scss'],
})
export class EmployeeShowNameComponent {
	@Input({required: true}) model!: EmployeeModel;

	constructor(
		protected readonly translateService: TranslateService,
	) {
	}

	get fullName(): Observable<string | undefined> {
		return combineLatest([
			this.model.name.value,
			this.model.salutation.value,
		])
			.pipe(mergeMap(([name, salutation]) => {
				if(name == null || salutation == null)
					return of(undefined);

				return this.translateService.get(`employee.salutations.fullName.${salutation}`, {name});
			}));
	}
}
