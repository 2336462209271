<mat-card>
	<mat-card-header class="logo">
		<fa-icon [icon]="iconService.MEDICAL_STORE" mat-card-avatar></fa-icon>
		<mat-card-title>{{ label | translate }}</mat-card-title>
	</mat-card-header>
	
	<mat-card-content>
		<portal-medical-store-list [data]="medicalStores"/>
	</mat-card-content>
</mat-card>
