import {
	Component,
	Input,
} from '@angular/core';
import {IconService} from '@app/main';
import {ContractModel} from '@contracts/frontend-api';

@Component({
	selector:    'portal-contract-card-list',
	templateUrl: './contract-card-list.component.html',
	styleUrls:   ['./contract-card-list.component.scss'],
})
export class ContractCardListComponent {
	@Input({required: true}) contracts?: ContractModel[] | null;
	@Input() label = 'model.contracts';

	constructor(
		public iconService: IconService,
	) {
	}
}
