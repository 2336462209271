<portal-table-client-side-searchable
	[headers]="tableHeaders"
	[searchFilter]="searchFilter"
	[data]="participations | async"
	[pageSize]="5"
	[storeSearch]="false"
>
	<ng-template column="trainingCourse" let-model="model" [data]="data">
		<portal-skeleton-field [content]="getTrainingCourse$(model)"/>
	</ng-template>

	<ng-template column="category" let-model="model" [data]="data">
		<portal-skeleton-field [content]="getCategory$(model)"/>
	</ng-template>

	<ng-template column="points" let-model="model" [data]="data">
		<ng-container *ngLet="getPoints$(model) | async as points">
			<portal-skeleton-field [loading]="model === null">
				{{ points }}
				@if (points === 0) {
					<fa-icon
						class="help"
						[icon]="iconService.QUESTION"
						[matTooltip]="'iqzTrainingCourse.isOutdated' | translate"
					/>
				}
			</portal-skeleton-field>
		</ng-container>
	</ng-template>

	<ng-template column="participationDate" let-model="model" [data]="data">
		<portal-skeleton-field [content]="getParticipationDate(model) | async | date"/>
	</ng-template>

</portal-table-client-side-searchable>