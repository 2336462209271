<portal-editable
	[label]="'externalContractNumber.modelType.'+type"
	[validator]="entriesForm"
>
	<div class="wrapper">
		<div [formGroup]="form" class="ecn-grid-container">
			<div class="ecn-headers">
				<div class="ecn-name">{{ 'externalContractNumber.number' | translate }}</div>
				<div class="ecn-description">{{ 'externalContractNumber.description' | translate }}</div>
			</div>
			@for (ecId of inheritedNumbers;track ecId) {
				<div class="ecn-grid-row ecn-placeholder-list">
					<span class="ecn-name">{{ ecId.number }}</span>
					<span class="ecn-description">{{ ecId.description }}</span>
					<button matSuffix mat-icon-button class="ecn-edit-button hidden">
						<fa-icon [icon]="iconService.EDIT"></fa-icon>
					</button>
				</div>
			}
			@for (control of entriesForm.controls;track control;let i = $index) {
				<form #autoFocus formArrayName="entries" class="ecn-grid-row" [class.deleting]="isDeleting(control)">
					@if (isFormGroup(control) && isEditing(control) && canEditExternalContractNumber) {
						<ng-container [formGroupName]="i">
							<mat-form-field *ngLet="control.get('number') as control" class="ecn-name">
								<input matInput [formControl]="control" [placeholder]="'externalContractNumber.modelType.' + type | translate"/>
								<portal-input-status-icon matSuffix [validator]="control"/>
								@for (error of formHelperService.getErrors(control) | keyvalue;track error) {
									<mat-error>
										{{ 'input.error.' + error.key | translate: error.value }}
									</mat-error>
								}
							</mat-form-field>
							<mat-form-field *ngLet="control.get('description') as control" class="ecn-description">
								<input matInput type="text" [formControl]="control" [placeholder]="'externalContractNumber.description' | translate"/>
								<portal-input-status-icon matSuffix [validator]="control"/>
								@for (error of formHelperService.getErrors(control) | keyvalue;track error) {
									<mat-error>
										{{ 'input.error.' + error.key | translate: error.value }}
									</mat-error>
								}
							</mat-form-field>
						</ng-container>
					} @else {
						<div class="ecn-name" [class.disabled]="!canEditExternalContractNumber" [innerText]="control.get('number')?.value | formatExternalContractNumbers: type"></div>
						<div class="ecn-description" [class.disabled]="!canEditExternalContractNumber" [innerText]="control.get('description')?.value"></div>
					}
					@if (!(isEditing(control) || isDeleting(control)) && canEditExternalContractNumber) {
						<button
							matSuffix mat-icon-button (click)="startEditing(control)" class="ecn-edit-button"
						>
							<fa-icon [icon]="iconService.EDIT"></fa-icon>
						</button>
					}
					@if (canEditExternalContractNumber) {
						<ng-container *ngLet="isDeleting(control) as isDeleting">
							<button
								matSuffix mat-icon-button (click)="setDeleting($event, control, !isDeleting)" class="ecn-delete-button"
							>
								<fa-icon [icon]="isDeleting ? iconService.STOP_DELETE : iconService.DELETE"></fa-icon>
							</button>
						</ng-container>
					}
				</form>
			}
		</div>
		<mat-accordion [hideToggle]="true">
			@if (canEditExternalContractNumber) {
				<mat-expansion-panel [(expanded)]="adding">
					<mat-expansion-panel-header>{{ 'general.collection.add' | translate }}</mat-expansion-panel-header>
					<mat-form-field class="search-field">
						<input matInput [formControl]="searchField" [matAutocomplete]="autocomplete" #searchInput [placeholder]="'externalContractNumber.search' | translate: {type}"/>
						<button matSuffix mat-icon-button class="search">
							<fa-icon matSuffix [spin]="isSearching" [icon]="isSearching? iconService.LOADING_SPINNER: iconService.SEARCH"></fa-icon>
						</button>
						@for (error of formHelperService.getErrors(searchField) | keyvalue;track error) {
							<mat-error>
								{{ 'input.error.' + error.key | translate: error.value }}
							</mat-error>
						}
						<mat-autocomplete #autocomplete>
							@for (ecnNumber of searchResults$ | async;track ecnNumber) {
								<mat-option [value]="undefined" (onSelectionChange)="add(ecnNumber, true)">
									@if (isExternalContractNumber(ecnNumber)) {
										<div class="searchEntry">
											<span class="value">{{ ecnNumber.number.value | formatExternalContractNumbers: (ecnNumber.type.value | async) | async }}</span>
											<span class="suffix">{{ ecnNumber.description.value | async }}</span>
										</div>
									} @else {
										<div class="searchEntry">
											<span class="value">{{ ecnNumber }}</span>
											<span matSuffix class="suffix">{{ 'general.collection.newEntry' | translate }}</span>
										</div>
									}
								</mat-option>
							}
						</mat-autocomplete>
					</mat-form-field>
				</mat-expansion-panel>
			}
		</mat-accordion>
		@if (errorHasOccurred) {
			<portal-form-error [error]="errorHasOccurred" [control]="control"/>
		}
	</div>
</portal-editable>