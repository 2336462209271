import {
	Component,
	DestroyRef,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {environment} from '@app/environment';
import {
	AbstractShowComponent,
	combineLatestSafe,
	InstitutionskennzeichenDownloadService,
} from '@app/main';
import {
	InstitutionskennzeichenModel,
	InstitutionskennzeichenService,
	PreQualificationCertificateDtoInterface,
} from '@contracts/frontend-api';
import {
	Observable,
	of,
} from 'rxjs';
import {
	map,
	mergeMap,
} from 'rxjs/operators';

@Component({
	selector:    'portal-institutionskennzeichen-page-show',
	templateUrl: './institutionskennzeichen-page-show.component.html',
	styleUrls:   ['./institutionskennzeichen-page-show.component.scss'],
})
export class InstitutionskennzeichenPageShowComponent extends AbstractShowComponent<InstitutionskennzeichenModel, InstitutionskennzeichenService> {
	protected readonly environment = environment;
	protected readonly permissions = {
		canCreatePreQualificationCertificate: false,
		canDelete:                            false,
		canRead:                              false,
	};
	protected errorOccurred?: Error;

	constructor(
		modelService: InstitutionskennzeichenService,
		private readonly institutionskennzeichenDownloadService: InstitutionskennzeichenDownloadService,
		protected readonly destroyRef: DestroyRef,
	) {
		super(modelService);
	}

	get canDelete$(): Promise<boolean> {
		return this.getModel().permissions.canDelete;
	}

	get canRead$(): Promise<boolean> {
		return this.getModel().permissions.canRead;
	}

	get canCreatePreQualificationCertificate$(): Observable<boolean | undefined> | undefined {
		return this.model?.medicalStore.value.pipe(
			mergeMap(medicalStore => {
				if(medicalStore == null)
					return of(medicalStore);

				return medicalStore.institutionskennzeichens.value;
			}),
			mergeMap(iks => combineLatestSafe(iks?.map(ik => ik.preQualificationCertificates.permissions.canCreate))),
			map(permissions => permissions?.includes(true)),
			takeUntilDestroyed(this.destroyRef),
		);
	}

	get pqcPrefillData(): Partial<PreQualificationCertificateDtoInterface> {
		return {
			institutionskennzeichen: this.getModel(),
		};
	}

	get hasNoDependencies$(): Observable<boolean> {
		return this.getModel()
		           .contractAccessions
		           .mapToObservable(contractAccessions => (contractAccessions == null) ? false : (contractAccessions.length < 1));
	}

	public createDownload(): Promise<void> {
		if(this.model == null)
			return Promise.resolve();

		return this.institutionskennzeichenDownloadService.downloadExport(this.model)
		           .catch((error) => {
			           this.errorOccurred = error;
			           throw error;
		           });
	}

	protected async postDeleteRedirect(): Promise<void> {
		const medicalStore = await this.model?.medicalStore.firstValue;
		return AbstractShowComponent.redirectToPageOrList(
			this.router,
			environment.medicalStoresFullUrl,
			medicalStore?.id,
		);
	}

	protected getModelName(): Promise<string | undefined> {
		return this.getModel().number.firstValue;
	}


	protected async onNewModel(newModel: InstitutionskennzeichenModel, oldModel?: InstitutionskennzeichenModel): Promise<void> {
		await super.onNewModel(newModel, oldModel);

		this.canCreatePreQualificationCertificate$?.subscribe(isPermitted => this.permissions.canCreatePreQualificationCertificate = isPermitted ?? false);
		this.canDelete$.then(isPermitted => this.permissions.canDelete = isPermitted);
	}
}
