import {
	Component,
	Input,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
} from '@angular/forms';

@Component({
	selector:    'portal-export-edit-standard-delay',
	templateUrl: './export-edit-standard-delay.component.html',
	styleUrls:   ['./export-edit-standard-delay.component.scss'],
})
export class ExportEditStandardDelayComponent implements OnChanges {
	@Input({required: true}) form!: UntypedFormGroup;
	@Input() fieldName = 'standardDelay';

	public field?: UntypedFormControl;

	ngOnChanges(changes: SimpleChanges): void {
		const field = this.form.get(this.fieldName);
		if(!(field instanceof UntypedFormControl))
			return;

		this.field = field;
	}
}
