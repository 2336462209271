import {
	Component,
	Input,
} from '@angular/core';
import {
	ContractModel,
	ContractSectionModel,
	MasterContractModel,
} from '@contracts/frontend-api';
import {CategoryItemInterface} from '../../../file/files-card/model-files-card.component';

@Component({
	selector:    'portal-base-contract-card-files',
	templateUrl: './base-contract-card-files.component.html',
	styleUrls:   ['./base-contract-card-files.component.scss'],
})
export class BaseContractCardFilesComponent {
	@Input({required: true}) contract?: MasterContractModel | ContractModel | ContractSectionModel;
	readonly categories: CategoryItemInterface[] = [
		{
			name: 'masterContract',
		},
		{
			name: 'contract',
		},
		{
			name: 'other',
		},
	];
}
