import {PhoneHelper} from '@angular-helpers/frontend-api';
import {
	Pipe,
	PipeTransform,
} from '@angular/core';

@Pipe({
	name: 'phone',
})
export class PhonePipe implements PipeTransform {
	transform(phone: string | null | undefined): string {
		if(phone == null)
			return '';

		return PhoneHelper.formatForDisplay(phone);
	}
}
