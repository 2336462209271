import {
	Component,
	Input,
} from '@angular/core';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';

@Component({
	selector:    'portal-show-field-date',
	templateUrl: './show-field-date.component.html',
	styleUrls:   ['./show-field-date.component.scss'],
})
export class ShowFieldDateComponent {
	@Input({required: true}) label!: string;
	@Input({required: true}) value?: Date | null;
	@Input() icon?: IconDefinition;
	@Input() showValue = false;
}
