<form class="flex-container">
	<mat-form-field class="col-12">
		<mat-label>{{ 'address.name' | translate }}</mat-label>
		<input type="text" matInput [formControl]="nameControl"/>
		@for (error of formHelperService.getErrors(nameControl) | keyvalue;track error) {
			<mat-error>
				{{ 'input.error.' + error.key | translate: error.value }}
			</mat-error>
		}
		<portal-input-status-icon matSuffix [validator]="nameControl"/>
	</mat-form-field>

	<mat-form-field class="col-10">
		<mat-label>{{ 'address.street' | translate }}</mat-label>
		<input type="text" matInput [formControl]="streetControl"/>
		@for (error of formHelperService.getErrors(streetControl) | keyvalue;track error) {
			<mat-error>
				{{ 'input.error.' + error.key | translate: error.value }}
			</mat-error>
		}
		<portal-input-status-icon matSuffix [validator]="streetControl"/>
	</mat-form-field>

	<mat-form-field class="col-2">
		<mat-label>{{ 'address.houseNumber' | translate }}</mat-label>
		<input type="text" matInput [formControl]="houseNumberControl"/>
		@for (error of formHelperService.getErrors(houseNumberControl) | keyvalue;track error) {
			<mat-error>
				{{ 'input.error.' + error.key | translate: error.value }}
			</mat-error>
		}
		<portal-input-status-icon matSuffix [validator]="houseNumberControl"/>
	</mat-form-field>

	<mat-form-field class="col-4">
		<mat-label>{{ 'address.zip' | translate }}</mat-label>
		<input type="text" matInput [formControl]="zipControl"/>
		@for (error of formHelperService.getErrors(zipControl) | keyvalue;track error) {
			<mat-error>
				{{ 'input.error.' + error.key | translate: error.value }}
			</mat-error>
		}
		<portal-input-status-icon matSuffix [validator]="zipControl"/>
	</mat-form-field>

	<mat-form-field class="col-8">
		<mat-label>{{ 'address.town' | translate }}</mat-label>
		<input type="text" matInput [formControl]="townControl"/>
		@for (error of formHelperService.getErrors(townControl) | keyvalue;track error) {
			<mat-error>
				{{ 'input.error.' + error.key | translate: error.value }}
			</mat-error>
		}
		<portal-input-status-icon matSuffix [validator]="townControl"/>
	</mat-form-field>

	<!--	TODO: implement google maps -->
</form>
