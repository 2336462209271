export class StringHelper {
	protected readonly words: readonly string[] = [];

	protected constructor(words: readonly string[]) {
		this.words = words;
	}

	static fromKebabCase(kebabCaseString: string): StringHelper {
		const words = kebabCaseString.split('-');
		return new StringHelper(words);
	}

	static fromCamelCase(camelCaseString: string): StringHelper {
		const words = camelCaseString
			.split(/(?<=[a-z])(?=[A-Z])|(?<=[A-Z])(?=[A-Z][a-z])/)
			.map(word => word.toLowerCase());

		return new StringHelper(words);
	}

	static fromScreamingCase(screamingCaseString: string): StringHelper {
		const words = screamingCaseString.toLowerCase().split('_');
		return new StringHelper(words);
	}

	static fromPascalCase(pascalCaseString: string): StringHelper {
		return this.fromCamelCase(pascalCaseString);
	}

	toCamelCase(): string {
		return this.words.map((word, index) => {
			if(index === 0)
				return word;

			if(word.length < 1)
				return word;

			return word[0].toUpperCase() + word.slice(1);
		}).join('');
	}

	toPascalCase(): string {
		const camelCase = this.toCamelCase();

		if(camelCase.length < 1)
			return camelCase;

		return camelCase[0].toUpperCase() + camelCase.slice(1);
	}

	toKebabCase(): string {
		return this.words.join('-');
	}

	toScreamingCase(): string {
		return this.words.map(x => x.toUpperCase()).join('_');
	}
}
