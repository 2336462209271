import {
	DtoEditFormHelper,
	InheritMergedProperty,
	ModelInterface,
	ModelNameHelper,
	PermissionedPropertyInterface,
} from '@angular-helpers/frontend-api';
import {HttpErrorResponse} from '@angular/common/http';
import {
	Component,
	DestroyRef,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {MatDialogRef} from '@angular/material/dialog';
import {
	BaseDialogData,
	combineLatestSafe,
	ConfirmDialogAnswer,
	ConfirmDialogConfig,
	DialogService,
	IconService,
} from '@app/main';
import {
	FileDtoModel,
	FileModel,
	FileService,
} from '@contracts/frontend-api';
import {
	BehaviorSubject,
	combineLatest,
	Observable,
	Subscription,
} from 'rxjs';
import {
	map,
	mergeMap,
	shareReplay,
	tap,
} from 'rxjs/operators';

interface FileRelationModelInterface extends ModelInterface {
	files: PermissionedPropertyInterface<FileModel[] | undefined>;
}

// declare type PipeType = [FileModel, (string | undefined), (boolean | undefined), (Date | undefined)][] | undefined;
interface PipeType {
	file: FileModel,
	type: string | undefined;
	isPublishable: boolean | undefined;
	description: string | undefined;
	validityStartAt: Date | undefined;
	name: string | undefined;
	createdAt: Date | undefined;
}

export interface CategoryItemInterface {
	name: string;
}

@Component({
	selector:    'portal-model-files-card',
	templateUrl: './model-files-card.component.html',
	styleUrls:   ['./model-files-card.component.scss'],
})
export class ModelFilesCardComponent implements OnChanges, OnInit {
	@ViewChild('editModeTemplateFiles') readonly editPopupContent!: TemplateRef<unknown>;
	publishedFiles$                                  = new BehaviorSubject<Map<string, FileModel[]> | null>(null);
	filesMap$                                        = new BehaviorSubject<Map<string, FileModel[]> | null>(null);
	readonly permissions                             = {
		canUpdate: false,
		canDelete: false,
	};
	@Input({required: true}) categories: CategoryItemInterface[] = [];
	@Input({required: true}) relationModel?: FileRelationModelInterface;
	@Input() hasHeader                                           = true;
	@Input() sortBy: Exclude<keyof PipeType, 'file'> = 'description';
	@Input() sortDirection: 'asc' | 'desc' = 'asc';
	protected filesSubscription?: Subscription;

	constructor(
		protected readonly iconService: IconService,
		private readonly dialogService: DialogService,
		private readonly fileService: FileService,
		private readonly destroyRef: DestroyRef,
	) {
	}

	ngOnInit(): void {
		this.loadFileComponents();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(this.categories.length > 0 && this.relationModel != null)
			this.loadFileComponents();
	}

	editFile(file: FileModel): void {
		const formHelper = DtoEditFormHelper.create(FileDtoModel, file, this.fileService);
		formHelper.control.then((control) => {
			let isSaving = false;

			let errorHasOccurred: Error | undefined           = undefined;
			let editDialog: MatDialogRef<unknown> | undefined = undefined;
			const data: BaseDialogData                        = {
				icon:              this.iconService.FILES,
				formHelper,
				headline:          'file.edit',
				content:           this.editPopupContent,
				acceptText:        'actions.save',
				cancelButtonText:  'actions.cancel',
				enableContentGrid: true,
				error:             errorHasOccurred,
				control,
			};

			const closeEditDialog                 = () => {
				editDialog?.close();
				editDialog = undefined;
			};
			const scrollToFirstInvalidFormControl = () => {
				const firstElementWithError: HTMLElement | null =
					      this.editPopupContent.elementRef.nativeElement.parentElement.querySelector('.ng-invalid');
				firstElementWithError?.scrollIntoView({
					behavior: 'smooth',
					block:    'center',
					inline:   'center',
				});
			};

			const save: () => Promise<void> = async () => {
				if(isSaving)
					return;

				const test = await formHelper.isInvalid();
				if(test) {
					scrollToFirstInvalidFormControl();
					return;
				}

				isSaving = true;
				try {
					await formHelper.save();
					closeEditDialog();
					errorHasOccurred = undefined;
				} catch(error) {
					if(error instanceof Error || error instanceof HttpErrorResponse || error === undefined)
						errorHasOccurred = error;
					else errorHasOccurred = new Error(`${error}`);

					throw error;
				} finally {
					isSaving = false;
				}
			};

			const abortEditing = async (forceClose = false): Promise<void> => {
				if(!forceClose && (await formHelper.control).dirty) {
					const confirmDialogData: ConfirmDialogConfig = {
						labelPositiv:  'system.abortChangesDialog.labelPositiv',
						labelNegative: 'system.abortChangesDialog.labelNegative',
						title:         'system.abortChangesDialog.title',
						message:       'system.abortChangesDialog.message',
						icon:          this.iconService.DIALOG_ATTENTION,
					};

					const confirmDialog = this.dialogService.openConfirmDialog(confirmDialogData);
					confirmDialog.afterClosed().subscribe((answer) => {
						if(answer === ConfirmDialogAnswer.negative)
							abortEditing(true);
					});

					return;
				}

				closeEditDialog();
				await formHelper.reset();
			};

			data.save   = save.bind(this);
			data.cancel = abortEditing.bind(this);

			editDialog = this.dialogService.openBaseDialog({
				data,
				minWidth: 'min-content',
			});
		});
	}

	async showDeleteDialog(file: FileModel): Promise<void> {
		const name = (await file.description.firstValue) ?? 'Unbekannt';

		let modelType = ModelNameHelper.fromObject(file).asBaseName();
		modelType     = modelType.replace(/^(.)/, (firstChar) => firstChar.toLowerCase());
		modelType     = `model.${modelType}`;

		this.dialogService.openDeleteDialog(name, modelType, () => this.fileService.delete(file));
	}

	loadFileComponents(): void {
		if(this.relationModel == null)
			return;

		this.relationModel.files.permissions.canUpdate
		    .then(isPermitted => this.permissions.canUpdate = isPermitted);
		this.relationModel.files.permissions.canDelete
		    .then(isPermitted => this.permissions.canDelete = isPermitted);

		const ownFiles$ = this.relationModel.files.value.pipe(
			takeUntilDestroyed(this.destroyRef),
			map(file => file ?? []),
		);
		let files$: Observable<FileModel[]> | undefined;

		if(this.relationModel.files instanceof InheritMergedProperty)
			files$ = this.relationModel.files.withParent.value;

		this.filesSubscription?.unsubscribe();

		const ownFiles2$ = this.fileData$(ownFiles$).pipe(shareReplay(1));

		this.filesSubscription = this.fileData$(files$ ?? ownFiles$)
		                             .pipe(
			                             mergeMap(fileList => ownFiles2$.pipe(map(own => [
				                             own,
				                             fileList,
			                             ]))),
			                             tap(([ownFiles, fileList]) => {
				                             const publishable = new Map<string, FileModel[]>();
				                             this.sortAndFilterPublishableToMap(fileList, publishable);
				                             this.sortAndFilterPublishableToMap(
					                             ownFiles,
					                             publishable,
					                             new Map<string, FileModel[]>(),
				                             );
			                             }),
		                             )
		                             .subscribe();
	}

	sortAndFilterPublishableToMap(resolvedArray: PipeType[] | undefined, publishable: Map<string, FileModel[]>, total?: Map<string, FileModel[]>): void {
		resolvedArray?.sort((a, b) => {
			const left  = this.sortDirection === 'asc' ? a[this.sortBy] : b[this.sortBy];
			const right = this.sortDirection === 'asc' ? b[this.sortBy] : a[this.sortBy];

			if(left === right)
				return 0;

			if(left == null || right == null)
				return -1;

			if(left instanceof Date && right instanceof Date)
				return left.getTime() - right.getTime();

			if(typeof left === 'string' && typeof right === 'string')
				return left.localeCompare(right);

			if(typeof left === 'boolean' && typeof right === 'boolean')
				return (left ? 1 : 0) - (right ? 1 : 0);

			throw new Error(`Unexpected type. Left: ${typeof left}, Right: ${typeof right}`);
		});

		resolvedArray?.forEach(x => {
			if(x.type == null)
				return;

			if(total != null) {
				let fileTypeList = total.get(x.type);

				if(!Array.isArray(
					fileTypeList))
					fileTypeList = [];

				fileTypeList.push(x.file);
				total.set(x.type, fileTypeList);
			}

			let publishedTypeList = publishable.get(x.type);
			if(!Array.isArray(publishedTypeList))
				publishedTypeList = [];

			if(x.isPublishable === true) {
				if(!publishedTypeList.includes(x.file)) {
					publishedTypeList.push(x.file);
					publishable.set(x.type, publishedTypeList);
				}
			}
		});

		this.publishedFiles$.next(publishable);

		if(total != null)
			this.filesMap$.next(total);
	}

	fileData$(files$: Observable<FileModel[] | undefined>): Observable<PipeType[] | undefined> {
		return files$.pipe(
			map((files) => {
				if(files == null)
					return files;

				return files.map((file) =>
						combineLatest([
							file.type.value,
							file.isPublishable.value,
							file.description.value,
							file.validityStartAt.value,
							file.name.value,
							file.createdAt.value,
						]).pipe(
							map(([type, isPublishable, description, validityStartAt, name, createdAt]) => ({
								file,
								type,
								isPublishable,
								description,
								validityStartAt,
								name,
								createdAt,
							})),
						)
					,
				);
			}),

			mergeMap((value) => combineLatestSafe(value)),
		);
	}
}
