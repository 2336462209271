import {
	Component,
	Inject,
} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';

export interface ConfirmDialogConfig {
	labelPositiv: string;
	labelNegative?: string;
	title: string;
	message: string;
	icon?: IconDefinition;
}

export enum ConfirmDialogAnswer {
	positive,
	negative,
	unknown,
}

@Component({
	selector:    'portal-confirm-dialog',
	templateUrl: './confirm-dialog.component.html',
	styleUrls:   ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
	readonly RETURN_POSITIVE = ConfirmDialogAnswer.positive;
	readonly RETURN_NEGATIVE = ConfirmDialogAnswer.negative;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogConfig,
	) {
	}
}
