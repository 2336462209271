import {
	Component,
	Input,
} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';

@Component({
	selector:  'portal-news-edit-date-field',
    templateUrl: './news-edit-date-field.component.html',
	styleUrls: ['./news-edit-date-field.component.scss'],
})
export class NewsEditDateFieldComponent {
    @Input({required: true}) control?: UntypedFormControl;
	label = 'news.displayStartAt';
}
