import {
	Component,
	Input,
} from '@angular/core';
import {IconService} from '@app/main';
import {
	InstitutionskennzeichenModel,
	InstitutionskennzeichenService,
} from '@contracts/frontend-api';


@Component({
	selector:    'portal-institutionskennzeichen-card-master-data',
	templateUrl: './institutionskennzeichen-card-master-data.component.html',
	styleUrls:   ['./institutionskennzeichen-card-master-data.component.scss'],
})
export class InstitutionskennzeichenCardMasterDataComponent {
	@Input({required: true}) institutionskennzeichen?: InstitutionskennzeichenModel;

	constructor(
		public readonly institutionskennzeichenService: InstitutionskennzeichenService,
		public iconService: IconService,
	) { }
}
