<portal-model-editable-box
	[icon]="iconService.MASTER_CONTRACT"
	headline="general.masterData"
	[formHelper]="formHelper"
	[hasEditButton]="canUpdate | async"
>

	@if (masterContract) {
		<ng-template #showModeContent>
			<portal-base-contract-status [model]="masterContract"/>
			<portal-base-contract-show-name [model]="masterContract"/>
			<portal-base-contract-show-validity-start [model]="masterContract"/>
			<portal-base-contract-show-terminated-at [model]="masterContract"/>
			<portal-base-contract-show-minimum-term [model]="masterContract"/>
			<portal-base-contract-show-notice-period-negotiations [model]="masterContract"/>
			<portal-base-contract-show-period-of-validity [model]="masterContract"/>
			<portal-base-contract-show-negotiators [model]="masterContract"/>
			<portal-base-contract-show-initial-signer [model]="masterContract"/>
			<portal-base-contract-show-awarded-mandates [model]="masterContract"/>
		</ng-template>
	}

	<ng-template #editModeContent let-control>
		<portal-base-contract-edit-name [control]="control"/>
		<portal-base-contract-edit-validity-start [model]="masterContract" [control]="control"/>
		<portal-base-contract-edit-terminated-at [model]="masterContract" [control]="control"/>
		<portal-base-contract-edit-minimum-term [control]="control"/>
		<portal-base-contract-edit-notice-period-negotiations [control]="control"/>
		<portal-base-contract-edit-period-of-validity [control]="control"/>
		<portal-base-contract-edit-negotiators [control]="control"/>
		<portal-base-contract-edit-initial-signer [control]="control"/>
		<portal-base-contract-edit-mandate-grantor [control]="control"/>
	</ng-template>

</portal-model-editable-box>
