import {
	Component,
	Input,
} from '@angular/core';
import {ContractAccessionListComponent} from '@app/contracts';
import {IconService} from '@app/main';

@Component({
	selector:    'portal-contract-accession-card-list',
	templateUrl: './contract-accession-card-list.component.html',
	styleUrls:   ['./contract-accession-card-list.component.scss'],
})
export class ContractAccessionCardListComponent {
	@Input({required: true}) contractAccessions!: ContractAccessionListComponent['data'];
	@Input() label: string | null = 'model.contractAccessions';
	@Input() hideIfEmpty          = true;

	constructor(
		public iconService: IconService,
	) {
	}

	isArray(value: unknown): value is unknown[] {
		return Array.isArray(value);
	}
}
